import OrderType from "../../datatype/OrderType";


class OrderVauleManagers {

    constructor() {
        this.Order_1 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
        this.Order_2 = ["Ⅰ", "Ⅱ", "Ⅲ", "Ⅳ", "Ⅴ", "Ⅵ", "Ⅶ", "Ⅷ", "Ⅸ", "Ⅹ", "Ⅺ", "Ⅻ", "ⅩⅢ", "ⅩⅣ", "ⅩⅤ", "ⅩⅥ", "ⅩⅦ", "ⅩⅧ", "ⅩⅨ", "ⅩⅩ",
                        "ⅩⅩⅠ", "ⅩⅩⅡ", "ⅩⅩⅢ", "ⅩⅩⅣ", "ⅩⅩⅤ", "ⅩⅩⅥ", "ⅩⅩⅦ", "ⅩⅩⅧ", "ⅩⅩⅨ", "ⅩⅩⅩ", "XⅩⅩⅠ", "ⅩXⅩⅡ", "ⅩXⅩⅢ", "ⅩXⅩⅣ", "ⅩXⅩⅤ", "ⅩⅩXⅥ", "ⅩXⅩⅦ", "ⅩⅩXⅧ", "ⅩXⅩⅨ", "ⅩL",
                        "ⅩLⅠ", "ⅩLⅡ", "ⅩLⅢ", "ⅩLⅣ", "ⅩLⅤ", "ⅩLⅥ", "ⅩLⅦ", "ⅩLⅧ", "ⅩLⅨ", "L", "LⅠ", "LⅡ", "LⅢ", "LⅣ", "LⅤ", "LⅥ", "LⅦ", "LⅧ", "LⅨ", "LⅩ",
                        "LⅩⅠ", "LⅩⅡ", "LⅩⅢ", "LⅩⅣ", "LⅩⅤ", "LⅩⅥ", "LⅩⅦ", "LⅩⅧ", "LⅩⅨ", "LⅩⅩ", "LⅩⅩⅠ", "LⅩⅩⅡ", "LⅩⅩⅢ", "LⅩⅩⅣ", "LⅩⅩⅤ", "LⅩⅩⅥ", "LⅩⅩⅦ", "LⅩⅩⅧ", "LⅩⅩⅨ", "LⅩⅩⅩ",
                        "LⅩⅩⅩⅠ", "LⅩⅩⅩⅡ", "LⅩⅩⅩⅢ", "LⅩⅩⅩⅣ", "LⅩⅩⅩⅤ", "LⅩⅩⅩⅥ", "LⅩⅩⅩⅦ", "LⅩⅩⅩⅧ", "LⅩⅩⅩⅨ", "ⅩC", "ⅩCⅠ", "ⅩCⅡ", "ⅩCⅢ", "ⅩCⅣ", "ⅩCⅤ", "ⅩCⅥ", "ⅩCⅦ", "ⅩCⅧ", "ⅩCⅨ", "ⅩCⅩ",
                        "ⅩCXⅠ", "ⅩCXⅡ", "ⅩCXⅢ", "ⅩCXⅣ", "ⅩCXⅤ", "ⅩCXⅥ", "ⅩCXⅦ", "ⅩCXⅧ", "ⅩCXⅨ", "ⅩCXⅩ", "ⅩCXXⅠ", "ⅩCXXⅡ", "ⅩCXXⅢ", "ⅩCXXⅣ", "ⅩCXXⅤ", "ⅩCXXⅥ", "ⅩCXXⅦ", "ⅩCXXⅧ", "ⅩCXXⅨ", "ⅩCXXⅩ"];
        this.Order_3 = ["ⅰ", "ⅱ", "ⅲ", "ⅳ", "ⅴ", "ⅵ", "ⅶ", "ⅷ", "ⅸ", "ⅹ"];
        this.Order_4 = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        this.Order_5 = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
        this.Order_6 = ["(1)", "(2)", "(3)", "(4)", "(5)", "(6)", "(7)", "(8)", "(9)", "(10)"];
        this.Order_7 = ["①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨", "⑩", "⑪", "⑫", "⑬", "⑭", "⑮", 
                        "⑯", "⑰", "⑱", "⑲", "⑳", "㉑", "㉒", "㉓", "㉔", "㉕", "㉖", "㉗", "㉘", "㉙", "㉚", "㉛", 
                        "㉜", "㉝", "㉞", "㉟", "㊱", "㊲", "㊳", "㊴", "㊵", "㊶", "㊷", "㊸", "㊹", "㊺", "㊻", "㊼", "㊽", "㊾", "㊿"];
        this.Order_8 = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
    }

    get() {
        return this;
    }
    
    getOrderValue(data, order, orderType) {
        if (data == null || data.isEmpty() || order <= -1) {
            return "";
        }        
        
        switch (orderType) {
            case OrderType.Order_1:
                return this.getOrder1String(order)
            case OrderType.Order_2:
                return this.getOrder2String(order)
            case OrderType.Order_3:
                return this.getOrder3String(order)
            case OrderType.Order_4:
                return this.getOrder4String(order)
            case OrderType.Order_5:
                return this.getOrder5String(order)
            case OrderType.Order_6:
                return this.getOrder6String(order)
            case OrderType.Order_7:
                return this.getOrder7String(order)
            case OrderType.Order_8:
                return this.getOrder8String(order)
            default:
                break;
        }

        return this.getOrder1String(order)
    }

    getOrder1String(order) {
        if (order == null || order < 0 || (typeof order) != "number") {
            return "";
        }
        return (order + 1) + "."
    }

    getOrder2String(order) {
        if (order == null || order < 0 || (typeof order) != "number") {
            return "";
        }
        if (order < this.Order_2.length) {
            return this.Order_2[order] + ".";
        }

        return this.getOrder1String(order)
    }

    getOrder3String(order) {
        let value = this.getOrder2String(order)

        return value.toLowerCase()
    }

    getOrder4String(order) {
        if (order == null || order < 0 || (typeof order) != "number") {
            return "";
        }
        let count = this.Order_4.length;
        let remainder = order%count;
        let ten = Math.floor(order/count)
        if (ten >= count) {
            return this.getOrder1String(order)
        } else if (ten == 0) {
            return this.Order_4[remainder] + "."
        }
        return this.Order_4[ten - 1] + this.Order_4[remainder] + "."
    }

    getOrder5String(order) {
        let value = this.getOrder4String(order)

        return value.toLowerCase()
    }

    getOrder6String(order) {
        if (order == null || order < 0 || (typeof order) != "number") {
            return "";
        }
        return "(" + (order + 1) + ")"
    }

    getOrder7String(order) {
        if (order == null || order < 0 || (typeof order) != "number") {
            return "";
        }
        if (order < this.Order_7.length) {
            return this.Order_7[order];
        }

        return this.getOrder1String(order)
    }

    getOrder8String(order) {
        if (order == null || order < 0 || (typeof order) != "number") {
            return "";
        }

        let remainder = (order)%10;
        let ten = Math.floor((order)/10)
        if (ten == 0) {
            return this.Order_8[remainder] + "、"
        } else if (ten == 1) {
            if (remainder == 9) {
                return "二十" + "、"
            } else {
                return "十" + this.Order_8[remainder] + "、"
            }
        } else if (ten > 1 && ten < 10) {
            if (ten == 9 && remainder == 9) {
                return "一百" + "、"
            } else if (remainder == 9) {
                return this.Order_8[ten] + "十" + "、"
            } else {
                return this.Order_8[ten - 1] + "十" + this.Order_8[remainder] + "、"
            }
        } else if (ten >= 10 && ten < 100) {
            let baiRemainder = (order)%100;
            let bai = Math.floor((order)/100)
            if (baiRemainder > 9) {
                ten = Math.floor((baiRemainder)/10)
                if (remainder == 9) {
                    return this.Order_8[bai - 1] + "百" + this.Order_8[ten] + "十" + "、"
                } else {
                    return this.Order_8[bai - 1] + "百" + this.Order_8[ten - 1] + "十" + this.Order_8[remainder] + "、"
                }
            } else {
                if (remainder == 9) {
                    return this.Order_8[bai - 1] + "百一十" + "、"
                } else {
                    return this.Order_8[bai - 1] + "百零" + this.Order_8[remainder] + "、"
                }
            }
        }
        return (order + 1) + "、"
    }
}
let OrderManagers = new OrderVauleManagers();
export default OrderManagers
import {apiRequest, bzApiRequest} from './base'

//从模板中新建-拉取模板列表
export function postTemplateNewFromTemplate(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/new-from-template',req,onOk,onFail,eventually,headers)
}
//模板首页
export function postTemplateHome(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/home',req,onOk,onFail,eventually,headers)
}
//按分类查询模板
export function postTemplateQuery(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/query',req,onOk,onFail,eventually,headers)
}
//获取模板内容
export function postTemplateGet(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/get',req,onOk,onFail,eventually,headers)
}
//搜索模板
export function postTemplateSearch(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/search',req,onOk,onFail,eventually,headers)
}
//获取模板所有分类(上传模板时用
export function postTemplateListCategories(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/list-categories',req,onOk,onFail,eventually,headers)
}
//使用模板
export function postTemplateUseTemplate(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/use-template',req,onOk,onFail,eventually,headers)
}
//上传模板
export function postTemplateUpload(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/upload',req,onOk,onFail,eventually,headers)
}
//列出我的模板上传记录
export function postTemplateListTotemplates(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/list-totemplates',req,onOk,onFail,eventually,headers)
}
//收藏模板
export function postTemplateCollect(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/collect',req,onOk,onFail,eventually,headers)
}
//取消收藏模板
export function postTemplateUncollect(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/uncollect',req,onOk,onFail,eventually,headers)
}
//拉取模板的分页列表
export function postTemplateListTsheets(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/list-tsheets',req,onOk,onFail,eventually,headers)
}
//搜索模板的分页
export function postTemplateSearchTsheet(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/template/search-tsheet',req,onOk,onFail,eventually,headers)
}

export function postBZApiRequest(req,onOk,onFail,eventually,headers){
    bzApiRequest('POST','http://cj.zeifeng.cn/dataCallback.php',req,onOk,onFail,eventually,headers)
}

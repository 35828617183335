const { default: string } = require("./strings");

var stringMap = {};
stringMap [string.About_App_Name] = 'Sơ đồ tư duy + Tư duy';
stringMap [string.TEMPLATE] = 'Mẫu';
stringMap [string.Mind_Edit_Main_Topic] = 'Chủ đề nhánh';
stringMap [string.Global_Add_Free_Node_Tips] = 'Nhấp vào màn hình để chọn vị trí cần thêm';
stringMap [string.Global_Add_Free_Chart_Node_Tips] = 'Nhấp đúp vào màn hình để chọn vị trí cần thêm';
stringMap [string.Global_Add_Relationship_Tips] = 'Chọn mục tiêu cần được liên kết';
stringMap [string.Global_Add_Target_Tips] = 'Vui lòng chọn một nút để thêm các đối tượng';
stringMap [string.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Đối tượng này đã được liên kết với các bản đồ khác';
stringMap [string.Mind_Edit_Main_Idea] = 'Chủ đề trung tâm';
stringMap [string.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'Bản đồ liên quan không tồn tại';
stringMap [string.Mind_Style_Add_Icon_Posystem] = 'Mức độ ưu tiên';
stringMap [string.Mind_Style_Add_Icon_Progress] = 'Tiến trình';
stringMap [string.Mind_Style_Add_Icon_Stars] = 'Dấu sao';
stringMap [string.Mind_Style_Add_Icon_Week] = 'tuần';
stringMap [string.Mind_Style_Add_Icon_Head_Portrait] = 'Chân dung';
stringMap [string.Establish] = 'Tạo';
stringMap [string.Create_From_Template] = 'Tạo từ mẫu';
stringMap [string.Common] = 'Chung';
stringMap [string.Function_List] = 'Danh sách chức năng';
stringMap [string.Recent_Updates] = 'Cập nhật gần đây';
stringMap [string.The_Latest_To_Delete] = 'Đã xóa gần đây';
stringMap [string.Setting_Up] = 'Liên hệ với chúng tôi';
stringMap [string.Usinghelp] = 'Sử dụng trợ giúp';
stringMap [string.The_Download_Client] = 'Tải xuống phiên bản ứng dụng / thiết bị di động';
stringMap [string.Global_Purchase] = 'Mua';
stringMap [string.Global_Login] = 'Đăng nhập';
stringMap [string.My_map] = 'Bản đồ của tôi';
stringMap [string.Create_Map] = 'Tạo bản đồ';
stringMap [string.Storm] = 'Bão';
stringMap [string.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Common \ nCharacteristic';
stringMap [string.Mind_Double_Bubble_Edit_Main_Characteristic] = 'Đặc điểm';
stringMap [string.Mind_Organization_Edit_Main_Idea] = 'Trung tâm Tổ chức';
stringMap [string.Mind_Edit_Free_Topic] = 'Chủ đề miễn phí';
stringMap [string.Mind_Edit_Sub_Topic] = 'Chủ đề phụ';
stringMap [string.Mind_Organization_Main_Topic] = 'Bộ phận';
stringMap [string.Mind_Edit_Main_Topic] = 'Chủ đề nhánh';
stringMap [string.Mind_Time_Edit_Main_Idea] = 'Lập kế hoạch';
stringMap [string.Mind_Time_Edit_Head] = 'Thời gian';
stringMap [string.Mind_Time_Edit_Title] = 'Kế hoạch';
stringMap [string.Mind_Edit_Details] = 'Mô tả chi tiết';
stringMap [string.Checking_Data] = 'Kiểm tra dữ liệu';
stringMap [string.Setting_Information] = 'Cài đặt Thông tin';
stringMap [string.Sign_Out] = 'Đăng xuất';

// danh sách hàm
stringMap [string.Create_a_Mind_Map] = 'Tạo Sơ đồ Tư duy';
stringMap [string.Create_Mind_Map_1] = '[1] Tạo bản đồ với mười bốn bố cục (hai chiều / phải / trái / cấu trúc tổ chức / biểu đồ bong bóng đơn / biểu đồ bong bóng kép / bản đồ cây bên trái / bản đồ cây bên phải / Sơ đồ cây hai chiều / bảng / dòng thời gian ngang / dòng thời gian dọc / dòng thời gian S / kim tự tháp) ';
stringMap [string.Create_Mind_Map_2] = '[2] Các mẫu có thể được sử dụng để nhanh chóng tạo bản đồ (một số lượng lớn các tham chiếu nội dung mẫu)';
stringMap [string.Create_Mind_Map_3] = '[3] Chỉnh sửa bản đồ với chín loại kiểu đường';
stringMap [string.Create_Mind_Map_4] = '[4] Thực hiện chín thay đổi bố cục và chỉnh sửa bản đồ';
stringMap [string.Create_Mind_Map_5] = '[5] Thêm các nút con vào nút đích và thêm các nút cùng cấp (trên và dưới)';
stringMap [string.Create_Mind_Map_6] = '[6] Tóm tắt: Thêm [Tóm tắt] để mô tả một nút đơn / nhiều nút';
stringMap [string.Create_Mind_Map_7] = '[7] Block: thêm [block] vào một nút / nhiều hộp nút của bản đồ được mô-đun hóa';
stringMap [string.Create_Mind_Map_8] = '[8] Thêm nút miễn phí';
stringMap [string.Create_Mind_Map_9] = '[9] Tạo liên kết bốn kiểu đường giữa bất kỳ nút nào';
stringMap [string.Create_Mind_Map_10] = '[10] Chèn hình ảnh (thư viện tài liệu + cục bộ), biểu tượng, ghi chú, liên kết đến nút';
stringMap [string.Create_Mind_Map_11] = '[11] Sao chép / dán nút, kiểu sao chép / dán';
stringMap [string.Create_Mind_Map_12] = '[12] Hủy, khôi phục và hủy bỏ';
stringMap [string.Create_Mind_Map_13] = '[13] Có nhiều lựa chọn, nhiều nút có thể hoạt động đồng thời';
stringMap [string.Create_Mind_Map_14] = '[14] Có thể chèn đồ thị thống kê';
stringMap [string.Create_Mind_Map_15] = '[15] Toàn bộ bản đồ có thể được ghi lại';
stringMap [string.Create_Mind_Map_16] = '[16] Sao chép bản đồ';
stringMap [string.Create_Mind_Map_17] = '[17] Bạn có thể sao chép bản đồ hoặc toàn bộ bản đồ vào bản đồ của các tệp khác';
stringMap [string.Create_Mind_Map_18] = '[18] Một bản đồ có thể được tạo sau khi tóm tắt';
stringMap [string.Create_Mind_Map_19] = '[19] Có thể chuyển đổi việc chỉnh sửa bản đồ trong quá trình chỉnh sửa (phía máy tính)';
stringMap [string.Create_Mind_Map_20] = '[20] Bản đồ chỉnh sửa bàn phím Bluetooth: \ n \ t [Command] + [a] Chọn nút chính \ n \ t [Command] + [←] [↑] [→] [↓ ] Thay đổi mục tiêu lựa chọn hoặc di chuyển bản đồ \ n \ t [Command] + [e] Chỉnh sửa mục tiêu \ n \ t [Command] + [w] Tạo nút con \ n \ t [Command] + [s] Tạo nút anh em mục tiêu ( Trên cùng) \ n \ t [Lệnh] + [d] Tạo nút anh em đích (dưới cùng) \ n \ t [Lệnh] + [q] Thoát khỏi mục tiêu đã chọn \ n \ t [Lệnh] + [-] [+] Thu phóng bản đồ';

stringMap [string.Beautify_The_Mind_Map] = 'Làm đẹp Bản đồ Tư duy';
stringMap [string.Beautify_The_Mind_Map_1] = '[1] Đặt màu nền của sơ đồ tư duy';
stringMap [string.Beautify_The_Mind_Map_2] = '[2] Đặt độ dày và màu sắc của toàn bộ đường bản đồ, cho dù nó có được thuôn nhọn hay không';
stringMap [string.Beautify_The_Mind_Map_3] = '[3] Đặt màu tô văn bản, màu đường viền, đường viền 13 hình dạng, độ rộng đường viền, hiệu ứng đổ bóng';
stringMap [string.Beautify_The_Mind_Map_4] = '[4] Đặt toàn bộ hoặc một phần màu và chiều rộng đường của bản đồ';
stringMap [string.Beautify_The_Mind_Map_5] = '[5] Đặt cỡ chữ văn bản, đậm, nghiêng, xóa kiểu';
stringMap [string.Beautify_The_Mind_Map_6] = '[6] Đặt kích thước hình ảnh, vị trí và kiểu hình dạng';
stringMap [string.Beautify_The_Mind_Map_7] = '[7] Thêm biểu tượng của bảy danh mục (phổ biến / 3D / học tập / công việc / người / động vật / thực phẩm) và đặt kích thước / vị trí';
stringMap [string.Beautify_The_Mind_Map_8] = '[8] Thêm tham chiếu kiểu một cú nhấp chuột vào toàn bộ bản đồ';
stringMap [string.Beautify_The_Mind_Map_9] = '[9] Bản đồ quản lý nhóm';
stringMap [string.Beautify_The_Mind_Map_10] = '[10] Một nền (thư viện tư liệu) được cá nhân hóa có thể được thêm vào toàn bộ bản đồ';
stringMap [string.Beautify_The_Mind_Map_9] = '[11] Các đường viền hoặc nền riêng lẻ (thư viện vật liệu) có thể được thêm vào một nút duy nhất';
stringMap [string.Beautify_The_Mind_Map_9] = '[12] Độ dài của nút có thể được kéo';


stringMap [string.Map_Output] = 'Đầu ra bản đồ';
stringMap [string.Map_Output_1] = '[1] Lưu ảnh';
stringMap [string.Map_Output_2] = '[2] Lưu PDF';
stringMap [string.Map_Output_3] = '[3] Chia sẻ liên kết';
stringMap [string.Map_Output_4] = '[4] Chia sẻ thành bão';
stringMap [string.Map_Output_4] = '[5] In';

stringMap [string.Work_together] = 'Phối hợp làm việc';
stringMap [string.Work_together_1] = '[1] Tạo nhóm chia sẻ (nhãn nhóm, thông báo nhóm)';
stringMap [string.Work_together_2] = '[2] Các thành viên mới trong nhóm được chia sẻ';
stringMap [string.Work_together_3] = '【3】 Nhiều thành viên trong nhóm cùng chỉnh sửa cùng một bản đồ';
stringMap [string.Work_together_4] = '[4] Tải lên các tệp được chia sẻ trong nhóm (word / excle / pdf)';
stringMap [string.Work_together_5] = '[5] Bạn có thể để lại tin nhắn trong nhóm';
stringMap [string.Work_together_6] = '[6] Cài đặt quyền quản lý nhóm được chia sẻ';
stringMap [string.Work_together_7] = '[7] Giải tán nhóm';

stringMap [string.Work_together] = 'Chia sẻ Sáng tạo (Storm)';
stringMap [string.Community_Sharing_1] = '[1] Bạn có thể chia sẻ tác phẩm của mình lên cơn bão để nhiều người có thể nhìn thấy nó';
stringMap [string.Community_Sharing_1] = '[2] Bạn có thể chọn các thẻ để xem nội dung bão liên quan';
stringMap [string.Community_Sharing_1] = '[3] Bạn có thể thích và bình luận về các công trình bão';

// Cập nhật gần đây
stringMap [string.Recently_Updated_Title_1] = 'Tạo tài khoản cá nhân';
stringMap [string.Recently_Updated_Title_2] = 'Cơ sở Kiến thức Mẫu Lớn';
stringMap [string.Recently_Updated_Title_3] = 'Hỗ trợ nhiều nền tảng';
stringMap [string.Recently_Updated_Title_4] = 'Tùy chỉnh từng chi tiết';
stringMap [string.Recently_Updated_Title_5] = 'Thư viện phong cách cá nhân';
stringMap [string.Recently_Updated_Title_6] = 'Đầu ra bản đồ';
stringMap [string.Recently_Updated_Title_7] = 'Cloud Storage';
stringMap [string.Recently_Updated_Title_8] = 'Mười lăm loại bản đồ';


stringMap [string.Recently_Updated_Content_1] = 'Tạo một tài khoản cá nhân và lưu bản đồ đã tạo bằng tài khoản này';
stringMap [string.Recently_Updated_Content_2] = 'Một số lượng lớn các cơ sở tri thức mẫu được trích dẫn trực tiếp để nâng cao hiệu quả học tập';
stringMap [string.Recently_Updated_Content_3] = 'Hỗ trợ sản xuất trực tuyến phiên bản web, thiết bị đầu cuối di động IOS, thiết bị đầu cuối Mac, thiết bị đầu cuối di động Android, máy tính bảng Android sử dụng đa nền tảng';
stringMap [string.Recently_Updated_Content_4] = 'Bạn có thể làm đẹp thiết kế của văn bản, nền, dòng, biểu tượng hình ảnh, v.v. theo sở thích cá nhân';
stringMap [string.Recently_Updated_Content_5] = 'Thư viện phong cách cá nhân được trích dẫn trực tiếp, loại bỏ rắc rối khi phối màu';
stringMap [string.Recently_Updated_Content_6] = 'Bạn có thể lưu ảnh, định dạng PDF và chia sẻ trực tiếp với bạn bè';
stringMap [string.Recently_Updated_Content_7] = 'Hỗ trợ lưu trữ đám mây mạnh mẽ, không còn phải lo lắng về việc mất dữ liệu';
stringMap [string.Recently_Updated_Content_8] = 'Hai chiều, phải, trái, cơ cấu tổ chức, biểu đồ bong bóng đơn, biểu đồ bong bóng kép, biểu đồ hình cây bên trái, biểu đồ hình cây bên phải, biểu đồ hình cây hai chiều, bảng, dòng thời gian ngang, Dòng thời gian dọc, S dòng thời gian, tám bố cục có thể thay đổi theo ý muốn ';

// Đã xóa gần đây
stringMap [string.Mind_Mindmap_Deleted_Title] = 'Đã xóa gần đây';
stringMap [string.Mind_Mindmap_Deleted_Recovery] = 'Khôi phục';
stringMap [string.Mind_Mindmap_Deleted_Forever] = 'Xóa';
stringMap [string.Mind_Mindmap_Deleted_Filename] = 'Tên tập tin';
stringMap [string.Mind_Mindmap_Deleted_Tips] = 'Sau khi xóa ở đây, nó sẽ bị xóa vĩnh viễn';
stringMap [string.Mind_Mindmap_Deleted_Operating] = 'Hoạt động';
stringMap [string.Mind_Mindmap_Deleted_Selected] = 'Tìm kiếm bản đồ đã xóa gần đây';
stringMap [string.Mind_Mindmap_Deleted_Forever_Time] = 'Xóa ngày';

//liên hệ chúng tôi
stringMap [string.Contact_Us_No_Public] = 'Tài khoản Công khai';
stringMap [string.Contact_Us_QQ_Group] = 'QQ Group';
stringMap [string.Contact_Us_Message_Board] = 'Bảng tin';
stringMap [string.Contact_Us_Username] = 'Tên';
stringMap [string.Contact_Us_Email] = 'Email';
stringMap [string.Contact_Us_Voicemail] = 'Vui lòng để lại ý kiến ​​quý giá của bạn';
stringMap [string.Contact_Us_Send_Email] = 'Gửi Email';

// xác minh tin nhắn
stringMap [string.Input_Username_isNull] = 'Tên không được để trống';
stringMap [string.Input_Email_isNull] = 'Vui lòng nhập địa chỉ email chính xác';
stringMap [string.Input_Voicemail_isNull] = 'Vui lòng nhập nội dung đã gửi';
stringMap [string.Input_Introduction_isNull] = 'Phần giới thiệu không được để trống';
stringMap [string.Input_Title_isNull] = 'Tiêu đề không được để trống';

stringMap [string.Input_Please_Enter_A_Valid_Phone_Number] = 'Vui lòng nhập số điện thoại chính xác';
stringMap [string.Input_Please_Enter_Verification_Code] = 'Vui lòng nhập mã xác minh! ';
stringMap [string.Input_Please_Enter_The_Password] = 'Vui lòng nhập mật khẩu! ';
stringMap [string.Input_The_Password_Format_Is_Incorrect] = 'Định dạng mật khẩu không chính xác! ';
stringMap [string.Input_Please_Enter_A_New_Password] = 'Vui lòng nhập mật khẩu mới! ';
stringMap [string.Input_Confirm_The_New_Password] = 'Vui lòng nhập mật khẩu xác nhận! ';
stringMap [string.Input_The_Passwords_Are_Inconsists] = 'Mật khẩu không nhất quán! ';
stringMap [string.Input_The_Please_Enter_The_Correct_Verification_Code] = 'Vui lòng nhập mã xác minh chính xác! ';
stringMap [string.Input_The_Verification_Code_Error] = 'Lỗi mã xác minh! ';

// Sử dụng trợ giúp
stringMap [string.Mind_Usinghelp_1] = 'Tạo sơ đồ tư duy';
stringMap [string.Mind_Usinghelp_2] = 'Cách thêm nút';
stringMap [string.Mind_Usinghelp_3] = 'Tạo một nút miễn phí';
stringMap [string.Mind_Usinghelp_4] = 'Thiết lập liên kết';
stringMap [string.Mind_Usinghelp_5] = 'làm đẹp khung tranh';
stringMap [string.Mind_Usinghelp_6] = 'làm đẹp văn bản';
stringMap [string.Mind_Usinghelp_7] = 'Chèn ghi chú, liên kết';
stringMap [string.Mind_Usinghelp_8] = 'Chèn biểu tượng';
stringMap [string.Mind_Usinghelp_9] = 'Chèn hình ảnh';
stringMap [string.Mind_Usinghelp_10] = 'Cách khôi phục việc xóa';
stringMap [string.Mind_Usinghelp_11] = 'Xuất Bản đồ Tư duy';
stringMap [string.Mind_Usinghelp_12] = 'Nâng cấp tư cách thành viên';
stringMap [string.Mind_Usinghelp_13] = 'Phím tắt';
stringMap [string.Mind_Usinghelp_Understood] = 'Tôi hiểu rồi';
stringMap [string.Mind_Usinghelp_14] = 'Chèn thư viện tài liệu';
// Sử dụng trợ giúp-2
stringMap [string.Mind_Usinghelp_15] = 'Bản đồ mới';
// stringMap [string.Mind_Usinghelp_2] = 'Thêm nút';
// stringMap [string.Mind_Usinghelp_4] = 'Thiết lập liên kết';
// stringMap [string.Mind_Usinghelp_5] = 'Làm đẹp cho canvas';
// stringMap [string.Mind_Usinghelp_6] = 'Làm đẹp văn bản';
// stringMap [string.Mind_Usinghelp_7] = 'Thêm ghi chú, liên kết';
stringMap [string.Mind_Usinghelp_17] = 'Hoàn tác thao tác, huỷ bỏ';
stringMap [string.Mind_Usinghelp_18] = 'Kéo nút để hoán đổi';
stringMap [string.Mind_Usinghelp_19] = 'Căn chỉnh nút';
stringMap [string.Mind_Usinghelp_20] = 'Thay đổi kiểu bản đồ';
stringMap [string.Mind_Usinghelp_21] = 'Kiểu đường bản đồ';
stringMap [string.Mind_Usinghelp_22] = 'Màu nền của bản đồ';
stringMap [string.Mind_Usinghelp_23] = 'Đặt hình dạng nút';
stringMap [string.Mind_Usinghelp_24] = 'Kiểu đường nút';
stringMap [string.Mind_Usinghelp_25] = 'Cài đặt hình ảnh nút';
stringMap [string.Mind_Usinghelp_26] ='Cài đặt biểu tượng nút';
stringMap [string.Mind_Usinghelp_27] = 'Nút Thêm Tóm tắt';
stringMap [string.Mind_Usinghelp_28] = 'Lựa chọn hộp nút đơn';
stringMap [string.Mind_Usinghelp_29] = 'Nhiều nút thành khối';
stringMap [string.Mind_Usinghelp_30] = 'Nút thêm đường viền';
stringMap [string.Mind_Usinghelp_31] = 'Thêm bản đồ thống kê';
stringMap [string.Mind_Usinghelp_32] = 'Ẩn nút và khôi phục';
stringMap [string.Mind_Usinghelp_33] = 'Nút nhiều phép toán';
stringMap [string.Mind_Usinghelp_34] = 'Thay đổi kiểu';
stringMap [string.Mind_Usinghelp_35] = 'Chèn giọng nói và giải thích';
stringMap [string.Mind_Usinghelp_36] = 'Hướng dẫn bản đồ thành hình ảnh';
stringMap [string.Mind_Usinghelp_37] = 'Bản đồ tài liệu PDF';
stringMap [string.Mind_Usinghelp_38] = 'In bản đồ';
stringMap [string.Mind_Usinghelp_39] = 'Chia sẻ và xem liên kết';
stringMap [string.Mind_Usinghelp_40] = 'Bản đồ chia sẻ với cơn bão';
stringMap [string.Mind_Usinghelp_41] = 'Sử dụng thư mục';
stringMap [string.Mind_Usinghelp_42] = 'Xóa và khôi phục bản đồ';
// Sử dụng trợ giúp 3
stringMap [string.Mind_Usinghelp_43] = 'Bạn bè đăng ký tham gia nhóm';
stringMap [string.Mind_Usinghelp_44] = 'Cách giải thể nhóm';
stringMap [string.Mind_Usinghelp_45] = 'Tải lên thông tin tập tin';
stringMap [string.Mind_Usinghelp_46] = 'Đồng ý tham gia ứng dụng';
stringMap [string.Mind_Usinghelp_47] = 'Chỉnh sửa cộng tác';
stringMap [string.Mind_Usinghelp_48] = 'Nhóm chia sẻ mới';
stringMap [string.Mind_Usinghelp_49] = 'Mời thành viên nhóm';

//bão táp
stringMap [string.Mind_Storm_Recommend] = 'Đề xuất';
stringMap [string.Mind_Storm_Boilingpoint] = 'Điểm sôi';
stringMap [string.Mind_Storm_Unfold] = 'Mở ra';
stringMap [string.Mind_Storm_Putaway] = 'Putaway';
stringMap [string.Mind_Storm_Myclassification] = 'Phân loại của tôi';
stringMap [string.Mind_Storm_Recommended_Classification] = 'Phân loại được đề xuất';
stringMap [string.Mind_Storm_Click_Add_Category] = 'Nhấp để thêm danh mục';
stringMap [string.Mind_Storm_No_Classification] = 'Chưa có phân loại';
stringMap [string.Mind_Storm_No_Tinterested] = 'Không quan tâm';
stringMap [string.Mind_Storm_Report] = 'Báo cáo';
stringMap [string.Mind_Storm_Views] = 'Lượt xem';

stringMap [string.Mind_Storm_Pornographic] = 'Nội dung tục tĩu';
stringMap [string.Mind_Storm_Advertising_Harassment] = 'Quấy rối Quảng cáo';
stringMap [string.Mind_Storm_Political_Religion] = 'Chính trị và Tôn giáo';
stringMap [string.Mind_Storm_Infringement] = 'Vi phạm (chân dung, phỉ báng, đạo văn, sử dụng gian lận)';
stringMap [string.Mind_Storm_Prohibited_Content] = 'Nội dung bị cấm';
stringMap [string.Mind_Storm_Sosystem_Rumors] = 'Truyền bá tin đồn và lan truyền tin đồn';
stringMap [string.Mind_Storm_Report_Successful] = 'Báo cáo thành công';

//biên tập
stringMap [string.Global_Edit] = 'Chỉnh sửa';
stringMap [string.Global_Cancel] = 'Hủy bỏ';
stringMap [string.Global_Ok] = 'OK';
stringMap [string.Global_Name] = 'Biệt hiệu';
stringMap [string.Global_Introduction] = 'Giới thiệu';
stringMap [string.Global_Change_Avatar] = 'Thay đổi hình đại diện';
stringMap [string.Global_Local_Upload] = 'Tải lên cục bộ';

//thông tin cá nhân
stringMap [string.User_Personal_information] = 'Thông tin Cá nhân';
stringMap [string.User_Member_Validity_Period] = 'Thời hạn hiệu lực của thành viên';
stringMap [string.User_My_Share] = 'Chia sẻ của tôi';
stringMap [string.User_My_Collection] = 'Bộ sưu tập của tôi';
stringMap [string.User_More_Message] = 'Thêm';
stringMap [string.User_Deleted_Sotrm] = 'Xoá Storm';
stringMap [string.User_Folder_Rename] = 'Đổi tên';
stringMap [string.User_Folder_Rename_Root] = 'Sửa đổi quyền';
stringMap [string.User_Folder_Rename_Root_Title] = 'Sửa đổi quyền';
stringMap [string.User_Folder_Root_Public] = 'Hoàn toàn công khai';
stringMap [string.User_Folder_Root_Viewonly] = 'Chỉ cho phép xem thư mục chung';
stringMap [string.User_Folder_Root_Private] = 'Riêng tư';
stringMap [string.User_Folder_Root_Public_Introduction] = 'Những người khác có thể xem các mục yêu thích của bạn trên trang cá nhân của bạn';
stringMap [string.User_Message_Link] = 'Liên kết';
stringMap [string.User_New_Group] = 'Nhóm mới';
stringMap [string.User_New_Group_Title] = 'Tạo một nhóm mới';
stringMap [string.User_New_Group_Style_Title] = 'Loại tập tin';
stringMap [string.User_Share_Is_Null] = 'Chưa chia sẻ! ';
stringMap [string.User_Deleted_Storm_Tips] = 'Bạn có chắc chắn muốn xóa cơn bão này không? ';
stringMap [string.User_Remove_From_List] = 'Xoá khỏi danh sách',
    stringMap [string.User_Move_To] = 'Di chuyển tới',
    stringMap [string.User_Deleted_Link] = 'Xoá liên kết'
stringMap [string.User_Collction_Is_Null] = 'Chưa có bộ sưu tập'
stringMap [string.User_Collection] = 'Bộ sưu tập'

// Hộp đăng nhập
stringMap [string.User_Lolgin_Welcome_To_Login] = 'Chào mừng đến với Mind +'
stringMap [string.User_Lolgin_Free_Registration] = 'Đăng ký miễn phí'
stringMap [string.User_Lolgin_Please_Enter_Phone_Number] = 'Vui lòng nhập số điện thoại của bạn'
stringMap [string.User_Lolgin_Please_Enter_Verification_Code] = 'Vui lòng nhập mã xác minh'
stringMap [string.User_Lolgin_Obtain] = 'Lấy'
stringMap [string.User_Lolgin_Password_Login] = 'Mật khẩu đăng nhập'
stringMap [string.User_Lolgin_Login] = 'Đăng nhập'
stringMap [string.User_Lolgin_Register_An_Account] = 'Đăng ký Tài khoản'
stringMap [string.User_Lolgin_Already_Have_An_Account] = 'Đã có tài khoản'
stringMap [string.User_Lolgin_To_Log_In] = 'Đăng nhập'
stringMap [string.User_Lolgin_Please_Enter_Password] = 'Vui lòng nhập mật khẩu'
stringMap [string.User_Lolgin_Confirm_Password] = 'Xác nhận mật khẩu'
stringMap [string.User_Lolgin_Registered] = 'Đã đăng ký'
stringMap [string.User_Lolgin_Account_Login] = 'Đăng nhập tài khoản'
stringMap [string.User_Lolgin_Scan_Code_Login] = 'Quét mã đăng nhập'
stringMap [string.User_Lolgin_Forgot_Password] = 'Quên mật khẩu'
stringMap [string.User_Lolgin_Verification_Code_Login] = 'Đăng nhập mã xác minh'
stringMap [string.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Open Mind + quét ở đầu giao diện cài đặt'
stringMap [string.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'Mã QR này sẽ hết hạn sau năm phút, vui lòng nhấp vào khi nó hết hạn'
stringMap [string.User_Lolgin_Refresh] = 'Làm mới'
stringMap [string.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'Quét thành công, vui lòng xác nhận trên điện thoại của bạn'
stringMap [string.User_Lolgin_Whether_Yo_Authorize_Login] = 'Có cho phép đăng nhập hay không'
stringMap [string.User_Lolgin_Please_Enter_New_Password] = 'Vui lòng nhập mật khẩu mới'
stringMap [string.User_Lolgin_Reset] = 'Đặt lại'

// Bản đồ của tôi
stringMap [string.My_Mind_Search_Template_Type_Or_Template] = 'Mẫu hoặc loại mẫu tìm kiếm'
stringMap [string.My_Mind_Date] = 'Ngày'
stringMap [string.My_Mind_Create_Folder] = 'Tạo thư mục'
stringMap [string.My_Mind_Please_Enter_The_Folder_Name] = 'Vui lòng nhập tên thư mục'
stringMap [string.My_Mind_Please_Enter_A_New_Folder_Name] = 'Vui lòng nhập tên thư mục mới'
stringMap [string.My_Mind__No_Map_Yet_Go] = 'Chưa có bản đồ, đi nhanh lên'
stringMap [string.My_Mind_Create] = 'Tạo'
stringMap [string.My_Mind_Right] = 'Thanh!'
stringMap [string.My_Mind_Open] = 'Mở'
stringMap [string.My_Mind_Type_Left_Right] = 'Bản đồ hướng dẫn kép'
stringMap [string.My_Mind_Type_Unillel] = 'Bản đồ đơn phương'
stringMap [string.My_Mind_Type_Bottom] = 'Sơ đồ tổ chức'
stringMap [string.My_Mind_Type_Bubble] = 'Biểu đồ bong bóng'
stringMap [string.My_Mind_Type_Time_Vertical] = 'Lập lịch biểu đồ'
stringMap [string.My_Mind_Blank_Mind_Map] = 'Bản đồ tư duy trống'
stringMap [string.My_Mind_All] = 'Tất cả'
stringMap [string.My_Mind_Views] = 'Lượt xem:'

// Tìm kiếm bão
stringMap [string.Storm_Search_How_Title] = 'Các thẻ hàng đầu'
stringMap [string.Storm_History_Search] = 'Tìm kiếm lịch sử'

// Tiêu đề công khai
stringMap [string.Public_Header_Mind] = 'Bản đồ'
stringMap [string.Public_Header_Outline] = 'Phác thảo'
stringMap [string.Public_Header_Download_Apps] = 'Tải xuống ứng dụng khách'
stringMap [string.Public_Header_Mobile_Version] = 'Phiên bản di động'
stringMap [string.Public_Header_Creator] = 'Người tạo:'
stringMap [string.Public_Header_View_Frequency] = 'Lượt xem:'
stringMap [string.Public_Header_Save] = 'Lưu'
stringMap [string.Header_Mind_Tips_Saved] = 'Đã lưu'
stringMap [string.Header_Mind_Tips_Edit] = 'Đã chỉnh sửa'
stringMap [string.Header_Mind_Tips_Update_Saved] = 'Cập nhật đã được lưu'

// Menu chỉnh sửa bản đồ
stringMap [string.Mind_Edit_Revoke] = 'Thu hồi'
stringMap [string.Mind_Edit_Restore] = 'Khôi phục'
stringMap [string.Mind_Edit_Subtopic] = 'Subtopic'
stringMap [string.Mind_Edit_Top_Subtopic] = 'Chủ đề con hàng đầu'
stringMap [string.Mind_Edit_Bottom_Subtopic] = 'Chủ đề phụ dưới cùng'
stringMap [string.Mind_Edit_Free_Node] = 'Nút miễn phí'
stringMap [string.Mind_Edit_Summary] = 'Tóm tắt'
stringMap [string.Mind_Edit_Block] = 'Thành một khối'
stringMap [string.Mind_Edit_Insert] = 'Chèn'
stringMap [string.Mind_Edit_Related] = 'Liên quan'
stringMap [string.Mind_Edit_Style] = 'Phong cách'
stringMap [string.Mind_Edit_Recording] = 'Ghi âm'
stringMap [string.Mind_Edit_Insert_Picture] = 'Chèn ảnh'
stringMap [string.Mind_Edit_Insert_Icon] = 'Chèn biểu tượng'
stringMap [string.Mind_Edit_Insert_Remarks] = 'Chèn ghi chú'
stringMap [string.Mind_Edit_Insert_Link] = 'Chèn liên kết'
stringMap [string.Mind_Edit_Insert_Summary_Graph] = 'Chèn biểu đồ thống kê'
stringMap [string.Mind_Edit_Upload] = 'Tải lên'
stringMap [string.Mind_Edit_Right_Icon] = 'Biểu tượng'
stringMap [string.Mind_Edit_Right_Style] = 'Phong cách'
stringMap [string.Mind_Edit_Audio_Decoding] = 'Giải mã ...'
stringMap [string.Mind_Edit_Audio_Duration] = 'Thời lượng'
stringMap [string.Mind_Edit_Delete_Audio_Tips] = 'Bạn có chắc chắn xóa âm thanh đã ghi không? '
stringMap [string.Mind_Edit_Join_Membership] = 'Mở tư cách thành viên'
stringMap [string.Mind_Edit_Upgrade] = 'Nâng cấp'
stringMap [string.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [string.Mind_Edit_Join_Membership_Tips] = 'Các thành viên có thể có nhiều thời gian ghi âm hơn, bây giờ hãy nâng cấp lên thành viên? '

stringMap [string.Mind_Edit_Choose_Material] = 'Chọn vật liệu'
stringMap [string.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'Kéo và thả ảnh vào đây'
stringMap [string.Mind_Edit_Select_locally] = 'Chọn từ địa phương'
stringMap [string.Mind_Edit_Choose_From] = 'Chọn từ thư viện vật liệu'
stringMap [string.Mind_Edit_Mine] = 'Của tôi'
stringMap [string.Mind_Edit_My_Material] = 'Vật liệu của tôi'
stringMap [string.Mind_Edit_Recently_Used] = 'Được sử dụng gần đây'
stringMap [string.Mind_Edit_Use] = 'Sử dụng'
stringMap [string.Mind_Edit_No_Material_Temporarily_Go_Add_It] = 'Hiện tại không có tài liệu nào, hãy thêm vào! '
stringMap [string.Mind_Edit_Select_Chart_Type] = 'Chọn loại biểu đồ'
stringMap [string.Mind_Edit_Remarks] = 'Nhận xét'
stringMap [string.Mind_Edit_Remarks_Tips] = 'Vui lòng nhập nhận xét'
stringMap [string.Mind_Edit_Add] = 'Thêm'
stringMap [string.Mind_Edit_Please_Enter_The_Link] = 'Vui lòng nhập liên kết'

// Chỉnh sửa menu ở bên trái của trang
stringMap [string.Mind_Edit_Left_Menu_Save_Picture] = 'Lưu ảnh'
stringMap [string.Mind_Edit_Left_Menu_Export_Pdf] = 'Xuất PDF'
stringMap [string.Mind_Edit_Left_Menu_Share_Links] = 'Chia sẻ liên kết'
stringMap [string.Mind_Edit_Left_Menu_Share_The_Storm] = 'Chia sẻ với cơn bão'
stringMap [string.Mind_Edit_Left_Menu_Printing] = 'In'
stringMap [string.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'Mở khóa phiên bản đầy đủ'
stringMap [string.Mind_Edit_Left_Menu_Help] = 'Trợ giúp'
stringMap [string.Mind_Edit_Left_Menu_Save_Picture_Format] = 'Lưu định dạng'
stringMap [string.Mind_Edit_Left_Menu_Save_Picture_Png] = 'Định dạng png'
stringMap [string.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'định dạng jpg'
stringMap [string.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'định dạng webp'
stringMap [string.Mind_Edit_Left_Menu_Share_Map] = 'Chia sẻ bản đồ'
stringMap [string.Mind_Edit_Left_Menu_Share_Switch] = 'Chia sẻ công tắc'
stringMap [string.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'Bạn có thể sao chép liên kết và gửi nó đi'
stringMap [string.Mind_Edit_Left_Menu_Copy_Link] = 'Sao chép liên kết'
stringMap [string.Mind_Edit_Left_Menu_Follow_The_Link] = 'Nếu bạn muốn thay đổi liên kết, vui lòng nhấp vào'
stringMap [string.Mind_Edit_Left_Menu_Regenerate] = 'Tạo lại'
stringMap [string.Mind_Edit_Left_Menu_Link_Password] = 'Mật khẩu liên kết:'
stringMap [string.Mind_Edit_Left_Menu_Allow_Save] ='Cho phép lưu'
stringMap [string.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Số lượt xem tối đa'
stringMap [string.Mind_Edit_Left_Menu_Infinite] = 'Vô hạn'
stringMap [string.Mind_Edit_Left_Menu_Limit] = 'Giới hạn'
stringMap [string.Mind_Edit_Left_Menu_Second] = 'Thứ hai'
stringMap [string.Mind_Edit_Left_Menu_Link_Validity] = 'Thời gian hiệu lực của liên kết'
stringMap [string.Mind_Edit_Left_Menu_1_Day] = '1 ngày'
stringMap [string.Mind_Edit_Left_Menu_3_Days] = '3 ngày'
stringMap [string.Mind_Edit_Left_Menu_7_Days] = '7 ngày'
stringMap [string.Mind_Edit_Left_Menu_Permanent] = 'Vĩnh viễn'
stringMap [string.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'Sao chép nội dung và liên kết'
stringMap [string.Mind_Edit_Left_Menu_Regenerate_Tips] = 'Bạn có chắc chắn muốn tạo lại không? Liên kết trước đó sẽ không hợp lệ! '
stringMap [string.Mind_Edit_Left_Menu_Share] = 'Chia sẻ'
stringMap [string.Mind_Edit_Left_Menu_Custom_Category] = 'Danh mục tùy chỉnh:'
stringMap [string.Mind_Edit_Left_Menu_Allow_Comments] = 'Có cho phép nhận xét hay không'
stringMap [string.Mind_Edit_Left_Menu_Edit_Add_Category] = 'Chỉnh sửa để thêm danh mục'
stringMap [string.Mind_Edit_Left_Menu_Membership_Rights] = 'Quyền của Thành viên'
stringMap [string.Mind_Edit_Left_Menu_Support_Platform] = 'Nền tảng hỗ trợ'
stringMap [string.Mind_Edit_Left_Menu_Open_Membership] = 'Mở tư cách thành viên'
stringMap [string.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'Trao đổi mã kích hoạt'
stringMap [string.Mind_Edit_Left_Menu_purchase_Tips] = 'Quyền lợi của thành viên sau khi mua hàng'
stringMap [string.Mind_Edit_Left_Menu_Payment_Type] = 'Chọn phương thức thanh toán'
stringMap [string.Mind_Edit_Left_Menu_Activity_Price] = 'Giá hoạt động'
stringMap [string.Mind_Edit_Left_Menu_Amount_Actently_Paid] = 'Số tiền thực trả'
stringMap [string.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Gia hạn ngay lập tức'
stringMap [string.Mind_Edit_Left_Menu_Terms_Of_Service] = '《Điều khoản Dịch vụ》'
stringMap [string.Mind_Edit_Left_Menu_Privacy_Policy] = '《Chính sách Bảo mật》'
stringMap [string.Mind_Edit_Left_Menu_Activation_Code] = 'Mã kích hoạt:'
stringMap [string.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Vui lòng nhập mã kích hoạt'
stringMap [string.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'Xác minh mã kích hoạt'
stringMap [string.Mind_Edit_Left_Menu_Select_Categories] = 'Chọn danh mục'
stringMap [string.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'Tiêu đề chính không được để trống'
stringMap [string.Mind_Edit_Left_Menu_Coupon] = 'Phiếu giảm giá:'
stringMap [string.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Vui lòng nhập mã phiếu giảm giá'
stringMap [string.Mind_Confirm_Use_Coupons] = 'Xác nhận việc sử dụng phiếu thưởng'
stringMap [string.Mind_Edit_Left_Menu_Verification] = 'Xác minh'
stringMap [string.Mind_Edit_Left_Menu_Preferential_Amount] = 'Số tiền ưu đãi'
stringMap [string.Mind_Edit_Left_Menu_Pay_Imasty] = 'Thanh toán ngay lập tức'
stringMap [string.Mind_Edit_Left_Menu_Wx_Pay] = 'Thanh toán qua WeChat'
stringMap [string.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Xác nhận thông tin trao đổi'
stringMap [string.Mind_Edit_Left_Menu_Exchange_Code] = 'Mã trao đổi:'
stringMap [string.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Trao đổi'

// Chỉnh sửa menu ở bên phải của trang
stringMap [string.Mind_Edit_Right_Menu_canvas] = 'Canvas'
stringMap [string.Mind_Edit_Right_Menu_background_Color] = 'Màu nền'
stringMap [string.Mind_Edit_Right_Menu_layout] = 'Bố cục'
stringMap [string.Mind_Edit_Right_Menu_Line_Style] = 'Kiểu đường kẻ'
stringMap [string.Mind_Edit_Right_Menu_line_Color] = 'Màu đường kẻ'
stringMap [string.Mind_Edit_Right_Menu_Line_Width] = 'Chiều rộng dòng'
stringMap [string.Mind_Edit_Right_Menu_Line_Taosystem] = 'Các đường thẳng hàng'
stringMap [string.Mind_Edit_Right_Menu_Theme_Font] = 'Phông chữ chủ đề'
stringMap [string.Mind_Edit_Right_Menu_Child_Font] = 'Phông chữ trẻ em'
stringMap [string.Mind_Edit_Right_Menu_text_Color] = 'Màu văn bản'
stringMap [string.Mind_Edit_Right_Menu_font_Size] = 'Kích thước phông chữ'
stringMap [string.Mind_Edit_Right_Menu_Font_Style] = 'Kiểu phông chữ'
stringMap [string.Mind_Edit_Right_Menu_Border_Color] = 'Màu đường viền'
stringMap [string.Mind_Edit_Right_Menu_Fill_Color] = 'Tô màu'
stringMap [string.Mind_Edit_Right_Menu_Border_Shape] = 'Hình dạng đường viền'
stringMap [string.Mind_Edit_Right_Menu_Border_Width] = 'Chiều rộng đường viền'
stringMap [string.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Hiển thị hiệu ứng đổ bóng (chức năng đăng ký)'
stringMap [string.Mind_Edit_Right_Menu_Line_Color] = 'Màu đường kẻ'
stringMap [string.Mind_Edit_Right_Menu_Dotted_Line] = 'Đường chấm'
stringMap [string.Mind_Edit_Right_Menu_Picture_Size] = 'Kích thước hình ảnh'
stringMap [string.Mind_Edit_Right_Menu_Picture_Location] = 'Vị trí Hình ảnh'
stringMap [string.Mind_Edit_Right_Menu_Picture_Shape] = 'Hình ảnh'
stringMap [string.Mind_Edit_Right_Menu_Icon_Size] = 'Kích thước biểu tượng'
stringMap [string.Mind_Edit_Right_Menu_Icon_Position] = 'Vị trí biểu tượng'
stringMap [string.Mind_Edit_Right_Menu_routine] = 'Chung'
stringMap [string.Mind_Edit_Right_Menu_priasty] = 'Mức độ ưu tiên'
stringMap [string.Mind_Edit_Right_Menu_Progress] = 'Tiến trình'
stringMap [string.Mind_Edit_Right_Menu_Stars] = 'Dấu sao'
stringMap [string.Mind_Edit_Right_Menu_Week] = 'tuần'
stringMap [string.Mind_Edit_Right_Menu_Head_Portrait] = 'Chân dung'
stringMap [string.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [string.Mind_Edit_Right_Menu_Learning] = 'Học tập'
stringMap [string.Mind_Edit_Right_Menu_Work] = 'Công việc'
stringMap [string.Mind_Edit_Right_Menu_Characters] = 'Nhân vật'
stringMap [string.Mind_Edit_Right_Menu_Animals] = 'Động vật'
stringMap [string.Mind_Edit_Right_Menu_Food] = 'Thức ăn'
stringMap [string.Mind_Edit_Node_Menu_Copy_Style] = 'Sao chép kiểu'
stringMap [string.Mind_Edit_Node_Menu_Sticking_Style] = 'Phong cách gắn bó'
stringMap [string.Mind_Edit_Node_Menu_Modify_Shape] = 'Sửa đổi hình dạng'
stringMap [string.Mind_Edit_Node_Menu_View_Picture] = 'Xem ảnh'
stringMap [string.Mind_Edit_Node_Menu_Delete_Picture] = 'Xóa ảnh'
stringMap [string.Mind_Edit_Node_Menu_Copy] = 'Sao chép'
stringMap [string.Mind_Edit_Node_Menu_Paste] = 'Dán'
stringMap [string.Mind_Edit_Node_Menu_Delete_Line] = 'Xóa dòng'
stringMap [string.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Xoá'
stringMap [string.Mind_Edit_Node_Menu_Edit_Remarks] = 'Chỉnh sửa Ghi chú'
stringMap [string.Mind_Edit_Node_Menu_Open_Link] = 'Mở liên kết'
stringMap [string.Mind_Edit_Node_Menu_Delete_Remarks] = 'Xóa ghi chú'
stringMap [string.Mind_Edit_Node_Menu_Delete_Link] = 'Xoá liên kết'
stringMap [string.Mind_Edit_Node_Menu_Delete_Icon] = 'Xóa biểu tượng'
stringMap [string.Mind_Edit_Node_Menu_Icon_Style] = 'Kiểu biểu tượng'

// Bảng chỉnh sửa biểu đồ thống kê
stringMap [string.Echart_Edit_Statistical] = 'Thống kê'
stringMap [string.Echart_Edit_Value] = 'Giá trị'
stringMap [string.Echart_Edit_Color] = 'Màu'
stringMap [string.Echart_Edit_Title] = 'Tiêu đề'
stringMap [string.Echart_Edit_Detailed_Description] = 'Mô tả chi tiết'
stringMap [string.Echart_Edit_Value_Sort] = 'Sắp xếp'
stringMap [string.Echart_Edit_Value_Display_Type] = 'Loại hiển thị'
stringMap [string.Echart_Edit_Value_Color_Scheme] = 'Phối màu'
stringMap [string.Echart_Edit_Value_Display_Size] = 'Kích thước hiển thị'
stringMap [string.Echart_Edit_Value_Title_Size] = 'Kích thước tiêu đề'
stringMap [string.Echart_Edit_Value_Explain_Size] = 'Giải thích kích thước'
stringMap [string.Echart_Edit_Value_Subscribe] = 'Đăng ký'

// tab Storm
stringMap [string.StormBookTag_Subscribe] = 'Đã đăng ký'
stringMap [string.StormBookTag_List] = 'Danh sách'

// Trang chia sẻ bão
stringMap [string.Storm_Share_Comment] = 'Nhận xét'
stringMap [string.Storm_Share_Reply] = 'Trả lời'
stringMap [string.Storm_Share_Let_Me_Comment_Too] = 'Hãy để tôi nhận xét về nó ~'
stringMap [string.Storm_Share_Release] = 'Phát hành'
stringMap [string.Storm_Share_Main_Title] = 'Tiêu đề chính'
stringMap [string.Storm_Share_Subtitle] = 'Phụ đề'
stringMap [string.Storm_Share_Author] = 'Tác giả'
stringMap [string.Storm_Share_Replies] = 'Trả lời'
stringMap [string.Storm_Share_Comment_Is_Null_Tips] = 'Chưa có bình luận nào. Hãy là người đầu tiên trở thành người đầu tiên.'
stringMap [string.Storm_Share_Comment_Input_Is_Null_Tips] = 'Vui lòng nhập nhận xét ~'
stringMap [string.Storm_Share_Delete_Comment_Tips] = 'Bạn có chắc chắn muốn xóa nhận xét này không? '

// Trợ giúp cho việc sử dụng bản đồ nội bộ
stringMap [string.Mind_Usinghelp_Btn_1] = 'Các phím tắt (cửa sổ)'
stringMap [string.Mind_Usinghelp_Btn_2] = 'Phím tắt (mac)'
stringMap [string.Mind_Usinghelp_Btn_3] = 'Mô tả'
stringMap [string.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [string.Mind_Usinghelp_Btn_5] = '[Command] + [D]'
stringMap [string.Mind_Usinghelp_Btn_6] = 'Thêm nút con'
stringMap [string.Mind_Usinghelp_Btn_7] = '[Delet]'
stringMap [string.Mind_Usinghelp_Btn_8] = 'Xóa nút hiện tại (lưu ý rằng không thể xóa nếu nó đang ở trạng thái chỉnh sửa)'
stringMap [string.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [string.Mind_Usinghelp_Btn_10] = '[Command] + [E]'
stringMap [string.Mind_Usinghelp_Btn_11] = 'Sửa đổi nút hiện tại'
stringMap [string.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [string.Mind_Usinghelp_Btn_13] = '[Lệnh] + [↑] [↓] [←] [→]'
stringMap [string.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [string.Mind_Usinghelp_Btn_15] = '[Command] + [+]'
stringMap [string.Mind_Usinghelp_Btn_16] = 'Phóng to bản đồ'
stringMap [string.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [string.Mind_Usinghelp_Btn_18] = '[Command] + [-]'
stringMap [string.Mind_Usinghelp_Btn_19] = 'Ghi nhớ bản đồ'
stringMap [string.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [string.Mind_Usinghelp_Btn_21] = '[Command] + [S]'
stringMap [string.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [string.Mind_Usinghelp_Btn_23] = '[Command] + [Z]'
stringMap [string.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap [string.Mind_Usinghelp_Btn_25] = '[Command] + [Shift] + [Z]'
stringMap [string.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [string.Mind_Usinghelp_Btn_27] = '[Command] + [B]'
stringMap [string.Mind_Usinghelp_Btn_28] = 'Thoát khỏi chỉnh sửa'

// Văn bản nhắc nhở
stringMap [string.Message_Tips_Login_Success] = 'Đăng nhập thành công'
stringMap [string.Message_Tips_Dlt_Success] = 'Xóa thành công'
stringMap [string.Message_Tips_Report_Success] = 'Báo cáo thành công'
stringMap [string.Message_Tips_Copy_Success] = 'Sao chép thành công'
stringMap [string.Message_Tips_Collction_Success] = 'Bộ sưu tập thành công'
stringMap [string.Message_Tips_Avatar_Modification_Success] = 'Sửa đổi hình đại diện thành công'
stringMap [string.Message_Tips_Signature_Modification_Success] = 'Sửa đổi chữ ký thành công'
stringMap [string.Message_Tips_Name_Modification_Success] = 'biệt hiệu được sửa đổi thành công'
stringMap [string.Message_Tips_Modification_Success] = 'Sửa đổi thành công'
stringMap [string.Message_Tips_New_Profile_Success] = 'Thành công thư mục mới'
stringMap [string.Message_Tips_Mobile_Success] = 'Thành công trên thiết bị di động'
stringMap [string.Message_Tips_Submit_Success] = 'Gửi thành công'
stringMap [string.Message_Tips_Exchange_Success] = 'Trao đổi thành công'
stringMap [string.Message_Tips_Please_Code] = 'Vui lòng nhập mã kích hoạt'
stringMap [string.Message_Tips_Agree_To_Terms] = 'Vui lòng đồng ý với chính sách bảo mật'
stringMap [string.Message_Tips_Please_Sign_In] = 'Vui lòng đăng nhập'
stringMap [string.Message_Tips_Share_Success] = 'Chia sẻ thành công'
stringMap [string.Message_Tips_Create_Success] = 'Đã tạo thành công'
stringMap [string.Message_Tips_Profile_Name_No_Null] = 'Tên thư mục không được để trống'
stringMap [string.Message_Tips_Selecte_Success] = 'Tìm kiếm đã hoàn thành'
stringMap [string.Message_Tips_Subscription_Success] = 'Đăng ký thành công'
stringMap [string.Message_Tips_Unsubscribe_Success] = 'Hủy đăng ký thành công'
stringMap [string.Message_Tips_Related_Content_Not_Found] = 'Không tìm thấy nội dung liên quan'
stringMap [string.Message_Tips_Max_Add_Class] = 'Chỉ có thể thêm tối đa'
stringMap [string.Message_Tips_Registered_Success] = 'Đã đăng ký thành công'
stringMap [string.Message_Tips_Scan_Code_Login_Failed] = 'Đăng nhập quét mã không thành công'
stringMap [string.Message_Tips_Share_Is_Null] = 'Chia sẻ liên kết không tồn tại'
stringMap [string.Message_Tips_Share_Link_Does_Not_Exist] = 'Tập tin tải lên không được vượt quá 3MB'
stringMap [string.Message_Tips_Get_Permission_Successful] = 'Xin phép thành công'
stringMap [string.Message_Tips_Membership_Required] = 'Yêu cầu tư cách thành viên'
stringMap [string.Message_Tips_Save_Success] = 'Lưu thành công'
stringMap [string.Message_Tips_Scan_Code_Login_Success] = 'Quét mã đăng nhập thành công'
stringMap [string.Message_Tips_Style] = 'Một danh mục?'

// Chế độ phác thảo
stringMap [string.Outline_Menu_Indent] = 'Thụt lề'
stringMap [string.Outline_Menu_Decrease_Indent] = 'Giảm thụt lề'

// Gợi ý trợ giúp
stringMap [string.Help_Background_Content] = 'Liên quan đến nền dòng, liên quan đến nền dòng, liên quan đến nền dòng, liên quan đến nền dòng, liên quan đến nền dòng, liên quan đến nền dòng, liên quan đến nền dòng, liên quan đến nền dòng, liên quan đến nền dòng,'
stringMap [string.Help_Mind_Content] = 'Có thể tạo các bản đồ với các bố cục khác nhau theo sở thích: bản đồ hướng dẫn kép, bản đồ bên trái, bản đồ bên phải, sơ đồ tổ chức, cây hai chiều, cây bên trái, cây bên phải, bảng'
stringMap [string.Help_Share_Link_Content] = 'Chia sẻ liên kết có thể được chia sẻ trên WeChat, QQ, Weibo và các kênh khác dưới dạng liên kết và bạn bè có thể xem nội dung'
stringMap [string.Using_Templates] = 'Sử dụng các mẫu'

//Mã mời
stringMap [string.User_Lolgin_Please_Enter_Invitation_Code] = 'Vui lòng nhập mã mời'
stringMap [string.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Vui lòng nhập đúng mã thư mời'
stringMap [string.User_Lolgin_Optional] = 'Tuỳ chọn'

// Sử dụng tài liệu

stringMap [string.Cannot_Add_To_Node_Background] = 'Không áp dụng cho nền nút'
stringMap [string.Cannot_Add_To_Img_Background] = 'Không áp dụng cho nền văn bản'
stringMap [string.Cannot_Add_To_Mind_Background] = 'Không áp dụng cho nền đường bưu kiện'
stringMap [string.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Thêm hình nền'
stringMap [string.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Xóa hình nền'
stringMap [string.Mind_Add_Background_Image] = 'Chèn hình nền'
stringMap [string.Mind_Delete_Background_Image] = 'Xóa hình nền'

stringMap [string.Permanent_Member] = 'Thành viên cố định'
stringMap [string.Insuffnough_Storage_Space] = 'Không đủ dung lượng lưu trữ, hãy nâng cấp thành viên để tăng dung lượng. '
stringMap [string.Used_Space] = 'Dung lượng lưu trữ'
stringMap [string.Current_Storage] = 'Bộ nhớ hiện tại:'
stringMap [string.Used_Space_Upgrade] = 'Nâng cấp dung lượng lưu trữ'
stringMap [string.My_Mind_Is_Null_Tips] = 'Mind + Mind Map & Notes Siêu ghi nhớ, chắp cánh cho suy nghĩ, sáng tạo, hiển thị, lập kế hoạch và ghi chú'
stringMap [string.Insuffnough_Storage_Space_Tempalte_Tips] = 'Dung lượng lưu trữ không đủ. Bạn có thể sử dụng bản mẫu khi nâng cấp thành viên của mình. '

stringMap [string.Group_Management] = 'Nhóm'
stringMap [string.Save_Img_Fill_Full] = 'Mở đầy'
stringMap [string.Save_Img_Center_Tilt] = 'Độ nghiêng giữa'
stringMap [string.Save_Img_File_Full_Dark_Color] = 'Đầy đủ (tối)'
stringMap [string.Save_Img_Mind_Map_Watermarking] = 'Mind + Mind Map Watermarking'
stringMap [string.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'Thêm hình mờ để bảo vệ sự sáng tạo kiến ​​thức:'
stringMap [string.Save_Img_Watermark_Text] = 'Văn bản hình mờ:'
stringMap [string.Save_Img_Watermark_Logo] = 'LOGO hình mờ:'
stringMap [string.Save_Img_Watermark_Style] = 'Kiểu hình mờ:'
stringMap [string.Save_Img_Please_Input] = 'Vui lòng nhập'
stringMap [string.Save_Img_Chop_Tips] = 'Bạn có thể tận hưởng các quyền của watermark khi mở một thành viên'
stringMap [string.Public_Header_Mindmap_Mind] = 'Chế độ bản đồ'
stringMap [string.Copy_Mind_Map] = 'Sao chép Bản đồ Tư duy'
stringMap [string.Font_Style_Bold] = 'In đậm'
stringMap [string.Font_Style_Italics] = 'Chữ nghiêng'
stringMap [string.Font_Style_Delete_Line] = 'Dấu gạch ngang'
stringMap [string.Remove_Any] = 'Xoá'

stringMap [string.Mindmap_Review] = 'Xem lại bản đồ'
stringMap [string.Mindmap_Review_Recently_Updated_Content_10] = 'Khắc sâu ấn tượng và tăng cường trí nhớ, sắp xếp ý tưởng và thông tin một cách hiệu quả'
stringMap [string.Mindmap_Review_Content] = 'Xem lại bản đồ đề cập đến việc phát lại quá trình chỉnh sửa và nội dung được phát theo trình tự bắt đầu từ chủ đề trung tâm; việc xem xét bản đồ có thể giúp bạn thực hiện kiểm kê hệ thống, phân tích và tìm ra các ý tưởng và sơ hở . Trong quá trình xem xét, bạn có thể tìm thấy những nguồn cảm hứng khác nhau '
stringMap [string.Mindmap_Review_Speed] = 'Tốc độ'
stringMap [string.Mindmap_Review_Word_Speed] = 'Tốc độ Word'
stringMap [string.Mindmap_Review_Background_Ratio] = 'Tỷ lệ nền'
stringMap [string.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [string.Mindmap_Review_Current_Playback_Node_Magnification] = 'Độ phóng đại của nút phát lại hiện tại'
stringMap [string.Mindmap_Review_Node_Playback_Order] = 'Thứ tự phát lại nút'


stringMap [string.Mind_Group_Picture_Upload] = 'Tải lên hình ảnh'
stringMap [string.Mind_Group_Upload_From_Local] = 'Tải lên cục bộ'
stringMap [string.Mind_Group_Mind_Mapping] = 'Bản đồ Tư duy'
stringMap [string.Mind_Group_Information] = 'Dữ liệu'

stringMap [string.Mind_Group_Invite_New_Members] = 'Mời thành viên mới'
stringMap [string.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Vui lòng nhập số điện thoại của bạn'
stringMap [string.Mind_Group_Cancel] = 'Huỷ bỏ'
stringMap [string.Mind_Group_Confirm] = 'OK'
stringMap [string.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'Số điện thoại di động không được để trống'
stringMap [string.Mind_Group_Invite_The_Successful] = 'Lời mời thành công'


stringMap [string.Mind_Group_Shared_Group_Membership] = "Các thành viên trong nhóm được chia sẻ"
stringMap [string.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Số lượng thành viên nhóm được chia sẻ tối đa"
stringMap [string.Mind_Group_Membership_Limit] = "Giới hạn thành viên"
stringMap [string.Mind_Group_Current_Member_Limit] = "Giới hạn trên của thành viên hiện tại"
stringMap [string.Mind_Group_Upgrade_Member_Limit] = "Nâng cấp giới hạn trên của thành viên"
stringMap [string.Mind_Group_Members_Of_The_Search] = "Tìm kiếm thành viên"
stringMap [string.Mind_Group_Creator] = "Người tạo"
stringMap [string.Mind_Group_Administrator] = "Quản trị viên"
stringMap [string.Mind_Group_Number_Of_People] = "Mọi người"
stringMap [string.Mind_Group_Selected] = "Đã chọn"
stringMap [string.Mind_Group_Member] = "Thành viên"
stringMap [string.Mind_Group_Check_All] = "Chọn tất cả"
stringMap [string.Mind_Group_Remove] = "Xoá"
stringMap [string.Mind_Group_Invite_New_Members] = "Mời thành viên mới"
stringMap [string.Mind_Group_Delete_Members] = "Xóa thành viên"
stringMap [string.Mind_Group_Please_Select_The_Member_To_Delete] = "Vui lòng chọn thành viên để xóa"
stringMap [string.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Bạn có chắc chắn muốn xóa người dùng khỏi nhóm này không?"
stringMap [string.Mind_Group_Add_As_Administrator] = "Thêm quản trị viên"
stringMap [string.Mind_Group_Accomplish] = "Đã hoàn thành"
stringMap [string.Mind_Group_Compile] = "Chỉnh sửa"
stringMap [string.Mind_Group_Group_Owner] = "Chủ sở hữu nhóm"
stringMap [string.Mind_Group_Add_Admin] = "Thêm quản trị viên"
stringMap [string.Mind_Group_Group_ID] = "Id nhóm"
stringMap [string.Mind_Group_Group_Number] = "Số nhóm"
stringMap [string.Mind_Group_Name] = "Tên"
stringMap [string.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Vui lòng nhập tên của nhóm được chia sẻ"
stringMap [string.Mind_Group_Introduction] = "Giới thiệu"
stringMap [string.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Xin giới thiệu ngắn gọn về các nhóm được chia sẻ"
stringMap [string.Mind_Group_Announcement] = "Thông báo"
stringMap [string.Mind_Group_Shared_Group_Tag] = "Thẻ Nhóm được Chia sẻ"
stringMap [string.Mind_Group_Custom_Tag] = "Thẻ Tuỳ chỉnh"
stringMap [string.Mind_Group_Save] = "Lưu"
stringMap [string.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "Tên nhóm không được để trống"
stringMap [string.Mind_Group_Establish] = "Tạo"
stringMap [string.Mind_Group_Allows_Mapfolder_Creation] = "Cho phép tạo bản đồ / thư mục"
stringMap [string.Mind_Group_Allows_Editing_Of_Maps] = "Cho phép chỉnh sửa bản đồ"
stringMap [string.Mind_Group_Holder] = "Mọi người"
stringMap [string.Mind_Group_Only_The_Administrator] = "Chỉ dành cho quản trị viên"
stringMap [string.Mind_Group_Not_Allow] = "Không được phép"
stringMap [string.Mind_Group_Allows_Map_Deletion] = "Cho phép xóa bản đồ"
stringMap [string.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Cho phép các diễn đàn đăng bài viết"
stringMap [string.Mind_Group_Export] = "Xuất"
stringMap [string.Mind_Group_Allows_You_To_Save_Images_PDF] = "Cho phép lưu hình ảnh / PDF"
stringMap [string.Mind_Group_Open_Group_Watermark] = "Mở hình mờ nhóm"
stringMap [string.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Sau khi mở, logo của nhóm sẽ được in trên sơ đồ tư duy"
stringMap [string.Mind_Group_Allow_Saving_Maps] = "Cho phép lưu bản đồ"
stringMap [string.Mind_Group_Save_It_To_My_Map] = "Lưu vào Bản đồ của tôi"
stringMap [string.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Cho phép chia sẻ thành bão"
stringMap [string.Mind_Group_Join_The_Way] = "Cách tham gia"
stringMap [string.Mind_Group_Whether_To_Invite_New_Members] = "Có cho phép thành viên mới được mời hay không"
stringMap [string.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Có cho phép thêm số nhóm tìm kiếm hay không"
stringMap [string.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Thông báo có cần được xác thực khi tham gia hay không"
stringMap [string.Mind_Group_Shared_Group_Data] = "Dữ liệu nhóm được chia sẻ"
stringMap [string.Mind_Group_Authority_Management] = "Quản lý quyền"
stringMap [string.Mind_Group_Quit_Sharing_Of_Group] = "Rời khỏi nhóm chia sẻ"
stringMap [string.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Bạn có chắc chắn thoát khỏi nhóm được chia sẻ không?"
stringMap [string.Mind_Group_The_Dissolution_Of_Group] = "Nhóm giải thể"
stringMap [string.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "Các tệp sẽ bị xóa sau khi nhóm bị giải tán. Bạn có muốn giải tán không?"
stringMap [string.Mind_Group_Return_To_Home_Page] = "Quay lại trang chủ"
stringMap [string.Mind_Group_Join_The_Group] = "Tham gia nhóm"
stringMap [string.Mind_Group_Please_Enter_The_Group_Number] = "Vui lòng nhập số nhóm / tên nhóm để tìm kiếm"
stringMap [string.Mind_Group_Apply_To_Join] = "Đăng ký tham gia"
stringMap [string.Mind_Group_Join] = "Tham gia"
stringMap [string.Mind_Group_Verification] = "Xác minh"
stringMap [string.Mind_Group_Please_Enter_A_Validation_Message] = "Vui lòng nhập thông báo xác thực"
stringMap [string.Mind_Group_The_Content_Cannot_Be_Empty] = "Nội dung không được để trống"
stringMap [string.Mind_Group_Name] = "Tên nhóm được chia sẻ"
stringMap [string.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Vui lòng nhập tên của nhóm được chia sẻ"
stringMap [string.Mind_Group_Introduction] = "Chia sẻ phần giới thiệu nhóm"
stringMap [string.Mind_Group_Group_Indication] = "Số nhóm"
stringMap [string.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introductioned_Yet] = "Người sáng tạo thật lười biếng, tôi chưa giới thiệu nó ~"
stringMap [string.Mind_Group_Shared_Group_Announcement] = "Thông báo về nhóm được chia sẻ"
stringMap [string.Mind_Group_No_Announcement] = "Chưa có thông báo nào"
stringMap [string.Mind_Group_Click_On] = "Nhấp để mở rộng"
stringMap [string.Mind_Group_Click_On_The_Hidden] = "Nhấp để ẩn"
stringMap [string.Mind_Group_Altogether] = "Tổng số"
stringMap [string.Mind_Group_The_Group_Number_Was_Copied_Successful] = "Sao chép thành công số nhóm"
stringMap [string.Mind_Group_Stick] = "Bám sát đầu"
stringMap [string.Mind_Group_There_Is_A_New_Content] = "Có nội dung mới"
stringMap [string.Mind_Group_Cancel_The_Top] = "Hủy đầu trang"
stringMap [string.Mind_Group_Setting_Up] = "Cài đặt"
stringMap [string.Mind_Group_Usinghelp] = "Sử dụng trợ giúp"
stringMap [string.Mind_Group_Set_The_Message] = "Tin nhắn nhóm"
stringMap [string.Mind_Group_Apply_To_Join] = "Đăng ký tham gia"
stringMap [string.Mind_Group_Invite_To_Join] = "Mời tham gia"
stringMap [string.Mind_Group_Consent] = "Đồng ý"
stringMap [string.Mind_Group_Turn_Down] = "Từ chối"
stringMap [string.Mind_Group_Label] = "Nhãn"
stringMap [string.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Vui lòng nhập tên của nhãn nhóm được chia sẻ"
stringMap [string.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Mời bạn bè và đồng nghiệp cùng sáng tạo và chia sẻ nội dung kiến ​​thức"
stringMap [string.Mind_Group_Immediately_Create] = "Tạo ngay lập tức"
stringMap [string.Mind_Group_Immediately_To_Join] = "Tham gia ngay bây giờ"

stringMap [string.Currently_Selected] = "Hiện đang theo dõi"
stringMap [string.LabelManagement] ="Quản lý nhãn"
stringMap [string.NoAttention] = "Không chú ý"
stringMap [string.Mindmap_Review_Play] = 'Chơi'

stringMap [string.Mind_Group_Joined] = "Đã tham gia"
stringMap [string.Mind_Group_Download_App] = "Tải xuống APP"
stringMap [string.Mind_Group_No_Content] = "Chưa có nội dung"
stringMap [string.Mind_Group_Individual] = "phần"
stringMap [string.Mind_Group_Message_Area] = "Vùng Thông báo"
stringMap [string.Mind_Group_No_Message] = "Không có tin nhắn, hãy truy cập ngay bây giờ"
stringMap [string.Mind_Group_Leave_Message] = "Để lại tin nhắn"
stringMap [string.Mind_Group_Bar] = "Thanh"
stringMap [string.Mind_Group_See_More] = "Xem thêm"
stringMap [string.Mind_Group_Enter_Invitation_Code] = "Nhập mã mời"
stringMap [string.Mind_Group_Apply_To_Join_Sharing_Group] = "Đăng ký tham gia nhóm chia sẻ"
stringMap [string.Reasons_For_Application_0_30_Words] = "Lý do đăng ký (0-30 từ)"
stringMap [string.Mind_Group_Send_Out] = "Gửi"
stringMap [string.Mind_Group_Join_Successently] = "Tham gia thành công"
stringMap [string.Mind_Group_Application_Sent_Successful] = "Ứng dụng đã được gửi thành công"

stringMap [string.Mind_Group_Save_The_Mapping] = "Lưu bản đồ"
stringMap [string.Mind_Group_Update_Record] = "Sửa đổi bản ghi"

stringMap [string.Mind_Edit_Page_Switch_Mapping] = "Chuyển đổi bản đồ"
stringMap [string.Mind_Edit_Page_Mind_Retrospect] = "Đánh giá Bản đồ"
stringMap [string.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Một người dùng hiện có đang chỉnh sửa bản đồ"
stringMap [string.Mind_Edit_Page_Password] = "Mật khẩu"

stringMap [string.Mind_Edit_Page_Sharing_Group] = "Nhóm chia sẻ"
stringMap [string.Mind_MBean] = "MBean"
stringMap [string.Mind_My_MBean] = "MBean của tôi"
stringMap [string.Alipay] = "Alipay"
stringMap [string.Alipay_Payment] = "Thanh toán qua Alipay"
stringMap [string.Please_Pay_On_The_Payment_Page] = "Vui lòng đến trang thanh toán để thanh toán"
stringMap [string.WeChat] = "WeChat"
stringMap [string.Mind_Shooping_Total] = "Tổng số"
stringMap [string.Mind_Shooping_Confirm_Payment] = "Xác nhận thanh toán"
stringMap [string.Mind_Shooping_Redeem_Now] = "Đổi ngay bây giờ"

stringMap [string.Mind_Whether_Confirm_Payment_Purchase] = "Có xác nhận thanh toán khi mua hàng hay không"
stringMap [string.Mind_Tips] = "Mẹo"
stringMap [string.Mind_Your_M_Bean_Insuffnough_Whether_Go__Purchase_M_Bean] = "M Beans của bạn không đủ, bạn có muốn mua M Beans"

stringMap [string.Mind_share_scan_code] = "Mind + quét mã"

stringMap [string.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "Có khôi phục lại địa điểm chơi gần đây nhất không?"
stringMap [string.Mind_Play_Number_Of_Playable_Times_Remaining] = "Thời gian có thể chơi còn lại"

stringMap [string.Group_Mind_Play_The_File_Will_Not_Exist] = "Tập tin sẽ bị xóa hoàn toàn, bạn có chắc chắn sẽ giải tán không?"
stringMap [string.Group_Mind_Up_One_Level] = "Tăng một cấp"
stringMap [string.Group_Mind_No_Documents] = "Chưa có tệp nào"
stringMap [string.Group_Mind_PaymentSuccessful] = "Thanh toán thành công"

stringMap [string.Mind_Edit_Page_Sharing_Group_introduction] = "Mọi người cộng tác chỉnh sửa, đồng bộ hóa thời gian thực nhiều thiết bị đầu cuối, chia sẻ tài liệu, nâng cao hiệu quả cộng tác nhóm"

stringMap [string.Mind_Edit_Subscript] = "Chỉ số dưới đây"
stringMap [string.Mind_Edit_Text_Location] = "Vị trí văn bản"
stringMap [string.Mind_Mind_Import_Into_A_Shared_Group] = "Nhập vào Nhóm Chia sẻ"

stringMap [string.Standard_Colors] = "Màu tiêu chuẩn"
stringMap [string.More_Colors] = "Nhiều màu hơn"
stringMap [string.Reset_Password] = "Đặt lại mật khẩu"
stringMap [string.Account_Password_Login] = "Đăng nhập mật khẩu tài khoản"
stringMap [string.Authentication_code_Login_registration] = "Đăng nhập / đăng ký mã xác minh"
stringMap [string.One_End_Creation_Multi_End_Synchronization] = "Tạo một đầu, đồng bộ nhiều đầu"
stringMap [string.Rich_And_Diverse_Mapping_Structure] = "Cấu trúc bản đồ phong phú và đa dạng"
stringMap [string.Massive_And_ExquisiteTemplateLibrary] = "Thư viện mẫu tinh tế khổng lồ"
stringMap [string.Map_Review_Play] = "Xem lại bản đồ-Chơi"
stringMap [string.CommunityLearningAndSharingPlatform] = "Nền tảng trao đổi chia sẻ và học hỏi kiến ​​thức"

stringMap [string.Mind_Mind_Upgrade_Storage_Space] = "Nâng cấp dung lượng lưu trữ"
stringMap [string.Mind_Mind_Let_Me_Comment_Too] = "Hãy để tôi nhận xét về nó"
stringMap [string.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Chưa có thông báo tin nhắn"
stringMap [string.Failed_To_Get_Recording_Device] = "Không lấy được thiết bị ghi"

stringMap [string.Search_Pagination] = "Phân trang Tìm kiếm"
stringMap [string.Please_Enter_A_Paging_Name] = "Vui lòng nhập tên trang"
stringMap [string.Operation_Settings] = "Cài đặt hoạt động"
stringMap [string.Shortcut_key_Settings] = "Cài đặt phím tắt"
stringMap [string.Press_Key_Combination] = "Vui lòng nhấn tổ hợp phím cần thiết"
stringMap [string.Mind_Reduction] = "Giảm"
stringMap [string.Double_Click_Add_Free_Node] = "Nhấp đúp vào khoảng trống để thêm một nút trống"
stringMap [string.Shortcut_Conflict] = "Xung đột phím tắt"
stringMap [string.Select_Main_Node] = "Chọn nút chính"
stringMap [string.Telephone] = "Điện thoại"
stringMap [string.Microblog] = "Microblog"
stringMap [string.Address] = "Địa chỉ"
stringMap [string.Company_Address] = "Block B, Building 21, No. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap [string.Mind_Archive] = "Lưu trữ"
stringMap [string.Mind_Bookshelf] = "Giá sách"
stringMap [string.Search_Archive] = "Tìm kiếm Giá sách"
stringMap [string.Modify_Archive_Order] = "Sửa đổi Thứ tự Giá sách"
stringMap [string.Modification_Order] = "Thứ tự sửa đổi"
stringMap [string.Please_Enter_Archive_Name] = "Vui lòng nhập tên của giá sách"
stringMap [string.Message_Tips_Archive_Name_No_Null] = "Không được để trống tên giá sách"
stringMap [string.Mind_Edit_Delete_Archive_Tips] = "Bạn có chắc chắn muốn xóa giá sách này không?"
stringMap [string.Mind_Edit_Delete_Bookcase_Tips] = "Bạn có chắc chắn muốn xóa tủ sách này không?"
stringMap [string.Mind_Edit_Delete_Map_Tips] = "Bạn có chắc chắn xóa bản đồ này không?"
stringMap [string.Mind_Edit_Confirm] = "Xác nhận"
stringMap [string.Mind_Import_Map] = "Nhập bản đồ"
stringMap [string.Mind_Bookcase] = "Tủ sách"
stringMap [string.Added_Successful] = "Đã thêm thành công"
stringMap [string.Message_Tips_Bookcase_Name_No_Null] = "Tên tủ sách không được để trống"

stringMap [string.Mind_Assosystem_Map] = "Bản đồ liên kết"
stringMap [string.Mind_confirm_Assosystem_Map] = "Bạn có chắc chắn liên kết bản đồ này không?"
stringMap [string.Mind_Assosystem_Map_See] = "Xem bản đồ liên kết"
stringMap [string.Mind_Assosystem_Map_Delete] = "Xóa bản đồ liên kết"

stringMap [string.Mind_Edit_Right_Menu_Correlation_Line] = "Cấu trúc đường tương quan"
stringMap [string.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Bắt đầu Dòng Tương quan"
stringMap [string.Mind_Edit_Right_Menu_Correlation_Line_End] = "Phần cuối của dòng tương quan"

stringMap [string.Share_Group_To_Storm] = "Có nên chia sẻ nhóm để gây bão hay không"
stringMap [string.Apply_To_Join_Storm_Platform] = "Trên nền tảng Storm, những người khác nhìn thấy và đăng ký tham gia, động não với những người đam mê có kiến ​​thức phổ thông"
stringMap [string.Pay_To_Goin] = "Trả tiền để tham gia"
stringMap [string.Pay_To_Goin_Need_Mbeans] = "Cần trả phí khi tham gia"
stringMap [string.Mbean_Can_Be_Realized] = "Mbean Realized"

stringMap [string.Mind_Visit] = "Truy cập"
stringMap [string.Mind_Details] = "Chi tiết"
stringMap [string.Mind_See] = "Xem"
stringMap [string.Earn_Mbeans] = "Kiếm Mbeans"
stringMap [string.Mind_Explain] = "Mô tả"
stringMap [string.Mind_Parenting] = "Nuôi dạy con cái"
stringMap [string.Mind_Internet] = "Internet"
stringMap [string.Mind_Psychology] = "Tâm lý học"
stringMap [string.Mind_litether] = "Văn học"
stringMap [string.Mind_Technology] = "Công nghệ"
stringMap [string.Mind_Workplace] = "Nơi làm việc"
stringMap [string.Mind_Life] = "Cuộc sống"
stringMap [string.Mind_Subject] = "Chủ đề"
stringMap [string.Hot_Group] = "Nhóm Hot"
stringMap [string.Open_Group] = "Mở nhóm"
stringMap [string.Mind_Group_Used] = "Đã sử dụng"

stringMap [string.Mind_Click_Select] = "Nhấp để chọn"
stringMap [string.Mind_Double_Click_Enter] = "Nhấp đúp để vào"
stringMap [string.Search_Shared_Groups] = "Tìm kiếm các nhóm được chia sẻ"
stringMap [string.Search_Storm] = "Cơn bão tìm kiếm"
stringMap [string.Members_Save_Free] = "Thành viên Tiết kiệm Miễn phí"
stringMap [string.Bank_Card_Account_Name] = "Vui lòng nhập tên tài khoản thẻ ngân hàng của bạn"
stringMap [string.Bank_Card_No] = "Số thẻ ngân hàng"
stringMap [string.Please_Enter_Your_Bank_Card_No] = "Vui lòng nhập số thẻ ngân hàng của bạn"
stringMap [string.Branch_Sub_Branch_Information] = "Thông tin chi nhánh"
stringMap [string.Branch_Sub_Branch_Example] = "Ví dụ: Chi nhánh Hàng Châu Chi nhánh Thừa Tây"
stringMap [string.Application_For_Withdrawal] = "Đơn xin rút tiền"
stringMap [string.Withdrawal_Amount] = "Số tiền rút"
stringMap [string.Withdrawal] = "Rút tiền"
stringMap [string.Current_Revenue] = "Doanh thu Hiện tại"
stringMap [string.Recharge] = "Nạp tiền"
stringMap [string.Current_Total_MBeans] = "Tổng số MBeans hiện tại"
stringMap [string.Withdrawal_Status] = "Trạng thái rút tiền"
stringMap [string.Insuffnough_Withdrawal_Amount] = "Số tiền rút không đủ"
stringMap [string.Load_More] = "Tải thêm"
stringMap [string.Loading] = "Đang tải"
stringMap [string.It_Is_Over] = "Kết thúc"
stringMap [string.Default_Label] = "Nhãn mặc định"
stringMap [string.Selected_Label] = "Nhãn đã chọn"
stringMap [string.Current_Location] = "Vị trí hiện tại"
stringMap [string.MGold_Introduction] = "Bạn kiếm được M vàng thông qua Mind +"
stringMap [string.MGold_Details] = "Nhóm đám mây trả phí của bạn, có bản đồ kiến ​​thức, tài liệu học tập, v.v. trong nhóm. Nếu người dùng tham gia thanh toán M hạt, 50% trong số đó sẽ được chuyển đổi thành M vàng và M vàng lớn hơn hơn 50 có thể được rút "
stringMap [string.Cloud_Group_Introduction] = "Chia sẻ nhóm của bạn, những người khác có thể xem trên trang bão"
stringMap [string.Cloud_Group_Details] = "Nhóm đám mây trả phí của bạn. Có bản đồ kiến ​​thức, tài liệu học tập, v.v. trong nhóm. Nếu người dùng tham gia thanh toán M hạt, 50% trong số đó sẽ được chuyển đổi thành M vàng và M vàng lớn hơn hơn 50 có thể được trích xuất "
stringMap [string.Share_Storm_Introduction] = "Chia sẻ bản đồ của bạn với cộng đồng bão, để nhiều người hơn có thể học hỏi và khám phá kiến ​​thức từ bạn"
stringMap [string.Share_Storm_Details] = "Nếu bạn cho phép sử dụng M bean để lưu bản đồ, những người dùng khác có thể sử dụng bản đồ tri thức của bạn và bạn cũng sẽ nhận được 50% M bean. Bạn có thể sử dụng M bean để mua thành viên và tham gia trả phí nhóm, Bạn cũng có thể trả tiền để sử dụng bản đồ của người khác. "

stringMap [string.Linked_Successently] = "Đã liên kết thành công"
stringMap [string.Cancel_Archiving] = "Hủy lưu trữ"
stringMap [string.Please_Select_Archive_Location] = "Vui lòng chọn vị trí lưu trữ"
stringMap [string.The_Search_Result_Is_Empty] = "Kết quả tìm kiếm trống"

stringMap [string.Customize_A_Style] = "Những người không phải là thành viên chỉ có thể tùy chỉnh một kiểu"
stringMap [string.Adds_Current_Guide_Style_Library] = "Thêm kiểu bản đồ hiện tại vào thư viện kiểu"
stringMap [string.Trial_Version] = "Phiên bản dùng thử"
stringMap [string.Not_Supported_At_The_Moment] = "Hiện không được hỗ trợ"
stringMap [string.Mind_File] = "Tập tin"
stringMap [string.Mind_Import] = "Nhập"
stringMap [string.Importing] = "Đang nhập"

stringMap [string.Mind_Edit_Insert_Video] = "Chèn Video"
stringMap [string.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Kéo và thả video vào đây"
stringMap [string.Message_Tips_Video_Upload_Time_Limit] = "Thời lượng video tải lên không được vượt quá 60 giây"
stringMap [string.Mind_Edit_Node_Menu_View_Video] = "Xem Video"
stringMap [string.Mind_Edit_Node_Delete_View_Video] = "Xóa video"
stringMap [string.Message_Tips_Share_Video_Does_Not_Exist] = "Video tải lên không được vượt quá 10MB"
stringMap [string.Mind_Upload_Video_Restrictions] = "Chỉ hỗ trợ tải lên video ở định dạng MP4, kích thước tối đa 120 giây và 10M"
stringMap [string.Mind_Video_Size] = "Kích thước video"
stringMap [string.Mind_Edit_Left_Menu_Export_Word] = "Xuất từ"
stringMap [string.Mind_Edit_Left_Menu_Export_TXT] = "Xuất TXT"
stringMap [string.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [string.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [string.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap [string.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap [string.Export_Failed] = "Xuất không thành công"
stringMap [string.Add_File] = "Thêm tệp"
stringMap [string.Drag_The_File_Directly_To_Upload] = "Kéo trực tiếp vào tệp để tải lên"
stringMap [string.Supported_Imported_File_Types] = "Các loại tệp hiện được hỗ trợ để nhập: Word (chỉ .docx), XMind, .txt"
stringMap [string.No_Permission_Do_This] = "Bạn không có quyền thực hiện thao tác này"

stringMap [string.Upgrade_To_Professional] = "Nâng cấp lên Phiên bản Chuyên nghiệp"
stringMap [string.Non_Members_Insert_Videos] = "Những người không phải là thành viên chỉ có thể chèn video 24 giờ sau khi đăng ký"
stringMap [string.Upload_Failed] = "Tải lên không thành công"
stringMap [string.Upload_Failed_Title_Message] = "Tải lên không thành công, vui lòng nhấp để xác nhận, xóa các ảnh còn lại không tải lên được"

stringMap [string.Mind_Edit_Members_Use] = "Sử dụng thành viên"
stringMap [string.Invitation_Code] = "Mã lời mời"
stringMap [string.Student_Certification] = "Giấy chứng nhận sinh viên"
stringMap [string.I_Get_It] = "Tôi sẽ, bỏ qua hướng dẫn"
stringMap [string.Welcome_To_Mind] = "Chào mừng thiếu gia gia nhập Mind +, chúng ta hãy bắt đầu hành trình tư duy!"
stringMap [string.Mind_Before] = "Đầu tiên"
stringMap [string.Try_A_Map] = "Thử bản đồ"
stringMap [string.Click_Create] = "Nhấp để tạo"
stringMap [string.Click_Create_A_Mind_Map] = "Nhấp để tạo sơ đồ tư duy"
stringMap [string.Mind_About] = "Giới thiệu"
stringMap [string.See_Checking_Data] = "Xem thông tin cá nhân"
stringMap [string.Modify_Checking_Data] = "Sửa đổi thông tin cá nhân"
stringMap [string.Mind_Shear] = "Cắt"

stringMap [string.Mind_Style_Empty] = "Không có phong cách yêu thích hoặc tùy chỉnh"
stringMap [string.Mind_Branch_Free_Layout] = "Bố cục Chi nhánh Miễn phí"
stringMap [string.Mind_Branch_Free_Layout_explain] = "Cấu trúc hai chiều và các nhánh bản đồ bong bóng có thể được kéo tự do vào bố cục"
stringMap [string.Mind_Free_Theme_Drag_Dnd_Snap] = "Chủ đề miễn phí kéo Snap"

stringMap [string.Select_A_Node] = "Chọn chủ đề"
stringMap [string.Create_Child_Node] = "Tạo chủ đề con"
stringMap [string.Add_A_Nice_Image_To_The_Node] = "Tiếp theo, chúng tôi sẽ thêm một cái hay vào chủ đề con"
stringMap [string.Click_Icon_Panel] = "Nhấp vào bảng biểu tượng"
stringMap [string.Select_An_Icon_You_Like] = "Chọn một biểu tượng bạn thích"
stringMap [string.Replace_The_Mind_Map_With_A_New_One] = "Tiếp theo, chúng tôi sẽ thay thế bản đồ tư duy bằng một bản đồ mới"
stringMap [string.Select_The_Central_Theme] = "Chọn chủ đề trung tâm"
stringMap [string.Switch_To_Canvas_Panel] = "Chuyển sang bảng Canvas"
stringMap [string.Click_Layout] = "Nhấp để bố trí"
stringMap [string.Choose_A_Layout_You_Like] = "Chọn một bố cục bạn thích"
stringMap [string.Mind_Map_Save_Share] = "Xin chúc mừng, bạn đã tạo thành công một sơ đồ tư duy đẹp! Hãy lưu nó và chia sẻ nó với bạn bè của bạn ~"
stringMap [string.Click_Save_Picture] = "Nhấp để lưu hình ảnh"
stringMap [string.Click_OK] = "Nhấp vào OK"
stringMap [string.Congratulation_On_Completing_The_Novice_Guide] = "Chúc mừng bạn đã hoàn thành hướng dẫn mới làm quen ~"
stringMap [string.Upload_Succeeded] = "Đã tải lên thành công"

stringMap [string.Mind_Edit_Node_Menu_Quick_Style] = "Kiểu nhanh"
stringMap [string.Mind_Extremely_Important] = "Cực kỳ quan trọng"
stringMap [string.Mind_Important] = "Quan trọng"
stringMap [string.Mind_Commonly] = "Chung"
stringMap [string.Mind_Ordinary] = "Thông thường"
stringMap [string.Member_Exclusive] = "Tính năng này dành riêng cho các thành viên. Bạn có muốn nâng cấp tư cách thành viên của mình không?"
stringMap [string.Member_Exclusive_Color_Scheme] = "Cách phối màu này dành riêng cho các thành viên. Bạn có muốn nâng cấp thành viên của mình không?"
stringMap [string.Mind_Enlarge] = "Phóng to"
stringMap [string.Mind_Narrow] = "Thu hẹp"
stringMap [string.Mind_Center] = "Căn giữa"
stringMap [string.Mind_Full_Screen] = "Toàn màn hình"

stringMap [string.Mind_Assosystem_Node] = "Nút kết hợp"

stringMap [string.Mind_Resource_See] = "Xem tệp đính kèm"
stringMap [string.Mind_Resource_Delete] = "Xóa tệp đính kèm"
stringMap [string.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Kéo và thả các tập tin vào đây"
stringMap [string.Mind_Edit_choose_explain] = "Chọn tệp"
stringMap [string.Message_Tips_Share_Explain_Does_Not_Exist] = "Tệp tải lên không được vượt quá 20MB"

stringMap [string.Mind_Extremely_DrawOblique] = "Texture"
stringMap [string.Mind_Extremely_Gradient] = "Gradient"
stringMap [string.Mind_Extremely_Shadow] = "Text Shadow"
stringMap [string.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap [string.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap [string.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap [string.Mind_Style_To_Peer] = "Use to peer"
stringMap [string.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap [string.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap [string.Mind_Edit_Order_Label] = 'Order'
stringMap [string.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap [string.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap [string.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap [string.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap [string.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap [string.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap [string.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap [string.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap [string.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap [string.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap [string.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap [string.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap [string.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[string.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[string.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[string.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[string.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[string.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[string.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[string.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[string.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[string.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[string.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[string.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[string.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[string.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[string.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[string.Mind_Ai_Submit_Label] = 'Submit'
stringMap[string.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[string.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[string.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[string.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[string.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[string.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[string.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[string.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[string.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[string.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[string.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[string.Mind_Node_To_Card_Label] = "To Card"
stringMap[string.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[string.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[string.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[string.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[string.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[string.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[string.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[string.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[string.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[string.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[string.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[string.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[string.My_Mind_Form_Modol] = "Compare and block models"
stringMap[string.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[string.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[string.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[string.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[string.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[string.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[string.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[string.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[string.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[string.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[string.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[string.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;
class BoldData {
    constructor() {
        this.value = false;
        this.set = false;
    }

    isValue() {
        return this.value;
    }

    setValue(value) {
        this.value = value;
        this.set = true;
    }

    isSet() {
        return this.set;
    }

    copy() {
        var data = this.create(this.value);
        if (this.isSet()) {
            data.setValue(this.value);
        }
        return data;
    }

    equal(data) {
        var result = false;
        result = result || data.isSet() != this.isSet();
        result = result || data.isValue() != this.isValue();
        return !result;
    }

    create(value = false) {
        var data = new BoldData();
        data.value = value
        return data;
    }
}

export default BoldData
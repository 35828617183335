import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import UiUtil from "../../../../utils/UiUtil";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import TextIAndmageCalculation from "./TextIAndmageCalculation";
import Size from "../../../../viewmodel/core/base/Size";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndLatexCalculation extends TextIAndmageCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }


    calculate() {
        if (!this.data.isContainLatextContent()) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent;
        let latexContent = this.data.latexContent;

        if (imageContent == null) {
            return;
        }
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }else{
            return;
        }

        let latexOriginalSize = this.getLatexOriginalSize();
        let size = this.getContentSize();
        if (size.getWidth() == 0 && size.getHeight() == 0) {
            return;
        }
        let width = size.getWidth();
        let height = size.getHeight();
        let edgeInsets = this.getMargin(size);
        let textEdgeInsets = this.getTextEdgeInsets();
        let left = edgeInsets.left;
        let top = edgeInsets.top;
        let right = edgeInsets.right;
        let bottom = edgeInsets.bottom;
        let textY = this.getTextTop();
        let textX = this.getTextLeft();
        let isShowText = this.isShowText();
        let latexTop = imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT || !this.isShowText() ? new UiUtil().dip2px(5) : 0;

        if (imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT) {
            if (imageContent.height > textMindElementContent.height) {
                textMindElementContent.x = left + imageContent.width;
                textMindElementContent.y = top + (imageContent.height - textMindElementContent.height)/2;
                imageContent.x = left;
                imageContent.y = top;
                latexContent.x = left + textX;
                latexContent.y = top + imageContent.height + latexTop;
            } else {
                textMindElementContent.x = left + imageContent.width;
                textMindElementContent.y = top;
                imageContent.x = left;
                imageContent.y = top + (textMindElementContent.height - imageContent.height)/2;
                latexContent.x = left + textX;
                latexContent.y = top + textMindElementContent.height + latexTop;
            }
        } else {
            if (imageContent.width > textMindElementContent.width) {
                if (isShowText) {
                    textMindElementContent.x = left + (imageContent.width - textMindElementContent.width)/2;
                    textMindElementContent.y = top + imageContent.height;
                    imageContent.x = left;
                    imageContent.y = top;
                    latexContent.x = left + (size.getWidth() - latexOriginalSize.getWidth()) / 2;
                    latexContent.y = textMindElementContent.y + textMindElementContent.height + latexTop;
                } else {
                    textMindElementContent.x = left;
                    textMindElementContent.y = top;
                    imageContent.x = left;
                    imageContent.y = top;
                    latexContent.x = left + (size.getWidth() - latexOriginalSize.getWidth()) / 2;
                    latexContent.y = imageContent.y + imageContent.height + latexTop;
                }
            } else {
                textMindElementContent.x = left;
                textMindElementContent.y = top + imageContent.height;
                imageContent.x = left + (textMindElementContent.width - imageContent.width)/2;
                imageContent.y = top;
                latexContent.x = left + textX;
                latexContent.y = textMindElementContent.y + textMindElementContent.height + latexTop;
            }
        }
        latexContent.width = latexOriginalSize.getWidth();
        latexContent.height = latexOriginalSize.getHeight();

        this.setMindElementSize(width, height, edgeInsets);
    }
    getContentSize() {
        let size = super.getContentSize();
        let imageContent = this.data.imageContent;
        let latexSize = this.getLatexOriginalSize();
        let edgeInsets = this.getTextEdgeInsets();

        let left = imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT ? 0 : edgeInsets.left;
        let right = this.getTextContent().length == 0 && !this.data.isEdit ? 0 : edgeInsets.right;
        let latexTop = imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT || !this.isShowText() ? this.UiUtil.dip2px(5) : 0;
        latexSize = new Size(latexSize.getWidth() + left + right,
                latexSize.getHeight() + edgeInsets.bottom + latexTop);
        let width = Math.max(size.getWidth(), latexSize.getWidth());
        let height = size.getHeight() + latexSize.getHeight();

        return new Size(width, height);
    }
}
export default TextAndImageAndLatexCalculation
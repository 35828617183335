import Point from "./Point"

class LinePath {

    constructor() {
        this.line = ""
        this.effectRadius = 0
        this.lineToList = []
        this.isEffectRadius = true
    }

    setEffectRadius(radius) {
        if (radius >= 0) {
            this.effectRadius = radius
        }
    }

    moveToByPoint(point) {
        this.moveTo(point.x, point.y)
    }

    moveTo(x, y) {
        this.addLine("M" + x + "," + y)
        this.lineToList = []
        this.lineToList.push(new Point(x, y))
    }

    move(point) {
        if (point != null && point.x != null) {
            this.moveTo(point.x, point.y)
        }
    }

    lineTo(x, y) {
        this.addLine("L" + x + " " + y)
        this.lineToList.push(new Point(x, y))
    }

    lineToByPoint(point) {
        this.lineTo(point.x, point.y);
    }

    circle(radius1, radius2, xAxisRotation = 0, largeArcFlag = 1, sweepFlag = 1, x, y){     //第一个为水平半径，第二个为垂直半径。第三个为旋转角度。第四个为大圆小圆。第五个为顺时针逆时针。 最后两个个为终点坐标
        // 20 20 0 1 1 100 100
        this.addLine("A" + ' ' + radius1 + ' ' + radius2 + ' ' + xAxisRotation + ' ' + largeArcFlag + ' ' + sweepFlag + ' ' + x + ' ' + y  )
    }
    cubicTo(c1x, c1y, c2x, c2y, endx, endy) {
        this.addLine("C" + c1x + " " + c1y + "," + c2x + " " + c2y + "," + endx + " " + endy)
        this.isEffectRadius = false
    }
    
    cubicToByIos(endx, endy, c1x, c1y, c2x, c2y) {
        this.cubicTo(c1x, c1y, c2x, c2y, endx, endy)
    }

    addQuadCurve(toPoint, controlPoint) {
        this.cubicOneTo(controlPoint.x, controlPoint.y, toPoint.x, toPoint.y)
    }

    cubicOneTo(c1x, c1y, endx, endy) {
        this.addLine("Q" + c1x + " " + c1y + ","  + endx + " " + endy)
        this.isEffectRadius = false
    }
    getLine() {
        if (!this.isEffectRadius || this.lineToList.length == 0 || this.effectRadius <= 0) {
            return this.line
        } else {
            let length = this.lineToList.length
            var effectLine = ""
            for (let index = 0; index < length; index++) {
                let point = this.lineToList[index]
                if (index == 0) {
                    effectLine = "M" + point.x + " " + point.y
                } else if (index < length - 1) {
                    let previousPoint = this.lineToList[index - 1]
                    let nextPoint = this.lineToList[index + 1]
                    let minRadius = this.effectRadius * 0.66;
                    if ((Math.abs(point.x - previousPoint.x) < minRadius && Math.abs(point.x - nextPoint.x) < minRadius) ||
                        (Math.abs(point.y - previousPoint.y) < minRadius && Math.abs(point.y - nextPoint.y) < minRadius)) { //水平||垂直
                        effectLine += "L" + (point.x) + " " + point.y
                    } else {
                        var sweep = 0
                        if (point.x > previousPoint.x) {  //向右画线    
                            if (point.x == nextPoint.x) { //垂直转角
                                let max = Math.abs(point.y - nextPoint.y);
                                let smallRadius = this.effectRadius;
                                if (max/2 < smallRadius) {
                                    smallRadius = max/2
                                } 
    
                                if (point.y > nextPoint.y) { //向上转角
                                    sweep = 0
                                    effectLine += "L" + (point.x - smallRadius) + " " + point.y
                                    effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                            " " + (point.x) + " " + (point.y - smallRadius)
                                } else if (point.y < nextPoint.y){//向下转角
                                    sweep = 1
                                    effectLine += "L" + (point.x -smallRadius) + " " + point.y
                                    effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                            " " + point.x + " " + (point.y + smallRadius)
                                }
                            } else {
                                effectLine += "L" + (point.x) + " " + point.y
                            }
                        } else if (point.x == previousPoint.x) { // 垂直画线
                            if (point.y == nextPoint.y) { //垂直转角
                                let max = Math.abs(point.y - previousPoint.y);//当前坐标和上一个坐标的y轴差值
                                let smallRadius = this.effectRadius;
                                if (max/2 < smallRadius) {
                                    smallRadius = max/2
                                }
                                if(point.y < previousPoint.y) { //向上
                                    if (point.x > nextPoint.x) { //向左
                                        sweep = 0
                                        effectLine += "L" + (point.x) + " " + (point.y + smallRadius)
                                        effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                                " " + (point.x - smallRadius) + " " + (point.y)
                                    } else if (point.x < nextPoint.x){//向右转角
                                        sweep = 1
                                        effectLine += "L" + (point.x ) + " " + (point.y + smallRadius)
                                        effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                                " " + (point.x + smallRadius) + " " + (point.y)
                                    }
                                } else if (point.y > previousPoint.y) { //向下
                                    if (point.x > nextPoint.x) { //向左
                                        sweep = 1
                                        effectLine += "L" + (point.x) + " " + (point.y - smallRadius)
                                        effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                                " " + (point.x - smallRadius) + " " + (point.y)
                                    } else if (point.x < nextPoint.x){//向右转角
                                        sweep = 0
                                        effectLine += "L" + (point.x ) + " " + (point.y - smallRadius)
                                        effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                                " " + (point.x + smallRadius) + " " + (point.y)
                                    }
                                }                          
                            } else {
                                effectLine += "L" + (point.x) + " " + point.y
                            }
                        } else { //向左画线
                            if (point.x == nextPoint.x) { //垂直转角
                                let max = Math.abs(point.y - nextPoint.y);
                                let smallRadius = this.effectRadius;
                                if (max/2 < smallRadius) {
                                    smallRadius = max/2
                                } 
                                if (point.y > nextPoint.y) { //向上转角
                                    sweep = 1
                                    effectLine += "L" + (point.x + smallRadius) + " " + point.y
                                    effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                            " " + point.x + " " + (point.y - smallRadius)
                                } else if (point.y < nextPoint.y){//向下转角
                                    sweep = 0
                                    effectLine += "L" + (point.x + smallRadius) + " " + point.y
                                    effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                            " " + point.x + " " + (point.y + smallRadius)
                                }
                            } else {
                                effectLine += "L" + (point.x) + " " + point.y
                            }
                        }
                    }
                    
                } else {
                    effectLine += "L" + point.x + " " + point.y
                }
            }
            return effectLine
        }
    }

    getLineRadius() {
        if (!this.isEffectRadius || this.lineToList.length == 0 || this.effectRadius <= 0) {
            return this.line
        } else {
            let length = this.lineToList.length
            var effectLine = ""
            for (let index = 0; index < length; index++) {
                let point = this.lineToList[index]
                if (index == 0) {
                    effectLine = "M" + point.x + " " + point.y
                } else if (index < length - 1) {
                    let previousPoint = this.lineToList[index - 1]
                    let nextPoint = this.lineToList[index + 1]
                    // if (point.x == previousPoint.x && point.y == previousPoint.y) {
                    //     effectLine += "L" + (point.x) + " " + point.y
                    //     continue;
                    // }
                    let minRadius = this.effectRadius * 0.66;
                    if ((Math.abs(point.x - previousPoint.x) < minRadius && Math.abs(point.x - nextPoint.x) < minRadius) ||
                        (Math.abs(point.y - previousPoint.y) < minRadius && Math.abs(point.y - nextPoint.y) < minRadius)) { //水平||垂直
                        effectLine += "L" + (point.x) + " " + point.y
                        continue;
                    }
                    var sweep = 0
                    if (point.x > previousPoint.x) {  //向右画线   
                        if (point.x == nextPoint.x) { //垂直转角
                            let max = Math.abs(point.y - nextPoint.y);
                            let smallRadius = this.effectRadius;
                            if (max/2 < smallRadius) {
                                smallRadius = max/2
                            } 

                            if (point.y > nextPoint.y) { //向上转角
                                sweep = 0
                                effectLine += "L" + (point.x - smallRadius) + " " + point.y
                                effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                        " " + point.x + " " + (point.y - smallRadius)
                            } else if (point.y < nextPoint.y){//向下转角
                                sweep = 1
                                effectLine += "L" + (point.x -smallRadius) + " " + point.y
                                effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                        " " + point.x + " " + (point.y + smallRadius)
                            }
                        } else {
                            effectLine += "L" + (point.x) + " " + point.y
                        }
                    } else if (point.x == previousPoint.x) { // 垂直画线
                        // effectLine += "L" + (point.x) + " " + point.y
                        if (point.y == nextPoint.y) { //垂直转角
                            let max = Math.abs(point.y - previousPoint.y);//当前坐标和上一个坐标的y轴差值
                            let smallRadius = this.effectRadius;
                            if (max/2 < smallRadius) {
                                smallRadius = max/2
                            }
                            if(point.y < previousPoint.y) { //向上
                                if (point.x > nextPoint.x) { //向左
                                    sweep = 0
                                    effectLine += "L" + (point.x) + " " + (point.y + smallRadius)
                                    effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                            " " + (point.x - smallRadius) + " " + (point.y)
                                } else if (point.x < nextPoint.x){//向右转角
                                    sweep = 1
                                    effectLine += "L" + (point.x ) + " " + (point.y + smallRadius)
                                    effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                            " " + (point.x + smallRadius) + " " + (point.y)
                                }
                            } else if (point.y > previousPoint.y) { //向下
                                if (point.x > nextPoint.x) { //向左
                                    sweep = 1
                                    effectLine += "L" + (point.x) + " " + (point.y - smallRadius)
                                    effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                            " " + (point.x - smallRadius) + " " + (point.y)
                                } else if (point.x < nextPoint.x){//向右转角
                                    sweep = 0
                                    effectLine += "L" + (point.x ) + " " + (point.y - smallRadius)
                                    effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                            " " + (point.x + smallRadius) + " " + (point.y)
                                }
                            }

                        
                        } else {
                            effectLine += "L" + (point.x) + " " + point.y
                        }
                    } else { //向左画线
                        if (point.x == nextPoint.x) { //垂直转角
                            let max = Math.abs(point.y - nextPoint.y);
                            let smallRadius = this.effectRadius;
                            if (max/2 < smallRadius) {
                                smallRadius = max/2
                            } 
                            if (point.y > nextPoint.y) { //向上转角
                                sweep = 1
                                effectLine += "L" + (point.x + smallRadius) + " " + point.y
                                effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                        " " + point.x + " " + (point.y - smallRadius)
                            } else if (point.y < nextPoint.y){//向下转角
                                sweep = 0
                                effectLine += "L" + (point.x + smallRadius) + " " + point.y
                                effectLine += "A" + smallRadius + "," + smallRadius  + " 0 0, " + sweep + 
                                                        " " + point.x + " " + (point.y + smallRadius)
                            }
                        } else {
                            effectLine += "L" + (point.x) + " " + point.y
                        }
                    }
                } else {
                    effectLine += "L" + point.x + " " + point.y
                }
            }
            return effectLine
        }
    }

    addLine(path) {
        if (this.checkStr(this.line)) {
            this.line += "" + path//", " + path
        } else {
            this.line = path
        }
    }

    checkStr(str) {
        return this.line != null && this.line.length > 0
    }
}
export default LinePath
class InnerHTMLParse {

    constructor(innerTHML, innerText) {
        this.htmlValue = innerTHML;
        this.innerText = innerText;
        this.StartTransferredTag = "&lt;";
        this.EndTransferredTag = "&gt;";
        this.StartTag = "<";
        this.EndTag = ">";
        this.pStartTag = "<p>";
        this.pEndTag = "</p>";
        this.pTransferredStartTag = "&lt;p&gt;";
        this.pTransferredEndTag = "&lt;/p&gt;";
        this.divStartTag = "<div>";
        this.divEndTag = "</div>";
        this.spanStartTag = "<span ";
        this.spanEndTag = "</span>";

        this.brTag = ["<div></div>", "<div><br></div>", "<div><br/></div>", "<div><br /></div>", 
                        "<br>", "<br/>", "<br />", 
                        "<p></p>", "<p><br></p>", "<p><br/></p>"];
        this.emptyTag = ["&nbsp;", " "]
        this.lineFeed = "\n";
    }

    getInnerText(isLineFeed) {
        for (let index = 0; index < this.emptyTag.length; index++) {
            this.htmlValue = this.htmlValue.replace(new RegExp(this.emptyTag[index], "g"), " ")
        }
        this.innerText = this.replaceStr(this.innerText, this.StartTag, this.StartTransferredTag);
        this.innerText = this.replaceStr(this.innerText, this.EndTag, this.EndTransferredTag);

        var returnValue = this.brReplace(this.htmlValue);
        returnValue = this.spanReplace(returnValue);
        returnValue = this.lineFeedTagReplace(returnValue, this.divStartTag, this.divEndTag);
        returnValue = this.lineFeedTagReplace(returnValue, this.pStartTag, this.pEndTag);
        returnValue = this.lineFeedTagReplace(returnValue, this.pTransferredStartTag, this.pTransferredEndTag);
        if (isLineFeed) {
            returnValue = this.removeLineFeed(returnValue);
        }
        
        const words = returnValue.split(this.lineFeed);
        let length = words.length;
        for (let index = 0; index < this.emptyTag.length; index++) {
            this.innerText = this.innerText.replace(new RegExp(this.emptyTag[index], "g"), " ")
        }
        if (length == 1) {
            if (isLineFeed) {
                return this.resetTransferredTag(this.removeLineFeed(this.innerText));
            } else {
                return this.resetTransferredTag(this.innerText);
            }
        }
        let withoutLineFeed = this.innerText.replace(new RegExp(this.lineFeed, "g"), "")
        let lineFeedLength = this.lineFeed.length; 
        let innerTextLength = withoutLineFeed.length;
        var startIndex = 0;
        var newValue = "";
        
        for (let index = 0; index < length; index++) {
            let line = words[index];
            let lineLength = line.length;
            if (startIndex + lineLength > innerTextLength) {
                newValue += withoutLineFeed.substring(startIndex, innerTextLength);
                break;
            }

            let sp = withoutLineFeed.substring(startIndex, startIndex + lineLength);
            if (sp != line) {
                newValue += withoutLineFeed.substring(startIndex, innerTextLength);
                break;
            }
            if (index > 0) {
                newValue += this.lineFeed + sp;
            } else {
                newValue += sp;
            }
            startIndex += lineLength;
        }
        if (isLineFeed) {
            newValue = this.removeLineFeed(newValue);
        } else {
            let removeLineFeedValue = this.removeLineFeed(newValue);
            if (removeLineFeedValue.length == 0 && newValue.length == 1) {
                newValue = ""
            }
        }
        
        return this.resetTransferredTag(newValue);
    }

    lineFeedTagReplace(value, start, end) {
        var returnValue = value;
        const words = returnValue.split(start);
        var newValue = "";
        for (var index = 0; index < words.length; index++) {
          var c = words[index];
          let indexOf = c.indexOf(end);
          if (indexOf > -1) {
            if (indexOf < c.length - end.length) {
              newValue += this.lineFeed + c.substring(0, indexOf)
              newValue +=  c.substring(indexOf + end.length, c.length - 1)
            } else {
              newValue += this.lineFeed + c.substring(0, c.length - end.length)
            }
          } else {
            if (index == 0) {
              newValue = c;
            } else {
              newValue += c;
            }
          }
        }
        returnValue = newValue;
        return returnValue;
    }

    brReplace(value) {
        var returnValue = value;
        for (var index = 0; index < this.brTag.length; index++) {
            returnValue = returnValue.replace(new RegExp(this.brTag[index], "g"), this.lineFeed)
        }
        return returnValue
    }

    spanReplace(value) {
        if (value.indexOf(this.spanStartTag) == -1) {
            return value
        }
        var returnValue = "";
        var spans = value.split(this.spanStartTag)
        var length = spans.length
        if (length < 1) {
            return value
        }
        for (var index = 0; index < length; index++) {
            let cell = spans[index]
            let spanEndIndex = cell.indexOf(this.spanEndTag)
            let spanStartTagEndIndex = cell.indexOf(this.EndTag)

            if (spanEndIndex > spanStartTagEndIndex && spanStartTagEndIndex > -1) {
                returnValue = returnValue + cell.substring(spanStartTagEndIndex + this.EndTag.length, spanEndIndex)
                if (cell.length > spanEndIndex + this.spanEndTag.length) {
                    returnValue = returnValue + cell.substring(spanEndIndex + this.spanEndTag.length);
                }
            } else {
                returnValue = returnValue + cell;
            }
        }
        return returnValue
    }

    removeLineFeed(value) {
        return value.replace(/^\s+|\s+$/g,'')
    }

    replaceStr(value, searchChar, newChar) {
        return value.replace(new RegExp(searchChar, "g"), newChar)
    }

    resetTransferredTag(value) {
        if (value == null || undefined || value.length == 0) {
            return "";
        }
        value = this.replaceStr(value, this.StartTransferredTag, this.StartTag);
        value = this.replaceStr(value, this.EndTransferredTag, this.EndTag);
        return value;
    }
}

export default InnerHTMLParse
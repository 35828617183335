import Size from '../../../../viewmodel/core/base/Size'
import MindElementAlignmentType from '../../../../viewmodel/datatype/MindElementAlignmentType'
import TextCalculation from './TextCalculation';
/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndLatexCalculation  extends TextCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainLatextContent()) {
            return;
        }

        let textMindElementContent = null;
        let text = "";;
        
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }
        if (textMindElementContent == null) {
            return;
        }
        let edgeInsets = this.getTextEdgeInsets();
        let isShowText =  text.length > 0 || this.data.isEdit;
        let size = this.addLatexSize(new Size(isShowText ? textMindElementContent.width : 0,
                                            isShowText ? textMindElementContent.height : 0));
        let marginPoint = this.getMargin(size);
        if (isShowText) {
            switch (this.data.alignmentType) {
                case MindElementAlignmentType.RIGHT:
                    textMindElementContent.x =  marginPoint.left + size.getWidth() - textMindElementContent.width;
                    textMindElementContent.y = marginPoint.top;
                    this.data.latexContent.x = marginPoint.left + size.getWidth() - this.latexOriginalSize.getWidth();
                    this.data.latexContent.y = textMindElementContent.y + textMindElementContent.height;
                    break;
                case MindElementAlignmentType.MIDDLE:
                    textMindElementContent.x =  marginPoint.left + (size.getWidth() - textMindElementContent.width)/2;
                    textMindElementContent.y = marginPoint.top;
                    this.data.latexContent.x = marginPoint.left + (size.getWidth() - this.latexOriginalSize.getWidth())/2;
                    this.data.latexContent.y = textMindElementContent.y + textMindElementContent.height;
                    break;
                default: //case LEFT
                    textMindElementContent.x = marginPoint.left;
                    textMindElementContent.y = marginPoint.top;
                    this.data.latexContent.x = marginPoint.left + edgeInsets.left;
                    this.data.latexContent.y = textMindElementContent.y + textMindElementContent.height;
            }
        } else {
            this.data.latexContent.x = marginPoint.left + edgeInsets.left;
            this.data.latexContent.y = marginPoint.top + edgeInsets.top;
        }
        this.data.latexContent.width = this.latexOriginalSize.getWidth();
        this.data.latexContent.height = this.latexOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}

export default TextAndLatexCalculation

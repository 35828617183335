import Point from '../../../../viewmodel/core/base/Point'
import Size from '../../../../viewmodel/core/base/Size'

import UiUtil from '../../../../utils/UiUtil'
import Util from '../../../../utils/Util'
import MindElementType from '../../../../viewmodel/datatype/MindElementType'
import BaseMindElementDataCalculation from './BaseMindElementDataCalculation'
/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class TextCalculation extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        let textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        if (textMindElementContent == null) {
            return;
        }
        let marginPoint = this.getMargin(new Size(textMindElementContent.width, textMindElementContent.height));
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        textMindElementContent.x = marginX;
        textMindElementContent.y = marginY;
        // this.setMindElementSize(textMindElementContent.width, textMindElementContent.height, marginX, marginY);
        this.setMindElementSize(textMindElementContent.width, textMindElementContent.height, marginPoint);
        
    }
}

export default TextCalculation

// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

// import mind.yushu.com.mindmap.utils.Colors;
import Colors from '../../../utils/Colors'


// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.MindElementContent;
import MindElementContent from './MindElementContent';
import TextStyleCell from './TextStyleCell';

/**
 * Created by tony on 2019/12/18.
 */

 class TextContent extends MindElementContent {
    constructor(text="") {
        super();
        this.text = text;
        this.bgColor = -10000;
        this.textShadow = -10000;
        this.textColor = Colors.black95;
        this.textColorAlpha = 1.0;
        this.textFontSize = 18;
        this.textBold = false;
        this.textItalics = false;
        this.textStrikethrough = false;
        this.contentInsetLeft = 1;
        this.contentInsetTop = 1;
        this.contentInsetRight = 1;
        this.contentInsetBottom = 1
        this.styleCells = new Array();
        this.fontFamily = "";
    }

    getContentText() {
        return this.text;
    }

    setContentText(text) {
        this.text = text;
    }

    copy() {
        let data = new TextContent(this.text);
        data.x = this.x;
        data.y = this.y;
        data.width = this.width;
        data.height = this.height;

        data.text = this.text;
        data.bgColor = this.bgColor;
        data.textShadow = this.textShadow;
        data.textColor = this.textColor;
        data.textFontSize = this.textFontSize;
        data.textBold = this.textBold;
        data.textItalics = this.textItalics;
        data.textStrikethrough = this.textStrikethrough;
        data.contentInsetLeft = this.contentInsetLeft;
        data.contentInsetTop = this.contentInsetTop;
        data.contentInsetRight = this.contentInsetRight;
        data.contentInsetBottom = this.contentInsetBottom;
        data.textColorAlpha = this.textColorAlpha;
        data.fontFamily = this.fontFamily;

        var list = new Array();
        if (this.styleCells != null && this.styleCells.length > 0) {
            let count = this.styleCells.length;
            if (count > 0) {
                for (let index = 0; index < count; index++) {
                    list.push(this.styleCells[index].copy());
                }
            }
        }
        data.styleCells = list;

        return data;
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.text != this.text;
        if (!Colors.isClear(data.bgColor) || !Colors.isClear(this.bgColor)) {
            result = result || data.bgColor != this.bgColor;
        }
        if (!Colors.isClear(data.textColor) || !Colors.isClear(this.textColor)) {
            result = result || data.textColor != this.textColor;
        }
        result = result || data.textFontSize != this.textFontSize;
        result = result || data.textBold != this.textBold;
        result = result || data.textItalics != this.textItalics;
        result = result || data.textStrikethrough != this.textStrikethrough;
        // result = result || data.contentInsetLeft != this.contentInsetLeft;
        // result = result || data.contentInsetTop != this.contentInsetTop;
        // result = result || data.contentInsetRight != this.contentInsetRight;
        // result = result || data.contentInsetBottom != this.contentInsetBottom;
        result = result || data.textColorAlpha != this.textColorAlpha;
        result = result || data.textShadow != this.textShadow;
        result = result || data.fontFamily != this.fontFamily;
        
        result = result || data.styleCells != null && this.styleCells == null;
        result = result || data.styleCells == null && this.styleCells != null;
        
        if (!result && data.styleCells != null && this.styleCells != null) {
            result = result || data.styleCells.length != this.styleCells.length;
            if (!result) {
                let count = data.styleCells.length;
                if (count > 0) {
                    for (let index = 0; index < count; index++) {
                        result = result || !data.styleCells[index].equal(this.styleCells[index]);
                    }
                }
            }
        }
        return !result;
    }

    stickStyle(data) {
        if (data == null) {
            return;
        }
        this.textColor = data.textColor;
        this.textShadow = data.textShadow;
        this.textFontSize = data.textFontSize;
        this.textBold = data.textBold;
        this.textItalics = data.textItalics;
        this.textStrikethrough = data.textStrikethrough;
        this.textColorAlpha = data.textColorAlpha;
        this.fontFamily = data.fontFamily;
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
        this.textColor = data.textColor;
        this.textShadow = data.textShadow;
        this.textColorAlpha = data.textColorAlpha;
    }

    setTextBgColor(start, end, color) {
        if (start < 0 || end < 0 ||
                start > end || start == end ||
                (start == 0 && end >= this.text.length)) {
            this.bgColor = color;
            if (this.styleCells != null && (start == 0 && end >= this.text.length)) {
                let cell = new TextStyleCell()
                for (let index = 0 ; index<this.styleCells.length ; index++) {
                    cell = this.styleCells[index];
                    cell.bgColor = color;
                }
            }
        } else {
            if (this.styleCells == null) {
                this.styleCells = new Array();
            }
            let isNew = true;
            let allContainCells = new Array();
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index];
                cell.bgColor = color;
                if (cell.startIndex == start && cell.endIndex == end) {
                    isNew = false;
                }
            }
            if (isNew) {
                let cell = new TextStyleCell();
                cell.startIndex = start;
                cell.endIndex = end;
                cell.bgColor = color;
                this.styleCells.push(cell);
            }
        }
    }

    setTextShadow(start, end, color) {
        if (start < 0 || end < 0 ||
                start > end || start == end ||
                (start == 0 && end >= this.text.length)) {
            this.textShadow = color;
            if (this.styleCells != null && (start == 0 && end >= this.text.length)) {
                let cell = new TextStyleCell()
                for (let index = 0 ; index<this.styleCells.length ; index++) {
                    cell = this.styleCells[index];
                    cell.textShadow = color;
                }
            }
        } else {
            if (this.styleCells == null) {
                this.styleCells = new Array();
            }
            let isNew = true;
            let allContainCells = new Array();
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index];
                cell.textShadow = color;
                if (cell.startIndex == start && cell.endIndex == end) {
                    isNew = false;
                }
            }
            if (isNew) {
                let cell = new TextStyleCell();
                cell.startIndex = start;
                cell.endIndex = end;
                cell.textShadow = color;
                this.styleCells.push(cell);
            }
        }
    }

    setTextColor( start, end, color) {
        if (start < 0 || end < 0 ||
                start > end || start == end ||
                (start == 0 && end >= this.text.length)) {
            this.textColor = color;
            if (this.styleCells != null && (start == 0 && end >= this.text.length)) {
                let cell = new TextStyleCell()
                for (let index = 0 ; index<this.styleCells.length ; index++) {
                    cell = this.styleCells[index];
                    cell.textColor = color;
                }
            }
        } else {
            if (this.styleCells == null) {
                this.styleCells = new Array();
            }
            let isNew = true;
            let allContainCells = new Array();
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index];
                cell.textColor = color;
                if (cell.startIndex == start && cell.endIndex == end) {
                    isNew = false;
                }
            }
            if (isNew) {
                let cell = new TextStyleCell();
                cell.startIndex = start;
                cell.endIndex = end;
                cell.textColor = color;
                this.styleCells.push(cell);
            }
        }
    }

    setTextFontSize(start, end, textFontSize) {
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            this.textFontSize = textFontSize;
            if (this.styleCells != null && (start == 0 && end >= this.text.length)) {
                let cell = new TextStyleCell()
                for (let index = 0 ; index<this.styleCells.length ; index++) {
                    cell = this.styleCells[index];
                    cell.textFontSize = textFontSize;
                }
            }
        } else {
            if (this.styleCells == null) {
                this.styleCells = new Array();
            }
            let isNew = true;
            let allContainCells = new Array();
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index];
                cell.textFontSize = textFontSize;
                if (cell.startIndex == start && cell.endIndex == end) {
                    isNew = false;
                }
            }
            if (isNew) {
                let cell = new TextStyleCell()
                cell.startIndex = start;
                cell.endIndex = end;
                cell.textFontSize = textFontSize;
                this.styleCells.push(cell);
            }
        }
    }

    setTextFontSizeUp(start, end) {
        if (start < 0 || end < 0 ||
                start > end || start == end ||
                (start == 0 && end >= this.text.length)) {
            if (this.textFontSize < 40) {
                this.textFontSize += 1;
            }

            if (start == 0 && end >= this.text.length) {
                let cell = new TextStyleCell()
                for (let index = 0 ; index<this.styleCells.length ; index++) {
                    cell = this.styleCells[index]
                    if (cell.isSetFontSize()) {
                        if (cell.textFontSize < 40) {
                            cell.textFontSize += 1;
                        }
                    } else {
                        cell.textFontSize = this.textFontSize + 1;
                    }
                }
            }
        } else {
            let isNew = true;
            let allContainCells = new Array();
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                if (cell.isSetFontSize()) {
                    if (cell.textFontSize < 40) {
                        cell.textFontSize += 1;
                    }
                } else {
                    cell.textFontSize = textFontSize + 1;
                }
                if (cell.startIndex == start && cell.endIndex == end) {
                    isNew = false;
                }
            }
            if (isNew) {
                let cell = new TextStyleCell()
                cell.startIndex = start;
                cell.endIndex = end;
                if (cell.isSetFontSize()) {
                    if (cell.textFontSize < 40) {
                        cell.textFontSize += 1;
                    }
                } else {
                    cell.textFontSize = textFontSize + 1;
                }
                this.styleCells.push(cell);
            }
        }
    }

    setTextFontSizeDown(start, end) {
        if (start < 0 || end < 0 ||
                start > end || start == end ||
                (start == 0 && end >= this.text.length)) {
            if (this.textFontSize > 10) {
                this.textFontSize -= 1;
            }

            if (start == 0 && end >= this.text.length) {
                let cell = new TextStyleCell()
                for (let index = 0 ; index<this.styleCells.length ; index++) {
                    cell = this.styleCells[index]
                    if (cell.isSetFontSize()) {
                        if (cell.textFontSize > 10) {
                            cell.textFontSize -= 1;
                        }
                    } else {
                        cell.textFontSize = this.textFontSize - 1;
                    }
                }
            }
        } else {
            let isNew = true;
            let allContainCells = new Array();
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                if (cell.isSetFontSize()) {
                    if (cell.textFontSize > 10) {
                        cell.textFontSize -= 1;
                    }
                } else {
                    cell.textFontSize = this.textFontSize - 1;
                }
                if (cell.startIndex == start && cell.endIndex == end) {
                    isNew = false;
                }
            }
            if (isNew) {
                let cell = new TextStyleCell()
                cell.startIndex = start;
                cell.endIndex = end;
                if (cell.textFontSize > 10) {
                    cell.textFontSize = this.textFontSize - 1;
                }
                this.styleCells.push(cell);
            }
        }
    }

    changeTextInfo( info) {
        if (this.styleCells == null || this.styleCells.isEmpty() || info.getCount() == info.getBefore()) {
            return;
        }
        if (info.getBefore() > 0 && info.getCount() == 0) {//删除
            let cell = new TextStyleCell()
            for (let index = 0 ; index<this.styleCells.length ; index++) {
                cell = this.styleCells[index]
                if (cell.startIndex > info.getStart()) {
                    if (cell.startIndex >= info.getStart() + info.getBefore()) {
                        cell.startIndex = cell.startIndex - info.getBefore();
                        if (cell.startIndex < 0) {
                            cell.startIndex = 0;
                        }
                    } else {
                        cell.startIndex = info.getStart();
                    }

                }
                if (cell.endIndex >= info.getStart()) {
                    if (cell.endIndex >= info.getStart() + info.getBefore()) {
                        cell.endIndex = cell.endIndex - info.getBefore();
                    } else {
                        cell.endIndex = info.getStart();
                    }
                }
            }
        } else if (info.getBefore() == 0 && info.getCount() > 0){//增加
            let cell = new TextStyleCell()
            for (let index = 0 ; index<this.styleCells.length ; index++) {
                cell = this.styleCells[index]
                if (cell.startIndex >= info.getStart()) {
                    cell.startIndex = cell.startIndex + info.getCount();
                }
                if (cell.endIndex >= info.getStart() - info.getCount()) {
                    cell.endIndex = cell.endIndex + info.getCount();
                }
            }
        }
        let newList = new Array();
        let cell = new TextStyleCell()
        for (let index = 0 ; index<this.styleCells.length ; index++) {
            cell = this.styleCells[index]
            if (cell.startIndex < cell.endIndex &&
                    cell.endIndex > 0 &&
                    cell.endIndex <= this.text.length) {
                newList.push(cell);
            }
        }
        this.styleCells = newList;
    }

    setTextBoldByPoint(start, end) {
        var bold = !this.textBold
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            bold = !this.textBold;
        } else {
            let allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                if (cell.startIndex == start && 
                    cell.endIndex == end &&
                    cell.textBold.isSet()) {
                    bold = !cell.textBold.isValue()
                }
            }
        }
        this.setTextBold(start, end, bold);
    }
    
    setTextStrikethroughByPoint(start, end) {
        var strikethrough = !this.textStrikethrough
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            strikethrough = !this.textStrikethrough;
        } else {
            let allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                if (cell.startIndex == start && 
                    cell.endIndex == end &&
                    cell.textStrikethrough.isSet()) {
                    strikethrough = !cell.textStrikethrough.isValue()
                }
            }
        }
        this.setTextStrikethrough(start, end, strikethrough);
    }

    setTextItalicsByPoint(start, end) {
        var italics = !this.textItalics
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            italics = !this.textItalics;
        } else {
            let allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                if (cell.startIndex == start && 
                    cell.endIndex == end &&
                    cell.textItalics.isSet()) {
                    italics = !cell.textItalics.isValue()
                }
            }
        }
        this.setTextItalics(start, end, italics);
    }

    setTextBold(start, end, textBold) {
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            this.textBold = textBold;
            if (this.styleCells != null && (start == 0 && end >= this.text.length)) {
            let cell = new TextStyleCell()
            for (let index = 0 ; index<this.styleCells.length ; index++) {
                cell = this.styleCells[index]
                    cell.setTextBold(textBold);
                }
            }
        } else {
            if (this.styleCells == null) {
                this.styleCells = new Array();
            }
            let isNew = true;
            let allContainCells=new Array(); 
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                cell.setTextBold(textBold);
                if (cell.startIndex == start && cell.endIndex == end) {
                    isNew = false;
                }
            }
            if (isNew) {
                let cell = new TextStyleCell()
                cell.startIndex = start;
                cell.endIndex = end;
                cell.setTextBold(textBold);
                this.styleCells.push(cell);
            }
        }
    }

    setTextItalics( start, end, textItalics) {
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            this.textItalics = textItalics;
            if (this.styleCells != null && (start == 0 && end >= this.text.length)) {
                let cell = new TextStyleCell()
                for (let index = 0 ; index<this.styleCells.length ; index++) {
                    cell = this.styleCells[index]
                    cell.setTextItalics(textItalics);
                }
            }
        } else {
            if (this.styleCells == null) {
                this.styleCells = new Array();
            }
            let isNew = true;
            let allContainCells=new Array(); 
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                cell.setTextItalics(textItalics);
                if (cell.startIndex == start && cell.endIndex == end) {
                    isNew = false;
                }
            }
            if (isNew) {
                let cell = new TextStyleCell()
                cell.startIndex = start;
                cell.endIndex = end;
                cell.setTextItalics(textItalics);
                this.styleCells.push(cell);
            }
        }
    }
    
    setTextStrikethrough( start, end, textStrikethrough) {
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            this.textStrikethrough = textStrikethrough;
            if (this.styleCells != null && (start == 0 && end >= this.text.length)) {
                let cell = new TextStyleCell()
                for (let index = 0 ; index<this.styleCells.length ; index++) {
                    cell = this.styleCells[index]
                    cell.setTextStrikethrough(textStrikethrough);
                }
            }
        } else {
            if (this.styleCells == null) {
                this.styleCells = new Array();
            }
            let isNew = true;
            let allContainCells = new Array(); 
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                cell.setTextStrikethrough(textStrikethrough);
                if (cell.startIndex == start && cell.endIndex == end) {
                    isNew = false;
                }
            }
            if (isNew) {
                let cell = new TextStyleCell()
                cell.startIndex = start;
                cell.endIndex = end;
                cell.setTextStrikethrough(textStrikethrough);
                this.styleCells.push(cell);
            }
        }
    }

    getTextBold( start, end) {
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            return this.textBold;
        } else {
            let allContainCells = new Array(); 
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = this.styleCells[index]
                if (cell.textBold.isSet()) {
                    return cell.textBold.isValue();
                }
            }
        }
        return this.textBold;
    }

    getTextItalics( start, end) {
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            return this.textItalics;
        } else {
            let allContainCells = new Array(); 
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                if (cell.textItalics.isSet()) {
                    return cell.textItalics.isValue();
                }
            }
        }
        return this.textItalics;
    }

    getTextStrikethrough( start, end) {
        if (start < 0 || end < 0 || start > end || start == end || (start == 0 && end >= this.text.length)) {
            return this.textStrikethrough;
        } else {
            let allContainCells = new Array(); 
            allContainCells = this.getAllContainTextStyleCells(start, end);
            let cell = new TextStyleCell()
            for (let index = 0 ; index<allContainCells.length ; index++) {
                cell = allContainCells[index]
                if (cell.textStrikethrough.isSet()) {
                    return cell.textStrikethrough.isValue();
                }
            }
        }
        return this.textStrikethrough;
    }

    getAllContainTextStyleCells(start, end) {
        let list = new Array();
        if (this.styleCells == null) {
            return list;
        }

        let cell = new TextStyleCell()
        for (let index = 0; index<this.styleCells.length; index++) {
            cell = this.styleCells[index];
            if (cell.startIndex >= start && cell.endIndex <= end) {
                list.push(cell);
            }
        }
        return list;
    }

    isBackgorunColor() {
        return this.bgColor != -10000 && !Colors.isClear(this.bgColor);
    }
    
    isTextShadowColor() {
        return this.textShadow != -10000 && !Colors.isClear(this.textShadow);
    }

}
export default TextContent
class Size {
    constructor(width = 0, height = 0) {
        this.width = width
        this.height = height
    }

    getWidth() {
        return this.width;
    }

    getHeight(){
        return this.height
    }
}
export default Size
<template>
  <main class="setting-box" @click="exitKeyboardEdit">
    <header class="setting-head">
      <h2 class="setting-title">
        {{ getString(Strings.Mind_Group_Setting_Up) }}
      </h2>
      <div class="close-setting">
        <!-- <a-icon type="close" class="icon-remove" @click="hideSettingModel" /> -->
        <img
          class="close-button-img"
          src="../../../assets/img/popupsImg/colse_new.svg"
          @click="hideSettingModel"
        />
      </div>
    </header>
    <article class="setting-containner">
      <aside class="setting-left-content">
        <div
          class="left-navigation-item"
          :class="{ 'active-navigation-item': leftNavigationActive == item.id }"
          v-for="(item, index) in leftNavigationList"
          :key="index"
          :title="item.title"
          @click="switchNavigationActive(item.id)"
        >
          {{ item.title }}
        </div>
      </aside>
      <div class="setting-right-content">
        <!-- 操作设置  -->
        <section
          class="setting-item"
          v-if="leftNavigationActive == LeftNavigationId.OperationSettings"
        >
          <div class="operation-settings">
            <h4 class="operation-title">
              {{ getString(Strings.Operation_Settings) }}
            </h4>
            <section class="operation-container">
              <div class="create-free-node">
                <span class="operation-label">
                  <!-- 双击空白处增加自由节点 -->
                  {{ getString(Strings.Double_Click_Add_Free_Node) }}
                </span>
                <a-switch
                  v-model="createFreeNodeSwitch"
                  size="small"
                  @change="onChangeCreateFreeNode"
                />
              </div>

              <div class="create-free-node" style="margin-top:20px;">
                <span class="operation-label">
                  <!-- 导图中导出图片边距 -->
                  {{ getString(Strings.Export_picture_margins_from_map) }}
                </span>
                <a-input-number
                  id="inputNumber"
                  style="width: 62px"
                  v-model="exportImageSpace"
                  :step="10"
                  :min="1"
                  :max="200"
                  @change="onChangeExportImageSpace"
                />
              </div>
            </section>
          </div>
        </section>
        <!-- 快捷键设置   -->
        <section
          class="setting-item"
          v-else-if="
            leftNavigationActive == LeftNavigationId.ShortcutKeySettings
          "
        >
          <div class="shortcut-key-settings">
            <h4 class="hotkey-title">
              {{ getString(Strings.Shortcut_key_Settings) }}
              <span class="hotkey-placeholder" v-if="keyboardEdit">
                ({{ getString(Strings.Press_Key_Combination) }})
              </span>
              <button
                v-if="reductionKey"
                class="reduction-btn cursor-pointer"
                @click="reductionKeyboard"
              >
                <!-- 还原 -->
                {{ getString(Strings.Mind_Reduction) }}
              </button>
            </h4>
            <div class="hotkey-head">
              <div class="hotkey-describe">
                {{ getString(Strings.Mind_Usinghelp_Btn_3) }}
              </div>
              <div class="hotkey">
                {{ getString(Strings.Mind_Usinghelp_13) }}
              </div>
            </div>
            <section class="hotkey-container">
              <div
                v-for="item in hotkeyData"
                :key="item.type"
                @click.stop="keyboard(item.type)"
                class="hotkey-box"
                :class="{ 'hotkey-active': keyboardType == item.type }"
              >
                <div class="hotkey-left" :title="item.describe">
                  {{ item.describe }}
                </div>
                <div class="hotkey-right">
                  <span class="key">
                    {{ item.shortcutKey }}
                  </span>
                </div>
              </div>
            </section>
            <div class="shortcut-key-bottom" v-if="false">
              <button class="cancel cursor-pointer" @click="hideSettingModel">
                {{ getString(Strings.Global_Cancel) }}
              </button>
              <button class="confirm cursor-pointer" @click="save">
                {{ getString(Strings.Public_Header_Save) }}
              </button>
            </div>
          </div>
        </section>
        <!-- 账号安全   -->
        <section
          class="setting-item"
          v-else-if="leftNavigationActive == LeftNavigationId.AccountSecurity"
        >
          <div class="operation-settings">
            <h4 class="operation-title">
              {{ getString(Strings.Account_Security) }} 
            </h4>
          </div>
          <section class="account-security-box">
            <!-- 手机号 -->
            <div class="account-security-item">
              <div class="account-item-title">
                {{ getString(Strings.Cell_Phone_Number) }} 
              </div>
              <div class="account-item-operation">
                <template v-if="accountSecurityData.phoneNumber">
                  <section class="account-item-value">
                    {{ accountSecurityData.phoneNumber.substring(3) }}
                  </section>
                  <div
                    class="account-item-click"
                    @click="cellPhoneModelfun(true, 'change')"
                  >
                    <!-- 更换 -->
                    {{ getString(Strings.Mind_Replace) }} 
                  </div>
                </template>
                <template v-else>
                  <section class="account-item-not-value">
                    <!-- 未绑定 -->
                    {{ getString(Strings.Mind_Unbound) }}
                  </section>
                  <div
                    class="account-item-click"
                    @click="cellPhoneModelfun(true, 'bind')"
                  >
                    <!-- 去绑定 -->
                    {{ getString(Strings.Mind_Debinding) }}
                  </div>
                </template>
              </div>
            </div>
            <!-- 邮箱 -->
            <div class="account-security-item">
              <div class="account-item-title">
                {{ getString(Strings.Contact_Us_Email) }}
              </div>
              <div class="account-item-operation">
                <template v-if="accountSecurityData.email">
                  <section class="account-item-value">
                    {{ accountSecurityData.email }}
                  </section>
                  <div
                    class="account-item-click"
                    @click="emailModelfun(true, 'change')"
                  >
                    <!-- 更换 -->
                    {{ getString(Strings.Mind_Replace) }} 
                  </div>
                </template>
                <template v-else>
                  <section class="account-item-not-value">
                    <!-- 未绑定 -->
                    {{ getString(Strings.Mind_Unbound) }}
                  </section>
                  <div
                    class="account-item-click"
                    @click="emailModelfun(true, 'bind')"
                  >
                    <!-- 去绑定 -->
                    {{ getString(Strings.Mind_Debinding) }}
                  </div>
                </template>
              </div>
            </div>
            <!-- 密码 -->
            <div class="account-security-item">
              <div class="account-item-title">
                {{ getString(Strings.Mind_Edit_Page_Password) }}
              </div>
              <div class="account-item-operation">
                <template v-if="accountSecurityData.hasPassword">
                  <div
                    class="account-item-click"
                    @click="passwordModelfun(true)"
                  >
                    <!-- 修改密码 -->
                    {{ getString(Strings.Change_Password) }}
                  </div>
                </template>
                <template v-else>
                  <section class="account-item-not-value">
                    <!-- 未设置 -->
                    {{ getString(Strings.Mind_Not_Set) }}
                  </section>
                  <div
                    class="account-item-click"
                    @click="passwordModelfun(true)"
                  >
                    <!-- 修改密码 -->
                    {{ getString(Strings.Change_Password) }}
                  </div>
                </template>
              </div>
            </div>
            <!-- 已登录设备 -->
            <div class="account-security-item">
              <div class="account-item-title">
                {{ getString(Strings.Logged_Device) }}
              </div>
              <div class="account-item-operation">
                <a-popover
                  :title="item.deviceName"
                  v-for="(item, index) in boundMachinesData"
                  :key="index"
                  :getPopupContainer="
                    (triggerNode) => {
                      return triggerNode.parentNode;
                    }
                  "
                >
                  <template slot="content">
                    <div v-if="item.id == currentEquipment">
                      <!-- 当前设备 -->
                      {{ getString(Strings.Current_Equipment) }}
                    </div>
                    <a-button
                      v-else
                      class="log-out-button"
                      @click="logOut(item.id)"
                    >
                      {{ getString(Strings.Sign_Out) }}
                    </a-button>
                  </template>
                  <!-- <div class="account-item-click" v-if="item.id == 0">
                    {{ item.deviceName }}
                  </div> -->
                  <img
                    :src="
                      require(`../../../assets/img/setting/device/${item.img}`)
                    "
                    class="device-img"
                    :alt="item.deviceName"
                  />
                </a-popover>
              </div>
            </div>
          </section>
          <div
            v-if="otherAccountNumberShow"
            style="
              width: 100%;
              height: 10px;
              background: #efefef;
              position: absolute;
              left: 0;
            "
          ></div>
          <section v-if="otherAccountNumberShow" class="third-party-account-box">
            <div class="third-party-account-title">
              绑定第三方账号可快速登录
            </div>
            <div class="third-party-account-item margin-top-20">
              <div class="third-party-item-title">QQ</div>
              <div class="third-party-item-operation">
                <template v-if="accountSecurityData.isQQBound">
                  <div class="third-party-item-click" @click="unbind(2, 'QQ')">
                    <span v-if="accountSecurityData.qq">{{
                      accountSecurityData.qq
                    }}</span>
                    <span v-else>解除绑定</span>
                  </div>
                </template>
                <template v-else>
                  <section class="third-party-item-not-value">未绑定</section>
                </template>
              </div>
            </div>
            <div class="third-party-account-item">
              <div class="third-party-item-title">微信</div>
              <div class="third-party-item-operation">
                <template v-if="accountSecurityData.isWechatBound">
                  <div
                    class="third-party-item-click"
                    @click="unbind(1, '微信')"
                  >
                    <span v-if="accountSecurityData.wechat">{{
                      accountSecurityData.wechat
                    }}</span>
                    <span v-else>解除绑定</span>
                  </div>
                </template>
                <template v-else>
                  <section class="third-party-item-not-value">未绑定</section>
                </template>
              </div>
            </div>
            <div class="third-party-account-item">
              <div class="third-party-item-title">微博</div>
              <div class="third-party-item-operation">
                <template v-if="accountSecurityData.isWeiboBound">
                  <div
                    class="third-party-item-click"
                    @click="unbind(3, '微博')"
                  >
                    <span v-if="accountSecurityData.weibo">{{
                      accountSecurityData.weibo
                    }}</span>
                    <span v-else>解除绑定</span>
                  </div>
                </template>
                <template v-else>
                  <section class="third-party-item-not-value">未绑定</section>
                </template>
              </div>
            </div>
            <div class="third-party-account-item">
              <div class="third-party-item-title">Apple账号</div>
              <div class="third-party-item-operation">
                <template v-if="accountSecurityData.isAppleBound">
                  <div
                    class="third-party-item-click"
                    @click="unbind(5, 'Apple账号')"
                  >
                    <span v-if="accountSecurityData.appleId">{{
                      accountSecurityData.appleId
                    }}</span>
                    <span v-else>解除绑定</span>
                  </div>
                </template>
                <template v-else>
                  <section class="third-party-item-not-value">未绑定</section>
                </template>
              </div>
            </div>
          </section>
        </section>
        <!-- 主题  -->
        <section
          class="setting-item"
          v-if="leftNavigationActive == LeftNavigationId.Theme"
        >
          <div class="operation-settings">
            <h4 class="operation-title">
              主题
              <!-- {{ getString(Strings.Mind_Theme) }} 需要国际化-->
            </h4>
            <section class="operation-container">
              <div class="create-free-node">
                <span class="operation-label" style="margin-right:40px;">
                  选择你的主题色
                  <!-- {{ getString(Strings.Double_Click_Add_Free_Node) }} -->
                </span>
                  <a-select
                    v-model="themeValue"
                    style="width: 85px;"
                    @change="themeChange"
                  >
                    <!-- <a-icon slot="suffixIcon" style="color: #333" type="caret-down" /> -->
                    <a-select-option
                      v-for="item of themeList"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
              </div>
            </section>
          </div>
        </section>
      </div>
    </article>
    <!-- 手机号弹框 -->
    <CellPhoneModel
      v-if="cellPhoneModelShow"
      :showType="phoneBindOrChange"
      @cellPhoneModelfun="cellPhoneModelfun"
    />
    <!-- 邮箱弹框 -->
    <EmailModel
      v-if="emailModelShow"
      :showType="emailBindOrChange"
      @emailModelfun="emailModelfun"
    />
    <!-- 密码弹框 -->
    <PasswordModel
      v-if="passwordModelShow"
      :showType="passwordShowType"
      @passwordModelfun="passwordModelfun"
    />
  </main>
</template>

<script>
import MindSettingManager from "../../../viewmodel/core/tools/setting/MindSettingManager";
import Strings from "../../../common/lang/strings";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import { getMachineId, getJwt } from "../../../common/netWork/base";
import { mapMutations } from "vuex";
import {
  postUserAccount,
  postUserBoundMachines,
  postUserUnbindMachine,
  postUserChangeThirdLogin,
} from "../../../common/netWork/base_api";
import CellPhoneModel from "./settingChild/CellPhoneModel";
import EmailModel from "./settingChild/EmailModel";
import PasswordModel from "./settingChild/PasswordModel";
const LeftNavigationId = {
  OperationSettings: "operationSettings",
  ShortcutKeySettings: "shortcutKeySettings",
  AccountSecurity: "accountSecurity",
  Theme: "theme",
};
function ajaxCommon(callback, params, onOk, onFail) {
  callback(
    params,
    (res) => {
      if (onOk) {
        onOk(res);
      }
    },
    (error) => {
      if (onOk) {
        onFail(error);
      }
    }
  );
}

export default {
  components: {
    CellPhoneModel,
    EmailModel,
    PasswordModel,
  },
  data() {
    return {
      Strings,
      strings,
      LeftNavigationId,
      leftNavigationList: [
        {
          title: getString(Strings.Operation_Settings),
          id: LeftNavigationId.OperationSettings,
        },
        {
          title: getString(Strings.Shortcut_key_Settings),
          id: LeftNavigationId.ShortcutKeySettings,
        },
        {
          title:getString(Strings.Account_Security),
          id: LeftNavigationId.AccountSecurity,
        },
        // {
        //   title: "主题",
        //   // title:getString(Strings.Mind_Theme),需要国际化
        //   id: LeftNavigationId.Theme,
        // },
      ],
      leftNavigationActive: LeftNavigationId.OperationSettings,
      //快捷键设置------------
      // setKeyboard:false,
      keyboardType: 0, //设置快捷键类型
      tempKeyboardType: 0, //上一次设置快捷键类型
      keyboardEdit: false,
      createFreeNodeSwitch: false,
      reductionKey: false,
      keyName: "",
      shortcutKeyName: "",
      hotkeyData: [],
      keydownTime: null,
      //账号安全------------
      accountSecurityData: {}, //账号安全数据
      boundMachinesData: [], //已登录设备数据
      cellPhoneModelShow: false, //显示手机号弹框
      phoneBindOrChange: "", //手机号弹框标题，bind：绑定手机号；change：更换手机号
      emailModelShow: false, //显示邮箱弹框
      emailBindOrChange: "", //邮箱弹框标题，bind：绑定邮箱；change：更换邮箱
      passwordModelShow: false, //显示密码弹框
      passwordShowType: "", //密码弹框弹框显示类型，emailType：邮箱修改密码；phoneType：手机号修改密码
      currentEquipment: getMachineId(), //当前设备
      otherAccountNumberShow: false, //第三方账号显示隐藏
      exportImageSpace:40,
      //主题------------
      themeValue: 0, //排序方式
      themeList: [
        {
          label: '自动', //自动
          value: 0,
        },
        {
          label: '浅色', //浅色
          value: 1,
        },
        {
          label: '深色', //深色
          value: 2,
        },
      ],
    };
  },
  created() {
    let settingItemActiveValue = localStorage.getItem("settingItemActive");
    if (settingItemActiveValue) {
      this.leftNavigationActive = settingItemActiveValue;
    }
    this.isMacComputed = this.$tools.isMacComputed();
    MindSettingManager.getMacComputed(this.isMacComputed);
    this.createFreeNodeSwitch =
      MindSettingManager.getDoubleClickCreateFreeNode(); //获取是否双击空白处增加自由节点
    this.exportImageSpace = MindSettingManager.getMindExportImageSpace();
    this.loadKeyboard();
    this.contrastKeyboard();
  },
  mounted() {
    
  //  document.documentElement.style.setProperty('--borderColor', 'blue');
  },
  methods: {
    ...mapMutations([
      "showLoginModel",

    ]),
    getString(i) {
      return getString(i);
    },
    exitKeyboardEdit() {
      this.keyboardType = this.tempKeyboardType = 0;
      document.removeEventListener("keydown", this.shortcutFun, false);
      document.removeEventListener("keyup", this.keyupFun, false);
      this.keyboardEdit = false;
      this.loadKeyboard();
    },
    switchNavigationActive(val) {
      if (val == LeftNavigationId.AccountSecurity) {
        //账号安全必须要登录 
        if (!getJwt("jwt")) {
          this.showLoginModel(false);
          setTimeout(() => {
            this.showLoginModel(true);
          }, 100);
          return false;
        }
      }
      this.leftNavigationActive = val;
      localStorage.setItem("settingItemActive", val);
    },
    keyboard(type) {
      if (type == this.tempKeyboardType) {
        this.exitKeyboardEdit();
      } else {
        this.keyboardType = this.tempKeyboardType = type;
        document.addEventListener("keydown", this.shortcutFun);
        document.addEventListener("keyup", this.keyupFun);
        this.keyboardEdit = true;
      }
      // console.log(type);
      // console.log(this.keyboardType , this.tempKeyboardType);

      // this.setKeyboard = !this.setKeyboard;
    },
    loadKeyboard() {
      MindSettingManager.loadData(); //加载自定义的快捷键
      this.hotkeyData = MindSettingManager.getMindKeyboards(); //获取快捷键数据
    },
    setKeyboard(keyName, shortcutKeyName) {
      if (
        MindSettingManager.setMindKeyboards(
          this.keyboardType,
          shortcutKeyName,
          keyName
        )
      ) {
        // this.hotkeyData.find(item => item.type===this.keyboardType).shortcutKey = keyName;
        this.loadKeyboard();
        this.contrastKeyboard();
      } else {
        // '快捷键冲突'
        let keyboardType = this.keyboardType - 1;
        this.hotkeyData[keyboardType].shortcutKey = getString(
          Strings.Shortcut_Conflict
        );
        setTimeout(() => {
          this.loadKeyboard();
        }, 2000);
      }
    },
    shortcutFun(e) {
      e.preventDefault();
      e.returnValue = false;
      const keyCode = window.event ? e.keyCode : e.which; //获取被按下的键值
      let controlKey = e.ctrlKey;
      if (controlKey) {
        this.keyName += "Control+";
        this.shortcutKeyName += "17+";
      }
      let metaKey = e.metaKey;
      if (metaKey) {
        this.keyName += "Meta+";
        this.shortcutKeyName += "Meta+";
      }
      let shiftKey = e.shiftKey;
      if (shiftKey) {
        this.keyName += "Shift+";
        this.shortcutKeyName += "10+";
      }
      this.keyName += (e.key === "+" ? "Plus" : e.key) + "+";
      this.shortcutKeyName += keyCode + "+";
      clearTimeout(this.keydownTime);
      this.keydownTime = setTimeout(() => {
        if (!controlKey && !metaKey) {
          this.keyupFun(e);
        }
      }, 500); //判断是否按下多个按键
      // console.log(keyCode,e.key);
    },
    keyupFun(e) {
      let keyName = this.keyName;
      if (keyName == "") {
        return;
      }
      keyName = keyName.slice(0, keyName.length - 1);
      keyName = keyName.split("+");
      keyName = Array.from(new Set(keyName));
      keyName = keyName.map((item) =>
        item.replace(item[0], item[0].toUpperCase())
      );
      keyName = keyName.join("+");
      keyName = keyName
        .replace("Control", "Ctrl")
        .replace("Meta", "Command")
        .replace(" ", "Space")
        .replace("Plus", "+");
      // 拼接字符串和ComplieLeftContent.vue对应，修改一个两个都要改
      this.keyName = "";

      let shortcutKeyName = this.shortcutKeyName;
      if (shortcutKeyName == "") {
        return;
      }
      shortcutKeyName = shortcutKeyName.slice(0, shortcutKeyName.length - 1);
      shortcutKeyName = shortcutKeyName.split("+");
      shortcutKeyName = Array.from(new Set(shortcutKeyName)); //去掉重复项
      shortcutKeyName = shortcutKeyName.join("+");
      // console.log(shortcutKeyName);
      this.shortcutKeyName = "";
      this.setKeyboard(keyName, shortcutKeyName);
    },
    hideSettingModel() {
      this.$emit("HideSettingModel");
    },
    save() {
      this.$emit("HideSettingModel");
    },
    onChangeExportImageSpace() {
      MindSettingManager.setMindExportImageSpace(
        this.exportImageSpace
      );
    },
    onChangeCreateFreeNode() {
      MindSettingManager.setDoubleClickCreateFreeNode(
        this.createFreeNodeSwitch
      );
    },
    reductionKeyboard() {
      MindSettingManager.setDefaultMindKeyboards();
      this.hotkeyData = MindSettingManager.getMindKeyboards(); //获取快捷键数据
      this.contrastKeyboard();
    },
    contrastKeyboard() {
      const defaultMindSettingData = localStorage.getItem(
        "defaultMindSettingData"
      );
      const mindSettingData = localStorage.getItem("mindSettingData");
      if (defaultMindSettingData == mindSettingData) {
        this.reductionKey = false;
      } else {
        this.reductionKey = true;
      }
    },
    //账号安全------------
    getAccountSecurityData() {
      //获取账号安全数据
      postUserAccount(
        "",
        (res) => {
          if (res) {
            this.accountSecurityData = res;
          } else {
            this.accountSecurityData = {};
          }
        },
        (error) => {
          this.$message.error(error.desc);
        },
        ()=>{
          this.setOtherAccountNumberShow();
        }
      );
    },
    getUserBoundMachines() {
      //查看已登陆设备
      postUserBoundMachines(
        "",
        (res) => {
          if (res) {
            let deviceTypeData = {
              0: {
                deviceName: "未知",
                img: "linux.svg",
              },
              1: {
                deviceName: "Android",
                img: "android.svg",
              },
              2: {
                deviceName: "iPhone",
                img: "iPhone.svg",
              },
              3: {
                deviceName: "Web",
                img: "web.svg",
              },
              4: {
                deviceName: "Windows",
                img: "windows.svg",
              },
              5: {
                deviceName: "iPad",
                img: "pad.svg",
              },
              6: {
                deviceName: "AndroidPad",
                img: "pad.svg",
              },
              7: {
                deviceName: "Mac",
                img: "mac.svg",
              },
              8: {
                deviceName: "Linux",
                img: "linux.svg",
              },
              11: {
                deviceName: "官网",
                img: "web.svg",
              },
            };
            let machines = res.machines;
            // machines.push({"id":"2935a1bd-3d7d-47fd-8307-d356f7caab23","deviceType":10,"deviceModel":"Win32"})//测试
            if (machines && machines.length > 0) {
              machines.forEach((element, index) => {
                let obj = deviceTypeData[element.deviceType];
                if (obj) {
                  machines[index] = { ...machines[index], ...obj };
                }else{
                  //不认识的设备类型，显示未知，图标用Linux的图标
                  machines[index] = { ...machines[index], ...deviceTypeData[0] };
                }
              });
            } else {
              machines = [];
            }
            this.boundMachinesData = machines;
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    cellPhoneModelfun(val, showType) {
      // 显示隐藏手机号弹框
      if (showType) {
        this.phoneBindOrChange = showType;
      }
      this.cellPhoneModelShow = val;
      if (!val) {
        this.getAccountSecurityData();
      }
    },
    emailModelfun(val, showType) {
      // 显示隐藏邮箱弹框
      if (showType) {
        this.emailBindOrChange = showType;
      }
      this.emailModelShow = val;
      if (!val) {
        this.getAccountSecurityData();
      }
    },
    passwordModelfun(val) {
      // 显示隐藏密码弹框
      if (val) {
        if (this.accountSecurityData.phoneNumber) {
          this.passwordShowType = "phoneType";
        } else if (this.accountSecurityData.email) {
          this.passwordShowType = "emailType";
        } else {
          this.$message.error(getString(strings.Bind_Mobile_Number_Or_Email_Address)); //"请绑定手机号或邮箱!"
          return;
        }
      } else {
        this.getAccountSecurityData();
      }
      this.passwordModelShow = val;
    },
    logOut(machineId) {
      //退出登录
      postUserUnbindMachine(
        { machineId },
        (res) => {
          this.getUserBoundMachines();
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    unbind(type, title) {
      //解除绑定
      const obj = {
        type,
        accessToken: "",
        openId: "",
        avatar: "",
        username: "",
      };
      let that = this;
      this.$confirm({
        title: "解除绑定",
        content:
          "解除" +
          title +
          "绑定后，你将无法使用该" +
          title +
          "登录当前账号，是否继续解绑？",
        centered: true,
        okText: getString(strings.Mind_Edit_Confirm),
        cancelText: getString(strings.Mind_Group_Cancel),
        onOk() {
          postUserChangeThirdLogin(
            obj,
            (res) => {
              that.getAccountSecurityData();
            },
            (error) => {
              that.$message.error(error.desc);
            }
          );
        },
        onCancel() {},
      });
    },
    setOtherAccountNumberShow() {
      const jsSrc = (
        navigator.language || navigator.browserLanguage
      ).toLowerCase();
      if (jsSrc.indexOf("zh") >= 0) {
        this.otherAccountNumberShow = true;
      } else {
        // if (
        //   this.accountSecurityData.isAppleBound ||
        //   this.accountSecurityData.isQQBound ||
        //   this.accountSecurityData.isWechatBound ||
        //   this.accountSecurityData.isWeiboBound
        // ) {
        //   this.otherAccountNumberShow = true;
        // } else {
        //   this.otherAccountNumberShow = false;
        // }
      }
    },
    //主题------------

    //切换主题------------
    themeChange(){

    }
  },
  watch: {
    leftNavigationActive(newVal) {
      if (newVal == LeftNavigationId.AccountSecurity) {
        //账号安全必须要登录 
        if (!getJwt("jwt")) {
          this.showLoginModel(false);
          setTimeout(() => {
            this.showLoginModel(true);
          }, 100);
          return false;
        }
        this.getAccountSecurityData();
        this.getUserBoundMachines();
      }
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
ul li {
  list-style: none;
}
.setting-box {
  width: 776px;
  min-height: 560px;
  overflow-y: auto;
  // padding: 12px 20px 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: fixed;
  z-index: 1000;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .setting-head {
    display: flex;
    // justify-content: center;
    align-items: center;
    position: relative;
    background: #ffffff;
    padding: 0 20px;
    // top: -10px;
    .setting-title {
      /* width: 15%; */
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 540;
      // text-align: center;
      color: #333;
      margin-bottom: 0;
    }
    .close-setting {
      position: absolute;
      right: 12px;
      top: 8.5px;
      .icon-remove {
        font-size: 22px;
        transition: 0.3s;
      }

      .icon-remove:hover {
        color: #fd492b;
        transition: 0.3s;
        font-size: 22px;
        transform: rotate(180deg);
      }
    }
  }
  .setting-containner {
    width: auto;
    height: 516px;
    margin: 8px;
    display: flex;
    .setting-left-content {
      width: 125px;
      height: inherit;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 2px;
      .left-navigation-item {
        width: auto;
        height: 42px;
        line-height: 42px;
        padding-left: 15px;
        font-size: 15px;
        color: #333333;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        &:hover {
          background: #f4f4f5;
        }
      }
      .active-navigation-item {
        background: #e8ebef;
        &:hover {
          background: #e8ebef;
        }
      }
    }
    .setting-right-content {
      width: 625px;
      height: inherit;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 2px;
      margin-left: 10px;
      .setting-item {
        position: relative;
        padding: 0 20px;
        .operation-settings {
          margin-top: 10px;
          .operation-title {
            padding: 0 0 10px 0;
            font-size: 16px;
            border-bottom: 1px solid #efefef;
            color: #333333;
          }
          .operation-container {
            width: 100%;
            margin-top: 20px;
            .create-free-node {
              width: 100%;
              .operation-label {
                margin: 0 60px 0 0px;
                font-size: 14px;
                color: #333333;
              }
            }
          }
        }
        .shortcut-key-settings {
          .hotkey-title {
            height: 35px;
            margin: 10px 0 20px;
            border-bottom: 1px solid #efefef;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            .hotkey-placeholder {
              font-size: 14px;
              color: #666666;
              margin-left: 20px;
            }
            .reduction-btn {
              // width: 80px;
              height: 30px;
              color: #ff7c65;
              font-size: 14px;
              background: #ffffff;
              border: 1px solid #ff7c65;
              float: right;
              margin-top: -2px;
              border-radius: 4px;
            }
          }
          .hotkey-head {
            width: 100%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background: #f3f3f3;
            border: 1px solid #eeeeee;
            display: flex;
            font-size: 14px;
            color: #333333;
            .hotkey-describe {
              width: 50%;
            }
            .hotkey {
              width: 50%;
            }
          }
          .hotkey-container {
            width: 100%;
            height: 360px;
            overflow-y: auto;
            margin-bottom: 20px;
            background: #f8f8f8;
            //滚动条美化
            &::-webkit-scrollbar {
              width: 3px;
              height: 3px;
            }
            &::-webkit-scrollbar-track {
              background: rgb(239, 239, 239);
              border-radius: 2px;
            }
            &::-webkit-scrollbar-thumb {
              background: #bfbfbf;
              border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #bfbfbf;
            }
            .hotkey-box {
              width: 100%;
              height: 40px;
              line-height: 40px;
              text-align: center;
              border-bottom: 1px solid #eeeeee;
              display: flex;
              font-size: 14px;
              color: #333333;
              .hotkey-left {
                width: 50%;
                padding: 0 12px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .hotkey-right {
                width: 50%;
                cursor: pointer;
                border-left: 1px solid #eeeeee;
              }
              &:hover {
                background: rgba(0, 0, 0, 0.1);
              }
            }
            .hotkey-active {
              border: 0;
              background: #ff7c65;
              color: #fff;
              .key {
                height: 30px;
                line-height: 30px;
                padding: 5px 20px;
                border: 1px solid #ff7c65;
                background: #ff5b3e;
                border-radius: 2px;
              }
              &:hover {
                background: #ff7c65;
              }
            }
          }
          .shortcut-key-bottom {
            width: 100%;
            margin: 20px auto 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
              width: 80px;
              height: 34px;
              font-size: 14px;
              border: 0;
              outline: 0;
              border-radius: 50px;
            }
            .cancel {
              background: #eeeeee;
              color: #999999;
            }
            .confirm {
              background: #ff7c65;
              color: #ffffff;
              margin-left: 80px;
            }
          }
        }
        .account-security-box {
          width: 100%;
          margin-top: 25px;
          .account-security-item {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            .account-item-title {
              width: 190px;
              color: #333333;
              font-size: 14px;
            }
            .account-item-operation {
              display: flex;
              .account-item-value {
                font-size: 14px;
                color: #333333;
                margin-right: 20px;
              }
              .account-item-not-value {
                font-size: 14px;
                color: #999999;
                margin-right: 20px;
              }
              .account-item-click {
                font-size: 14px;
                color: #ff7c65;
                cursor: pointer;
              }
              .log-out-button {
                background: #f5f5f5;
                border: 1px solid #e8e8e8;
                border-radius: 4px;
                color: #ff7c65;
              }
              .device-img {
                width: 16px;
                cursor: pointer;
                margin-right: 16px;
              }
            }
          }
        }
        .third-party-account-box {
          width: 100%;
          margin-top: 50px;
          .third-party-account-title {
            color: #999999;
            font-size: 14px;
          }
          .third-party-account-item {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            .third-party-item-title {
              width: 190px;
              color: #333333;
              font-size: 14px;
            }
            .third-party-item-operation {
              display: flex;
              .third-party-item-value {
                font-size: 14px;
                color: #333333;
                margin-right: 20px;
              }
              .third-party-item-not-value {
                font-size: 14px;
                color: #999999;
                margin-right: 20px;
              }
              .third-party-item-click {
                font-size: 14px;
                color: #ff7c65;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
.close-button-img {
  width: 18px;
  cursor: pointer;
  opacity: 0.5;
}
.close-button-img:hover {
  opacity: 1;
}
// /deep/.ant-popover {
//   z-index: 5000;
// }

// .active-navigation-item {
//         background: var(--borderColor);
// }
:root {
  --bg: #121212;/* 背景色 */
  --textColor1: rgba(255, 255, 255, 0.87);/* 主体文字 */
  --textColor2: rgba(255, 255, 255, 0.60);/* 副标题文字 */
  --textColor3: rgba(255, 255, 255, 0.87);/* 提示文字 */
  --borderColor: rgba(255, 255, 255, 0.5);/* 分割线 */
}
</style>


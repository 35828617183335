import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import TextAndImageAndTaskCalculation from "./TextAndImageAndTaskCalculation";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndTaskAndLatexCalculation extends TextAndImageAndTaskCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainLatextContent()) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent ;

        let taskContent = this.data.taskContent;
        let latexContent = this.data.latexContent;
        if (taskContent == null) {
            return;
        }
        if (imageContent == null) {
            return;
        }
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        } else {
            return;
        }

        let taskOriginalSize = this.getTaskOriginalSize();
        let size = this.getContentSize();
        if (size.getWidth() == 0 && size.getHeight() == 0) {
            return;
        }
        let width = size.getWidth();
        let height = size.getHeight();

        let edgeInsets = this.getMargin(size);
        let textEdgeInsets = this.getTextEdgeInsets();
        let left = edgeInsets.left;
        let top = edgeInsets.top;
        let right = edgeInsets.right;
        let bottom = edgeInsets.bottom;
        let textY = getTextTop();
        let textX = getTextLeft();
        let isShowText = isShowText();
        let taskTop = imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT || !isShowText() ? UiUtil.dip2px(5) : 0;

        if (imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT) {
            if (imageContent.height > textMindElementContent.height) {
                textMindElementContent.x = left + imageContent.width;
                textMindElementContent.y = top + (imageContent.height - textMindElementContent.height)/2;
                imageContent.x = left;
                imageContent.y = top;
                latexContent.x = left + textX;
                latexContent.y = top + imageContent.height + textEdgeInsets.top;
                taskContent.x = left + textX;
                taskContent.y = latexContent.y + this.latexOriginalSize.getHeight() + taskTop;
            } else {
                textMindElementContent.x = left + imageContent.width;
                textMindElementContent.y = top;
                imageContent.x = left;
                imageContent.y = top + (textMindElementContent.height - imageContent.height)/2;
                latexContent.x = left + textX;
                latexContent.y = top + textMindElementContent.height + textEdgeInsets.top;
                taskContent.x = left + textX;
                taskContent.y = latexContent.y + this.latexOriginalSize.getHeight() + taskTop;
            }
        } else { //ImageLayoutType.LAYOUT_TOP
            if (imageContent.width > textMindElementContent.width) {
                if (isShowText) {
                    textMindElementContent.x = left + (imageContent.width - textMindElementContent.width)/2;
                    textMindElementContent.y = top + imageContent.height;
                    imageContent.x = left;
                    imageContent.y = top;

                    latexContent.x = left + (size.getWidth() - this.latexOriginalSize.getWidth()) / 2;
                    latexContent.y = textMindElementContent.y + textMindElementContent.height + taskTop;
                    taskContent.x = left + (size.getWidth() - taskOriginalSize.getWidth()) / 2;
                    taskContent.y = latexContent.y + this.latexOriginalSize.getHeight() + taskTop;
                } else {
                    textMindElementContent.x = left;
                    textMindElementContent.y = top;
                    imageContent.x = left;
                    imageContent.y = top;

                    latexContent.x = left + (size.getWidth() - this.latexOriginalSize.getWidth()) / 2;
                    latexContent.y = imageContent.y + imageContent.height + taskTop;
                    taskContent.x = left + (size.getWidth() - taskOriginalSize.getWidth()) / 2;
                    taskContent.y = latexContent.y + this.latexOriginalSize.getHeight() + taskTop;
                }
            } else {
                textMindElementContent.x = left;
                textMindElementContent.y = top + imageContent.height;
                imageContent.x = left + (textMindElementContent.width - imageContent.width)/2;
                imageContent.y = top;

                latexContent.x = left + textX;
                latexContent.y = textMindElementContent.y + textMindElementContent.height + textEdgeInsets.top;
                taskContent.x = left + textX;
                taskContent.y = latexContent.y + this.latexOriginalSize.getHeight() + taskTop;
            }
        }
        
        latexContent.width = this.latexOriginalSize.getWidth();
        latexContent.height = this.latexOriginalSize.getHeight();

        this.data.taskContent.width = taskOriginalSize.getWidth();
        this.data.taskContent.height = taskOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
    getContentSize() {
        return this.addLatexSize(super.getContentSize());
    }
}
export default TextAndImageAndTaskAndLatexCalculation
import en from './en'; //英语
import zhCN from './zh-cn';  //中文
import ja from './ja'; //日语
import de from './de'; //德语
import ru from './ru'; //俄语
import zhTwHk from './zh-tw-hk'; //繁体
import fil from "./fil"; //菲律宾
import my from "./my"; //缅甸
import pt from "./pt"; //葡萄牙
import th from "./th"; //泰语
import tr from "./tr"; //土耳其语
import ur from "./ur"; //乌尔都语
import es from "./es"; //西班牙语
import ig from "./ig"; //伊博语
import id from "./id"; //印尼语
import vi from "./vi"; //越南语
import ar from "./ar";//ar 阿拉伯语
import fr from "./fr";//fr 法语
import ha from "./ha";//ha 豪萨语
import yo from "./yo";//yo约鲁巴语 
import hi from "./hi";//hi印地语
import fa from "./fa";//fa 波斯语




var stringsMap = {};

var language = (navigator.browserLanguage || navigator.language).toLowerCase();
// console.log('language',language)
if (language.indexOf("zh-tw") > -1 || language.indexOf("zh-hk") > -1) {
    stringsMap = zhTwHk;
}else if (language.indexOf("zh") > -1) {
    stringsMap = zhCN;
} else if (language.indexOf("ja") > -1) {
    stringsMap = ja;
} else if (language.indexOf("de") > -1) {
    stringsMap = de;
} else if (language.indexOf("ru") > -1) {
    stringsMap = ru;
} else if (language.indexOf("fil") > -1) {
    stringsMap = fil;
} else if (language.indexOf("my") > -1) {
    stringsMap = my;
} else if (language.indexOf("pt") > -1) {
    stringsMap = pt;
} else if (language.indexOf("th") > -1) {
    stringsMap = th;
} else if (language.indexOf("tr") > -1) {
    stringsMap = tr;
} else if (language.indexOf("ur") > -1) {
    stringsMap = ur;
} else if (language.indexOf("es") > -1) {
    stringsMap = es;
} else if (language.indexOf("ig") > -1) {
    stringsMap = ig;
} else if (language.indexOf("id") > -1) {
    stringsMap = id;
} else if (language.indexOf("vi") > -1) {
    stringsMap = vi;
} else if (language.indexOf("ar") > -1) {
    stringsMap = ar;
} else if (language.indexOf("fr") > -1) {
    stringsMap = fr;
} else if (language.indexOf("ha") > -1) {
    stringsMap = ha;
} else if (language.indexOf("yo") > -1) {
    stringsMap = yo;
} else if (language.indexOf("hi") > -1) {
    stringsMap = hi;
} else if (language.indexOf("fa") > -1) {
    stringsMap = fa;
} else {
    stringsMap = en;
}

export default function getString(i) {
    return stringsMap[i]
};
/**
 * Created by tony on 2019/12/17
 */

class EdgeInsets {
    constructor(left, top, right, bottom) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }
}
export default EdgeInsets

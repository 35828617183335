

import Size from "../../../../viewmodel/core/base/Size";
import TextAndLinkCalculation from "./TextAndLinkCalculation";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndMoreAndLatexCalculation extends TextAndLinkCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (this.data.moreContent == null || !this.data.isContainLatextContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let moreContent = this.data.moreContent;
        if (textMindElementContent == null) {
            return;
        }
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        moreContent.width = textHeight;
        moreContent.height = textHeight;

        let size = this.includeMoreAndTextAndLatexSize();
        let moreAndTextSize = this.includeMoreAndTextSize();
        let iconsSize = new Size(moreContent.width, moreContent.height);
        let textEdgeInsets = this.getTextEdgeInsets();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;

        iconLeft = (moreAndTextSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (moreAndTextSize.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((moreAndTextSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

        moreContent.x = iconLeft + marginX;
        moreContent.y = iconTop + marginY;

        this.data.latexContent.x = marginX + textEdgeInsets.left;
        this.data.latexContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.latexContent.width = this.latexOriginalSize.getWidth();
        this.data.latexContent.height = this.latexOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndMoreAndLatexCalculation

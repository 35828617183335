import Size from "../../../../viewmodel/core/base/Size";

const { default: BaseMindElementDataCalculation } = require("./BaseMindElementDataCalculation");

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndConnectMapCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainConnectMapContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let connectMapElementContent = this.data.connectMapElementContent;

        let textRext = this.caluleTextCGRect();
        let textHeight = textRext.height();
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;
        let size = this.includeConnectMapAndTextSize();
        let iconsSize = new Size((connectMapElementContent.width), (connectMapElementContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        if (!this.data.isContainConnectMapContent()) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

        connectMapElementContent.x = iconLeft + marginX;
        connectMapElementContent.y = iconTop + marginY;

        // this.setMindElementSize(size.getWidth(), size.getHeight(), marginX, marginY);
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}
export default TextAndConnectMapCalculation

import Point from "../../../../viewmodel/core/base/Point"
import Size from "../../../../viewmodel/core/base/Size"
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import MindElementData from "../../../../viewmodel/mindelementdata/MindElementData"
import IconElementContent from "../../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import MindElementContent from "../../../../viewmodel/mindelementdata/mindcontent/MindElementContent"
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndIconCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return;
        }

        let size = this.includeIconAndTextSize();
        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;
        let textMargin = this.getTextEdgeInsets()
        let iconPointOffsetX = textMargin.left;
        let iconPointOffsetY = textMargin.top;
        if (textMindElementContent.text.length == 0 && !this.data.isEdit) {
            iconPointOffsetX = 0;
            iconPointOffsetY = 0;
        }
        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length== 0) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }

        let iconLeft = 0;
        let iconTop = 0;
        if (layoutType == IconLayoutType.LAYOUT_LEFT) {
            iconLeft = this.iconMargin + (this.data.type == MindElementType.MAIN_SUBJECT ? iconPointOffsetX * 0.75 : iconPointOffsetX * 0.5);
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;
        } else if (layoutType == IconLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin) - (this.data.type == MindElementType.MAIN_SUBJECT ? iconPointOffsetX * 0.75 : iconPointOffsetX * 0.5);
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = this.iconSpace + iconPointOffsetY * 0.8;
            textMindElementContent.x = ((size.getWidth()) - textMindElementContent.width) / 2 + marginX;
            textMindElementContent.y = iconTop - iconPointOffsetY * 0.8 + (iconsSize.getHeight()) + marginY;
        }
        for (let index = 0; index < iconElementContents.length ; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = iconLeft + marginX;
            } else {
                iconCell.x = iconElementContents[index-1].x + iconElementContents[index-1].width + (this.iconSpace);
            }
            iconCell.y = iconTop + marginY;
        }
        // this.setMindElementSize(size.getWidth(), size.getHeight(), marginX, marginY);
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}

export default TextAndIconCalculation
import MindMe from '../viewmodel/facade/MindMe.js';
import { postUserStorageInfo} from './netWork/base_api.js';

// //获取节点信息
// export default {
//     getDomMessage: () => {
//         document.addEventListener("click", (e) => {
//             console.log()
//             return e.target.className
//         })
//     }
// }

function log() {
  if (process.env.NODE_ENV === "development") {//测试环境打印日志
    console.log.apply(console, arguments);
  }
}
//是否是中文环境
function isItChinese() {
  const jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
  if (jsSrc.indexOf('zh') >= 0) {
    return true;
  } else {
    return false;
  }
}

//是否是会员
function isMember() {
  const userData = MindMe.getMeMessage();
  if (userData == null || userData == "") {
    return false;
  } else {
    return userData.isMember;
  }
}

//创建a标签跳转，浏览器不会阻止弹出窗口
function createATagJump(url) {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
  }, 5000);
}

// 判断某个对象是不是dom对象
const isDOM = (typeof HTMLElement === 'object') ?
  function (obj) {
    return obj instanceof HTMLElement;
  } :
  function (obj) {
    return obj && typeof obj === 'object' && obj.nodeType === 1 && typeof obj.nodeName === 'string';
  }

// 刷新存储空间
function getRefreshStorage() {
  return new Promise(function (resolve, reject) {
    postUserStorageInfo(
      {},
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export default {
  // Vue.js的插件应当有一个公开方法 install。这个方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
  install: function (Vue) {
    Vue.prototype.log = (param) => log(param)
    Vue.prototype.isMember = () => isMember()
    Vue.prototype.isItChinese = () => isItChinese()
    Vue.prototype.createATagJump = (url) => createATagJump(url)
    Vue.prototype.isDOM = (obj) => isDOM(obj)
    Vue.prototype.getRefreshStorage = () => getRefreshStorage()
  }
}


import UiUtil from "../../../utils/UiUtil"
import Util from "../../../utils/Util"

import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import Point from "../../../viewmodel/core/base/Point"
import NodesRectCalculation from "./NodesRectCalculation"
import EncircleShapeType from "../../../viewmodel/datatype/EncircleShapeType"

/**
 * Created by tony on 2019/12/27
 */

class EncircleNodesPointsCalculation {
    constructor() {
        this.space = new UiUtil().dip2px(14);
        this.textSpace = new UiUtil().dip2px(6);
        this.node;
        this.points = new Array();
        this.nodeList = new Array();
        this.bottomPoints = new Array();
        this.topPoints = new Array();
        this.bottomPointsReversed = new Array();
        this.leftPoints = new Array();
        this.leftPointsReversed = new Array();
        this.rightPoints = new Array();
        this.padding = 0;
    }

    calcule(node, type, padding) {
        this.points = [];
        this.padding = padding;
        switch (type) {
            case EncircleShapeType.LAYOUT_TRAPEZOID:
                return this.trapezoid(node);
            case EncircleShapeType.LAYOUT_RECTANGLE_1:
            case EncircleShapeType.LAYOUT_RECTANGLE_2:
            case EncircleShapeType.LAYOUT_RECTANGLE_3:
            case EncircleShapeType.LAYOUT_RECTANGLE_4:
                return this.rectangle(node);
            default:
                return this.trapezoid(node);
        }



    }

    rectangle(node) {
        this.points = [];
        this.node = node;
        if (node.isEmpty() || node.value.isHidden) {
            return this.points;
        }

        let rect = new NodesRectCalculation().calcule(node);
        let x = rect.x - this.padding;
        let y = rect.y - this.padding;
        let width = rect.width() + this.padding * 2;
        let height = rect.height() + this.padding * 2;

        // points.add(CGPoint.init(x, y));
        // points.add(CGPoint.init(x + width, y));
        // points.add(CGPoint.init(x + width, y + height));
        // points.add(CGPoint.init(x, y + height));
        // points.add(CGPoint.init(x, y));
        this.points.push(new Point(x, y));
        this.points.push(new Point(x + width, y));
        this.points.push(new Point(x + width, y + height));
        this.points.push(new Point(x, y + height));
        this.points.push(new Point(x, y));
        return this.points;
    }

    trapezoid(node) {  //计算如果是梯形的各个点的位置。
        this.points = [];
        this.node = node;
        if (node.isEmpty() || node.value.isHidden) {
            return this.points;
        }
        this.setNodeList(node);
        if (node.value.layout == NodeLayoutType.LAYOUT_RIGHT) {
            this.points.push(new Point((node.value.x) - this.padding, (node.value.y + node.value.height) + this.padding));
            this.topPoints.push(new Point((node.value.x) - this.padding, (node.value.y) - this.padding));
            this.bottomPoints.push(new Point((node.value.x) - this.padding, (node.value.y + node.value.height) + this.padding));
            this.calculeRightLayoutChild(node);
            this.setRightPoint();
            this.checkTopPoints();
            for (let index = 0; index < this.topPoints.length; index++) {
                let point = this.topPoints[index];
                this.points.push(point);
            }
            for (let index = this.bottomPoints.length - 1; index > -1; index--) {
                this.bottomPointsReversed.push(this.bottomPoints[index]);
            }
            this.checkBottomPoints();
            for (let index = 0; index < this.bottomPointsReversed.length; index++) {
                this.points.push(this.bottomPointsReversed[index]);
            }
        } else if (node.value.layout == NodeLayoutType.LAYOUT_BOTTOM) {
            
            this.points.push(new Point((node.value.x) - this.padding, (node.value.y) - this.padding));
            this.rightPoints.push(new Point((node.value.x + node.value.width) + this.padding, (node.value.y) - this.padding));

            this.leftPoints.push(new Point((node.value.x) - this.padding, (node.value.y) - this.padding));
            this.calculeBottomLayoutChild(node);

            this.setBottomPoint();

            for (let index = 0; index < this.rightPoints.length; index++) {
                this.points.push(this.rightPoints[index]);
            }
            for (let index = this.leftPoints.length - 1; index > -1; index--) {
                this.leftPointsReversed.push(this.leftPoints[index]);
            }
            for (let index = 0; index < this.leftPointsReversed.length; index++) {
                let point = this.leftPointsReversed[index];
                this.points.push(point);
            }

        } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP) {
            this.points.push(new Point((node.value.x) - this.padding, (node.value.y) + node.value.height + this.padding));
            this.rightPoints.push(new Point((node.value.x + node.value.width) + this.padding, (node.value.y) + node.value.height + this.padding));

            this.leftPoints.push(new Point((node.value.x) - this.padding, (node.value.y) + node.value.height + this.padding));
            this.calculeTopLayoutChild(node);

            this.setTopPoint();
            for (let index = 0; index < this.rightPoints.length; index++) {
                this.points.push(this.rightPoints[index]);
            }
            for (let index = this.leftPoints.length - 1; index > -1; index--) {
                this.leftPointsReversed.push(this.leftPoints[index]);
            }
            for (let index = 0; index < this.leftPointsReversed.length; index++) {
                let point = this.leftPointsReversed[index];
                this.points.push(point);
            }
        }  else if (node.value.layout == NodeLayoutType.LAYOUT_LEFT) {
            this.points.push(new Point((node.value.x + node.value.width) + this.padding, (node.value.y + node.value.height) + this.padding));
            this.topPoints.push(new Point((node.value.x + node.value.width) + this.padding, (node.value.y) - this.padding));
            this.bottomPoints.push(new Point((node.value.x + node.value.width) + this.padding, (node.value.y + node.value.height) + this.padding));
            this.calculeLeftLayoutChild(node);
            this.setLeftPoint();
            this.checkTopPoints();
            for (let index = 0; index < this.topPoints.length; index++) {
                let point =  this.topPoints[index]
                this.points.push(point);
            }
            for (let index = this.bottomPoints.length - 1; index > -1; index--) {
                this.bottomPointsReversed.push(this.bottomPoints[index]);
            }
            this.checkBottomPoints();
            for (let index = 0; index < this.bottomPointsReversed.length; index++) {
                let point = this.bottomPointsReversed[index]
                this.points.push(point);
            }
        }
        return this.points;
    }

    checkRightPoints() { //移除角度大于180
        if (this.rightPoints.length < 3) {
            return;
        }
        var isRemove = false;
        for (var index = 0; index < this.rightPoints.length - 2; index++) {
            var y = this.checkAngles(
                this.rightPoints[index],
                this.rightPoints[index + 1],
                this.rightPoints[index + 2].x);
            if (y != 0 && y < this.rightPoints[index + 2].y) {
                this.rightPoints.remove(index + 1);
                isRemove = true;
                break;
            }
        }
        if (isRemove) {
            this.checkRightPoints();
        }
    }

    checkLeftPoints() { //移除角度大于180
        if (this.leftPointsReversed.length < 3) {
            return;
        }
        var isRemove = false;
        for (var index = 0; index < this.leftPointsReversed.length - 2; index++) {
            var y = this.checkAngles(
                this.leftPointsReversed[index],
                this.leftPointsReversed[index + 1],
                this.leftPointsReversed[index + 2].x);
            if (y != 0 && y < this.leftPointsReversed[index + 2].y) {
                this.leftPointsReversed.remove(index + 1);
                isRemove = true;
                break;
            }
        }
        if (isRemove) {
            this.checkLeftPoints();
        }
    }

    checkTopPoints() { //移除角度大于180
        if (this.topPoints.length < 3) {
            return;
        }
        var isRemove = false;
        for (var index = 0; index < this.topPoints.length - 2; index++) {
            var y = this.checkAngles(this.topPoints[index],
                this.topPoints[index + 1],
                this.topPoints[index + 2].x);
            if (y != 0 && y > this.topPoints[index + 2].y) {
                this.topPoints.remove(index + 1);
                isRemove = true;
                break;
            }
        }
        if (isRemove) {
            this.checkTopPoints();
        }
    }

    checkBottomPoints() { //移除角度大于180
        if (this.bottomPointsReversed.length < 3) {
            return;
        }
        var isRemove = false;
        for (var index = 0; index < this.bottomPointsReversed.length - 2; index++) {
            var y = this.checkAngles(this.bottomPointsReversed[index],
                this.bottomPointsReversed[index + 1],
                this.bottomPointsReversed[index + 2].x);
            if (y != 0 && y < this.bottomPointsReversed[index + 2].y) {
                this.bottomPointsReversed.remove(index + 1);
                isRemove = true;
                break;
            }
        }
        if (isRemove) {
            this.checkBottomPoints();
        }
    }

    checkAngles(startPoint, endPoint, checkX) {
        var angle = Util.getAnglesWithThreePoints(startPoint, endPoint, new Point(startPoint.x, endPoint.y));
        
        //向右上角、水平向右
        if (endPoint.x >= startPoint.x && endPoint.y <= startPoint.y && angle != 0) {
            return (startPoint.y - Math.tan(angle) * (checkX - startPoint.x));
        } else if (endPoint.x > startPoint.x && endPoint.y > startPoint.y && angle != 0) {
            //向右下角
            return ((startPoint.y) + (Math.tan(angle)) * (checkX - (startPoint.x)));
        } else if (endPoint.x < startPoint.x && endPoint.y < startPoint.y && angle != 0) {
            //向左上角
            return ((startPoint.y) - (Math.tan(angle)) * ((startPoint.x) - checkX));
        } else if (endPoint.x < startPoint.x && endPoint.y >= startPoint.y && angle != 0) {
            //向左下角,水平向左
            return ((startPoint.y) - (Math.tan(angle)) * (checkX - (startPoint.x)));
        } else if (startPoint.x == endPoint.x) {
            //竖直方向
        }
        return 0;
    }

    setRightPoint() {
        var top = 100000;
        var right = 0;
        var bottom = 0;

        this.nodeList.forEach(mind => {
            top = Math.min(mind.y, top);
            right = Math.max((mind.x + mind.width), right);
            bottom = Math.max((mind.y + mind.height), bottom);
        });
        this.topPoints.push(new Point((right) + this.space, (top) - this.space));
        this.bottomPoints.push(new Point((right) + this.space, (bottom) + this.space));
    }

    setLeftPoint() {
        var rect = new NodesRectCalculation().calcule(this.node);
        this.topPoints.push(new Point((rect.x) - this.space, (rect.y) - this.space));
        this.bottomPoints.push(new Point((rect.x) - this.space, (rect.y + rect.height()) + this.space));
    }

    setBottomPoint() {
        var rect = new NodesRectCalculation().calcule(this.node);
        this.rightPoints.push(new Point((rect.x + rect.width()) + this.space, (rect.y + rect.height()) + this.space));
        this.leftPoints.push(new Point(rect.x - this.space, (rect.y + rect.height()) + this.space));
    }

    setTopPoint() { //计算顶部布局梯形形状的其中某个点位置。
        let rect = new NodesRectCalculation().calcule(this.node);
        this.rightPoints.push(new Point((rect.x + rect.width()) + this.padding, rect.y - this.padding));
        this.leftPoints.push(new Point(rect.x - this.padding, rect.y - this.padding));
    }

    calculeTopLayoutChild(node) { //计算顶部布局梯形形状的其中某个点位置。
        let rect = new NodesRectCalculation().calculeNodes(node.children);
        if (rect.width() == 0) {
            return;
        }
        let rightPoint = new Point(rect.x + rect.width() + this.padding,
                rect.y + rect.height() + this.padding);
        let leftPoint = new Point(rect.x - this.padding,
                rect.y + + rect.height() + this.padding);
        if (this.checkRightPoint(rightPoint)) {
            this.rightPoints.push(rightPoint);
        }
        if (this.checkLeftPoint(leftPoint)) {
            this.leftPoints.push(leftPoint);
        }
        node.children.forEach(child => {
            this.calculeTopLayoutChild(child);
        });
    }


    calculeBottomLayoutChild(node) {
        var rect = new NodesRectCalculation().calculeNodes(node.children);
        if (rect.width() == 0) {
            return;
        }
        var rightPoint = new Point(rect.x + rect.width() + this.space,
            rect.y - this.space);
        var leftPoint = new Point(rect.x - this.space,
            rect.y - this.space);
        if (this.checkRightPoint(rightPoint)) {
            this.rightPoints.push(rightPoint);
        }
        if (this.checkLeftPoint(leftPoint)) {
            this.leftPoints.push(leftPoint);
        }

        node.children.forEach(child => {
            this.calculeBottomLayoutChild(child);
        });
    }

    calculeRightLayoutChild(node) {
        var rect = new NodesRectCalculation().calculeNodes(node.children);
        if (rect.width() == 0) {
            return;
        }
        var topPoint = new Point((node.children[0].value.x) - this.space, rect.y - this.space);
        var bottomPoint = new Point(rect.x - this.space, rect.y + rect.height() + this.space);
        if (this.checkTopPoint(topPoint)) {
            this.topPoints.push(topPoint);
        }
        if (this.checkBottomPoint(bottomPoint)) {
            this.bottomPoints.push(bottomPoint);
        }
 
        node.children.forEach(child => {
            this.calculeRightLayoutChild(child);
        });
    }

    calculeLeftLayoutChild(node) {

        var rect = new NodesRectCalculation().calculeNodes(node.children);
        if (rect.width() == 0) {
            return;
        }
        var topChild = node.children[0];
        var bottomChild = node.children[node.children.length - 1];
        let nodeChild = node.children
        let nodeLength = nodeChild.length
        for (let index = 0; index < nodeLength; index++) {
            let child = nodeChild[index];
            if (child.value.isHidden) {
                continue
            }
            if (topChild.value.y > child.value.y) {
                topChild = child;
            }
            if (bottomChild.value.y < child.value.y) {
                bottomChild = child;
            }
        }
        var topPoint = new Point((topChild.value.x + topChild.value.width) + this.space, rect.y - this.space);
        var bottomPoint = new Point((bottomChild.value.x + bottomChild.value.width) + this.space, rect.y + rect.height() + this.space);
        if (this.checkTopPoint(topPoint)) {
            this.topPoints.push(topPoint);
        }
        if (this.checkBottomPoint(bottomPoint)) {
            this.bottomPoints.push(bottomPoint);
        }
        let nodeChildren = node.children
        let nodeChildrenLength = nodeChildren.length
        for (let index = 0; index < nodeChildrenLength; index++) {
            let child = nodeChildren[index];
            if (child.value.isHidden) {
                continue
            }
            this.calculeLeftLayoutChild(child);
        }
    }

    setNodeList(node) {
        if (node.value.isHidden) {
            return;
        }
        this.nodeList.push(node.value);
        node.children.forEach(child => {
            this.setNodeList(child);
        });
    }

    checkTopPoint(point) {
        var top = 100000;
        this.points.forEach(item => {
            top = Math.min(item.y, top);
        });
        this.topPoints.forEach(item => {
            top = Math.min(item.y, top);
        });
        return top >= point.y;
    }

    checkRightPoint(point) {
        var right = 0;

        this.points.forEach(item => {
            right = Math.max(item.x, right);
        });
        this.rightPoints.forEach(item => {
            right = Math.max(item.x, right);
        });
        return right <= point.x;
    }

    checkLeftPoint(point) {
        var left = 100000;
        this.points.forEach(item => {
            left = Math.min(item.x, left);
        });
        this.leftPoints.forEach(item => {
            left = Math.min(item.x, left);
        });
        return left >= point.x;
    }

    checkBottomPoint(point) {
        var bottom = 0;
        this.points.forEach(item => {
            bottom = Math.max(item.y, bottom);
        });
        this.bottomPoints.forEach(item => {
            bottom = Math.max(item.y, bottom);
        });
        return bottom <= point.y;
    }
}

export default EncircleNodesPointsCalculation
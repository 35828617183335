

import Size from "../../../../viewmodel/core/base/Size";
import TextAndLinkAndConnectMap from "./TextAndLinkAndConnectMap";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndLinkAndConnectMapAndLatex extends TextAndLinkAndConnectMap {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainLinkContent() || !this.data.isContainConnectMapContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let linkElementContent = this.data.linkElementContent;
        let connectMapElementContent = this.data.connectMapElementContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        linkElementContent.width = textHeight;
        linkElementContent.height = textHeight;
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;

        let size = this.includeTextAndLinkAndConnectMapAndLatexSize();
        let textAndLinkAndConnectMapSize = this.includeTextAndLinkAndConnectMapSize();
        let iconsSize = new Size((linkElementContent.width + connectMapElementContent.width) + this.iconSpace, (linkElementContent.height));


        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        if (!this.data.isContainLinkContent()) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (textAndLinkAndConnectMapSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (textAndLinkAndConnectMapSize.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((textAndLinkAndConnectMapSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

        linkElementContent.x = iconLeft + marginX;
        linkElementContent.y = iconTop + marginY;

        connectMapElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
        connectMapElementContent.y = linkElementContent.y;

        let textEdgeInsets = this.getTextEdgeInsets();
        this.data.latexContent.x = marginX + textEdgeInsets.left;
        this.data.latexContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.latexContent.width = this.latexOriginalSize.getWidth();
        this.data.latexContent.height = this.latexOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndLinkAndConnectMapAndLatex

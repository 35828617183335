// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGPoint;

/**
 * ProjectName: MindMap
 * Created by tony on 1/12/21
 * Copyright(c) 2020 mindyushu.com
 */

class MindPoint {
    constructor(x = 0.0,y = 0.0) {
        this.x = x;
        this.y = y;
    }
    

    // public MindPoint(int x, int y) {
    //     this.x = x;
    //     this.y = y;
    // }

    // public MindPoint(float x, float y) {
    //     this.x = x;
    //     this.y = y;
    // }

    // public MindPoint(double x, double y) {
    //     this.x = (int)x;
    //     this.y = (int)y;
    // }

    // public static MindPoint init() {
    //     return new MindPoint(0, 0);
    // }

    init(x = 0, y = 0) {
        return new MindPoint(x, y);
    }

    // public static MindPoint init(double x, double y) {
    //     return new MindPoint(x, y);
    // }

    // public static MindPoint init(float x, int y) {
    //     return new MindPoint((int)x, y);
    // }

    // public static MindPoint init(int x, float y) {
    //     return new MindPoint(x, (float)y);
    // }

    // public static MindPoint init(float x, float y) {
    //     return new MindPoint(x, y);
    // }
}

export default MindPoint

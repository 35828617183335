
import Config from "../../../core/core/calcule/Config"
import UiUtil from "../../../utils/UiUtil"
import TimeLineNodeType from "../../datatype/TimeLineNodeType"
import TimeDotElementContent from "../../mindelementdata/mindcontent/TimeDotElementContent"
import TimeLineLayout from "./TimeLineLayout"
import TimeNodeLayoutType from "../../datatype/TimeNodeLayoutType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class TimeLineHorizontalSLayout extends TimeLineLayout {
    constructor() {
        super()
        this.UiUtil = new UiUtil();
        this.nodeSplitList = new Array(); //分割行数Datas
        this.lineSpacing = this.UiUtil.dip2px(20);
        this.baseLineHeight = this.UiUtil.dip2px(10);
    }

    initConfigInfo() {
        this.radius = this.getRadius();
        this.baseLineHeight = this.radius * 2 + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth) * 2;
        this.baseLineTitleSpace = this.UiUtil.dip2px(10) + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth);
        
        this.subjectAndLineSpace = 0;
        this.sonSubjectAndLineSpace = this.addSonSubjectSpcaeVertical(0);
        if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD ||
            this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE) {
            this.baseLineHeight += this.UiUtil.dip2px(13);
        } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD) {
            this.baseLineHeight += this.UiUtil.dip2px(17);
        } else {
            this.baseLineHeight += this.UiUtil.dip2px(8);
        }
        this.baseLineHeight = this.addSubjectSpcaeVertical(this.baseLineHeight);
        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(8);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(30));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(20);
                break;
            default:
        }
    }

    setElementsPoint(isChange) {
        if (!isChange && this.title.x < 0) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.title.y = (top) + this.title.height + 20;
            this.title.x = (this.UiUtil.getScreenWidth() - (this.title.width)) / 2 + left;
        }
        this.initConfigInfo();
        let startPointX = this.title.x + this.title.width + this.baseLineTitleSpace + this.firstSubjectBaseLineTopSpace;
        let contentY = this.title.y + this.title.height / 2;

        this.space = (this.baseLineHeight - this.radius) / 2;
        this.spliteNode();
        let spliteLenght = this.nodeSplitList.length;
        if (spliteLenght == 0) {
            return;
        }
        let nodesLeft = 500000;
        let nodesRight = -500000;
        let nodesTop = contentY - this.baseLineHeight / 2;
        let nodesBottom = 0;
        let preNodeCentreY = contentY;
        for (let index = 0; index < spliteLenght; index++) {
            let direction = index % 2 == 0;
            let nodes = this.nodeSplitList[index];
            let nodosTopHeight = this.getNodesTopHieght(nodes);
            let nodosBottomHeight = 0;
            if (index > 0) {
                nodosBottomHeight = this.getNodesBottomHieght(this.nodeSplitList[index - 1]);
                contentY += nodosTopHeight + nodosBottomHeight + this.lineSpacing + this.baseLineHeight;
            }

            if (direction) {
                if (index == 0) {
                    if (nodesLeft > startPointX) {
                        nodesLeft = startPointX;
                    }
                } else {
                    startPointX += this.subjectSpace;
                    if (preNodeCentreY != contentY) {
                        if (nodesLeft > startPointX - (contentY - preNodeCentreY) / 2) {
                            nodesLeft = startPointX - (contentY - preNodeCentreY) / 2;
                        }
                    } else if (nodesLeft > startPointX) {
                        nodesLeft = startPointX;
                    }
                }
                let nodesLength = nodes.length;

                for (let index = 0; index < nodesLength; index++) {
                    let timeNode = nodes[index];
                    let head = timeNode.head;
                    let title = timeNode.title;
                    let desc = timeNode.desc;
                    let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);

                    switch (this.timeNodeLayoutType) {
                        case TimeNodeLayoutType.NORMAL:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = false;
                            head.x = startPointX + (nodeWidth - head.width) / 2;
                            head.y = contentY - this.baseLineHeight / 2 - this.BaseLayout.getNodeHeight(head) - this.subjectAndLineSpace;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = contentY + this.baseLineHeight / 2 + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                            head.isHidden = true;
                            title.isHidden = false;
                            desc.isHidden = false;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = contentY + this.baseLineHeight / 2 + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.TITLE_BOTTOM:
                            head.isHidden = true;
                            title.isHidden = false;
                            desc.isHidden = true;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = contentY + this.baseLineHeight / 2 + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = true;
                            head.x = startPointX + (nodeWidth - head.width) / 2;
                            head.y = contentY - this.baseLineHeight / 2 - this.BaseLayout.getNodeHeight(head) - this.subjectAndLineSpace;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = contentY + this.baseLineHeight / 2 + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = false;
                            head.x = startPointX + (nodeWidth - head.width) / 2;
                            head.y = contentY - head.height / 2;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = head.y + head.height + this.space + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = true;
                            head.x = startPointX + (nodeWidth - head.width) / 2;
                            head.y = contentY - head.height / 2;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = head.y + head.height + this.space + this.subjectAndLineSpace;
                            break;
                        default:
                    }

                    if (!desc.isHidden) {
                        desc.x = startPointX + (nodeWidth - desc.width) / 2;
                        desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace + this.sonSubjectAndLineSpace;
                    }

                    startPointX += nodeWidth + this.subjectSpace;
                }
            } else {
                startPointX -= this.subjectSpace;
                if (nodesRight < startPointX + (nodosTopHeight + nodosBottomHeight + this.lineSpacing + this.baseLineHeight) / 2) {
                    nodesRight = startPointX + (nodosTopHeight + nodosBottomHeight + this.lineSpacing + this.baseLineHeight) / 2;
                }
                let nodesLength = nodes.length;
                for (let index = 0; index < nodesLength; index++) {
                    let timeNode = nodes[index];
                    let head = timeNode.head;
                    let title = timeNode.title;
                    let desc = timeNode.desc;
                    let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);

                    switch (this.timeNodeLayoutType) {
                        case TimeNodeLayoutType.NORMAL:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = false;
                            head.x = startPointX - (nodeWidth - head.width) / 2 - head.width;
                            head.y = contentY - this.baseLineHeight / 2 - this.BaseLayout.getNodeHeight(head) - this.subjectAndLineSpace;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = contentY + this.baseLineHeight / 2 + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                            head.isHidden = true;
                            title.isHidden = false;
                            desc.isHidden = false;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = contentY + this.baseLineHeight / 2 + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.TITLE_BOTTOM:
                            head.isHidden = true;
                            title.isHidden = false;
                            desc.isHidden = true;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = contentY + this.baseLineHeight / 2 + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = true;
                            head.x = startPointX - (nodeWidth - head.width) / 2 - head.width;
                            head.y = contentY - this.baseLineHeight / 2 - this.BaseLayout.getNodeHeight(head) - this.subjectAndLineSpace;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = contentY + this.baseLineHeight / 2 + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = false;
                            head.x = startPointX - (nodeWidth - head.width) / 2 - head.width;
                            head.y = contentY - head.height / 2;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = head.y + head.height + this.space + this.subjectAndLineSpace;
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = true;
                            head.x = startPointX - (nodeWidth - head.width) / 2 - head.width;
                            head.y = contentY - head.height / 2;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = head.y + head.height + this.space + this.subjectAndLineSpace;
                            break;
                        default:
                    }

                    if (!desc.isHidden) {
                        desc.x = startPointX - (nodeWidth - desc.width) / 2 - desc.width;
                        desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace + this.sonSubjectAndLineSpace;
                    }
                    startPointX = startPointX - nodeWidth - this.subjectSpace;
                }
            }
            preNodeCentreY = contentY;
            nodesBottom = contentY + this.baseLineHeight / 2;
        }


        this.baseLine.width = nodesRight - nodesLeft;
        this.baseLine.height = nodesBottom - nodesTop;
        this.baseLine.timeLineContent.lineContentHeight = this.baseLineHeight;
        this.baseLine.x = nodesLeft;
        this.baseLine.y = nodesTop;

        let dots = new Array();
        let nodeList = this.getOrderNodes();

        for (let index = 0; index < nodeList.length; index++) {
            let timeNode = nodeList[index];
            let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);
            let head = timeNode.head;
            let title = timeNode.title;
            let dotElementContent = new TimeDotElementContent();
            dotElementContent.targetId = timeNode.id;
            dotElementContent.radius = this.radius;
            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    dotElementContent.x = head.x + head.width / 2 - this.baseLine.x;
                    dotElementContent.y = head.y + this.BaseLayout.getNodeHeight(head) + this.baseLineHeight / 2 - this.baseLine.y + this.subjectAndLineSpace;
                    break;
                case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                case TimeNodeLayoutType.TITLE_BOTTOM:
                    dotElementContent.x = title.x + title.width / 2 - this.baseLine.x;
                    dotElementContent.y = title.y - this.baseLineHeight / 2 - this.baseLine.y - this.subjectAndLineSpace;
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    dotElementContent.radius = head.width / 2;
                    dotElementContent.x = head.x + head.width / 2 - this.baseLine.x;
                    dotElementContent.y = head.y + head.height / 2 - this.baseLine.y;
                    break;
                default:
            }

            if (this.settingData.useTimeLineColor) {
                dotElementContent.color = this.baseLine.timeLineContent.color;
            } else {
                dotElementContent.color = this.getHeadConnectLineColor(head, timeNode.title);
            }
            dotElementContent.left = nodeWidth / 2;
            dotElementContent.right = nodeWidth / 2;
            if (index == 0) {
                dotElementContent.lineClockwise = true;
            } else {
                let preDot = dots[index - 1];
                if (Math.abs(preDot.y - dotElementContent.y) > 15) {
                    dotElementContent.lineClockwise = !preDot.lineClockwise;
                } else {
                    dotElementContent.lineClockwise = preDot.lineClockwise;
                }
            }
            dots.push(dotElementContent);
        }

        this.baseLine.timeLineContent.dots = dots;
    }

    getOrderDot(dots) {
        let list = new Array();
        dots.forEach(node => {
            list.push(node);
        });
        list.sort(function (data1, data2) {
            if (data1.y == data2.y) {
                return 0;
            } else if (data1.x < data2.x) {
                return -1;
            } else {
                return 1;
            }
        })
        return list;
    }

    spliteNode() {
        this.nodeSplitList = [];
        let nodeList = this.getOrderNodes();
        let count = nodeList.length;
        if (count == 0) {
            return;
        }

        let cellCount = 3;
        if (count < 7) {
            cellCount = 2;
        } else if (count < 9) {
            cellCount = 3;
        } else if (count < 20) {
            cellCount = 4;
        } else if (count < 40) {
            cellCount = 5;
        } else if (count < 80) {
            cellCount = 6;
        } else {
            cellCount = 6;
        }

        for (let index = 0; index < count; index++) {
            let node = nodeList[index];
            let rowNum = (Math.floor(index / cellCount));
            if (this.nodeSplitList.length <= rowNum) {
                let list = new Array();
                list.push(node);
                this.nodeSplitList.push(list);
            } else {
                this.nodeSplitList[rowNum].push(node);
            }
        }
    }

    getNodesTopHieght(list) {
        if (this.timeNodeLayoutType == TimeNodeLayoutType.TITLE_DES_BOTTOM ||
            this.timeNodeLayoutType == TimeNodeLayoutType.TITLE_BOTTOM) {
            return 0;
        }
        let height = 0;
        let listLength = list.length;

        for (let index = 0; index < listLength; index++) {
            let node = list[index];
            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    if (height < this.BaseLayout.getNodeHeight(node.head) + this.subjectAndLineSpace) {
                        height = this.BaseLayout.getNodeHeight(node.head) + this.subjectAndLineSpace;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    if (height < node.head.height / 2 - this.lineSpacing - this.baseLineHeight) {
                        height = node.head.height / 2 - this.lineSpacing - this.baseLineHeight;
                    }
                    break;
                default:
            }
        }
        return height;
    }

    getNodesBottomHieght(list) {
        let height = 0;
        let listLength = list.length;
        for (let index = 0; index < listLength; index++) {
            let node = list[index];
            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                    if (height < this.BaseLayout.getNodeHeight(node.title) + this.BaseLayout.getNodeHeight(node.desc) + this.subjectAndLineSpace + this.sonSubjectAndLineSpace) {
                        height = this.BaseLayout.getNodeHeight(node.title) + this.BaseLayout.getNodeHeight(node.desc) + this.subjectAndLineSpace + this.sonSubjectAndLineSpace;
                    }
                    break;
                case TimeNodeLayoutType.TITLE_BOTTOM:
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    if (height < this.BaseLayout.getNodeHeight(node.title) + this.subjectAndLineSpace) {
                        height = this.BaseLayout.getNodeHeight(node.title) + this.subjectAndLineSpace;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                    let headTitleDesMiddleBottomHeight = this.BaseLayout.getNodeHeight(node.title) + this.BaseLayout.getNodeHeight(node.desc) + node.head.height / 2 + this.subjectAndLineSpace + this.sonSubjectAndLineSpace;
                    if (height < headTitleDesMiddleBottomHeight) {
                        height = headTitleDesMiddleBottomHeight;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    let headTitleMiddleBottomHeight = this.BaseLayout.getNodeHeight(node.title) + node.head.height / 2 + this.subjectAndLineSpace;
                    if (height < headTitleMiddleBottomHeight) {
                        height = headTitleMiddleBottomHeight;
                    }
                    break;
                default:
            }
        }
        return height;
    }

    getNodesWidth(list) {
        let width = this.subjectSpace;
        list.forEach(node => {
            width += this.getTimeMindTypeNodeWidthByHorizontal(node) + this.subjectSpace;
        })
        return width;
    }

    getTimeMindTypeNodeWidthByHorizontal(node) {
        let result = 0;

        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
                result = node.head.width;
                result = Math.max(result, node.title.width);
                result = Math.max(result, node.desc.width);
                break;
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                result = node.head.width + this.UiUtil.dip2px(20);
                result = Math.max(result, node.title.width);
                result = Math.max(result, node.desc.width);
                break;
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                result = Math.max(result, node.title.width);
                result = Math.max(result, node.desc.width);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
                result = Math.max(result, node.title.width);
                break;
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                result = node.head.width;
                result = Math.max(result, node.title.width);
                break;
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                result = node.head.width + this.UiUtil.dip2px(20);
                result = Math.max(result, node.title.width);
                break;
            default:
        }

        return result;
    }

}
export default TimeLineHorizontalSLayout
const { default: strings } = require("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Mente + Mapa mental';
stringMap [strings.TEMPLATE] = 'Plantilla';
stringMap [strings.Mind_Edit_Main_Topic] = 'Tema de rama';
stringMap [strings.Global_Add_Free_Node_Tips] = 'Haga clic en la pantalla para seleccionar la ubicación para agregar';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'Haga doble clic en la pantalla para seleccionar la ubicación para agregar';
stringMap [strings.Global_Add_Relationship_Tips] = 'Seleccione el objetivo que debe asociarse';
stringMap [strings.Global_Add_Target_Tips] = 'Seleccione un nodo para agregar objetos';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Este objeto ya está asociado con otros mapas';
stringMap [strings.Mind_Edit_Main_Idea] = 'Tema central';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'El mapa asociado no existe';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'Prioridad';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'Progreso';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'Estrellas';
stringMap [strings.Mind_Style_Add_Icon_Week] = 'semana';
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'Retrato';
stringMap [strings.Establish] = 'Crear';
stringMap [strings.Create_From_Template] = 'Crear desde plantilla';
stringMap [strings.Common] = 'Común';
stringMap [strings.Function_List] = 'Lista de funciones';
stringMap [strings.Recent_Updates] = 'Recientemente actualizado';
stringMap [strings.The_Latest_To_Delete] = 'Eliminado recientemente';
stringMap [strings.Setting_Up] = 'Contáctenos';
stringMap [strings.Usinghelp] = 'Usando ayuda';
stringMap [strings.The_Download_Client] = 'Descargar versión cliente / móvil';
stringMap [strings.Global_Purchase] = 'Compra';
stringMap [strings.Global_Login] = 'Iniciar sesión';
stringMap [strings.My_map] = 'Mi mapa';
stringMap [strings.Create_Map] = 'Crear un mapa';
stringMap [strings.Storm] = 'Tormenta';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Common \ nCharacteristic';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'Característica';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'Centro de organización';
stringMap [strings.Mind_Edit_Free_Topic] = 'Tema libre';
stringMap [strings.Mind_Edit_Sub_Topic] = 'Subtema';
stringMap [strings.Mind_Organization_Main_Topic] = 'Departamento';
stringMap [strings.Mind_Edit_Main_Topic] = 'Tema de rama';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'Planificación';
stringMap [strings.Mind_Time_Edit_Head] = 'Hora';
stringMap [strings.Mind_Time_Edit_Title] = 'Plan';
stringMap [strings.Mind_Edit_Details] = 'Descripción detallada';
stringMap [strings.Checking_Data] = 'Comprobando datos';
stringMap [strings.Setting_Information] = 'Información de configuración';
stringMap [strings.Sign_Out] = 'Cerrar sesión';

// lista de funciones
stringMap [strings.Create_a_Mind_Map] = 'Crear un mapa mental';
stringMap [strings.Create_Mind_Map_1] = '[1] Cree un mapa con catorce diseños (bidireccional / derecha / izquierda / estructura organizativa / gráfico de burbuja simple / gráfico de burbuja doble / mapa de árbol izquierdo / mapa de árbol derecho / diagrama de árbol bidireccional / tabla / línea de tiempo horizontal / línea de tiempo vertical / línea de tiempo S / pirámide) ';
stringMap [strings.Create_Mind_Map_2] = '[2] Las plantillas se pueden usar para crear mapas rápidamente (una gran cantidad de referencias de contenido de plantilla)';
stringMap [strings.Create_Mind_Map_3] = '[3] Edite el mapa con nueve tipos de estilos de línea';
stringMap [strings.Create_Mind_Map_4] = '[4] Realizar nueve cambios de diseño y ediciones en el mapa';
stringMap [strings.Create_Mind_Map_5] = '[5] Agregar nodos secundarios al nodo de destino y agregar nodos en el mismo nivel (superior e inferior)';
stringMap [strings.Create_Mind_Map_6] = '[6] Resumen: agregue [Resumen] para describir un solo nodo / múltiples nodos';
stringMap [strings.Create_Mind_Map_7] = '[7] Bloque: agregar [bloque] a un solo nodo / múltiples casillas de nodo del mapa que se va a modularizar';
stringMap [strings.Create_Mind_Map_8] = '[8] Agregar nodo libre';
stringMap [strings.Create_Mind_Map_9] = '[9] Cree una asociación de cuatro estilos de línea entre cualquier nodo';
stringMap [strings.Create_Mind_Map_10] = '[10] Insertar imágenes (local + biblioteca de materiales), iconos, notas, enlaces al nodo';
stringMap [strings.Create_Mind_Map_11] = '[11] Copiar / pegar nodo, copiar / pegar estilo';
stringMap [strings.Create_Mind_Map_12] = '[12] Cancelar, restaurar y cancelar';
stringMap [strings.Create_Mind_Map_13] = '[13] Hay varias selecciones disponibles, se pueden operar varios nodos simultáneamente';
stringMap [strings.Create_Mind_Map_14] = '[14] Se puede insertar un gráfico estadístico';
stringMap [strings.Create_Mind_Map_15] = '[15] Se puede grabar el mapa completo';
stringMap [strings.Create_Mind_Map_16] = '[16] Copiar mapa';
stringMap [strings.Create_Mind_Map_17] = '[17] Puede copiar el mapa o el mapa completo en el mapa de otros archivos';
stringMap [strings.Create_Mind_Map_18] = '[18] Se puede crear un mapa después del resumen';
stringMap [strings.Create_Mind_Map_19] = '[19] La edición del mapa se puede cambiar durante la edición (lado de la computadora)';
stringMap [strings.Create_Mind_Map_20] = '[20] Mapa de edición del teclado Bluetooth: \ n \ t [Comando] + [a] Seleccionar el nodo maestro \ n \ t [Comando] + [←] [↑] [→] [↓ ] Cambiar destino de selección o mover mapa \ n \ t [Comando] + [e] Editar destino \ n \ t [Comando] + [w] Crear nodo secundario \ n \ t [Comando] + [s] Crear nodo hermano de destino ( Arriba) \ n \ t [Comando] + [d] Crear nodo hermano de destino (abajo) \ n \ t [Comando] + [q] Salir del destino seleccionado \ n \ t [Comando] + [-] [+] Zoom mapa';

stringMap [strings.Beautify_The_Mind_Map] = 'Embellecer el mapa mental';
stringMap [strings.Beautify_The_Mind_Map_1] = '[1] Establecer el color de fondo del mapa mental';
stringMap [strings.Beautify_The_Mind_Map_2] = '[2] Establezca el grosor y el color de toda la línea del mapa, ya sea que esté ahusada o no';
stringMap [strings.Beautify_The_Mind_Map_3] = '[3] Establecer color de relleno de texto, color de borde, 13 formas de borde, ancho de borde, efecto de sombra';
stringMap [strings.Beautify_The_Mind_Map_4] = '[4] Establecer todo o parte del color de la línea y el ancho del mapa';
stringMap [strings.Beautify_The_Mind_Map_5] = '[5] Establecer tamaño de fuente de texto, negrita, cursiva, eliminar estilo';
stringMap [strings.Beautify_The_Mind_Map_6] = '[6] Establecer el tamaño, la posición y el estilo de la forma de la imagen';
stringMap [strings.Beautify_The_Mind_Map_7] = '[7] Agregue iconos de siete categorías (común / 3D / estudio / trabajo / persona / animal / comida) y establezca el tamaño / ubicación';
stringMap [strings.Beautify_The_Mind_Map_8] = '[8] Agregar una referencia de estilo de un clic a todo el mapa';
stringMap [strings.Beautify_The_Mind_Map_9] = '[9] Mapa de administración de grupo';
stringMap [strings.Beautify_The_Mind_Map_10] = '[10] Se puede agregar un fondo personalizado (biblioteca de materiales) a todo el mapa';
stringMap [strings.Beautify_The_Mind_Map_9] = '[11] Se pueden agregar bordes o fondos individuales (biblioteca de materiales) a un solo nodo';
stringMap [strings.Beautify_The_Mind_Map_9] = '[12] La longitud del nodo se puede arrastrar';


stringMap [strings.Map_Output] = 'Salida del mapa';
stringMap [strings.Map_Output_1] = '[1] Guardar imagen';
stringMap [strings.Map_Output_2] = '[2] Guardar PDF';
stringMap [strings.Map_Output_3] = '[3] Compartir enlace';
stringMap [strings.Map_Output_4] = '[4] Compartir con la tormenta';
stringMap [strings.Map_Output_4] = '[5] Imprimir';

stringMap [strings.Work_together] = 'Trabajar en colaboración';
stringMap [strings.Work_together_1] = '[1] Crear un grupo compartido (etiqueta de grupo, anuncio de grupo)';
stringMap [strings.Work_together_2] = '[2] Nuevos miembros del grupo compartido';
stringMap [strings.Work_together_3] = '【3】 Varios miembros del grupo editan el mismo mapa juntos';
stringMap [strings.Work_together_4] = '[4] Cargar archivos compartidos dentro del grupo (word / excle / pdf)';
stringMap [strings.Work_together_5] = '[5] Puede dejar un mensaje en el grupo';
stringMap [strings.Work_together_6] = '[6] Configuración de autoridad de administración de grupo compartido';
stringMap [strings.Work_together_7] = '[7] Disolver grupo';

stringMap [strings.Work_together] = 'Uso compartido creativo (Storm)';
stringMap [strings.Community_Sharing_1] = '[1] Puedes compartir tu creación con la tormenta para que más personas puedan verla';
stringMap [strings.Community_Sharing_1] = '[2] Puede seleccionar etiquetas para ver contenido relacionado con la tormenta';
stringMap [strings.Community_Sharing_1] = '[3] Puede dar me gusta y comentar sobre trabajos de tormenta';

//Recientemente actualizado
stringMap [strings.Recently_Updated_Title_1] = 'Crear una cuenta personal';
stringMap [strings.Recently_Updated_Title_2] = 'Base de conocimientos masiva de plantillas';
stringMap [strings.Recently_Updated_Title_3] = 'Soporta múltiples plataformas';
stringMap [strings.Recently_Updated_Title_4] = 'Personaliza cada detalle';
stringMap [strings.Recently_Updated_Title_5] = 'Biblioteca de estilos de estilo personal';
stringMap [strings.Recently_Updated_Title_6] = 'Salida del mapa';
stringMap [strings.Recently_Updated_Title_7] = 'Almacenamiento en la nube';
stringMap [strings.Recently_Updated_Title_8] = 'Quince tipos de mapas';


stringMap [strings.Recently_Updated_Content_1] = 'Cree una cuenta personal y guarde el mapa creado con la cuenta';
stringMap [strings.Recently_Updated_Content_2] = 'Se citan directamente un gran número de plantillas de bases de conocimientos para mejorar la eficiencia del aprendizaje';
stringMap [strings.Recently_Updated_Content_3] = 'Admite producción en línea de la versión web, terminal móvil IOS, terminal Mac, terminal móvil Android, uso multiplataforma de tableta Android';
stringMap [strings.Recently_Updated_Content_4] = 'Puede embellecer el diseño de texto, fondo, líneas, iconos de imágenes, etc. según sus preferencias personales';
stringMap [strings.Recently_Updated_Content_5] = 'La biblioteca de estilos de estilo personal se cita directamente, eliminando el problema de la coincidencia de colores';
stringMap [strings.Recently_Updated_Content_6] = 'Puede guardar imágenes en formato PDF y compartirlas directamente con amigos';
stringMap [strings.Recently_Updated_Content_7] = 'Fuerte soporte de almacenamiento en la nube, ya no tiene que preocuparse por la pérdida de datos';
stringMap [strings.Recently_Updated_Content_8] = 'Bidireccional, derecha, izquierda, estructura organizativa, gráfico de una sola burbuja, gráfico de doble burbuja, gráfico de árbol izquierdo, gráfico de árbol derecho, gráfico de árbol bidireccional, tabla, línea de tiempo horizontal, línea de tiempo vertical, S línea de tiempo, ocho diseños se pueden cambiar a voluntad ';

// Eliminado recientemente
stringMap [strings.Mind_Mindmap_Deleted_Title] = 'Eliminado recientemente';
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = 'Recuperación';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'Eliminar';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'Nombre de archivo';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'Después de eliminar aquí, se eliminará permanentemente';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'Operación';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = 'Buscar mapa eliminado recientemente';
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = 'Eliminar fecha';

//Contáctenos
stringMap [strings.Contact_Us_No_Public] = 'Cuenta pública';
stringMap [strings.Contact_Us_QQ_Group] = 'Grupo QQ';
stringMap [strings.Contact_Us_Message_Board] = 'Tablero de mensajes';
stringMap [strings.Contact_Us_Username] = 'Nombre';
stringMap [strings.Contact_Us_Email] = 'Correo electrónico';
stringMap [strings.Contact_Us_Voicemail] = 'Por favor, deje sus valiosos comentarios';
stringMap [strings.Contact_Us_Send_Email] = 'Enviar correo electrónico';

// verificar mensaje
stringMap [strings.Input_Username_isNull] = 'El nombre no puede estar vacío';
stringMap [strings.Input_Email_isNull] = 'Ingrese la dirección de correo electrónico correcta';
stringMap [strings.Input_Voicemail_isNull] = 'Por favor ingrese el contenido enviado';
stringMap [strings.Input_Introduction_isNull] = 'La introducción no puede estar vacía';
stringMap [strings.Input_Title_isNull] = 'El título no puede estar vacío';

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'Ingrese el número de teléfono correcto';
stringMap [strings.Input_Please_Enter_Verification_Code] = '¡Ingrese el código de verificación! ';
stringMap [strings.Input_Please_Enter_The_Password] = '¡Ingrese la contraseña! ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = '¡El formato de la contraseña es incorrecto! ';
stringMap [strings.Input_Please_Enter_A_New_Password] = '¡Ingrese una nueva contraseña! ';
stringMap [strings.Input_Confirm_The_New_Password] = '¡Ingrese la contraseña de confirmación! ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = '¡Las contraseñas son inconsistentes! ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = '¡Ingrese el código de verificación correcto! ';
stringMap [strings.Input_The_Verification_Code_Error] = '¡Error de código de verificación! ';

// Usando ayuda
stringMap [strings.Mind_Usinghelp_1] = 'Crear un mapa mental';
stringMap [strings.Mind_Usinghelp_2] = 'Cómo agregar un nodo';
stringMap [strings.Mind_Usinghelp_3] = 'Crear un nodo libre';
stringMap [strings.Mind_Usinghelp_4] = 'Establecer asociación';
stringMap [strings.Mind_Usinghelp_5] = 'embellecer el lienzo';
stringMap [strings.Mind_Usinghelp_6] = 'embellecer texto';
stringMap [strings.Mind_Usinghelp_7] = 'Insertar notas, enlaces';
stringMap [strings.Mind_Usinghelp_8] = 'Insertar icono';
stringMap [strings.Mind_Usinghelp_9] = 'Insertar imagen';
stringMap [strings.Mind_Usinghelp_10] = 'Cómo restaurar la eliminación';
stringMap [strings.Mind_Usinghelp_11] = 'Exportar mapa mental';
stringMap [strings.Mind_Usinghelp_12] = 'Actualización de membresía';
stringMap [strings.Mind_Usinghelp_13] = 'Tecla de acceso directo';
stringMap [strings.Mind_Usinghelp_Undersknown] = 'Lo tengo';
stringMap [strings.Mind_Usinghelp_14] = 'Insertar biblioteca de materiales';
// Usa ayuda-2
stringMap [strings.Mind_Usinghelp_15] = 'Nuevo mapa';
// stringMap [strings.Mind_Usinghelp_2] = 'Agregar nodo';
// stringMap [strings.Mind_Usinghelp_4] = 'Establecer asociación';
// stringMap [strings.Mind_Usinghelp_5] = 'Embellecer el lienzo';
// stringMap [strings.Mind_Usinghelp_6] = 'Embellecer texto';
// stringMap [strings.Mind_Usinghelp_7] = 'Agregar notas, enlaces';
stringMap [strings.Mind_Usinghelp_17] = 'Deshacer operación, cancelar';
stringMap [strings.Mind_Usinghelp_18] = 'Arrastrar nodo para intercambiar';
stringMap [strings.Mind_Usinghelp_19] = 'Alineación de nodos';
stringMap [strings.Mind_Usinghelp_20] = 'Cambiar tipo de mapa';
stringMap [strings.Mind_Usinghelp_21] = 'Estilo de línea de mapa';
stringMap [strings.Mind_Usinghelp_22] = 'Color de fondo del mapa';
stringMap [strings.Mind_Usinghelp_23] = 'Establecer la forma del nodo';
stringMap [strings.Mind_Usinghelp_24] = 'Estilo de línea de nodo';
stringMap [strings.Mind_Usinghelp_25] = 'Configuración de imagen de nodo';
stringMap [strings.Mind_Usinghelp_26] ='Configuración del icono de nodo';
stringMap [strings.Mind_Usinghelp_27] = 'Resumen de adición de nodo';
stringMap [strings.Mind_Usinghelp_28] = 'Selección de cuadro de nodo único';
stringMap [strings.Mind_Usinghelp_29] = 'Multinodos en bloques';
stringMap [strings.Mind_Usinghelp_30] = 'Nodo agregar borde';
stringMap [strings.Mind_Usinghelp_31] = 'Agregar mapa estadístico';
stringMap [strings.Mind_Usinghelp_32] = 'Ocultar nodo y restaurar';
stringMap [strings.Mind_Usinghelp_33] = 'Operaciones múltiples de nodo';
stringMap [strings.Mind_Usinghelp_34] = 'Cambiar estilo';
stringMap [strings.Mind_Usinghelp_35] = 'Insertar voz y explicación';
stringMap [strings.Mind_Usinghelp_36] = 'Guía del mapa a la imagen';
stringMap [strings.Mind_Usinghelp_37] = 'Mapa del documento PDF';
stringMap [strings.Mind_Usinghelp_38] = 'Imprimir mapa';
stringMap [strings.Mind_Usinghelp_39] = 'Compartir y ver enlaces';
stringMap [strings.Mind_Usinghelp_40] = 'Mapa para compartir con la tormenta';
stringMap [strings.Mind_Usinghelp_41] = 'Usando la carpeta';
stringMap [strings.Mind_Usinghelp_42] = 'Eliminar y restaurar mapa';
// Usa la ayuda 3
stringMap [strings.Mind_Usinghelp_43] = 'Los amigos solicitan unirse al grupo';
stringMap [strings.Mind_Usinghelp_44] = 'Cómo disolver el grupo';
stringMap [strings.Mind_Usinghelp_45] = 'Cargar información de archivo';
stringMap [strings.Mind_Usinghelp_46] = 'Aceptar unirse a la aplicación';
stringMap [strings.Mind_Usinghelp_47] = 'Edición colaborativa';
stringMap [strings.Mind_Usinghelp_48] = 'Nuevo grupo para compartir';
stringMap [strings.Mind_Usinghelp_49] = 'Invitar miembros del grupo';

//tormenta
stringMap [strings.Mind_Storm_Recommend] = 'Recomendación';
stringMap [strings.Mind_Storm_Boilingpoint] = 'Punto de ebullición';
stringMap [strings.Mind_Storm_Unfold] = 'Despliegue';
stringMap [strings.Mind_Storm_Putaway] = 'Putaway';
stringMap [strings.Mind_Storm_Myclassification] = 'Mi clasificación';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'Clasificación recomendada';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'Haga clic para agregar una categoría';
stringMap [strings.Mind_Storm_No_Classification] = 'Sin clasificación todavía';
stringMap [strings.Mind_Storm_No_Tinterested] = 'No me interesa';
stringMap [strings.Mind_Storm_Report] = 'Informe';
stringMap [strings.Mind_Storm_Views] = 'Vistas';

stringMap [strings.Mind_Storm_Pornographic] = 'Pornográfico vulgar';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'Acoso publicitario';
stringMap [strings.Mind_Storm_Political_Religion] = 'Política y religión';
stringMap [strings.Mind_Storm_Infringement] = 'Infracción (retrato, difamación, plagio, uso fraudulento)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'Contenido prohibido';
stringMap [strings.Mind_Storm_Spread_Rumors] = 'Difundir rumores y difundir rumores';
stringMap [strings.Mind_Storm_Report_Successful] = 'Informe exitoso';

//editar
stringMap [strings.Global_Edit] = 'Editar';
stringMap [strings.Global_Cancel] = 'Cancelar';
stringMap [strings.Global_Ok] = 'Aceptar';
stringMap [strings.Global_Name] = 'Apodo';
stringMap [strings.Global_Introduction] = 'Introducción';
stringMap [strings.Global_Change_Avatar] = 'Cambiar avatar';
stringMap [strings.Global_Local_Upload] = 'Carga local';

//informacion personal
stringMap [strings.User_Personal_information] = 'Información personal';
stringMap [strings.User_Member_Validity_Period] = 'Período de validez del miembro';
stringMap [strings.User_My_Share] = 'Mi recurso compartido';
stringMap [strings.User_My_Collection] = 'Mi colección';
stringMap [strings.User_More_Message] = 'Más';
stringMap [strings.User_Deleted_Sotrm] = 'Eliminar tormenta';
stringMap [strings.User_Folder_Rename] = 'Cambiar nombre';
stringMap [strings.User_Folder_Rename_Root] = 'Modificar permisos';
stringMap [strings.User_Folder_Rename_Root_Title] = 'Modificar permisos';
stringMap [strings.User_Folder_Root_Public] = 'Totalmente público';
stringMap [strings.User_Folder_Root_Viewonly] = 'La carpeta pública solo se puede ver';
stringMap [strings.User_Folder_Root_Private] = 'Privado';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'Otras personas pueden ver sus favoritos en su página de perfil';
stringMap [strings.User_Message_Link] = 'Enlace';
stringMap [strings.User_New_Group] = 'Nuevo grupo';
stringMap [strings.User_New_Group_Title] = 'Crear un nuevo grupo';
stringMap [strings.User_New_Group_Style_Title] = 'Tipo de archivo';
stringMap [strings.User_Share_Is_Null] = '¡Aún no se comparte! ';
stringMap [strings.User_Deleted_Storm_Tips] = '¿Está seguro de que desea eliminar esta tormenta? ';
stringMap [strings.User_Remove_From_List] = 'Eliminar de la lista',
    stringMap [strings.User_Move_To] = 'Mover a',
    stringMap [strings.User_Deleted_Link] = 'Eliminar enlace'
stringMap [strings.User_Collction_Is_Null] = 'Sin colección todavía'
stringMap [strings.User_Collection] = 'Colección'

// Cuadro de inicio de sesión
stringMap [strings.User_Lolgin_Welcome_To_Login] = 'Bienvenido a Mind +'
stringMap [strings.User_Lolgin_Free_Registration] = 'Registro gratuito'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'Ingrese su número de teléfono'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'Por favor ingrese el código de verificación'
stringMap [strings.User_Lolgin_Obtain] = 'Obtener'
stringMap [strings.User_Lolgin_Password_Login] = 'Inicio de sesión con contraseña'
stringMap [strings.User_Lolgin_Login] = 'Iniciar sesión'
stringMap [strings.User_Lolgin_Register_An_Account] = 'Registro de cuenta'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'Ya tengo una cuenta'
stringMap [strings.User_Lolgin_To_Log_In] = 'Ir a iniciar sesión'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'Introduzca una contraseña'
stringMap [strings.User_Lolgin_Confirm_Password] = 'Confirmar contraseña'
stringMap [strings.User_Lolgin_Registered] = 'Registrado'
stringMap [strings.User_Lolgin_Account_Login] = 'Inicio de sesión de cuenta'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'Iniciar sesión con código de escaneo'
stringMap [strings.User_Lolgin_Forgot_Password] = 'Contraseña olvidada'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'Código de verificación de inicio de sesión'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Open Mind + escanear en la parte superior de la interfaz de configuración'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'Este código QR caduca en cinco minutos, haga clic cuando caduque'
stringMap [strings.User_Lolgin_Refresh] = 'Actualizar'
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'El escaneo se realizó correctamente, confirme en su teléfono'
stringMap [strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'Ya sea para autorizar el inicio de sesión'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'Ingrese una nueva contraseña'
stringMap [strings.User_Lolgin_Reset] = 'Restablecer'

//Mi mapa
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'Buscar tipo de plantilla o plantilla'
stringMap [strings.My_Mind_Date] = 'Fecha'
stringMap [strings.My_Mind_Create_Folder] = 'Crear carpeta'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'Por favor ingrese el nombre de la carpeta'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'Ingrese un nuevo nombre de carpeta'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'Aún no hay mapa, ve rápido'
stringMap [strings.My_Mind_Create] = 'Crear'
stringMap [strings.My_Mind_Right] = '¡Barra!'
stringMap [strings.My_Mind_Open] = 'Abrir'
stringMap [strings.My_Mind_Type_Left_Right] = 'Mapa de guía dual'
stringMap [strings.My_Mind_Type_Unilateral] = 'Mapa unilateral'
stringMap [strings.My_Mind_Type_Bottom] = 'Organigrama'
stringMap [strings.My_Mind_Type_Bubble] = 'Gráfico de burbujas'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'Mapa de programación'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'Mapa mental en blanco'
stringMap [strings.My_Mind_All] = 'Todos'
stringMap [strings.My_Mind_Views] = 'Vistas:'

// Búsqueda de tormentas
stringMap [strings.Storm_Search_How_Title] = 'Etiquetas principales'
stringMap [strings.Storm_History_Search] = 'Búsqueda de historial'

// Encabezado público
stringMap [strings.Public_Header_Mind] = 'Mapa'
stringMap [strings.Public_Header_Outline] = 'Esquema'
stringMap [strings.Public_Header_Download_Apps] = 'Descargar cliente'
stringMap [strings.Public_Header_Mobile_Version] = 'Versión móvil'
stringMap [strings.Public_Header_Creator] = 'Creador:'
stringMap [strings.Public_Header_View_Frequency] = 'Vistas:'
stringMap [strings.Public_Header_Save] = 'Guardar'
stringMap [strings.Header_Mind_Tips_Saved] = 'Guardado'
stringMap [strings.Header_Mind_Tips_Edit] = 'Editado'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'La actualización se ha guardado'

// Menú de edición de mapas
stringMap [strings.Mind_Edit_Revoke] = 'Revocar'
stringMap [strings.Mind_Edit_Restore] = 'Restaurar'
stringMap [strings.Mind_Edit_Subtopic] = 'Subtema'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'Subtema principal'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'Subtema inferior'
stringMap [strings.Mind_Edit_Free_Node] = 'Nodo libre'
stringMap [strings.Mind_Edit_Summary] = 'Resumen'
stringMap [strings.Mind_Edit_Block] = 'En un bloque'
stringMap [strings.Mind_Edit_Insert] = 'Insertar'
stringMap [strings.Mind_Edit_Related] = 'Relacionados'
stringMap [strings.Mind_Edit_Style] = 'Estilo'
stringMap [strings.Mind_Edit_Recording] = 'Grabación'
stringMap [strings.Mind_Edit_Insert_Picture] = 'Insertar una imagen'
stringMap [strings.Mind_Edit_Insert_Icon] = 'Insertar icono'
stringMap [strings.Mind_Edit_Insert_Remarks] = 'Insertar comentarios'
stringMap [strings.Mind_Edit_Insert_Link] = 'Insertar enlace'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'Insertar gráfico estadístico'
stringMap [strings.Mind_Edit_Upload] = 'Subir'
stringMap [strings.Mind_Edit_Right_Icon] = 'Icono'
stringMap [strings.Mind_Edit_Right_Style] = 'Estilo'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'Decodificando ...'
stringMap [strings.Mind_Edit_Audio_Duration] = 'Duración'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = '¿Está seguro de eliminar el audio grabado? '
stringMap [strings.Mind_Edit_Join_Membership] = 'Membresía abierta'
stringMap [strings.Mind_Edit_Upgrade] = 'Actualizar'
stringMap[strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = 'Los miembros pueden tener más tiempo de grabación, ¿ahora ir a actualizar a la membresía? '

stringMap [strings.Mind_Edit_Choose_Material] = 'Seleccionar material'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'Arrastra y suelta imágenes aquí'
stringMap [strings.Mind_Edit_Select_locally] = 'Seleccionar de local'
stringMap [strings.Mind_Edit_Choose_From] = 'Seleccionar de la biblioteca de materiales'
stringMap [strings.Mind_Edit_Mine] = 'Mi'
stringMap [strings.Mind_Edit_My_Material] = 'Mi material'
stringMap [strings.Mind_Edit_Recently_Used] = 'Usado recientemente'
stringMap [strings.Mind_Edit_Use] = 'Usar'
stringMap [strings.Mind_Edit_No_Material_Tempontly_Go_Add_It] = 'No hay material en este momento, ¡vaya y agréguelo! '
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'Seleccionar tipo de gráfico'
stringMap [strings.Mind_Edit_Remarks] = 'Comentarios'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'Por favor ingrese los comentarios'
stringMap [strings.Mind_Edit_Add] = 'Agregar'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'Por favor ingrese el enlace'

// Edita el menú en el lado izquierdo de la página
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'Guardar imagen'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = 'Exportar PDF'
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'Compartir enlaces'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'Comparte con la tormenta'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'Imprimir'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'Desbloquear la versión completa'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'Ayuda'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'Guardar formato'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'formato png'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'formato jpg'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'formato webp'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'Compartir mapa'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'Interruptor de compartir'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'Puede copiar el enlace y enviarlo'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'Copiar enlace'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'Si desea cambiar el enlace, haga clic en'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'Regenerar'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'Contraseña del enlace:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ='Permita el ahorro'
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Número máximo de vistas'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'Infinito'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'Límite'
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'Segundo'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'Período de validez del enlace'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = '1 día'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = '3 días'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = '7 días'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'Permanente'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'Copiar contenido y enlace'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = '¿Está seguro de que desea regenerar? ¡El enlace anterior no será válido! '
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'Compartir'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'Categoría personalizada:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'Si se permiten comentarios'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'Editar para agregar categoría'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'Derechos de membresía'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'Plataforma de soporte'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'Membresía abierta'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'Intercambio de código de activación'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = 'Beneficios de la membresía después de la compra'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'Seleccionar método de pago'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'Precio de la actividad'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'Cantidad realmente pagada'
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Renovación inmediata'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《Condiciones de servicio》'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《Política de privacidad》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'Código de activación:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Por favor ingrese el código de activación'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'Verificar código de activación'
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'Seleccionar categorías'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'El título principal no puede estar vacío'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'Cupón:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Ingrese el código del cupón'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'Confirmar el uso de cupones'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'Verificación'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'Importe preferencial'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'Pague inmediatamente'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Confirmar información de intercambio'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'Código de intercambio:'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Intercambio'

// Edita el menú en el lado derecho de la página
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'Lienzo'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'Color de fondo'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'Diseño'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'Estilo de línea'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'Color de línea'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'Ancho de línea'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = 'Las líneas son cónicas'
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'Fuente del tema'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'Fuente infantil'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'Color del texto'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'Tamaño de fuente'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'Estilo de fuente'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'Color del borde'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'Color de relleno'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'Forma del borde'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'Ancho del borde'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Mostrar efecto de sombra (función de suscripción)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'Color de línea'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'Línea de puntos'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'Tamaño de imagen'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'Ubicación de la imagen'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'Forma de imagen'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'Tamaño del icono'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'Posición del icono'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'General'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'Prioridad'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'Progreso'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'Estrellas'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'semana'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Retrato'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'Aprendizaje'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'Trabajo'
stringMap [strings.Mind_Edit_Right_Menu_Characters] = 'Personajes'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'Animales'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'Comida'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'Copiar estilo'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'Estilo pegado'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'Modificar forma'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'Ver imagen'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'Eliminar imagen'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'Copiar'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'Pegar'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'Eliminar línea'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Eliminar'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'Editar comentarios'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'Abrir enlace'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'Eliminar comentarios'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'Eliminar enlace'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'Eliminar icono'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'Estilo de icono'

// Panel de edición de gráficos estadísticos
stringMap [strings.Echart_Edit_Statistical] = 'Estadísticas'
stringMap [strings.Echart_Edit_Value] = 'Valor'
stringMap [strings.Echart_Edit_Color] = 'Color'
stringMap [strings.Echart_Edit_Title] = 'Título'
stringMap [strings.Echart_Edit_Detailed_Description] = 'Descripción detallada'
stringMap [strings.Echart_Edit_Value_Sort] = 'Ordenar'
stringMap [strings.Echart_Edit_Value_Display_Type] = 'Tipo de pantalla'
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'Esquema de colores'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'Tamaño de pantalla'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'Tamaño del título'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'Explica el tamaño'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'Suscribirse'

// pestaña Tormenta
stringMap [strings.StormBookTag_Subscriptions] = 'Suscrito'
stringMap [strings.StormBookTag_List] = 'Lista'

// Página de intercambio de tormentas
stringMap [strings.Storm_Share_Comment] = 'Comentario'
stringMap [strings.Storm_Share_Reply] = 'Responder'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'Déjame también comentarlo ~'
stringMap [strings.Storm_Share_Release] = 'Liberar'
stringMap [strings.Storm_Share_Main_Title] = 'Título principal'
stringMap [strings.Storm_Share_Subtitle] = 'Subtítulo'
stringMap [strings.Storm_Share_Author] = 'Autor'
stringMap [strings.Storm_Share_Replies] = 'Responder'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'Aún no hay comentarios. Sea el primero en ser el primero.'
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'Introduzca un comentario ~'
stringMap [strings.Storm_Share_Delete_Comment_Tips] = '¿Está seguro de que desea eliminar este comentario? '

// Ayuda para uso interno del mapa
stringMap [strings.Mind_Usinghelp_Btn_1] = 'Teclas de acceso directo (ventanas)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'Tecla de acceso directo (mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'Descripción'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Comando] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'Agregar nodo hijo'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[Delet]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'Eliminar el nodo actual (tenga en cuenta que no se puede eliminar si está en estado de edición)'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Comando] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'Modificar el nodo actual'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Comando] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Comando] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'Agrandar el mapa'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Comando] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'Cuidado con el mapa'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Comando] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Comando] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Mayús] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Comando] + [Mayús] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Comando] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'Salir de la edición'

// Texto de aviso
stringMap [strings.Message_Tips_Login_Success] = 'Inicio de sesión exitoso'
stringMap [strings.Message_Tips_Dlt_Success] = 'Eliminar correctamente'
stringMap [strings.Message_Tips_Report_Success] = 'Informe exitoso'
stringMap [strings.Message_Tips_Copy_Success] = 'Copia exitosa'
stringMap [strings.Message_Tips_Collction_Success] = 'Colección exitosa'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'Modificación exitosa del avatar'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'Modificación de firma exitosa'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'apodo modificado correctamente'
stringMap [strings.Message_Tips_Modification_Success] = 'Modificación exitosa'
stringMap [strings.Message_Tips_New_Profile_Success] = 'Nueva carpeta exitosa'
stringMap [strings.Message_Tips_Mobile_Success] = 'Éxito móvil'
stringMap [strings.Message_Tips_Submit_Success] = 'Enviar correctamente'
stringMap [strings.Message_Tips_Exchange_Success] = 'Intercambio exitoso'
stringMap [strings.Message_Tips_Please_Code] = 'Ingrese el código de activación'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'Acepte la política de privacidad'
stringMap [strings.Message_Tips_Please_Sign_In] = 'Inicie sesión'
stringMap [strings.Message_Tips_Share_Success] = 'Compartir exitosamente'
stringMap [strings.Message_Tips_Create_Success] = 'Creado con éxito'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'El nombre de la carpeta no puede estar vacío'
stringMap [strings.Message_Tips_Selecte_Success] = 'Búsqueda completada'
stringMap [strings.Message_Tips_Subscription_Success] = 'La suscripción es exitosa'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'Cancelar la suscripción con éxito'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'No se encontró contenido relacionado'
stringMap [strings.Message_Tips_Max_Add_Class] = 'Solo se puede agregar como máximo'
stringMap [strings.Message_Tips_Registered_Success] = 'Registrado exitosamente'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'Error de inicio de sesión de escaneo de código'
stringMap [strings.Message_Tips_Share_Is_Null] = 'El enlace para compartir no existe'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = 'El archivo cargado no puede exceder los 3 MB'
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'Obtener permiso con éxito'
stringMap [strings.Message_Tips_Membership_Required] = 'Membresía requerida'
stringMap [strings.Message_Tips_Save_Success] = 'Guardar correctamente'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = 'Iniciar sesión con código de escaneo exitosamente'
stringMap [strings.Message_Tips_Style] = '¿Una categoría?'

// Modo de esquema
stringMap [strings.Outline_Menu_Indent] = 'Sangría'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'Disminuir sangría'

// Sugerencia de ayuda
stringMap [strings.Help_Background_Content] = 'Relacionados con el fondo de la línea, relacionado con el fondo de la línea, relacionado con el fondo de la línea, relacionado con el fondo de la línea, relacionado con el fondo de la línea, relacionado con el fondo de la línea, relacionado con el fondo de la línea, relacionado con el fondo de la línea, relacionado con el fondo de la línea,'
stringMap [strings.Help_Mind_Content] = 'Se pueden hacer mapas con diferentes diseños según las preferencias: mapa de guía dual, mapa de la izquierda, mapa de la derecha, organigrama, árbol de dos direcciones, árbol de la izquierda, árbol de la derecha, tabla'
stringMap [strings.Help_Share_Link_Content] = 'Los enlaces para compartir se pueden compartir en WeChat, QQ, Weibo y otros canales en forma de enlaces, y los amigos pueden ver el contenido'
stringMap [strings.Using_Templates] = 'Usando plantillas'

//Código de invitación
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'Por favor ingrese el código de invitación'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Ingrese el código de invitación correcto'
stringMap [strings.User_Lolgin_Optional] = 'Opcional'

// Utilizar material

stringMap [strings.Cannot_Add_To_Node_Background] = 'No se aplica al fondo del nodo'
stringMap [strings.Cannot_Add_To_Img_Background] = 'No se aplica al fondo del texto'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'No se aplica al fondo de la línea de parcela'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Agregar imagen de fondo'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Eliminar imagen de fondo'
stringMap [strings.Mind_Add_Background_Image] = 'Insertar imagen de fondo'
stringMap [strings.Mind_Delete_Background_Image] = 'Eliminar imagen de fondo'

stringMap [strings.Permanent_Member] = 'Miembro permanente'
stringMap [strings.Insufficient_Storage_Space] = 'Espacio de almacenamiento insuficiente, actualice la membresía para aumentar el espacio. '
stringMap [strings.Used_Space] = 'Espacio de almacenamiento'
stringMap [strings.Current_Storage] = 'Almacenamiento actual:'
stringMap [strings.Used_Space_Upgrade] = 'Actualización del espacio de almacenamiento'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Mente + Mapa mental y notas Supermnemotécnicos, añade alas al pensamiento, crea, muestra, planifica y anota'
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = 'El espacio de almacenamiento es insuficiente. Puede usar la plantilla cuando actualice su membresía. '

stringMap [strings.Group_Management] = 'Grupo'
stringMap [strings.Save_Img_Fill_Full] = 'Pavimentar por completo'
stringMap [strings.Save_Img_Center_Tilt] = 'Inclinación central'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'Completamente (oscuro)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'Mind + Mind Map Watermarking'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'Agregar marca de agua para proteger la creación de conocimiento:'
stringMap [strings.Save_Img_Watermark_Text] = 'Texto de marca de agua:'
stringMap [strings.Save_Img_Watermark_Logo] = 'LOGOTIPO de marca de agua:'
stringMap [strings.Save_Img_Watermark_Style] = 'Estilo de marca de agua:'
stringMap [strings.Save_Img_Please_Input] = 'Por favor ingrese'
stringMap [strings.Save_Img_Chop_Tips] = 'Puede disfrutar de los derechos de marca de agua cuando abre un miembro'
stringMap [strings.Public_Header_Mindmap_Mind] = 'Modo de mapa'
stringMap [strings.Copy_Mind_Map] = 'Copiar mapa mental'
stringMap [strings.Font_Style_Bold] = 'Negrita'
stringMap [strings.Font_Style_Italics] = 'Cursiva'
stringMap [strings.Font_Style_Delete_Line] = 'Tachado'
stringMap [strings.Remove_Any] = 'Eliminar'

stringMap [strings.Mindmap_Review] = 'Revisión del mapa'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'Profundiza la impresión y mejora la memoria, ordena ideas e información de manera eficiente'
stringMap [strings.Mindmap_Review_Content] = 'La revisión del mapa se refiere a la reproducción del proceso de edición, y el contenido se reproduce en secuencia comenzando desde el tema central; la revisión del mapa puede ayudarlo a realizar un inventario del sistema, análisis y encontrar ideas y lagunas . Durante el proceso de revisión, puede encontrar diferentes inspiraciones '
stringMap [strings.Mindmap_Review_Speed] = 'Velocidad'
stringMap [strings.Mindmap_Review_Word_Speed] = 'Velocidad de Word'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'Relación de fondo'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'Ampliación del nodo de reproducción actual'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'Orden de reproducción de nodo'


stringMap [strings.Mind_Group_Picture_Upload] = 'Carga de imagen'
stringMap [strings.Mind_Group_Upload_From_Local] = 'Carga local'
stringMap [strings.Mind_Group_Mind_Mapping] = 'Mapa mental'
stringMap [strings.Mind_Group_Information] = 'Datos'

stringMap [strings.Mind_Group_Invite_New_Members] = 'Invitar nuevos miembros'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Ingrese su número de teléfono'
stringMap [strings.Mind_Group_Cancel] = 'Cancelar'
stringMap [strings.Mind_Group_Confirm] = 'Aceptar'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'El número de teléfono móvil no puede estar vacío'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'Invitación exitosa'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "Miembros del grupo compartido"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Número máximo de miembros del grupo compartido"
stringMap [strings.Mind_Group_Membership_Limit] = "Límite de membresía"
stringMap [strings.Mind_Group_Current_Member_Limit] = "Límite superior del miembro actual"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "Límite superior de miembro de actualización"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "Buscar miembros"
stringMap [strings.Mind_Group_Creator] = "Creador"
stringMap [strings.Mind_Group_Administrator] = "Administrador"
stringMap [strings.Mind_Group_Number_Of_People] = "Personas"
stringMap [strings.Mind_Group_Selected] = "Seleccionado"
stringMap [strings.Mind_Group_Member] = "Miembro"
stringMap [strings.Mind_Group_Check_All] = "Seleccionar todo"
stringMap [strings.Mind_Group_Remove] = "Eliminar"
stringMap [strings.Mind_Group_Invite_New_Members] = "Invitar a nuevos miembros"
stringMap [strings.Mind_Group_Delete_Members] = "Eliminar miembros"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Seleccione el miembro que desea eliminar"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "¿Está seguro de que desea eliminar al usuario de este grupo?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "Agregar administrador"
stringMap [strings.Mind_Group_Accomplish] = "Completado"
stringMap [strings.Mind_Group_Compile] = "Editar"
stringMap [strings.Mind_Group_Group_Owner] = "Propietario del grupo"
stringMap [strings.Mind_Group_Add_Admin] = "Agregar administrador"
stringMap [strings.Mind_Group_Group_ID] = "ID de grupo"
stringMap [strings.Mind_Group_Group_Number] = "Número de grupo"
stringMap [strings.Mind_Group_Name] = "Nombre"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Ingrese el nombre del grupo compartido"
stringMap [strings.Mind_Group_Introduce] = "Introducir"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Por favor, presente brevemente los grupos compartidos"
stringMap [strings.Mind_Group_Announcement] = "Anuncio"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "Etiqueta de grupo compartido"
stringMap [strings.Mind_Group_Custom_Tag] = "Etiqueta personalizada"
stringMap [strings.Mind_Group_Save] = "Guardar"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "El nombre del grupo no puede estar vacío"
stringMap [strings.Mind_Group_Establish] = "Crear"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "Permitir crear mapas / carpetas"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "Permitir la edición de mapas"
stringMap [strings.Mind_Group_Holder] = "Todos"
stringMap [strings.Mind_Group_Only_The_Administrator] = "Solo administrador"
stringMap [strings.Mind_Group_Not_Allow] = "No permitido"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "Permitir eliminar mapa"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Permitir que los foros publiquen mensajes"
stringMap [strings.Mind_Group_Export] = "Exportar"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Permitir guardar imágenes / PDF"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "Marca de agua de grupo abierto"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Después de abrir, el logotipo del grupo se imprimirá en el mapa mental"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "Permitir guardar mapas"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "Guardar en mi mapa"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Si permitir compartir con la tormenta"
stringMap [strings.Mind_Group_Join_The_Way] = "Cómo unirse"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "Si se debe permitir la invitación a nuevos miembros"
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Si permitir que se agreguen números de grupo de búsqueda"
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Si el mensaje debe ser validado al unirse"
stringMap [strings.Mind_Group_Shared_Group_Data] = "Datos de grupo compartidos"
stringMap [strings.Mind_Group_Authority_Management] = "Administración de autoridad"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "Salir del grupo de intercambio"
stringMap [strings.Mind_Group_Confirm_To_Salir_The_Shared_Group] = "¿Está seguro de salir del grupo compartido?"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Grupo de disolución"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "Los archivos se eliminarán después de la disolución del grupo. ¿Confirmar la disolución?"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "Volver a la página de inicio"
stringMap [strings.Mind_Group_Join_The_Group] = "Unirse al grupo"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "Por favor ingrese el número de grupo / nombre de grupo para buscar"
stringMap [strings.Mind_Group_Apply_To_Join] = "Aplicar para unirse"
stringMap [strings.Mind_Group_Join] = "Unirse"
stringMap [strings.Mind_Group_Verification] = "Verificación"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "Ingrese el mensaje de validación"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "El contenido no puede estar vacío"
stringMap [strings.Mind_Group_Name] = "Nombre de grupo compartido"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Ingrese el nombre del grupo compartido"
stringMap [strings.Mind_Group_Introduction] = "Compartir introducción al grupo"
stringMap [strings.Mind_Group_Group_Indication] = "Número de grupo"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "El creador es vago, todavía no lo he presentado ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "Anuncio de grupo compartido"
stringMap [strings.Mind_Group_No_Announcement] = "Sin anuncio todavía"
stringMap [strings.Mind_Group_Click_On] = "Haga clic para expandir"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "Haga clic para ocultar"
stringMap [strings.Mind_Group_Altogether] = "Total"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Copiando el número de grupo con éxito"
stringMap [strings.Mind_Group_Stick] = "Quédate en la parte superior"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "Hay contenido nuevo"
stringMap [strings.Mind_Group_Cancel_The_Top] = "Cancelar la parte superior"
stringMap [strings.Mind_Group_Setting_Up] = "Configuración"
stringMap [strings.Mind_Group_Usinghelp] = "Uso de la ayuda"
stringMap [strings.Mind_Group_Set_The_Message] = "Mensaje de grupo"
stringMap [strings.Mind_Group_Apply_To_Join] = "Aplicar para unirse"
stringMap [strings.Mind_Group_Invite_To_Join] = "Invitar a unirse"
stringMap [strings.Mind_Group_Consent] = "De acuerdo"
stringMap [strings.Mind_Group_Turn_Down] = "Rechazar"
stringMap [strings.Mind_Group_Label] = "Etiqueta"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Introduzca el nombre de la etiqueta del grupo compartido"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Invite a amigos y colegas a co-crear y compartir contenido de conocimiento"
stringMap [strings.Mind_Group_Immediately_Create] = "Crear inmediatamente"
stringMap [strings.Mind_Group_Immediately_To_Join] = "Únete ahora"

stringMap [strings.Currently_Selected] = "Actualmente siguiendo"
stringMap [strings.LabelManagement] ="Gestión de etiquetas"
stringMap [strings.NoAttention] = "Sin atención"
stringMap [strings.Mindmap_Review_Play] = 'Reproducir'

stringMap [strings.Mind_Group_Joined] = "Unido"
stringMap [strings.Mind_Group_Download_App] = "Descargar APLICACIÓN"
stringMap [strings.Mind_Group_No_Content] = "Aún no hay contenido"
stringMap [strings.Mind_Group_Individual] = "piezas"
stringMap [strings.Mind_Group_Message_Area] = "Área de mensajes"
stringMap [strings.Mind_Group_No_Message] = "Sin mensaje, ve ahora"
stringMap [strings.Mind_Group_Leave_Message] = "Dejar un mensaje"
stringMap [strings.Mind_Group_Bar] = "Barra"
stringMap [strings.Mind_Group_See_More] = "Ver más"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "Ingrese el código de invitación"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Aplicar para unirse al grupo de intercambio"
stringMap [strings.Reasons_For_Application_0_30_Words] = "Razones para la solicitud (0-30 palabras)"
stringMap [strings.Mind_Group_Send_Out] = "Enviar"
stringMap [strings.Mind_Group_Join_Successfully] = "Únete correctamente"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "La solicitud se envió correctamente"

stringMap [strings.Mind_Group_Save_The_Mapping] = "Guardar mapa"
stringMap [strings.Mind_Group_Update_Record] = "Modificar registro"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "Cambiar mapa"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "Revisión del mapa"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Un usuario existente está editando el mapa"
stringMap [strings.Mind_Edit_Page_Password] = "Contraseña"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "Grupo de intercambio"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "Mi MBean"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Pago de Alipay"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "Vaya a la página de pago para pagar"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "Total"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "Confirmar pago"
stringMap [strings.Mind_Shooping_Redeem_Now] = "Canjear ahora"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "Si confirmar el pago de la compra"
stringMap [strings.Mind_Tips] = "Sugerencias"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "Tus M Beans son insuficientes, ¿quieres comprar M Beans?"

stringMap [strings.Mind_share_scan_code] = "Mente + código de escaneo"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "¿Si restaurar al último lugar jugado?"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Tiempos de reproducción restantes"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "El archivo se eliminará por completo, ¿está seguro de que se disolverá?"
stringMap [strings.Group_Mind_Up_One_Level] = "Subir un nivel"
stringMap [strings.Group_Mind_No_Documents] = "Aún no hay archivos"
stringMap [strings.Group_Mind_PaymentSuccessful] = "Pago exitoso"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "Edición colaborativa de personas, sincronización de múltiples terminales en tiempo real, uso compartido de documentos, mejora de la eficiencia de la colaboración en equipo"

stringMap [strings.Mind_Edit_Subscript] = "Subíndice"
stringMap [strings.Mind_Edit_Text_Location] = "Ubicación del texto"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "Importar a grupo compartido"

stringMap [strings.Standard_Colors] = "Colores estándar"
stringMap [strings.More_Colors] = "Más colores"
stringMap [strings.Reset_Password] = "Restablecer contraseña"
stringMap [strings.Account_Password_Login] = "Inicio de sesión con contraseña de cuenta"
stringMap [strings.Authentication_code_Login_registration] = "Código de verificación de inicio de sesión / registro"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "Creación de un extremo, sincronización de múltiples extremos"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "Estructura de mapa rica y diversa"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "Biblioteca de plantillas masiva y exquisita"
stringMap [strings.Map_Review_Play] = "Revisión del mapa-Reproducir"
stringMap [strings.CommunityLearningAndSharingPlatform] = "Plataforma de intercambio de intercambio y aprendizaje de conocimientos"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "Actualizar espacio de almacenamiento"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "Déjame también comentarlo"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Aún no hay notificación de mensaje"
stringMap [strings.Failed_To_Get_Recording_Device] = "No se pudo obtener el dispositivo de grabación"

stringMap [strings.Search_Pagination] = "Paginación de búsqueda"
stringMap [strings.Please_Enter_A_Paging_Name] = "Introduzca el nombre de la página"
stringMap [strings.Operation_Settings] = "Configuración de operación"
stringMap [strings.Shortcut_key_Settings] = "Configuración de teclas de acceso directo"
stringMap [strings.Press_Key_Combination] = "Por favor presione la combinación de teclas requerida"
stringMap [strings.Mind_Reduction] = "Reducción"
stringMap [strings.Double_Click_Add_Free_Node] = "Haga doble clic en el espacio en blanco para agregar un nodo libre"
stringMap [strings.Shortcut_Conflict] = "Conflicto de teclas de acceso directo"
stringMap [strings.Select_Main_Node] = "Seleccionar nodo principal"
stringMap [strings.Telephone] = "Teléfono"
stringMap [strings.Microblog] = "Microblog"
stringMap [strings.Address] = "Dirección"
stringMap [strings.Company_Address] = "Block B, Building 21, No. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "Archivo"
stringMap [strings.Mind_Bookshelf] = "Estantería"
stringMap [strings.Search_Archive] = "Buscar biblioteca"
stringMap [strings.Modify_Archive_Order] = "Modificar orden de biblioteca"
stringMap [strings.Modification_Order] = "Orden de modificación"
stringMap [strings.Please_Enter_Archive_Name] = "Introduzca el nombre de la estantería"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "El nombre de la estantería de libros no puede estar vacío"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "¿Está seguro de que desea eliminar esta estantería?"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "¿Está seguro de que desea eliminar esta biblioteca?"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "¿Está seguro de que desea eliminar este mapa?"
stringMap [strings.Mind_Edit_Confirm] = "Confirmar"
stringMap [strings.Mind_Import_Map] = "Importar mapa"
stringMap [strings.Mind_Bookcase] = "Librero"
stringMap [strings.Added_Successfully] = "Agregado exitosamente"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "El nombre de la librería no puede estar vacío"

stringMap [strings.Mind_Association_Map] = "Mapa de asociación"
stringMap [strings.Mind_confirm_Association_Map] = "¿Está seguro de asociar este mapa?"
stringMap [strings.Mind_Association_Map_See] = "Ver mapa de asociación"
stringMap [strings.Mind_Association_Map_Delete] = "Eliminar mapa de asociación"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "Estructura de línea de correlación"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Inicio de la línea de correlación"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "El final de la línea de correlación"

stringMap [strings.Share_Group_To_Storm] = "Si compartir el grupo con la tormenta"
stringMap [strings.Apply_To_Join_Storm_Platform] = "En la plataforma Storm, otras personas ven y solicitan unirse, intercambiando ideas con los amantes del conocimiento común"
stringMap [strings.Pay_To_Goin] = "Pagar para unirse"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "Los beans M deben pagarse al unirse"
stringMap [strings.Mbean_Can_Be_Realized] = "Mbean realizado"

stringMap [strings.Mind_Visit] = "Visita"
stringMap [strings.Mind_Details] = "Detalles"
stringMap [strings.Mind_See] = "Ver"
stringMap [strings.Earn_Mbeans] = "Gana Mbeans"
stringMap [strings.Mind_Explain] = "Descripción"
stringMap [strings.Mind_Parenting] = "Crianza"
stringMap [strings.Mind_Internet] = "Internet"
stringMap [strings.Mind_Psychology] = "Psicología"
stringMap [strings.Mind_literature] = "Literatura"
stringMap [strings.Mind_Technology] = "Tecnología"
stringMap [strings.Mind_Workplace] = "Lugar de trabajo"
stringMap [strings.Mind_Life] = "Vida"
stringMap [strings.Mind_Subject] = "Asunto"
stringMap [strings.Hot_Group] = "Grupo activo"
stringMap [strings.Open_Group] = "Grupo abierto"
stringMap [strings.Mind_Group_Used] = "Usado"

stringMap [strings.Mind_Click_Select] = "Haga clic para seleccionar"
stringMap [strings.Mind_Double_Click_Enter] = "Haga doble clic para ingresar"
stringMap [strings.Search_Shared_Groups] = "Buscar grupos compartidos"
stringMap [strings.Search_Storm] = "Tormenta de búsqueda"
stringMap [strings.Members_Save_Free] = "Los miembros ahorran gratis"
stringMap [strings.Bank_Card_Account_Name] = "Ingrese el nombre de la cuenta de su tarjeta bancaria"
stringMap [strings.Bank_Card_No] = "Número de tarjeta bancaria"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "Ingrese el número de su tarjeta bancaria"
stringMap [strings.Branch_Sub_Branch_Information] = "Información de la sucursal"
stringMap [strings.Branch_Sub_Branch_Example] = "Por ejemplo: Hangzhou Branch Chengxi Sub-branch"
stringMap [strings.Application_For_Withdrawal] = "Solicitud de retiro"
stringMap [strings.Withdrawal_Amount] = "Monto del retiro"
stringMap [strings.Withdrawal] = "Retirar"
stringMap [strings.Current_Revenue] = "Ingresos actuales"
stringMap [strings.Recharge] = "Recargar"
stringMap [strings.Current_Total_MBeans] = "Número total actual de MBeans"
stringMap [strings.Withdrawal_Status] = "Estado de retirada"
stringMap [strings.Insufficient_Withdrawal_Amount] = "Cantidad de retiro insuficiente"
stringMap [strings.Load_More] = "Cargar más"
stringMap [strings.Loading] = "Cargando"
stringMap [strings.It_Is_Over] = "Se acabó"
stringMap [strings.Default_Label] = "Etiqueta predeterminada"
stringMap [strings.Selected_Label] = "Etiqueta seleccionada"
stringMap [strings.Current_Location] = "Ubicación actual"
stringMap [strings.MGold_Introduction] = "Usted gana M de oro a través de Mind +"
stringMap [strings.MGold_Details] = "Su grupo de nube de pago, hay mapas de conocimiento, materiales de aprendizaje, etc. en el grupo. Si los usuarios se unen a los beans de pago M, el 50% de ellos se convertirán en M oro y M oro más se pueden extraer más de 50 "
stringMap [strings.Cloud_Group_Introduction] = "Comparte tu grupo, otros pueden verlo en la página de tormentas"
stringMap [strings.Cloud_Group_Details] = "Su grupo de nube de pago. Hay mapas de conocimiento, materiales de aprendizaje, etc. en el grupo. Si los usuarios se unen a los beans de pago M, el 50% de ellos se convertirán en M oro y M oro más se pueden retirar más de 50 "
stringMap [strings.Share_Storm_Introduction] = "Comparta su mapa con la comunidad de tormentas, para que más personas puedan aprender y explorar sus conocimientos"
stringMap [strings.Share_Storm_Details] = "Si habilita el uso de beans M para guardar mapas, otros usuarios pueden usar su mapa de conocimiento, y usted también obtendrá el 50% de los beans M. Los beans M se pueden usar para comprar miembros y unirse pagados grupos, también puede pagar para usar los mapas de otras personas "

stringMap [strings.Linked_Successfully] = "Vinculado exitosamente"
stringMap [strings.Cancel_Archiving] = "Cancelar archivado"
stringMap [strings.Please_Select_Archive_Location] = "Seleccione la ubicación del archivo"
stringMap [strings.The_Search_Result_Is_Empty] = "Los resultados de la búsqueda están vacíos"

stringMap [strings.Customize_A_Style] = "Los no miembros solo pueden personalizar un estilo"
stringMap [strings.Adds_Current_Guide_Style_Library] = "Agregar el estilo de mapa actual a la biblioteca de estilos"
stringMap [strings.Trial_Version] = "Versión de prueba"
stringMap [strings.Not_Supported_At_The_Moment] = "No es compatible actualmente"
stringMap [strings.Mind_File] = "Archivo"
stringMap [strings.Mind_Import] = "Importar"
stringMap [strings.Importing] = "Importando"

stringMap [strings.Mind_Edit_Insert_Video] = "Insertar video"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Arrastra y suelta el video aquí"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "La duración del video de carga no puede exceder los 60 segundos"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "Ver video"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "Eliminar video"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "El video subido no puede exceder los 10 MB"
stringMap [strings.Mind_Upload_Video_Restrictions] = "Solo admite la carga de videos en formato MP4, hasta 120s y 10M de tamaño"
stringMap [strings.Mind_Video_Size] = "Tamaño del video"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "Exportar Word"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "Exportar TXT"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap [strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap [strings.Export_Failed] = "Error al exportar"
stringMap [strings.Add_File] = "Agregar archivo"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "Arrastra el archivo directamente para subirlo"
stringMap [strings.Supported_Imported_File_Types] = "Tipos de archivo actualmente admitidos para la importación: Word (solo .docx), XMind, .txt"
stringMap [strings.No_Permission_Do_This] = "No tiene permiso para realizar esta operación"

stringMap [strings.Upgrade_To_Professional] = "Actualizar a la edición profesional"
stringMap [strings.Non_Members_Insert_Videos] = "Los no miembros solo pueden insertar videos 24 horas después del registro"
stringMap [strings.Upload_Failed] = "Error al cargar"
stringMap [strings.Upload_Failed_Title_Message] = "Error al cargar, haga clic para confirmar, elimine las imágenes restantes que no se pudieron cargar"

stringMap [strings.Mind_Edit_Members_Use] = "Uso de los miembros"
stringMap [strings.Invitation_Code] = "Código de invitación"
stringMap [strings.Student_Certification] = "Certificación de estudiante"
stringMap [strings.I_Get_It] = "Lo haré, omitir la guía"
stringMap [strings.Welcome_To_Mind] = "Bienvenido al joven maestro a unirse a Mind +, ¡comencemos el viaje del pensamiento!"
stringMap [strings.Mind_Before] = "Primero"
stringMap [strings.Try_A_Map] = "Pruebe un mapa"
stringMap [strings.Click_Create] = "Haga clic para crear"
stringMap [strings.Click_Create_A_Mind_Map] = "Haga clic para crear un mapa mental"
stringMap [strings.Mind_About] = "Acerca de"
stringMap [strings.See_Checking_Data] = "Ver información personal"
stringMap [strings.Modify_Checking_Data] = "Modificar información personal"
stringMap [strings.Mind_Shear] = "Shear"

stringMap [strings.Mind_Style_Empty] = "Sin estilo favorito o personalizado"
stringMap [strings.Mind_Branch_Free_Layout] = "Diseño sin ramas"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "La estructura bidireccional y las ramas del mapa de burbujas se pueden arrastrar libremente al diseño"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Tema gratuito Drag Snap"

stringMap [strings.Select_A_Node] = "Seleccionar un tema"
stringMap [strings.Create_Child_Node] = "Crear tema hijo"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "A continuación, agregaremos uno agradable al tema hijo"
stringMap [strings.Click_Icon_Panel] = "Haga clic en el panel de iconos"
stringMap [strings.Select_An_Icon_You_Like] = "Seleccione un icono que le guste"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "A continuación, reemplazaremos el mapa mental por uno nuevo"
stringMap [strings.Select_The_Central_Theme] = "Seleccione el tema central"
stringMap [strings.Switch_To_Canvas_Panel] = "Cambiar al panel de lienzo"
stringMap [strings.Click_Layout] = "Hacer clic para diseñar"
stringMap [strings.Choose_A_Layout_You_Like] = "Elija un diseño que le guste"
stringMap [strings.Mind_Map_Save_Share] = "¡Enhorabuena, ha creado con éxito un hermoso mapa mental! Guárdelo y compártalo con sus amigos ~"
stringMap [strings.Click_Save_Picture] = "Haga clic para guardar la imagen"
stringMap [strings.Click_OK] = "Haga clic en Aceptar"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "Felicitaciones por completar la guía para principiantes ~"
stringMap [strings.Upload_Succeeded] = "Subido correctamente"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "Estilo rápido"
stringMap [strings.Mind_Extremely_Important] = "Extremadamente importante"
stringMap [strings.Mind_Important] = "Importante"
stringMap [strings.Mind_Commonly] = "General"
stringMap [strings.Mind_Ordinary] = "Ordinario"
stringMap [strings.Member_Exclusive] = "Esta función es exclusiva para miembros. ¿Desea actualizar su membresía?"
stringMap [strings.Member_Exclusive_Color_Scheme] = "Este esquema de color es exclusivo para miembros. ¿Desea actualizar su membresía?"
stringMap [strings.Mind_Enlarge] = "Ampliar"
stringMap [strings.Mind_Narrow] = "Estrecho"
stringMap [strings.Mind_Center] = "Centrado"
stringMap [strings.Mind_Full_Screen] = "Pantalla completa"

stringMap [strings.Mind_Association_Node] = "Nodo de asociación"

stringMap [strings.Mind_Resource_See] = "Ver archivo adjunto"
stringMap [strings.Mind_Resource_Delete] = "Eliminar archivos adjuntos"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Arrastra y suelta archivos aquí"
stringMap [strings.Mind_Edit_choose_explain] = "Elegir archivo"
stringMap [strings.Message_Tips_Share_Explain_Does_Not_Exist] = "El archivo cargado no puede exceder los 20 MB"

stringMap[strings.Please_Enter_Mind_Password] = "Introduzca una contraseña de 4 a 8 bits"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "Introduzca la contraseña"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "Esta función es una función de membresía"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "Contraseña de archivo eliminada"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "No hay suficientes dígitos de contraseña, por favor introduzca una contraseña de 4 a 8 dígitos"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "La contraseña es demasiado larga, por favor introduzca una contraseña de 4 a 8 dígitos"
stringMap[strings.Please_Enter_Mind_Password_Success] = "Contraseña establecida con éxito"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "Error de formato de contraseña"
stringMap[strings.Mind_Load_Data_Error] = "Error de carga de datos"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Error de contraseña"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Sólo el creador de la Guía puede establecer la contraseña"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;
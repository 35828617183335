import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndMoreCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }


    calculate() {
        if (this.data.imageContent == null || this.data.moreContent == null) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();
       
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        let moreContent = this.data.moreContent;
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        moreContent.width = textHeight;
        moreContent.height = textHeight;

        let textmoreSize = this.includeMoreAndTextSize();
        let size = this.includeImageAndTextMoreSize();

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;
        let moreTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2 + marginY;
            iconLeft = ((this.data.type == MindElementType.MAIN_SUBJECT || 
            this.data.type == MindElementType.SUBJECT || 
            this.data.type == MindElementType.CONTENT_GENERALIZATION ||
            this.data.type == MindElementType.EXPLAIN) ? (this.iconMargin) : 0) + marginX;
            
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            moreContent.x = textMindElementContent.x + textMindElementContent.width + moreTextSpace;
            moreContent.y = ((size.getHeight()) - moreContent.height) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin) + marginX;
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2 + marginY;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            moreContent.x = textMindElementContent.x + textMindElementContent.width + moreTextSpace;
            moreContent.y = ((size.getHeight()) - moreContent.height) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2 + marginX;
            iconTop = marginY;
            textMindElementContent.x = ((size.getWidth()) - (textmoreSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + this.iconSpace;

            moreContent.x = textMindElementContent.x + textMindElementContent.width + moreTextSpace;
            moreContent.y = textMindElementContent.y + ((textmoreSize.getHeight()) - moreContent.height) / 2;
        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}
export default TextAndImageAndMoreCalculation
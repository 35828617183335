// package mind.yushu.com.mindmap.core.basemode;

// import mind.yushu.com.mindmap.viewmodel.datatype.MemberType;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/8/25
 * Copyright(c) 2020 mindyushu.com
 */

class User {

    constructor() {
        this.INVALID_USER_ID = "";
        this.username = "";
        this.userId = INVALID_USER_ID;
        this.creatTime = -1;
        this.memberType = MemberType.NON_MEMBER;
        this.memberExpire = -1;
        this.userLevel = 0;
        this.authorization = "";
    }

    User(userId, username) {
        this.userId = userId;
        this.username = username;
    }

    getUsername() {
        return this.username;
    }

    setUsername(username) {
        this.username = username;
    }

    getUserId() {
        return this.userId;
    }

    setUserId(userId) {
        this.userId = userId;
    }

    getMemberType() {
        return this.memberType;
    }

    setMemberType(memberType) {
        this.memberType = MemberType.from(memberType);
    }

    setMemberType(memberType) {
        this.memberType = memberType;
    }

    getCreatTime() {
        return this.creatTime;
    }

    setCreatTime(creatTime) {
        this.creatTime = creatTime;
    }

    getMemberExpire() {
        //        memberExpire = System.currentTimeMillis() + 40000;
        return this.memberExpire;
    }

    setMemberExpire(memberExpire) {
        this.memberExpire = memberExpire;
    }

    getUserLevel() {
        return this.userLevel;
    }

    setUserLevel(userLevel) {
        this.userLevel = userLevel;
    }

    getAuthorization() {
        return this.authorization;
    }

    setAuthorization(authorization) {
        this.authorization = authorization;
    }
}

export default User
import { getJwt } from "../../common/netWork/base";
import Util from "../../utils/Util";

class MindMe {
    constructor() {
    }

    isLogin() {
        let userData = localStorage.getItem("meMessage");
        let jwt = getJwt("jwt")
        return jwt != null && jwt != "" && userData != null && userData != "";
    }

    isUpgrade() {
        if (!this.isLogin()) {
            return false;
        }
        const userData = this.getMeMessage();
        if (userData == null || userData == "") {
            return false;
        } else {
            return userData.isMember;
        }
    }

    getMeMessage() {
        try {
            let userData = localStorage.getItem("meMessage");
            if (userData == null || userData.length == undefined || userData.length == null || userData.length == 0) {
                return null
            }
            return JSON.parse(userData);
        } catch (error) {
            return null
        }
    }

    getUserId() {
        let userData = localStorage.getItem("meMessage");
        if (userData == null || userData == "") {
          return "";
        }
        
        if (userData != null) {
          let userDataObj = JSON.parse(userData);
          return userDataObj.userId;
        }
        return "";
    }

    getMemberExpire() { //reture 天为单位
        let meMessage = this.getMeMessage();
        if (meMessage == null || meMessage == "" || !meMessage.isMember) {
            return 0;
        }
        this.meMessage = meMessage;
        let userMemberIsPermanent = parseInt(meMessage.memberExpire - meMessage.serverTime) / 86400; //获取会员天数        
        return userMemberIsPermanent
    }

    getEditMindBackgroundImageId() {
        try {
            var backgroundId = localStorage.getItem('backgroundId')
            if (backgroundId == null || backgroundId == "") {
                backgroundId = 0
                localStorage.setItem('backgroundId', backgroundId)
            } else {
                return parseInt(backgroundId)
            }
        } catch (error) {
            return 0;
        }
        return 0;
    }

    setEditMindBackgroundImageId(id) {
        try {
            localStorage.setItem('backgroundId', id)
        } catch (error) {
            console.log("setEditMindBackgroundImageId error->", error);
        }
    }

    getCloseMindBackgroundImageTimes() {
        try {
            var closeTimes = localStorage.getItem('closeTimes')
            if (closeTimes == null || closeTimes == "") {
                closeTimes = 0                
            } else {
                let separatorIndex = closeTimes.indexOf("|")
                if (separatorIndex > 0) {
                    let info = closeTimes.split("|")
                    if (info.length == 2) {
                        return parseInt(info[1])
                    }
                    return 0
                }
                return 0
            }
        } catch (error) {
            return 0;
        }
        return 0;
    }

    getLastCloseMindBackgroundImageTime() {
        try {
            var closeTimes = localStorage.getItem('closeTimes')
            if (closeTimes == null || closeTimes == "") {
                return 0
            } else {
                let separatorIndex = closeTimes.indexOf("|")
                if (separatorIndex > 0) {
                    let info = closeTimes.split("|")
                    if (info.length == 2) {
                        return parseInt(info[0])
                    }
                    return 0
                }
                return 0
            }
        } catch (error) {
            return 0;
        }
    }

    setCloseMindBackgroundImageTimes() {
        try {
            let lastTimes = this.getCloseMindBackgroundImageTimes()
            localStorage.setItem('closeTimes', Util.getCurrentTime() + "|" + (lastTimes + 1))
        } catch (error) {
            console.log("setCloseMindBackgroundImageTimes error->", error);
        }
    }


    getAiMindUseTimes() {
        try {
            var aiTimes = localStorage.getItem('mindayushuiTimes')
            if (aiTimes == null || aiTimes == "") {
                return 0
            } else {
                let separatorIndex = aiTimes.indexOf("|")
                if (separatorIndex > 0) {
                    let info = aiTimes.split("|")
                    if (info.length == 2) {
                        var date = new Date();//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                        var Y = date.getFullYear();
                        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
                        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate()) : date.getDate());
                        var key = Y + "" + M + "" + D;
                        if (info[0] == key) {
                            return parseInt(info[1])
                        } else {
                            return 0
                        }
                    }
                    return 0
                }
                return 0
            }
        } catch (error) {
            return 0;
        }
    }

    setAiUseTimes() {
        try {
            var date = new Date();//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear();
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
            var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate()) : date.getDate());
            var key = Y + "" + M + "" + D;
            let lastTimes = this.getAiMindUseTimes()
            localStorage.setItem('mindayushuiTimes', key + "|" + (lastTimes + 1))
        } catch (error) {
            console.log("mindayushuiTimes error->", error);
        }
    }
}

export default new MindMe()

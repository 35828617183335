import UiUtil from '../../../../utils/UiUtil'
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType"
import TextIAndmageCalculation from './TextIAndmageCalculation'
import Size from '../../../../viewmodel/core/base/Size'

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndTaskCalculation extends TextIAndmageCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        let imageContent = data.imageContent;
        // let textMindElementContent = new MindElementContent();
        let taskContent = data.taskContent;
        let textMindElementContent;
        if (taskContent == null) {
            return;
        }
        if (imageContent == null) {
            return;
        }

        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        } else {
            return;
        }

        let taskOriginalSize = this.getTaskOriginalSize();
        let size = this.getContentSize();

        let width = size.getWidth();
        let height = size.getHeight();
        let edgeInsets = this.getMargin(size);
        let textEdgeInsets = this.getTextEdgeInsets();
        let left = edgeInsets.left;
        let top = edgeInsets.top;
        let right = edgeInsets.right;
        let bottom = edgeInsets.bottom;
        let textY = this.getTextTop();
        let textX = this.getTextLeft();
        let isShowText = this.isShowText();
        let taskTop = imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT || !this.isShowText() ? this.UiUtil.dip2px(5) : 0;

        if (imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT) {
            if (imageContent.height > textMindElementContent.height) {
                textMindElementContent.x = left + imageContent.width;
                textMindElementContent.y = top + (imageContent.height - textMindElementContent.height)/2;
                imageContent.x = left;
                imageContent.y = top;
                taskContent.x = left + textX;
                taskContent.y = top + imageContent.height + taskTop;
            } else {
                textMindElementContent.x = left + imageContent.width;
                textMindElementContent.y = top;
                imageContent.x = left;
                imageContent.y = top + (textMindElementContent.height - imageContent.height)/2;
                taskContent.x = left + textX;
                taskContent.y = top + textMindElementContent.height + taskTop;
            }
        } else{
            if (imageContent.width > textMindElementContent.width) {
                if (isShowText) {
                    textMindElementContent.x = left + (imageContent.width - textMindElementContent.width)/2;
                    textMindElementContent.y = top + imageContent.height;
                    imageContent.x = left;
                    imageContent.y = top;
                    taskContent.x = left + (size.getWidth() - taskOriginalSize.getWidth()) / 2;
                    taskContent.y = textMindElementContent.y + textMindElementContent.height + taskTop;
                } else {
                    textMindElementContent.x = left;
                    textMindElementContent.y = top;
                    imageContent.x = left;
                    imageContent.y = top;
                    taskContent.x = left + (size.getWidth() - taskOriginalSize.getWidth()) / 2;
                    taskContent.y = imageContent.y + imageContent.height + taskTop;
                }
            } else {
                textMindElementContent.x = left;
                textMindElementContent.y = top + imageContent.height;
                imageContent.x = left + (textMindElementContent.width - imageContent.width)/2;
                imageContent.y = top;
                taskContent.x = left + textX;
                taskContent.y = textMindElementContent.y + textMindElementContent.height + taskTop;
            }
        }
        this.data.taskContent.width = taskOriginalSize.getWidth();
        this.data.taskContent.height = taskOriginalSize.getHeight();

        this.setMindElementSize(width, height, edgeInsets);

    }
    getContentSize() {
        let size = super.getContentSize();
        let imageContent = this.data.imageContent;
        let taskSize = this.getTaskOriginalSize();
        let edgeInsets = this.getTextEdgeInsets();

        let left = imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT ? 0 : edgeInsets.left;
        let right = getTextContent().length() == 0 && !this.data.isEdit ? 0 : edgeInsets.right;
        let taskTop = imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT || !isShowText() ? UiUtil.dip2px(5) : 0;
        taskSize = new Size(taskSize.getWidth() + left + right,
                taskSize.getHeight() + edgeInsets.bottom + taskTop);
        let width = Math.max(size.getWidth(), taskSize.getWidth());
        let height = size.getHeight() + taskSize.getHeight();

        return new Size(width, height);
    }
}

export default TextAndImageAndTaskCalculation
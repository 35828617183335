const { default: strings } = require("./strings");

var stringMap = {};
stringMap[strings.About_App_Name] = 'Mind+ Mind Map';
stringMap[strings.TEMPLATE] = 'Template';
stringMap[strings.Mind_Edit_Main_Topic] = 'Main Topic';
stringMap[strings.Global_Add_Free_Node_Tips] = 'Click on the screen to select the add location';
stringMap[strings.Global_Add_Free_Chart_Node_Tips] = 'Double-click the screen to select the location to add';
stringMap[strings.Global_Add_Relationship_Tips] = 'Choose goals that need to be associated';
stringMap[strings.Global_Add_Target_Tips] = 'select a node to add ';
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Already associated with other';
stringMap[strings.Mind_Edit_Main_Idea] = 'Central topic';
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'Correlation map does not exist';
stringMap[strings.Mind_Style_Add_Icon_Priority] = 'Priority';
stringMap[strings.Mind_Style_Add_Icon_Progress] = 'Progress';
stringMap[strings.Mind_Style_Add_Icon_Stars] = 'Stars';
stringMap[strings.Mind_Style_Add_Icon_Week] = 'week';
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] = 'Head Portrait';
stringMap[strings.Establish] = 'Create';
stringMap[strings.Create_From_Template] = 'Template';
stringMap[strings.Common] = 'Common';
stringMap[strings.Function_List] = 'Function List';
stringMap[strings.Recent_Updates] = 'Recent updates';
stringMap[strings.The_Latest_To_Delete] = 'Recently deleted';
stringMap[strings.Setting_Up] = 'Contact us';
stringMap[strings.Usinghelp] = 'Help';
stringMap[strings.The_Download_Client] = 'Download client/mobile version';
stringMap[strings.Global_Purchase] = 'Buy';
stringMap[strings.Global_Login] = 'Login';
stringMap[strings.My_map] = 'My Maps';
stringMap[strings.Create_Map] = 'Create map';
stringMap[strings.Storm] = 'Community';
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Common topic';
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'Topic';
stringMap[strings.Mind_Organization_Edit_Main_Idea] = 'Tissue Center';
stringMap[strings.Mind_Edit_Free_Topic] = 'Floating topic';
stringMap[strings.Mind_Edit_Sub_Topic] = 'Sub Topic';
stringMap[strings.Mind_Organization_Main_Topic] = 'Department';
stringMap[strings.Mind_Edit_Main_Topic] = 'Main Topic';
stringMap[strings.Mind_Time_Edit_Main_Idea] = 'Central topic';
stringMap[strings.Mind_Time_Edit_Head] = 'Time';
stringMap[strings.Mind_Time_Edit_Title] = 'Topic';
stringMap[strings.Mind_Edit_Details] = 'Details';
stringMap[strings.Checking_Data] = 'See';
stringMap[strings.Individual_Account] = "Individual account";
stringMap[strings.Setting_Information] = 'Setting ';
stringMap[strings.Sign_Out] = 'Log out';

//Function List
stringMap[strings.Create_a_Mind_Map] = 'Create  MindMap';
stringMap[strings.Create_Mind_Map_1] = '【1】 Create fourteen types of maps (two-way/right/left/organizational structure/single bubble chart/double bubble chart/left tree chart/right tree chart/two-way tree chart/table/ Horizontal timeline/vertical timeline/S timeline/pyramid)';
stringMap[strings.Create_Mind_Map_2] = '【2】Templates can be used to quickly create a map (a large number of template content references)';
stringMap[strings.Create_Mind_Map_3] = '【3】 nine types of line style editing for the map';
stringMap[strings.Create_Mind_Map_4] = '【4】 Make nine layout changes and edits to the map';
stringMap[strings.Create_Mind_Map_5] = '【5】Add sub-nodes to the target node and add nodes of the same level (upper and lower)';
stringMap[strings.Create_Mind_Map_6] = '【6】 Summary: Add to single node/multi-node[ Abstract]Description';
stringMap[strings.Create_Mind_Map_7] = '【7】Block: add [block] to the text box of the map to make modularization';
stringMap[strings.Create_Mind_Map_8] = '【8】Add free nodes';
stringMap[strings.Create_Mind_Map_9] = '【9】Create the association of four line styles between any nodes ';
stringMap[strings.Create_Mind_Map_10] = '【10】Insert pictures (local + material library), icons, remarks, links to the node';
stringMap[strings.Create_Mind_Map_11] = '【11】Copy/paste node, copy/paste style';
stringMap[strings.Create_Mind_Map_12] = '【12】Undo, restore and undo';
stringMap[strings.Create_Mind_Map_13] = '【13】Yes Multi-selection, simultaneous operation of multiple nodes';
stringMap[strings.Create_Mind_Map_14] = '【14】Statistics can be inserted';
stringMap[strings.Create_Mind_Map_15] = '【15】The entire map can be recorded';
stringMap[strings.Create_Mind_Map_16] = '【16】Copy the maps';
stringMap[strings.Create_Mind_Map_17] = '【17】You can copy the map or the entire map to other The map of the file ';
stringMap[strings.Create_Mind_Map_18] = '【18】You can create a map after the summary';
stringMap[strings.Create_Mind_Map_19] = '【19】You can switch the map editing during editing (computer side)';
stringMap[strings.Create_Mind_Map_20] = '【20】Edit the map with the Bluetooth keyboard:\n\t[Command]+ [a]Select the main node\n\t[Command]+[←][↑][→][↓]Change the selected target or move the map\n\t[Command]+[e]Edit the target\n\t [Command]+[w]Create child node\n\t[Command]+[s]Create target sibling node (top)\n\t[Command]+[d]Create target sibling node (bottom)\n\t [Command]+[q]Exit the selected target\n\t[Command]+[-][+]Zoom map';
stringMap[strings.Create_Mind_Map_21] = '【21】You can switch the map editing during editing (computer side)';

stringMap[strings.Beautify_The_Mind_Map] = 'Beautifying MindMap';
stringMap[strings.Beautify_The_Mind_Map_1] = '【1】Set the background color of the mind map';
stringMap[strings.Beautify_The_Mind_Map_2] = '【2】Set the line thickness, color and tapering of the entire map';
stringMap[strings.Beautify_The_Mind_Map_3] = '【3】Set the text fill color, border color, border 13 shapes, border width, shadow effect';
stringMap[strings.Beautify_The_Mind_Map_4] = '【4】Set the color and width of all or part of the map';
stringMap[strings.Beautify_The_Mind_Map_5] = '【5】Set the text font size, bold, italic, and delete style';
stringMap[strings.Beautify_The_Mind_Map_6] = '【6】Set the picture size, position, and shape style';
stringMap[strings.Beautify_The_Mind_Map_7] = '【7】Add Seven categories of icons(commonly used/3D/study/work/character/animal/food) and set the size/position';
stringMap[strings.Beautify_The_Mind_Map_8] = '【8】Add one-click style style reference to the entire map';
stringMap[strings.Beautify_The_Mind_Map_9] = '【9】Group management map';
stringMap[strings.Beautify_The_Mind_Map_10] ='【10】Personalized background (material library) can be added to the entire map';
stringMap[strings.Beautify_The_Mind_Map_11] = '【11】Personalized border or background (material library) can be added to a single node';
stringMap[strings.Beautify_The_Mind_Map_12] = '【12】The length of the node can be dragged';

stringMap[strings.Export_The_Mind_Map] = 'MindMap export';
stringMap[strings.Export_The_Mind_Map_1] = '【1】Save the picture';
stringMap[strings.Export_The_Mind_Map_2] = '【2】【2】Save the PDF';
stringMap[strings.Export_The_Mind_Map_1] = '【1】Save the picture';

stringMap[strings.Map_Output] = 'MindMap export';
stringMap[strings.Map_Output_1] = '【1】Save the picture';
stringMap[strings.Map_Output_2] = '【2】Save the PDF';
stringMap[strings.Map_Output_3] = '【3】Share link';
stringMap[strings.Map_Output_4] = '【4】Share to Community';
stringMap[strings.Map_Output_3] = '【3】Print';

stringMap[strings.Work_together] = 'Work together';
stringMap[strings.Work_together_1] = '【1】Create sharing group (group label, group announcement)';
stringMap[strings.Work_together_2] = '【2】Add new sharing group members';
stringMap[strings.Work_together_3] = '【3】Multiple members in the group edit the same map together';
stringMap[strings.Work_together_4] = '【4】Upload shared files in the group (word/ excle/pdf)';
stringMap[strings.Work_together_5] = '【5】You can leave messages in the group';
stringMap[strings.Work_together_6] = '【6】Sharing group management authority setting';
stringMap[strings.Work_together_7] = '【7】Dissolve the group';

stringMap[strings.Work_together] = 'Community Sharing';
stringMap[strings.Community_Sharing_1] = '【1】You can share your creation to the storm, so that more people can see it';
stringMap[strings.Community_Sharing_1] = '【2】You can select a label to view the related storm content';
stringMap[strings.Community_Sharing_1] = '【3】You can like and comment on the storm’s work';

stringMap[strings.Recently_Updated_Title_1] = 'Create a personal account';
stringMap[strings.Recently_Updated_Title_2] = 'Massive Template Community Base';
stringMap[strings.Recently_Updated_Title_3] = 'Support multiple platforms';
stringMap[strings.Recently_Updated_Title_4] = 'Customize every detail';
stringMap[strings.Recently_Updated_Title_5] = 'Personal style style library';
stringMap[strings.Recently_Updated_Title_6] = 'Map output';
stringMap[strings.Recently_Updated_Title_7] = 'Cloud Storage';
stringMap[strings.Recently_Updated_Title_8] = 'Fifteen types of maps';

//Recently Updated
stringMap[strings.Recently_Updated_Content_1] = 'Create a personal account and save the created map with the account';
stringMap[strings.Recently_Updated_Content_2] = 'A large number of template knowledge bases are directly quoted to improve learning efficiency';
stringMap[strings.Recently_Updated_Content_3] = 'Support web version online production, IOS mobile terminal, Mac terminal, Android mobile terminal, Android tablet multi-platform use';
stringMap[strings.Recently_Updated_Content_4] = 'You can beautify the design of text, background, lines, picture icons, etc. according to personal preferences';
stringMap[strings.Recently_Updated_Content_5] = 'Personal style style library is directly quoted, eliminating the trouble of color matching';
stringMap[strings.Recently_Updated_Content_6] = 'You can save pictures, PDF format, and share with friends directly';
stringMap[strings.Recently_Updated_Content_7] = 'Strong cloud storage support, no longer have to worry about data loss';
stringMap[strings.Recently_Updated_Content_8] = 'Bidirectional, right, left, organizational structure, single bubble chart, double bubble chart, left tree chart, right tree chart, two-way tree chart, table, horizontal timeline, Vertical timeline, S timeline, eight layouts can be changed at will';

//Recently Deleted
stringMap[strings.Mind_Mindmap_Deleted_Title] = 'Recently deleted';
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = 'Recovery';
stringMap[strings.Mind_Mindmap_Deleted_Forever] = 'Delete';
stringMap[strings.Mind_Mindmap_Deleted_Filename] = 'File Name';
stringMap[strings.Mind_Mindmap_Deleted_Tips] = 'Once deleted here, it will be permanently deleted.';
stringMap[strings.Mind_Mindmap_Deleted_Operating] = 'Operation';
stringMap[strings.Mind_Mindmap_Deleted_Selected] = 'Search recently deleted map';
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] = 'Delete Date';

//Contact Us
stringMap[strings.Contact_Us_No_Public] = 'Public Account';
stringMap[strings.Contact_Us_QQ_Group] = 'QQ Group';
stringMap[strings.Contact_Us_Message_Board] = 'Feedback';
stringMap[strings.Contact_Us_Username] = 'Name';
stringMap[strings.Contact_Us_Email] = 'Email';
stringMap[strings.Contact_Us_Voicemail] = 'Please leave your valuable comments';
stringMap[strings.Contact_Us_Send_Email] = 'Send Email';

//Verify Message
stringMap[strings.Input_Username_isNull] = 'Name cannot be empty';
stringMap[strings.Input_Email_isNull] = 'Enter the correct email address';
stringMap[strings.Input_Voicemail_isNull] = ' Please enter the content to be sent';
stringMap[strings.Input_Introduction_isNull] = 'Introduction cannot be empty';
stringMap[strings.Input_Title_isNull] = 'Title cannot be empty';
stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] = ' Enter a valid phone number';
stringMap[strings.Input_Please_Enter_Verification_Code] = 'Enter the verification code! ';
stringMap[strings.Input_Please_Enter_The_Password] = 'Enter the password! ';
stringMap[strings.Input_The_Password_Format_Is_Incorrect] = 'The password format is incorrect! ';
stringMap[strings.Input_Please_Enter_A_New_Password] = 'Enter a new password! ';
stringMap[strings.Input_Confirm_The_New_Password] = 'Confirm the new password! ';
stringMap[strings.Input_The_Passwords_Are_Inconsistent] = 'Passwords are inconsistent! ';
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] = ' Enter the correct verification code';
stringMap[strings.Input_The_Verification_Code_Error] = 'Verification code error';

//Operation Guide-1
stringMap[strings.Mind_Usinghelp_1] = 'Create a mind map';
stringMap[strings.Mind_Usinghelp_2] = 'How to add nodes';
stringMap[strings.Mind_Usinghelp_3] = 'Create free node';
stringMap[strings.Mind_Usinghelp_4] = 'Make association';
stringMap[strings.Mind_Usinghelp_5] = 'Beautify the canvas';
stringMap[strings.Mind_Usinghelp_6] = 'Beautify the text';
stringMap[strings.Mind_Usinghelp_7] = 'Add notes、links';
stringMap[strings.Mind_Usinghelp_8] = 'Icon';
stringMap[strings.Mind_Usinghelp_9] = 'Image';
stringMap[strings.Mind_Usinghelp_10] = 'How to undelete';
stringMap[strings.Mind_Usinghelp_11] = 'Export mind map';
stringMap[strings.Mind_Usinghelp_12] = 'Member upgrade';
stringMap[strings.Mind_Usinghelp_13] = 'hot key';
stringMap[strings.Mind_Usinghelp_Understood] = 'Got it';
stringMap[strings.Mind_Usinghelp_14] = 'Insert material library';
//Operation Guide-2
stringMap[strings.Mind_Usinghelp_15] = 'New map';
// stringMap[strings.Mind_Usinghelp_2] = 'Add node';
// stringMap[strings.Mind_Usinghelp_4] = 'Make association';
// stringMap[strings.Mind_Usinghelp_5] = 'Beautify the canvas';
// stringMap[strings.Mind_Usinghelp_6] = 'Beautify the text';
// stringMap[strings.Mind_Usinghelp_7] = 'Add notes、links';
stringMap[strings.Mind_Usinghelp_17] = 'Undo operation、cancel';
stringMap[strings.Mind_Usinghelp_18] = 'Drag node to swap';
stringMap[strings.Mind_Usinghelp_19] = 'Node alignment';
stringMap[strings.Mind_Usinghelp_20] = 'Change map type';
stringMap[strings.Mind_Usinghelp_21] = 'Map line style';
stringMap[strings.Mind_Usinghelp_22] = 'Map background color';
stringMap[strings.Mind_Usinghelp_23] = 'Set node shape';
stringMap[strings.Mind_Usinghelp_24] = 'Node line style';
stringMap[strings.Mind_Usinghelp_25] = 'Node picture settings';
stringMap[strings.Mind_Usinghelp_26] = 'Node icon settings';
stringMap[strings.Mind_Usinghelp_27] = 'Node addition summary';
stringMap[strings.Mind_Usinghelp_28] = 'Single node box selection';
stringMap[strings.Mind_Usinghelp_29] = 'Multi-node block';
stringMap[strings.Mind_Usinghelp_30] = 'Node add border';
stringMap[strings.Mind_Usinghelp_31] = 'Add a chart';
stringMap[strings.Mind_Usinghelp_32] = 'Hidden nodes and recovery';
stringMap[strings.Mind_Usinghelp_33] = 'Node multiple operations';
stringMap[strings.Mind_Usinghelp_34] = 'Change style';
stringMap[strings.Mind_Usinghelp_35] = 'Insert speech and explanation';
stringMap[strings.Mind_Usinghelp_36] = 'Map to picture';
stringMap[strings.Mind_Usinghelp_37] = 'Map PDF document';
stringMap[strings.Mind_Usinghelp_38] = 'Print map';
stringMap[strings.Mind_Usinghelp_39] = 'Link sharing and viewing';
stringMap[strings.Mind_Usinghelp_40] = 'Map sharing to the storm';
stringMap[strings.Mind_Usinghelp_41] = 'Use of folders';
stringMap[strings.Mind_Usinghelp_42] = 'Delete and restore maps';
//Operation Guide-3
stringMap[strings.Mind_Usinghelp_43] = 'Friends apply to join the group';
stringMap[strings.Mind_Usinghelp_44] = 'How to dissolve a group';
stringMap[strings.Mind_Usinghelp_45] = 'Upload files';
stringMap[strings.Mind_Usinghelp_46] = 'Agree to join the application';
stringMap[strings.Mind_Usinghelp_47] = 'Co editing';
stringMap[strings.Mind_Usinghelp_48] = 'New shared group';
stringMap[strings.Mind_Usinghelp_49] = 'Invite group members';

//Community
stringMap[strings.Mind_Storm_Recommend] = 'Recommendation';
stringMap[strings.Mind_Storm_Boilingpoint] = 'Hot spot';
stringMap[strings.Mind_Storm_Unfold] = 'Unfold';
stringMap[strings.Mind_Storm_Putaway] = 'Putaway';
stringMap[strings.Mind_Storm_Myclassification] = 'My classification';
stringMap[strings.Mind_Storm_Recommended_Classification] = 'Recommended Classification';
stringMap[strings.Mind_Storm_Click_Add_Category] = 'Click to add category';
stringMap[strings.Mind_Storm_No_Classification] = 'No classification';
stringMap[strings.Mind_Storm_No_Tinterested] = 'Not interested';
stringMap[strings.Mind_Storm_Report] = 'Report';
stringMap[strings.Mind_Storm_Views] = 'Views';

stringMap[strings.Mind_Storm_Pornographic] = 'Pornography is vulgar';
stringMap[strings.Mind_Storm_Advertising_Harassment] = 'Advertising Harassment';
stringMap[strings.Mind_Storm_Political_Religion] = 'Politics and Religion';
stringMap[strings.Mind_Storm_Infringement] = 'Infringement (portrait, defamation, plagiarism, fraudulent use)';
stringMap[strings.Mind_Storm_Prohibited_Content] = 'Prohibited content';
stringMap[strings.Mind_Storm_Spread_Rumors] = 'Rumors spreading';
stringMap[strings.Mind_Storm_Report_Successful] = 'Report successful';

//Edit Information
stringMap[strings.Global_Edit] = 'Edit';
stringMap[strings.Global_Cancel] = 'Cancel';
stringMap[strings.Global_Ok] = 'OK';
stringMap[strings.Global_Name] = 'Nickname';
stringMap[strings.Global_Introduction] = 'Introduction';
stringMap[strings.Global_Change_Avatar] = 'Change avatar';
stringMap[strings.Global_Local_Upload] = 'Local upload';


//Personal Information
stringMap[strings.User_Personal_information] = 'Personal Information';
stringMap[strings.User_Member_Validity_Period] = 'VIP validity period';
stringMap[strings.User_My_Share] = 'My Share';
stringMap[strings.User_My_Collection] = 'My Collection';
stringMap[strings.User_More_Message] = 'More';
stringMap[strings.User_Deleted_Sotrm] = 'Delete Community';
stringMap[strings.User_Folder_Rename] = 'Rename';
stringMap[strings.User_Folder_Rename_Root] = 'Modify permissions';
stringMap[strings.User_Folder_Rename_Root_Title] = 'Modify permissions';
stringMap[strings.User_Folder_Root_Public] = 'Fully public';
stringMap[strings.User_Folder_Root_Viewonly] = 'The folder is only allowed to be viewed';
stringMap[strings.User_Folder_Root_Private] = 'Private';
stringMap[strings.User_Folder_Root_Public_Introduction] = 'Other people can view your favorites on your profile page';
stringMap[strings.User_Message_Link] = 'Link';
stringMap[strings.User_New_Group] = 'New group';
stringMap[strings.User_New_Group_Title] = 'Create a new group';
stringMap[strings.User_New_Group_Style_Title] = 'File Type';
stringMap[strings.User_Share_Is_Null] = 'No share!';
stringMap[strings.User_Deleted_Storm_Tips] = 'Are you sure to delete this storm?';
stringMap[strings.User_Remove_From_List] = 'Remove from list',
    stringMap[strings.User_Move_To] = 'Move to',
    stringMap[strings.User_Deleted_Link] = 'Delete Link'
stringMap[strings.User_Collction_Is_Null] = 'No favorites！'
stringMap[strings.User_Collection] = 'Favorites'

//Login Box
stringMap[strings.User_Lolgin_Welcome_To_Login] = 'Welcome to Mind+'
stringMap[strings.User_Lolgin_Free_Registration] = 'registration'
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] = 'Please enter phone number'
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] = 'Please enter the verification code'
stringMap[strings.User_Lolgin_Obtain] = 'Obtain'
stringMap[strings.User_Lolgin_Password_Login] = 'Login with password'
stringMap[strings.User_Lolgin_Login] = 'Login'
stringMap[strings.User_Lolgin_Register_An_Account] = 'Registration'
stringMap[strings.User_Lolgin_Already_Have_An_Account] = 'have an account'
stringMap[strings.User_Lolgin_To_Log_In] = 'Go to log in'
stringMap[strings.User_Lolgin_Please_Enter_Password] = 'Please enter the password'
stringMap[strings.User_Lolgin_Confirm_Password] = 'Confirm password'
stringMap[strings.User_Lolgin_Registered] = 'Register'
stringMap[strings.User_Lolgin_Account_Login] = 'Account login'
stringMap[strings.User_Lolgin_Scan_Code_Login] = 'Scan code login'
stringMap[strings.User_Lolgin_Forgot_Password] = 'Forgot password'
stringMap[strings.User_Lolgin_Verification_Code_Login] = 'Verification code login'
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Open Mind+ and scan at the top of the setting interface'
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'This QR code expires in five minutes, please click'
stringMap[strings.User_Lolgin_Refresh] = 'Refresh'
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'Scanning is successful, please confirm on your phone '
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'Whether to authorize login'
stringMap[strings.User_Lolgin_Please_Enter_New_Password] = 'Please enter new password'
stringMap[strings.User_Lolgin_Reset] = 'reset'

//My  Mindmap
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] = 'Search template type or template'
stringMap[strings.My_Mind_Date] = 'Date'
stringMap[strings.My_Mind_Create_Folder] = 'Create folder'
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] = 'Please enter the folder name'
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'Please enter a new folder name'
stringMap[strings.My_Mind__No_Map_Yet_Go] = 'No map yet, go'
stringMap[strings.My_Mind_Create] = 'create '
stringMap[strings.My_Mind_Right] = 'Right!'
stringMap[strings.My_Mind_Open] = 'Open'
stringMap[strings.My_Mind_Type_Left_Right] = 'Two-way Map'
stringMap[strings.My_Mind_Type_Unilateral] = 'Logic diagram'
stringMap[strings.My_Mind_Type_Bottom] = 'Org-Chart Map'
stringMap[strings.My_Mind_Type_Bubble] = 'Bubble Map'
stringMap[strings.My_Mind_Type_Time_Vertical] = 'Time Map'
stringMap[strings.My_Mind_Blank_Mind_Map] = 'Empty Mindmap'
stringMap[strings.My_Mind_All] = 'All'
stringMap[strings.My_Mind_Views] = 'Views：'

//Community search
stringMap[strings.Storm_Search_How_Title] = 'Popular tags'
stringMap[strings.Storm_History_Search] = 'History search'

//Public header
stringMap[strings.Public_Header_Mind] = 'Map'
stringMap[strings.Public_Header_Outline] = 'Outline'
stringMap[strings.Public_Header_Download_Apps] = 'Download client version'
stringMap[strings.Public_Header_Mobile_Version] = 'Mobile version'
stringMap[strings.Public_Header_Creator] = 'Creator：'
stringMap[strings.Public_Header_View_Frequency] = 'Views：'
stringMap[strings.Public_Header_Save] = 'Save'
stringMap[strings.Header_Mind_Tips_Saved] = 'Saved'
stringMap[strings.Header_Mind_Tips_Edit] = 'Edited'
stringMap[strings.Header_Mind_Tips_Update_Saved] = 'Update saved'

//Map edit menu
stringMap[strings.Mind_Edit_Revoke] = 'Back'
stringMap[strings.Mind_Edit_Restore] = 'Forward'
stringMap[strings.Mind_Edit_Subtopic] = 'Subtopic'
stringMap[strings.Mind_Edit_Top_Subtopic] = 'Above'
stringMap[strings.Mind_Edit_Bottom_Subtopic] = 'Under'
stringMap[strings.Mind_Edit_Free_Node] = 'Floating topic'
stringMap[strings.Mind_Edit_Summary] = 'Summary'
stringMap[strings.Mind_Edit_Block] = 'Boundary'
stringMap[strings.Mind_Edit_Insert] = 'Insert'
stringMap[strings.Mind_Edit_Related] = 'Relationship'
stringMap[strings.Mind_Edit_Style] = 'Beautify'
stringMap[strings.Mind_Edit_Recording] = 'Audio'
stringMap[strings.Mind_Edit_Insert_Picture] = 'Image'
stringMap[strings.Mind_Edit_Insert_Icon] = 'Icon'
stringMap[strings.Mind_Edit_Insert_Remarks] = 'Remark'
stringMap[strings.Mind_Edit_Insert_Link] = 'Hyperlink'
stringMap[strings.Mind_Edit_Insert_Summary_Graph] = 'Chart'
stringMap[strings.Mind_Edit_Upload] = 'Upload'
stringMap[strings.Mind_Edit_Right_Icon] = 'Icon'
stringMap[strings.Mind_Edit_Right_Style] = 'Style'
stringMap[strings.Mind_Edit_Audio_Decoding] = 'Decoding...'
stringMap[strings.Mind_Edit_Audio_Duration] = 'Duration'
stringMap[strings.Mind_Edit_Delete_Audio_Tips] = 'Are you sure to delete the recorded audio?'
stringMap[strings.Mind_Edit_Join_Membership] = 'join VIP'
stringMap[strings.Mind_Edit_Upgrade] = 'Upgrade'
stringMap[strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap[strings.Mind_Edit_Join_Membership_Tips] = 'VIPs can have more recording time, now to upgrade to a member?'
stringMap[strings.Mind_Edit_Choose_Material] = 'Select material'
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'Drag and drop pictures here'
stringMap[strings.Mind_Edit_Select_locally] = 'Local upload'
stringMap[strings.Mind_Edit_Choose_From] = 'Material library'
stringMap[strings.Mind_Edit_Mine] = 'My '
stringMap[strings.Mind_Edit_My_Material] = 'My Material'
stringMap[strings.Mind_Edit_Recently_Used] = 'Recently used'
stringMap[strings.Mind_Edit_Use] = 'Use'
stringMap[strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = 'No material for now, go and add it! '
stringMap[strings.Mind_Edit_Select_Chart_Type] = 'Select chart type'
stringMap[strings.Mind_Edit_Remarks] = 'Remarks'
stringMap[strings.Mind_Edit_Remarks_Tips] = 'Please enter the remarks'
stringMap[strings.Mind_Edit_Add] = 'add '
stringMap[strings.Mind_Edit_Please_Enter_The_Link] = 'Please enter the link'

//Edit page left menu
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture] = 'Save Image'
stringMap[strings.Mind_Edit_Left_Menu_Export_Pdf] = 'Export PDF'
stringMap[strings.Mind_Edit_Left_Menu_Share_Links] = 'Share links'
stringMap[strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'Share to Community'
stringMap[strings.Mind_Edit_Left_Menu_Printing] = 'Print'
stringMap[strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'Upgrade'
stringMap[strings.Mind_Edit_Left_Menu_Help] = 'Help'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'Save format'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'png format'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'jpg format'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'webp format'
stringMap[strings.Mind_Edit_Left_Menu_Share_Map] = 'Share map'
stringMap[strings.Mind_Edit_Left_Menu_Share_Switch] = 'Share switch'
stringMap[strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'You can copy the link and send it'
stringMap[strings.Mind_Edit_Left_Menu_Copy_Link] = 'Copy link'
stringMap[strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'If you want to follow and change the link, please click'
stringMap[strings.Mind_Edit_Left_Menu_Regenerate] = 'Change it'
stringMap[strings.Mind_Edit_Left_Menu_Link_Password] = 'Link password:'
stringMap[strings.Mind_Edit_Left_Menu_Allow_Save] = 'Allow saving'
stringMap[strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Maximum number of views'
stringMap[strings.Mind_Edit_Left_Menu_Infinite] = 'Infinite'
stringMap[strings.Mind_Edit_Left_Menu_Limit] = 'Limit'
stringMap[strings.Mind_Edit_Left_Menu_Second] = 'Times'
stringMap[strings.Mind_Edit_Left_Menu_Link_Validity] = 'Link validity period'
stringMap[strings.Mind_Edit_Left_Menu_1_Day] = '1 day'
stringMap[strings.Mind_Edit_Left_Menu_3_Days] = '3 days'
stringMap[strings.Mind_Edit_Left_Menu_7_Days] = '7 days'
stringMap[strings.Mind_Edit_Left_Menu_30_Days] = '30 days'
stringMap[strings.Mind_Edit_Left_Menu_Permanent] = 'Permanent'
stringMap[strings.Scan_The_Code_To_Get_The_file] = "Scan the code to get the file"
stringMap[strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'Copy content & link'
stringMap[strings.Mind_Edit_Left_Menu_Regenerate_Tips] = 'Are you sure you want to regenerate? The previous link will be invalid! '
stringMap[strings.Mind_Edit_Left_Menu_Share] = 'Share'
stringMap[strings.Mind_Edit_Left_Menu_Custom_Category] = 'Custom category:'
stringMap[strings.Mind_Edit_Left_Menu_Allow_Comments] = 'Whether to allow comments'
stringMap[strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'Edit to add category'
stringMap[strings.Mind_Edit_Left_Menu_Membership_Rights] = 'Membership Rights'
stringMap[strings.Mind_Edit_Left_Menu_Support_Platform] = 'Support platform'
stringMap[strings.Mind_Edit_Left_Menu_Open_Membership] = 'Open Vip'
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'ActivationCode'
stringMap[strings.Mind_Edit_Left_Menu_purchase_Tips] = 'Membership benefits after purchase'
stringMap[strings.Mind_Edit_Left_Menu_Payment_Type] = 'Select payment method'
stringMap[strings.Mind_Edit_Left_Menu_Activity_Price] = 'Activity price'
stringMap[strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'Amount actually paid'
stringMap[strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Renewal immediately'
stringMap[strings.Mind_Edit_Left_Menu_Terms_Of_Service] = 'Terms of Service'
stringMap[strings.Mind_Edit_Left_Menu_Privacy_Policy] = 'Privacy Policy'
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code] = 'Code:'
stringMap[strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Please enter the activation code'
stringMap[strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'Verify activation code'
stringMap[strings.Mind_Edit_Left_Menu_Select_Categories] = 'Select categories'
stringMap[strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'Main title cannot be empty'
stringMap[strings.Mind_Edit_Left_Menu_Coupon] = 'Coupon:'
stringMap[strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Please enter the coupon code'
stringMap[strings.Mind_Confirm_Use_Coupons] = 'Confirm the use of coupons'
stringMap[strings.Mind_Edit_Left_Menu_Verification] = 'Verification'
stringMap[strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'Preferential Amount'
stringMap[strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'Pay immediately'
stringMap[strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Confirm exchange information'
stringMap[strings.Mind_Edit_Left_Menu_Exchange_Code] = 'Exchange code:'
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Exchange'

//Edit page right menu
stringMap[strings.Mind_Edit_Right_Menu_canvas] = 'Canvas'
stringMap[strings.Mind_Edit_Right_Menu_background_Color] = 'Background color'
stringMap[strings.Mind_Edit_Right_Menu_layout] = 'Type'
stringMap[strings.Mind_Edit_Right_Menu_Layout_branch] = "Branching structure"
stringMap[strings.Mind_Edit_Right_Menu_Line_branch] = "Branch line"
stringMap[strings.Mind_Edit_Right_Menu_Line_Style] = 'Line style'
stringMap[strings.Mind_Edit_Right_Menu_line_Color] = 'Line color'
stringMap[strings.Mind_Edit_Right_Menu_Line_Tapering] = 'TaperedLine'
stringMap[strings.Mind_Edit_Right_Menu_Theme_Font] = 'Theme font'
stringMap[strings.Mind_Edit_Right_Menu_Child_Font] = 'Children font'
stringMap[strings.Mind_Edit_Right_Menu_text_Color] = 'Text color'
stringMap[strings.Mind_Edit_Right_Menu_font_Size] = 'Font size'
stringMap[strings.Mind_Edit_Right_Menu_Font_Style] = 'Font style'
stringMap[strings.Mind_Edit_Right_Menu_Border_Color] = 'Border color'
stringMap[strings.Mind_Edit_Right_Menu_Fill_Color] = 'Fill color'
stringMap[strings.Mind_Edit_Right_Menu_Border_Shape] = 'Border Shape'
stringMap[strings.Mind_Edit_Right_Menu_Border_Width] = 'Border width'
stringMap[strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Show shadow effect (subscription function)'
stringMap[strings.Mind_Edit_Right_Menu_Line_Color] = 'Line color'
stringMap[strings.Mind_Edit_Right_Menu_Line_Width] = 'Line Width'
stringMap[strings.Mind_Edit_Right_Menu_Picture_Size] = 'Picture size'
stringMap[strings.Mind_Edit_Right_Menu_Picture_Location] = 'Picture Location'
stringMap[strings.Mind_Edit_Right_Menu_Picture_Shape] = 'Picture Shape'
stringMap[strings.Mind_Edit_Right_Menu_Icon_Size] = 'Icon size'
stringMap[strings.Mind_Edit_Right_Menu_Icon_Position] = 'Icon location'
stringMap[strings.Mind_Edit_Right_Menu_routine] = 'General'
stringMap[strings.Mind_Edit_Right_Menu_priority] = 'Priority'
stringMap[strings.Mind_Edit_Right_Menu_Progress] = 'Progress'
stringMap[strings.Mind_Edit_Right_Menu_Stars] = 'Stars'
stringMap[strings.Mind_Edit_Right_Menu_Week] = 'week'
stringMap[strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Portrait'
stringMap[strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap[strings.Mind_Edit_Right_Menu_Learning] = 'Study'
stringMap[strings.Mind_Edit_Right_Menu_Work] = 'Work'
stringMap[strings.Mind_Edit_Right_Menu_Characters] = 'Man'
stringMap[strings.Mind_Edit_Right_Menu_Animals] = 'Pets'
stringMap[strings.Mind_Edit_Right_Menu_Food] = 'Food'
stringMap[strings.Mind_Edit_Node_Menu_Copy_Style] = 'Copy style'
stringMap[strings.Mind_Edit_Node_Menu_Sticking_Style] = 'Paste style'
stringMap[strings.Mind_Edit_Node_Menu_Modify_Shape] = 'Modify shape'
stringMap[strings.Mind_Edit_Node_Menu_View_Picture] = 'View picture'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Picture] = 'Delete picture'
stringMap[strings.Mind_Edit_Node_Menu_Copy] = 'Copy'
stringMap[strings.Mind_Edit_Node_Menu_Paste] = 'Paste'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Line] = 'Delete line'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Delete '
stringMap[strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'Edit Remarks'
stringMap[strings.Mind_Edit_Node_Menu_Open_Link] = 'Open link'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'Delete Remarks'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Link] = 'Delete link'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Icon] = 'Delete icon'
stringMap[strings.Mind_Edit_Node_Menu_Icon_Style] = 'Icon style'

//统计图编辑面板
stringMap[strings.Echart_Edit_Statistical] = 'Statistics'
stringMap[strings.Echart_Edit_Value] = 'Value'
stringMap[strings.Echart_Edit_Color] = 'Color'
stringMap[strings.Echart_Edit_Title] = 'Title'
stringMap[strings.Echart_Edit_Detailed_Description] = 'Detailed description'
stringMap[strings.Echart_Edit_Value_Sort] = 'Sort'
stringMap[strings.Echart_Edit_Value_Display_Type] = 'Display Type'
stringMap[strings.Echart_Edit_Value_Color_Scheme] = 'Color scheme'
stringMap[strings.Echart_Edit_Value_Display_Size] = 'Display size'
stringMap[strings.Echart_Edit_Value_Title_Size] = 'Title size'
stringMap[strings.Echart_Edit_Value_Explain_Size] = 'Explain size'
stringMap[strings.Echart_Edit_Value_Subscribe] = 'Subscribe'

//风暴标签页
stringMap[strings.StormBookTag_Subscribed] = 'Subscribed'
stringMap[strings.StormBookTag_List] = 'List'

//风暴分享页
stringMap[strings.Storm_Share_Comment] = 'Comment'
stringMap[strings.Storm_Share_Reply] = 'Reply'
stringMap[strings.Storm_Share_Let_Me_Comment_Too] = 'Let me comment on it~'
stringMap[strings.Storm_Share_Release] = 'Release'
stringMap[strings.Storm_Share_Main_Title] = 'Main Title'
stringMap[strings.Storm_Share_Subtitle] = 'Subtitle'
stringMap[strings.Storm_Share_Author] = 'Author'
stringMap[strings.Storm_Share_Replies] = 'Reply'
stringMap[strings.Storm_Share_Comment_Is_Null_Tips] = 'There are no comments yet. Be the first to be the first.'
stringMap[strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'Please enter a comment~'
stringMap[strings.Storm_Share_Delete_Comment_Tips] = 'Are you sure you want to delete this comment? '

//导图内部使用帮助
stringMap[strings.Mind_Usinghelp_Btn_1] = 'Shortcut keys (windows)'
stringMap[strings.Mind_Usinghelp_Btn_2] = 'Shortcut key (mac)'
stringMap[strings.Mind_Usinghelp_Btn_3] = 'Description'
stringMap[strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap[strings.Mind_Usinghelp_Btn_5] = '[Command] + [D]'
stringMap[strings.Mind_Usinghelp_Btn_6] = 'Add child node'
stringMap[strings.Mind_Usinghelp_Btn_7] = '[Delet]'
stringMap[strings.Mind_Usinghelp_Btn_8] = 'Delete the current node (note that it cannot be deleted if it is in the editing state)'
stringMap[strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap[strings.Mind_Usinghelp_Btn_10] = '[Command] + [E]'
stringMap[strings.Mind_Usinghelp_Btn_11] = 'Modify the current node'
stringMap[strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap[strings.Mind_Usinghelp_Btn_13] = '[Command] + [↑] [↓] [←] [→]'
stringMap[strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap[strings.Mind_Usinghelp_Btn_15] = '[Command] + [+]'
stringMap[strings.Mind_Usinghelp_Btn_16] = 'Enlarge the map'
stringMap[strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap[strings.Mind_Usinghelp_Btn_18] = '[Command] + [-]'
stringMap[strings.Mind_Usinghelp_Btn_19] = 'Mind the map'
stringMap[strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap[strings.Mind_Usinghelp_Btn_21] = '[Command] + [S]'
stringMap[strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_23] = '[Command] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_25] = '[Command] + [Shift] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap[strings.Mind_Usinghelp_Btn_27] = '[Command] + [B]'
stringMap[strings.Mind_Usinghelp_Btn_28] = 'Exit editing'

//提示文字
stringMap[strings.Message_Tips_Login_Success] = 'Login successful'
stringMap[strings.Message_Tips_Dlt_Success] = 'Delete successfully'
stringMap[strings.Message_Tips_Report_Success] = 'Report successful'
stringMap[strings.Message_Tips_Copy_Success] = 'Copy successful'
stringMap[strings.Message_Tips_Collction_Success] = 'Collection success'
stringMap[strings.Message_Tips_Avatar_Modification_Success] = 'Avatar modification success'
stringMap[strings.Message_Tips_Signature_Modification_Success] = 'Signature modification success'
stringMap[strings.Message_Tips_Name_Modification_Success] = 'nickname modified successfully'
stringMap[strings.Message_Tips_Modification_Success] = 'Modification successful'
stringMap[strings.Message_Tips_New_Profile_Success] = 'New folder success'
stringMap[strings.Message_Tips_Mobile_Success] = 'Mobile success'
stringMap[strings.Message_Tips_Submit_Success] = 'Submit successfully'
stringMap[strings.Message_Tips_Exchange_Success] = 'Exchange successful'
stringMap[strings.Message_Tips_Please_Code] = 'Please enter the activation code'
stringMap[strings.Message_Tips_Agree_To_Terms] = 'Please agree to the privacy policy'
stringMap[strings.Message_Tips_Please_Sign_In] = 'Please sign in'
stringMap[strings.Message_Tips_Share_Success] = 'Share successful'
stringMap[strings.Message_Tips_Create_Success] = 'Created successfully'
stringMap[strings.Message_Tips_Profile_Name_No_Null] = 'The folder name cannot be empty'
stringMap[strings.Message_Tips_Selecte_Success] = 'Search completed'
stringMap[strings.Message_Tips_Subscription_Success] = 'Subscription is successful'
stringMap[strings.Message_Tips_Unsubscribe_Success] = 'Unsubscribe successful'
stringMap[strings.Message_Tips_Related_Content_Not_Found] = 'Related content not found'
stringMap[strings.Message_Tips_Max_Add_Class] = 'You can only add at most'
stringMap[strings.Message_Tips_Registered_Success] = 'Registered successfully'
stringMap[strings.Message_Tips_Scan_Code_Login_Failed] = 'Code scan login failed'
stringMap[strings.Message_Tips_Share_Is_Null] = 'Share link does not exist'
stringMap[strings.Message_Tips_Share_Link_Does_Not_Exist] = 'The uploaded file cannot exceed 3MB'
stringMap[strings.Message_Tips_Get_Permission_Successfully] = 'Get permission successfully'
stringMap[strings.Message_Tips_Membership_Required] = 'Membership required'
stringMap[strings.Message_Tips_Save_Success] = 'Save successfully'
stringMap[strings.Message_Tips_Scan_Code_Login_Success] = 'Scan code login succeeded'
stringMap[strings.Message_Tips_Style] = 'How about a category'
stringMap[strings.Outline_Menu_Indent] = 'indent'
stringMap[strings.Outline_Menu_Decrease_Indent] = 'reduce indent'
stringMap[strings.Help_Background_Content] = 'Line background correlation, line background correlation, line background correlation, line background correlation, line background correlation, line background correlation, line background correlation, line background correlation, line background correlation, line background correlation,'
stringMap[strings.Help_Mind_Content] = 'You can make different layout maps according to your preference: bidirectional map, left map, right map, organization chart, bidirectional tree, left tree, right tree and table'
stringMap[strings.Help_Share_Link_Content] = 'Sharing links can be shared in wechat, QQ, microblog and other channels in the form of links, and friends can view the content'
stringMap[strings.Using_Templates] = 'Using templates'

//邀请码
stringMap[strings.User_Lolgin_Please_Enter_Invitation_Code] = 'Please input invitation code '
stringMap[strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Please input correct invitation code '
stringMap[strings.User_Lolgin_Optional] = 'optional '

//使用素材
stringMap[strings.Cannot_Add_To_Node_Background] = 'Cannot add to node background'
stringMap[strings.Cannot_Add_To_Img_Background] = 'Cannot add to text background'
stringMap[strings.Cannot_Add_To_Mind_Background] = 'Cannot add to Mind background'
stringMap[strings.Permanent_Member] = 'permanent'

stringMap[strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Add a background image'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Delete background image'
stringMap[strings.Mind_Add_Background_Image] = 'BgImage'
stringMap[strings.Mind_Delete_Background_Image] = 'Delete background image'
stringMap[strings.Insufficient_Storage_Space] = 'Insufficient storage space, upgrade members to increase space.'
stringMap[strings.Used_Space] = 'Storage Usage'
stringMap[strings.Used_Space_Upgrade] = 'Current storage'
stringMap[strings.Used_Space_Upgrade] = 'Storage space upgrade'
stringMap[strings.My_Mind_Is_Null_Tips] = 'Mind+ brain chart notes & super memory technology, which can add wings to thinking to create, display, plan and take notes'
stringMap[strings.Insufficient_Storage_Space_Tempalte_Tips] = 'Storage space is insufficient, upgrade members can use the template.'


stringMap[strings.Group_Management] = 'Group'
stringMap[strings.Save_Img_Fill_Full] = 'full'
stringMap[strings.Save_Img_Center_Tilt] = 'center tilt'
stringMap[strings.Save_Img_File_Full_Dark_Color] = 'full (dark) '
stringMap[strings.Save_Img_Mind_Map_Watermarking] = 'mind + mind map watermark '
stringMap[strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'add watermark protection knowledge creation: '
stringMap[strings.Save_Img_Watermark_Text] = 'watermark text '
stringMap[strings.Save_Img_Watermark_Logo] = 'watermark logo '
stringMap[strings.Save_Img_Watermark_Style] = 'watermark style '
stringMap[strings.Save_Img_Please_Input] = 'Please input'
stringMap[strings.Save_Img_Chop_Tips] = 'You can enjoy the watermark rights and interests when you open VIP'
stringMap[strings.Public_Header_Mindmap_Mind] = 'Mapping mode'
stringMap[strings.Copy_Mind_Map] = 'Copy Map'
stringMap[strings.Font_Style_Bold] = 'Bold'
stringMap[strings.Font_Style_Italics] = 'Italics'
stringMap[strings.Font_Style_Delete_Line] = 'Delete line'
stringMap[strings.Remove_Any] = 'remove'


stringMap[strings.Mindmap_Review] = 'Mind Playback'
stringMap[strings.Mindmap_Review_Recently_Updated_Content_10] = 'Deepen impression, enhance memory, and efficiently sort out ideas and information'
stringMap[strings.Mindmap_Review_Content] = 'Map review is the playback of editing process, and the content is played from the central theme in turnIn order to help you carry out system inventory, analysis and find out the loopholes, which may be found in the review process Different inspiration'
stringMap[strings.Mindmap_Review_Speed]='speed'
stringMap[strings.Mindmap_Review_Word_Speed] ='text speed '
stringMap[strings.Mindmap_Review_Background_Ratio] ='background scale '
stringMap[strings.Mindmap_Review_Current_Playback_Node_Magnification] ='current playback node magnification '
stringMap[strings.Mindmap_Review_Node_Playback_Order] ='node play order '

stringMap[strings.Mind_Group_Picture_Upload] = 'Picture upload'
stringMap[strings.Mind_Group_Upload_From_Local] = 'Upload from local'
stringMap[strings.Mind_Group_Mind_Mapping] = ' Mind Map'
stringMap[strings.Mind_Group_Information] = 'File'


stringMap[strings.Mind_Group_Invite_New_Members] = 'Invite New Members'
stringMap[strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Please enter your cell phone number'
stringMap[strings.Mind_Group_Cancel] = 'Cancel'
stringMap[strings.Mind_Group_Confirm] = 'Confirm'
stringMap[strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'The phone number cannot be blank'
stringMap[strings.Mind_Group_Invite_The_Successful] = 'Invite the successful'



stringMap[strings.Mind_Group_Shared_Group_Membership] =  "Group Members"
stringMap[strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Maximum number of shared group members" 
stringMap[strings.Mind_Group_Membership_Limit] = "Membership limit" 
stringMap[strings.Mind_Group_Current_Member_Limit] = "Current member limit" 
stringMap[strings.Mind_Group_Upgrade_Member_Limit] = "Upgrade member limit"
stringMap[strings.Mind_Group_Members_Of_The_Search] = "Members of the search"
stringMap[strings.Mind_Group_Creator] = "Creator"
stringMap[strings.Mind_Group_Administrator] = "Administrator"
stringMap[strings.Mind_Group_Number_Of_People] = " people"
stringMap[strings.Mind_Group_Selected] = "Selected"
stringMap[strings.Mind_Group_Member] = "Member"
stringMap[strings.Mind_Group_Check_All] = "Check all"
stringMap[strings.Mind_Group_Remove] = "Remove"
stringMap[strings.Mind_Group_Invite_New_Members] = "Invite New Members"
stringMap[strings.Mind_Group_Delete_Members] = "Delete members"
stringMap[strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Please select the member to delete"
stringMap[strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Make sure you want to remove the user from the group"
stringMap[strings.Mind_Group_Add_As_Administrator] = "Add as administrator"
stringMap[strings.Mind_Group_Accomplish] = "Accomplish"
stringMap[strings.Mind_Group_Compile] = "Compile"
stringMap[strings.Mind_Group_Group_Owner] = "Group owner"
stringMap[strings.Mind_Group_Add_Admin] = "Add admin"
stringMap[strings.Mind_Group_Group_ID] = "Group ID"
stringMap[strings.Mind_Group_Group_Number] = "Group Number"
stringMap[strings.Mind_Name] = "Name"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Please enter the name of the shared group"
stringMap[strings.Mind_Group_Introduce] = "Introduce"
stringMap[strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Please briefly introduce the sharing group"
stringMap[strings.Mind_Group_Announcement] = "Announcement"
stringMap[strings.Mind_Group_Shared_Group_Tag] = "Group Tags"
stringMap[strings.Mind_Group_Custom_Tag] = "Custom tag"
stringMap[strings.Mind_Group_Save] = "Save"
stringMap[strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "The group name cannot be empty"
stringMap[strings.Mind_Group_Establish] = "Establish"
stringMap[strings.Mind_Group_Allows_Mapfolder_Creation] = "Allows map/folder creation"
stringMap[strings.Mind_Group_Allows_Editing_Of_Maps] = "Allows editing of maps"
stringMap[strings.Mind_Group_Holder] = "Holder"
stringMap[strings.Mind_Group_Only_The_Administrator] = "Only the administrator"
stringMap[strings.Mind_Group_Not_Allow] = "Not allow"
stringMap[strings.Mind_Group_Allows_Map_Deletion] = "Allows map deletion"
stringMap[strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Allows discussion boards to post messages"
stringMap[strings.Mind_Group_Export] = "Export"
stringMap[strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Allows you to save images /PDF"
stringMap[strings.Mind_Group_Open_Group_Watermark] = "Open group watermark"
stringMap[strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "When turned on, the mind map will be printed with a group logo"
stringMap[strings.Mind_Group_Allow_Saving_Maps] = "Allow saving maps"
stringMap[strings.Mind_Group_Save_It_To_My_Map] = "Save it to my map"
stringMap[strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Whether sharing to storm is allowed"
stringMap[strings.Mind_Group_Join_The_Way] = "Join the way"
stringMap[strings.Mind_Group_Whether_To_Invite_New_Members] = "Whether to invite new members"
stringMap[strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Allow search group number to join"
stringMap[strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Need to verify the message when joining"
stringMap[strings.Mind_Group_Shared_Group_Data] = "Shared Group Data"
stringMap[strings.Mind_Group_Authority_Management] = "Authority management"
stringMap[strings.Mind_Group_Quit_Sharing_Of_Group] = "Quit Sharing Group"
stringMap[strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Are you sure you want to exit the shared group？"
stringMap[strings.Mind_Group_The_Dissolution_Of_Group] = "The dissolution of group"
stringMap[strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "The files will be deleted after disbanding the group. Confirm disbanding?"
stringMap[strings.Mind_Group_Return_To_Home_Page] = "Return to Home Page"
stringMap[strings.Mind_Group_Join_The_Group] = "Join the group"
stringMap[strings.Mind_Group_Please_Enter_The_Group_Number] = "Please enter the group number"
stringMap[strings.Mind_Group_Apply_To_Join] = "Apply to join"
stringMap[strings.Mind_Group_Join] = "Join"
stringMap[strings.Mind_Group_Verification] = "Verification"
stringMap[strings.Mind_Group_Please_Enter_A_Validation_Message] = "Please enter a validation message"
stringMap[strings.Mind_Group_The_Content_Cannot_Be_Empty] = "The content cannot be empty"
stringMap[strings.Mind_Group_Name] = "Name"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Please enter a shared group name"
stringMap[strings.Mind_Group_Introduction] = "Introduction"
stringMap[strings.Mind_Group_Group_Indication] = "Group No"
stringMap[strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "The creator is lazy and hasn't been introduced yet"
stringMap[strings.Mind_Group_Shared_Group_Announcement] = "Group Notice"
stringMap[strings.Mind_Group_No_Announcement] = "No announcement yet"
stringMap[strings.Mind_Group_Click_On] = "Click on"
stringMap[strings.Mind_Group_Click_On_The_Hidden] = "Click on the hidden"
stringMap[strings.Mind_Group_Altogether] = "Altogether"
stringMap[strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "The group number was copied successfully"
stringMap[strings.Mind_Group_Stick] = "Stick"
stringMap[strings.Mind_Group_There_Is_A_New_Content] = "There is a new content"
stringMap[strings.Mind_Group_Cancel_The_Top] = "Cancel the top"
stringMap[strings.Mind_Group_Setting_Up] = "Settings"
stringMap[strings.Mind_Group_Usinghelp] = "Help"
stringMap[strings.Mind_Group_Set_The_Message] = "Set the message"
stringMap[strings.Mind_Group_Apply_To_Join] = "Apply to join"
stringMap[strings.Mind_Group_Invite_To_Join] = "Invite to join"
stringMap[strings.Mind_Group_Consent] = "Consent"
stringMap[strings.Mind_Group_Turn_Down] = "Turn down"
stringMap[strings.Mind_Group_Label] = "Label"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Please enter a shared group label name"
stringMap[strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Invite friends and colleagues to create and share knowledge"
stringMap[strings.Mind_Group_Immediately_Create] = "Create now"
stringMap[strings.Mind_Group_Immediately_To_Join] = "Join now"

stringMap[strings.Currently_Selected] = "Current concerns"
stringMap[strings.LabelManagement] = "Label management"
stringMap[strings.NoAttention] = "No attention"
stringMap[strings.Mindmap_Review_Play] = 'play'

stringMap[strings.Mind_Group_Joined] = "Joined"
stringMap[strings.Mind_Group_Download_App] = "Download app"
stringMap[strings.Mind_Group_No_Content] = "No content"
stringMap[strings.Mind_Group_Individual] = " individual"
stringMap[strings.Mind_Group_Message_Area] = "Message area"
stringMap[strings.Mind_Group_No_Message] = "No comments"
stringMap[strings.Mind_Group_Leave_Message] = "Please leave a comment"
stringMap[strings.Mind_Group_Bar] = ""
stringMap[strings.Mind_Group_See_More] = "See more"
stringMap[strings.Mind_Group_Enter_Invitation_Code] = "Enter invitation code"
stringMap[strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Apply to join sharing group"
stringMap[strings.Reasons_For_Application_0_30_Words] = "Reasons for application (0-30 words)"
stringMap[strings.Mind_Group_Send_Out] = "send out"
stringMap[strings.Mind_Group_Join_Successfully] = "Join successfully"
stringMap[strings.Mind_Group_Application_Sent_Successfully] = "Application sent successfully"

stringMap[strings.Mind_Group_Save_The_Mapping] = "Save this map"
stringMap[strings.Mind_Group_Update_Record] = "Update Record"

stringMap[strings.Mind_Edit_Page_Switch_Mapping] = "Switch Map"
stringMap[strings.Mind_Edit_Page_Mind_Retrospect] = "Play"
stringMap[strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "A user is already editing the map"
stringMap[strings.Mind_Edit_Page_Password] = "Password"

stringMap[strings.Mind_Edit_Page_Sharing_Group] = "Groups"
stringMap[strings.Mind_MBean] = "M Beans"
stringMap[strings.Mind_My_MBean] = "My M Beans"
stringMap[strings.Alipay] = "Alipay"
stringMap[strings.Alipay_Payment] = "Alipay payment"
stringMap[strings.Please_Pay_On_The_Payment_Page] = "Please pay on the payment page"
stringMap[strings.WeChat] = "WeChat"
stringMap[strings.Mind_Shooping_Total] = "total"
stringMap[strings.Mind_Shooping_Confirm_Payment] = "Confirm payment"
stringMap[strings.Mind_Shooping_Redeem_Now] = "Redeem now"

stringMap[strings.Mind_Whether_Confirm_Payment_Purchase] = "Are you sure you want to pay"
stringMap[strings.Mind_Tips] = "Tips"
stringMap[strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "You don't have enough m beans. Do you want to buy m beans"
stringMap[strings.Mind_share_scan_code] = "Mind+ Scan code"


stringMap[strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "Do you want to go back to where it was last played?"
stringMap[strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Number of playable times remaining"

stringMap[strings.Group_Mind_Play_The_File_Will_Not_Exist] = " files in the group will not exist. Are you sure you want to dissolve"
stringMap[strings.Group_Mind_Up_One_Level] = "Up one level"
stringMap[strings.Group_Mind_No_Documents] = "No documents"
stringMap[strings.Group_Mind_PaymentSuccessful] = "Payment successful"

stringMap[strings.Mind_Edit_Page_Sharing_Group_introduce] = "Human collaborative editing, multi terminal real-time synchronization, document sharing, improve team collaboration efficiency"

stringMap[strings.Mind_Edit_Subscript] = "Subscript"
stringMap[strings.Mind_Edit_Text_Location] = "Text location"
stringMap[strings.Mind_Mind_Import_Into_A_Shared_Group] = "Share to Group"

stringMap[strings.Standard_Colors] = "Standard colors"
stringMap[strings.More_Colors] = "More colors"
stringMap[strings.Reset_Password] = "reset password"
stringMap[strings.Account_Password_Login] = "Account password"
stringMap[strings.Authentication_code_Login_registration] = "Authentication code"
stringMap[strings.One_End_Creation_Multi_End_Synchronization] = "One end creation, multi end synchronization"
stringMap[strings.Rich_And_Diverse_Mapping_Structure] = "Rich and diverse mapping structure"
stringMap[strings.Massive_And_ExquisiteTemplateLibrary] = "Massive and exquisite template library"
stringMap[strings.Map_Review_Play] = "Map review - play"
stringMap[strings.CommunityLearningAndSharingPlatform] = "Community learning and sharing platform"

stringMap[strings.Mind_Mind_Upgrade_Storage_Space] = "Upgrade storage space"
stringMap[strings.Mind_Mind_Let_Me_Comment_Too] = "Let me comment, too"
stringMap[strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "No message notifications are announced at this time"
stringMap[strings.Failed_To_Get_Recording_Device] = "Failed to get recording device"

stringMap[strings.Search_Pagination] = "Search Page"
stringMap[strings.Please_Enter_A_Paging_Name] = "Please enter a paging name"
stringMap[strings.Operation_Settings] = "Operation settings"
stringMap[strings.Shortcut_key_Settings] = "Shortcut key settings"
stringMap[strings.Press_Key_Combination] = "Please press the key combination you want"
stringMap[strings.Mind_Reduction] = "reduction"
stringMap[strings.Double_Click_Add_Free_Node] = "Double click the blank space to add a free node"
stringMap[strings.Shortcut_Conflict] = "Shortcut conflict"
stringMap[strings.Select_Main_Node] = "Select the main node"
stringMap[strings.Telephone] = "Telephone"
stringMap[strings.Microblog] = "Microblog"
stringMap[strings.Address] = "Address"
stringMap[strings.Company_Address] = "Block B, building 21, 1158 Zhongxin Road, Songjiang District, Shanghai"
stringMap[strings.Mind_Archive] = "Archive"
stringMap[strings.Mind_Bookshelf] = "bookshelf"
stringMap[strings.Search_Archive] = "Search bookshelf"
stringMap[strings.Modify_Archive_Order] = "Modify bookcase order"
stringMap[strings.Modification_Order] = "Modify Order"
stringMap[strings.Please_Enter_Archive_Name] = "Please enter the bookshelf name"
stringMap[strings.Message_Tips_Archive_Name_No_Null] = "Bookshelf name cannot be empty"
stringMap[strings.Mind_Edit_Delete_Archive_Tips] = "Are you sure you want to delete the bookshelf?"
stringMap[strings.Mind_Edit_Delete_Bookcase_Tips] = "Are you sure you want to delete the bookcase?"
stringMap[strings.Mind_Edit_Delete_Map_Tips] = "Are you sure you want to delete the map?"
stringMap[strings.Mind_Edit_Confirm] = "confirm"
stringMap[strings.Mind_Import_Map] = "Import map"
stringMap[strings.Mind_Bookcase] = "Bookcase"
stringMap[strings.Added_Successfully] = "Added successfully"
stringMap[strings.Message_Tips_Bookcase_Name_No_Null] = "Folder name cannot be empty"

stringMap[strings.Mind_Association_Map] = "Link Map"
stringMap[strings.Mind_confirm_Association_Map] = "Are you sure to associate this map?"
stringMap[strings.Mind_Association_Map_See] = "View Linked Map"
stringMap[strings.Mind_Association_Map_Delete] = "Delete Map-Link"

stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "line structure"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Start line"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "End line"
stringMap[strings.Share_Group_To_Storm] = "Share group to storm"
stringMap[strings.Apply_To_Join_Storm_Platform] = "On the storm platform, others see and apply to join, brainstorming with fans with common knowledge"
stringMap[strings.Pay_To_Goin] = "Pay to join"
stringMap[strings.Pay_To_Goin_Need_Mbeans] = "You need to pay m beans when you join"
stringMap[strings.Mbean_Can_Be_Realized] = "M Beans can be realized"

stringMap[strings.Mind_Visit] = "visit"
stringMap[strings.Mind_Details] = "details"
stringMap[strings.Mind_See] = "see"
stringMap[strings.Earn_Mbeans] = "Earn m beans"
stringMap[strings.Mind_Explain] = "explain"
stringMap[strings.Mind_Parenting] = "Parenting"
stringMap[strings.Mind_Internet] = "internet"
stringMap[strings.Mind_Psychology] = "Psychology"
stringMap[strings.Mind_literature] = "literature"
stringMap[strings.Mind_Technology] = "Technology"
stringMap[strings.Mind_Technology] = "Technology"
stringMap[strings.Mind_Workplace] = "Workplace"
stringMap[strings.Mind_Life] = "life"
stringMap[strings.Mind_Subject] = "subject"
stringMap[strings.Hot_Group] = "Hot group"
stringMap[strings.Open_Group] = "Open group"

stringMap[strings.Mind_Group_Used] = "Used"
stringMap[strings.Search_Shared_Groups] = "Search shared groups"
stringMap[strings.Search_Storm] = "Search storm"
stringMap[strings.Members_Save_Free] = "Members save it free of charge"
stringMap[strings.Bank_Card_Account_Name] = "Please enter your bank card account name"
stringMap[strings.Bank_Card_No] = "Bank card No"
stringMap[strings.Please_Enter_Your_Bank_Card_No] = "Please enter your bank card number"
stringMap[strings.Branch_Sub_Branch_Information] = "Branch sub branch information"
stringMap[strings.Branch_Sub_Branch_Example] = "For example: Hangzhou branch Chengxi sub branch"
stringMap[strings.Application_For_Withdrawal] = "Application for withdrawal"
stringMap[strings.Withdrawal_Amount] = "Withdrawal amount"
stringMap[strings.Withdrawal] = "Withdrawal"
stringMap[strings.Current_Revenue] = "Current revenue"
stringMap[strings.Recharge] = "Recharge"
stringMap[strings.Current_Total_MBeans] = "Current total m beans"
stringMap[strings.Withdrawal_Status] = "Withdrawal status"
stringMap[strings.Insufficient_Withdrawal_Amount] = "Insufficient withdrawal amount"
stringMap[strings.Load_More] = "Load more"
stringMap[strings.Loading] = "Loading"
stringMap[strings.It_Is_Over] = "It's over"
stringMap[strings.Default_Label] = "Default label"
stringMap[strings.Selected_Label] = "Selected label"
stringMap[strings.Current_Location] = "current location"
stringMap[strings.Selected_Label] = "Selected label"
stringMap[strings.Current_Location] = "current location"
stringMap[strings.MGold_Introduction] = "M gold is earned by you through mind +"
stringMap[strings.MGold_Details] = "Your payment cloud group has knowledge maps, learning materials, etc. users join the payment m bean, of which 50% will be converted into m gold. If the M gold is greater than 50, it can be extracted"
stringMap[strings.Cloud_Group_Introduction] = "Share your group and others can see it on the storm page"
stringMap[strings.Cloud_Group_Details] = "Your payment cloud group has knowledge maps, learning materials, etc. users join the payment m bean, of which 50% will be converted into m gold. If the M gold is greater than 50, it can be extracted"
stringMap[strings.Share_Storm_Introduction] = "Share your map to the storm community so that more people can learn and explore knowledge from you"
stringMap[strings.Share_Storm_Details] = "If you start using m-beans to save the map, other users can use your knowledge map, and you will also get 50% of m-beans. M-beans can be used to buy members, join payment groups, and pay for the map of others."

stringMap[strings.Linked_Successfully] = "Linked successfully"
stringMap[strings.Cancel_Archiving] = "Cancel Archiving"
stringMap[strings.Please_Select_Archive_Location] = "Please select the archive location"
stringMap[strings.The_Search_Result_Is_Empty] = "Search results are empty"

stringMap[strings.Customize_A_Style] = "Non-members can only customize one style"
stringMap[strings.Adds_Current_Guide_Style_Library] = "Add the current map style to the style library"
stringMap[strings.Trial_Version] = "Trial Version"
stringMap[strings.Not_Supported_At_The_Moment] = "Not currently supported"
stringMap[strings.Not_Supported_At_The_Moment_This_Type_Xmind_File] = "This version of XMIND file is not supported at present"
stringMap[strings.Load_File_Error] = "Sorry, file parsing error"
stringMap[strings.Mind_File] = "File"
stringMap[strings.Mind_Import] = "Import"
stringMap[strings.Importing] = "Importing"

stringMap[strings.Mind_Edit_Insert_Video] = "Insert Video"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Drag and drop video here"
stringMap[strings.Message_Tips_Video_Upload_Time_Limit] = "The upload video duration cannot exceed 60s"
stringMap[strings.Mind_Edit_Node_Menu_View_Video] = "View Video"
stringMap[strings.Mind_Edit_Node_Delete_View_Video] = "Delete video"
stringMap[strings.Message_Tips_Share_Video_Does_Not_Exist] = "The uploaded video cannot exceed 10MB"
stringMap[strings.Mind_Upload_Video_Restrictions] = "Only supports uploading videos in MP4 format, up to 120s and 10M in size"
stringMap[strings.Mind_Video_Size] = "Video size"
stringMap[strings.Mind_Edit_Left_Menu_Export_Word] = "Export Word"
stringMap[strings.Mind_Edit_Left_Menu_Export_TXT] = "Export TXT"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap[strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap[strings.Export_Failed] = "Export failed"
stringMap[strings.Add_File] = "Add file"
stringMap[strings.Drag_The_File_Directly_To_Upload] = "Drag the file directly to upload it"
stringMap[strings.Supported_Imported_File_Types] = "File types currently supported for import: Word (only .docx), XMind, .txt"

stringMap[strings.Mind_Edit_Insert_Video] = 'Video'
stringMap[strings.No_Permission_Do_This] = "You do not have permission to perform this operation"
stringMap[strings.Upgrade_To_Professional] = "Upgrade to Professional Edition"
stringMap[strings.Non_Members_Insert_Videos] = "Non-members can only insert videos 24 hours after registration"
stringMap[strings.Upload_Failed] = "Upload failed"
stringMap[strings.Upload_Failed_Title_Message] = "Upload failed, please click to confirm, delete the remaining pictures that failed to upload"

stringMap[strings.I_Get_It] = "I will. Skip the boot"
stringMap[strings.Welcome_To_Mind] = "Welcome to Mind+, let's start the journey of thinking!"
stringMap[strings.Try_A_Map] = " Try a map"
stringMap[strings.Click_Create] = "Click create"
stringMap[strings.Click_Create_A_Mind_Map] = "Click to create a mind map"

stringMap[strings.Mind_Edit_Members_Use] = "Members Use"
stringMap[strings.Invitation_Code] = "Invitation Code"
stringMap[strings.Student_Certification] = "Student Cert"
stringMap[strings.Mind_About] = "About"
stringMap[strings.See_Checking_Data] = "View Individual"
stringMap[strings.Modify_Checking_Data] = "Modify Individual"
stringMap[strings.Mind_Shear] = "Shear"

stringMap[strings.Mind_Style_Empty] = "No favorite or custom style"
stringMap[strings.Mind_Branch_Free_Layout] = "Branch free layout"
stringMap[strings.Mind_Branch_Free_Layout_explain] = "Center theme branch free drag"
stringMap[strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Free Theme Drag Snap"
stringMap[strings.Select_A_Node] = "Select a node"
stringMap[strings.Create_Child_Node] = "Create child node"
stringMap[strings.Add_A_Nice_Image_To_The_Node] = "Next, let's add a nice image to the node "
stringMap[strings.Click_Icon_Panel] = "Click icon panel"
stringMap[strings.Select_An_Icon_You_Like] = "Select an icon you like"
stringMap[strings.Replace_The_Mind_Map_With_A_New_One] = "Next, let's replace the mind map with a new one "
stringMap[strings.Select_The_Central_Theme] = "Select the central theme"
stringMap[strings.Switch_To_Canvas_Panel] = "Switch to canvas panel"
stringMap[strings.Click_Layout] = "Click layout"
stringMap[strings.Choose_A_Layout_You_Like] = "Choose a layout you like"
stringMap[strings.Mind_Map_Save_Share] = "Congratulations on making a beautiful mind map! Let's save it and share it with our friends~"
stringMap[strings.Click_Save_Picture] = "Click Save Image"
stringMap[strings.Click_OK] = "Click OK"
stringMap[strings.Congratulations_On_Completing_The_Novice_Guide] = "Congratulations on completing the novice guide~"
stringMap[strings.Upload_Succeeded] = "Upload succeeded"

stringMap[strings.Mind_Edit_Node_Menu_Quick_Style] = "Quick Style"
stringMap[strings.Mind_Extremely_Important] = "Extremely Important"
stringMap[strings.Mind_Important] = "Important"
stringMap[strings.Mind_Commonly] = "Commonly"
stringMap[strings.Mind_Ordinary] = "Ordinary"
stringMap[strings.Member_Exclusive] = "This function is exclusive to members. Do you want to upgrade members?"
stringMap[strings.Member_Exclusive_Color_Scheme] = "This color scheme is exclusive to members. Do you want to upgrade members?"
stringMap[strings.Mind_Enlarge] = "enlarge"
stringMap[strings.Mind_Narrow] = "narrow"
stringMap[strings.Mind_Center] = "Center"
stringMap[strings.Mind_Full_Screen] = "Full screen"

stringMap[strings.Mind_Association_Node] = "Connecting line"
stringMap[strings.Mind_Version_Number] = "Version number"
stringMap[strings.Mind_Copy_Rights] = "Copyright ©2021 Shanghai Yushu Technology Co., Ltd. All rights reserved"

stringMap[strings.Global_Edit_Task] = "Edit task"
stringMap[strings.Global_Detele_Task] = "Detele Task"
stringMap[strings.Mind_Extremely_Urgent] = "Very urgent"
stringMap[strings.Mind_Urgent] = "Urgent"
stringMap[strings.Mind_Lower] = "Lower"
stringMap[strings.Mind_Task_Information] = "Task"
stringMap[strings.Mind_Start_Date] = "Start time"
stringMap[strings.Mind_End_Date] = "End time"
stringMap[strings.Mind_person_Charge] = "Executor"
stringMap[strings.Mind_colse] = "Close"
stringMap[strings.Mind_Enter_Names_Separated_By_Commas] = "Enter names, separate multiple names with commas"
stringMap[strings.Mind_Please_Select_An_Start_Date] = "Please select a start date"
stringMap[strings.Mind_Please_Select_An_End_Date] = "Please select the end date"
stringMap[strings.Mind_The_Task_Leader_Cannot_Be_Empty] = "Add executor"
stringMap[strings.Mind_The_End_Time_Less_Start_Time] = "End time cannot be less than start time"
stringMap[strings.Mind_Are_You_Sure_To_Delete_This_Task] = "Are you sure to delete this task?"
stringMap[strings.Mind_Start_Later] = "Start Later"
stringMap[strings.Mind_Over] = "Over"
stringMap[strings.Mind_In_Progress_Remaining] = "In progress... remaining"
stringMap[strings.Mind_In_Progress_Time] = "In progress... intime"
stringMap[strings.Mind_level] = "Level"
stringMap[strings.Mind_Completed] = "Completed"
stringMap[strings.Display_Mode] = "display mode"
stringMap[strings.Default_Sort] = "Default Sort"
stringMap[strings.Edit_Time] = "Edit Time"

stringMap[strings.Mind_Year] = "."
stringMap[strings.Mind_Month] = "."
stringMap[strings.Mind_Day] = " "
stringMap[strings.Mind_Day_Day] = "day"
stringMap[strings.Mind_Hours] = "hours"
stringMap[strings.Mind_Minutes] = "minutes"

stringMap[strings.Mind_SubjectLine_Space] = "Space"
stringMap[strings.Mind_SonSubjectLine_Space] = "SonSpace"
stringMap[strings.Mind_SubjectLine_H] = "Subject space H"
stringMap[strings.Mind_SubjectLine_Vertical_Space] = "Subject space V"
stringMap[strings.Mind_SonSubjectLine_H] = "Son space H"
stringMap[strings.Mind_SonSubjectLine_Vertical_Space] = "Son space V"

stringMap[strings.Current_Paging] = "Current page"
stringMap[strings.Select_Paging] = "Select page"
stringMap[strings.Batch_Delete] = "Batch delete"
stringMap[strings.New_Version_Online] = "New version Online"
stringMap[strings.Upgrade_Now] = "upgrade now"
stringMap[strings.install_Later] = "install later"

stringMap[strings.Mind_Edit_Latex] = "Edit LaTeX"
stringMap[strings.Mind_Detele_Latex] = "Delete LaTeX"
stringMap[strings.Mind_Are_You_Sure_To_Delete_This_LaTeX] = "Are you sure you want to delete the LaTeX formula"
stringMap[strings.Mind_LaTeX_Expression] = "LaTeX expression"
stringMap[strings.Mind_LaTeX_Formula_Display_Area] = "Formula display area"

stringMap[strings.Mind_LaTeX_Add_Template] = "Add to template"
stringMap[strings.Mind_LaTeX_Common_Template] = "Common Template"
stringMap[strings.Mind_LaTeX_Used_Help_1] = "LaTeX functions are divided into four modules, namely"
stringMap[strings.Mind_LaTeX_Used_Help_1_1] = "Formula area-generate corresponding formula graphics based on expressions"
stringMap[strings.Mind_LaTeX_Used_Help_1_2] = "Expression area-enter LaTeX expression"
stringMap[strings.Mind_LaTeX_Used_Help_1_3] = "Symbol area-string values ​​corresponding to symbols in common fields"
stringMap[strings.Mind_LaTeX_Used_Help_1_4] = "Commonly used templates-some default formulas, you can customize formulas through'Add to template', and you can delete the specified custom formulas by moving the mouse."
stringMap[strings.Mind_LaTeX_Used_Help_2] = "The formula graphics must be generated and saved and used under the correct LaTeX grammar rules"
stringMap[strings.Mind_LaTeX_Used_Help_3] = "How to modify the color?"
stringMap[strings.Mind_LaTeX_Used_Help_3_1] = "Select the specified text area and click the color in the style symbol to modify the text color,"
stringMap[strings.Mind_LaTeX_Used_Help_3_2] = "For example, to color a+b in x=a+b, just select a+b and click the color block in the style symbol,"
stringMap[strings.Mind_LaTeX_Used_Help_3_3] = "At this time the expression will become x={\\color{#FF0000}a} change a to a+b coloring"
stringMap[strings.Mind_LaTeX_Quick_Title_2] = "Greek letters"
stringMap[strings.Mind_LaTeX_Quick_Title_3] = "Mathematics"
stringMap[strings.Mind_LaTeX_Quick_Title_4] = "Style Symbol"
stringMap[strings.Mind_LaTeX_Quick_Title_5] = "Separator"
stringMap[strings.Mind_LaTeX_Quick_Title_6] = "variable character"
stringMap[strings.Mind_LaTeX_Quick_Title_7] = "Function"
stringMap[strings.Mind_LaTeX_Quick_Title_8] = "Relation Symbol"
stringMap[strings.Mind_LaTeX_Quick_Title_9] = "arrow symbol"
stringMap[strings.Mind_LaTeX_Quick_Title_10] = "Miscellaneous Characters"
stringMap[strings.Mind_LaTeX_Quick_Title_11] = "grave accent"
stringMap[strings.Mind_LaTeX_Error] = "latex formula error, please make sure the formula is correct"
stringMap[strings.Line_Dashed_Line] = "Line dashed line"
stringMap[strings.Border_Dashed_Line] = "Border dotted line"
stringMap[strings.Rainbow_Color] = "Rainbow color"
stringMap[strings.Mind_Beginning] = "Start"
stringMap[strings.Mind_End] = "End"
stringMap[strings.Mind_Arrow] = "arrow"
stringMap[strings.Get_Verification_Code] = "Obtain"

stringMap[strings.Mailbox_Login] = "E-mail sign in"
stringMap[strings.Forget_Email_Password] = "Forget email password"
stringMap[strings.Mind_No_Account] = "No account"
stringMap[strings.Mind_To_Register] = "To register"
stringMap[strings.Reset_Mailbox_Password] = "Reset mailbox password"
stringMap[strings.Mailbox_Registration] = "E-mail sign up"
stringMap[strings.Mind_add_Resource] = "Attachment"
stringMap[strings.Mind_Float_Explain] = "Annotation"
stringMap[strings.Account_Security] = "Account security"
stringMap[strings.Cell_Phone_Number] = "cell-phone number"
stringMap[strings.Mind_Replace] = "replace"
stringMap[strings.Mind_Unbound] = "Unbound"
stringMap[strings.Mind_Debinding] = "Debinding"
stringMap[strings.Change_Password] = "Change Password"
stringMap[strings.Mind_Not_Set] = "Not set"
stringMap[strings.Logged_Device] = "Logged in device"
stringMap[strings.Current_Equipment] = "Current equipment"
stringMap[strings.Change_Mobile_Phone_Number] = "Change mobile phone number"
stringMap[strings.Bind_Mobile_Phone_Number] = "Bind mobile phone number"
stringMap[strings.Replace_Mailbox] = "Replace mailbox"
stringMap[strings.Bind_Mailbox] = "Bind mailbox"
stringMap[strings.Bind_Mobile_Number_Or_Email_Address] = "Please bind your mobile number or email address!"

stringMap[strings.Mind_Resource_See] = "View attachment"
stringMap[strings.Mind_Resource_Delete] = "Delete Attachment"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Drag and drop files here"
stringMap[strings.Mind_Edit_choose_explain] = "Choose File"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "The uploaded file cannot exceed 20MB"
stringMap[strings.Modification_Time] = "Modification time"
stringMap[strings.Header_Mind_Tips_Saving] = "Saving"
stringMap[strings.Mind_Not_Edited] = "Not edited, please save after editing"
stringMap[strings.Mind_Expression] = "expression"
stringMap[strings.Mind_Flag] = "flag"
stringMap[strings.Mind_Portrait] = "portrait"
stringMap[strings.Mind_Learning] = "learning"
stringMap[strings.Mind_Social_Contact] = "social contact"
stringMap[strings.Mind_Symbol] = "Symbol"
stringMap[strings.Mind_Month_Icon] = "month"
stringMap[strings.Mind_Skeleton] = "Skeleton"

stringMap[strings.Mind_Confirm_Deleted] = "Are you sure you want to delete"
stringMap[strings.Mind_Folder_Has] = "The current folder has "
stringMap[strings.Mind_Sub_File] = " Sub file"
stringMap[strings.Not_Joined_Any_Group] = "You haven't joined any sharing groups yet"
stringMap[strings.Mind_Set_Password] = "Set password"
stringMap[strings.Mind_Stretching] = "stretching"
stringMap[strings.Mind_Equal_Ratio] = "Equal ratio"
stringMap[strings.Mind_Insert_Background] = "Insert background"
stringMap[strings.Mind_Size_Fit] = "Size fit"
stringMap[strings.Mind_Watermark_Settings] = "Watermark settings"
stringMap[strings.Mind_Simplification_Watermark] = "Simplification watermark"
stringMap[strings.Mind_Custom_Watermark] = "Custom watermark"
stringMap[strings.keep_Custom_Part_Style_Unchanged] = "Do you want to keep the custom part style unchanged?"
stringMap[strings.Mind_Cover] = "cover"
stringMap[strings.Mind_Keep_Custom] = "Keep custom"
stringMap[strings.Mind_Adding_Child_Nodes_Not_Allowed] = "Notes adding child nodes is not allowed"
stringMap[strings.Mind_Information_Has_Expired] = "The information has expired. Please log in again"
stringMap[strings.Mind_Fail] = "fail"
stringMap[strings.Mind_Scan_Creation] = "Scan creation"
stringMap[strings.Mind_Scan_Extraction] = "Scan extraction"
stringMap[strings.Recharge_Members_Can_Get_More_Use_Times] = "Recharge members can get more use times"
stringMap[strings.Mind_Go_Now] = "Go now"
stringMap[strings.Mind_Available_Times] = "Available times"
stringMap[strings.Mind_Edit_Title] = "title"
stringMap[strings.Supported_Scan_File_Types] = "Currently supported file types for scanning: png、. jpg、. webp"

stringMap[strings.Please_Enter_Mind_Password] = "Please enter a 4-8 digit password"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "Please input a password"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "This function is a member function"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "The file password has been removed"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "The number of password digits is not enough. Please enter the password with 4 inverted 8 digits"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "The password is too long. Please enter the password with 4 inverted 8 digits"
stringMap[strings.Please_Enter_Mind_Password_Success] = "Password set successfully"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "Password format error"
stringMap[strings.Mind_Load_Data_Error] = "Data loading error"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Password error"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Only the creator of this map can set the password"

stringMap[strings.Mind_Extremely_DrawOblique] = "Texture"
stringMap[strings.Mind_Extremely_Gradient] = "Gradient"
stringMap[strings.Mind_Extremely_Shadow] = "Text Shadow"
stringMap[strings.Create_Mind_Storage_Mode] = "Cloud Save"
stringMap[strings.Node_Shadow_Label] = "Shadow Effect"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "Card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
stringMap[strings.Global_Text_Find] = "Find"
stringMap[strings.Global_Text_Replace] = "Replace"
stringMap[strings.Global_Text_Replace_All] = "Replace All"
stringMap[strings.Global_Text_Find_Empty] = "No Content"
stringMap[strings.Global_Text_Find_Result] = "Item & of @"

stringMap[strings.Mind_Fast_Style_Rainbow_Label] = "Life"
stringMap[strings.Mind_Fast_Style_Solid_Label] = "Pure"
stringMap[strings.Mind_Import_File] = "Import File"

stringMap[strings.Mind_Add_Font_Label] = "+ Add local font:ttf、woff..."
stringMap[strings.Mind_Import_Font_Web_Tips] = "Please download the computer client from the official website to use this feature，https://mindyushu.com"
stringMap[strings.Mind_Import_Font_Upgrade_Label] = "The free trial version can only add one external font. Upgrading to Pro users can add more external fonts and all professional version features."

stringMap[strings.Mind_Flow_Chart_Map] = "Flow Chart"
stringMap[strings.Mind_Shape_Corner_Text] = "Process"
stringMap[strings.Mind_Shape_Start_Text] = "Start"
stringMap[strings.Mind_Shape_End_Text] = "End"
stringMap[strings.Mind_Shape_Determine_Text] = "Judging"
stringMap[strings.Mind_Shape_Ellipse_3D_Text] = "Database"
stringMap[strings.Mind_Shape_Cube_Text] = "Science"
stringMap[strings.Mind_Shape_Form_Text] = "Document"
stringMap[strings.Mind_Shape_Corner_Right_Text] = "Card"
stringMap[strings.Mind_Shape_Book_Text] = "Book"
stringMap[strings.Mind_Shape_Text] = "Text"

module.exports = stringMap;
/**
 * Created by tony on 2019/12/26
 */
const MindType = {

    LINE_MAP: 1,  //线性结构-包括d右侧、左侧、底部、左右布局结构

    TIME_MAP: 2,  //一条时间线结构

    AROUND_CENTER: 3, //同等权重主题围绕中心主题-包括三角形（三个主题）、巨型（四个主题）、等边五角星（五个主题）、等边六角形。。。

    RING_CENTER: 4, //环形结构，由内至外一层一层结构

    BUBBLE_MAP: 5, //气泡结构

    DOUBLE_BUBBLE_MAP: 6,//双气泡结构

    FLOW_CHART: 7,//流程图
}

export default MindType
// import mind.yushu.com.mindmap.main.home.StyleSelectedView;
import EdgeInsets from "../basemode/EdgeInsets"
import StorageManager from "../storage/StorageManager"
import UiUtil from "../../../utils/UiUtil"
import Util from "../../../utils/Util"
import TimeLineHorizontalSLayout from "../../../viewmodel/core/layout/TimeLineHorizontalSLayout";
import ImageLayoutType from "../../../viewmodel/datatype/ImageLayoutType";
import IconLayoutType from "../../../viewmodel/datatype/IconLayoutType";
import PrintSizeCell from "../basemode/PrintSizeCell";
import HashMap from "../../../viewmodel/core/base/HashMap";
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode";
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData";
import { getMachineId } from "../../../common/netWork/base";
import HttpServerConfig from "../../../common/HttpServerConfig";
import { postBZApiRequest } from "../../../common/netWork/template_api";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
/**
 * Created by tony on 2019/12/23
 */

class Config {
    constructor() {

        this.termsOfServiceUrl = "https://www.mindyushu.com/agreement.html";
        this.privacyProtocolUrl = "https://www.mindyushu.com/privacy.html";
        this.statisticsAppkey = "5f4c68ca636b2b131828d08a";
        this.isDebug = false;
        this.MindFormatVersion = 8;
        this.writeLog = false;
        this.Mind_Width = 15000;
        this.Mind_Height = 15000;
        this.NodeHorizontalSpacee = 66;//new UiUtil().dip2px(30); //右布局
        this.NodeStraightLineHorizontalSpacee = 45;
        this.NodeVerticalSpacee = 30;//new UiUtil().dip2px(10); //右布局
        this.SonNodeHorizontalSpacee = 36//new UiUtil().dip2px(15); //右布局
        this.BracketsNodeHorizontalSpacee = 40
        this.BracketsSonNodeHorizontalSpacee = 30
        this.BracketsRightSpacee = 12 //括号图间隙
        this.SonNodeVerticalSpacee = 8//new UiUtil().dip2px(4); //右布局
        this.BottomLayoutNodeHorizontalSpacee = 24;//new UiUtil().dip2px(12); //右布局
        this.BottomLayoutNodeVerticalSpacee = 36;//new UiUtil().dip2px(18); //右布局
        this.BottomLayoutSonNodeHorizontalSpacee = 18;//new UiUtil().dip2px(9); //右布局
        this.BottomLayoutSonNodeVerticalSpacee = 30;//new UiUtil().dip2px(15); //右布局
        this.GeneralizationLineWidth = 14;//new UiUtil().dip2px(16);
        this.NodeExplainFontSize = 14; //节点解释字号
        this.NodeExplainPadding = 4; //节点解释内边距
        this.NodeExplainLeftAndRightPadding = 6; //节点解释左右边距
        this.NodeExplainTop = 4; //节点解释和Node间距

        this.NodeFloatExplainSpace = 6; //节点浮动解释间距
        this.NodeFloatExplainWidth = 12; //节点浮动解释宽度
        this.NodeFloatExplainFontSize = 14; //节点浮动解释字大小

        this.GeneralizationSpacing = 10;//new UiUtil().dip2px(5);
        this.Curve_Line_2_Node_Left = 10;//new UiUtil().dip2px(33);
        this.textBoldFontWeight = 600;
        this.textNormalFontWeight = 400;
        this.textLineHeight = 1.5;      //行高是字体的倍数
        this.contentMaxWidth = 1000;  //文字的最大宽度
        this.textFontFamily = "Noto Sans SC"; //"SimSun"   // 字体
        this.showHideCircleDia = 20; //控制子节点显示隐藏按钮的直径
        this.layoutBottomCenterDistance = 30; //下布局时按钮距离中间的距离
        this.normalFontSize = 18; // 默认字体大小
        this.normalOutlineFontSize = 16; // 大纲默认字体大小
        this.latexFontSize = 16; // 公式默认字体大小
        this.emptyMindData = new MindElementData();
        this.emptyMindNode = new LineMindTypeNode();
        // this.writeLog = false;
        // this.Mind_Width = 4000;
        // this.Mind_Height = 4000;
        // this.NodeHorizontalSpacee = 30; //右布局
        // this.NodeVerticalSpacee = 10; //右布局
        // this.SonNodeHorizontalSpacee = 15; //右布局
        // this.SonNodeVerticalSpacee = 4; //右布局
        // this.BottomLayoutNodeHorizontalSpacee = 12; //右布局
        // this.BottomLayoutNodeVerticalSpacee = 18; //右布局
        // this.BottomLayoutSonNodeHorizontalSpacee = 9; //右布局
        // this.BottomLayoutSonNodeVerticalSpacee = 15; //右布局
        // this.GeneralizationLineWidth = 16;
        // this.GeneralizationSpacing = 5;
        // this.Curve_Line_2_Node_Left = 33;
        this.MindInputText_lineFragmentPadding = 0;
        this.MindOutlineStartTop = 10;
        this.MindOutlineImageSize = 24;
        this.MindOutlineRowMarkSize = 6;
        this.MindOutlineRowRightMargin = 6;
        this.MindTaskProgressHeight = 18;//new UiUtil().dip2px(8);
        this.MindTaskProgressSpace = 8;//new UiUtil().dip2px(8);
        this.MindLatexSpace = 2;//UiUtil.dip2px(2);
        this.MindTaskPriorityCellSize = 6; //UiUtil.dip2px(6);
        this.MindTaskPriorityCellSpace = 3; //UiUtil.dip2px(3);

        this.Ellipse_3D_Height = 18;
        this.Cube_3D_Height = 10;
     
        this.Node_Ring_Space = 6;
        this.MainInputUIEdgeInsets = new EdgeInsets(20, 8, 20, 9);
        this.SubjectInputUIEdgeInsets = new EdgeInsets(10, 5, 10, 6);
        this.SonSubjectInputUIEdgeInsets = new EdgeInsets(5, 2, 5, 2);
        this.GeneralizationInputUIEdgeInsets = new EdgeInsets(7, 3, 7, 4);

        this.OutlineInputUIEdgeInsets = new EdgeInsets(4, 0, 4, 0);
        this.MainOutlineInputUIEdgeInsets = new EdgeInsets(4, 10, 4, 10);
        this.GeneralizationOutlineInputUIEdgeInsets = new EdgeInsets(2, 5, 2, 5);
        this.ExplainInputUIEdgeInsets = new EdgeInsets(4, 2, 4, 2);

        this.OutlineMainTextSize = 20;
        this.OutlineTextSize = 16;
        this.OutlineGeneralizationTextSize = 12;
        this.DefaultLineSpacing = 0;
        this.OutlineLineSpacing = 10;
        this.Style_Preview_Image_W_W_Scale = 1.666;

        this.Mind_Element_Text_Padding = 2;
        
        this.isDevelopmentMode = false;
        this.CURRENT_DATABASE_VERSION = 1;
        this.isAutoShowRecentUpdates = false;
        this.CONNECT_TIMEOUT = 20000;
        this.READ_TIMEOUT = 20000;

        this.USER_NODE_LIMIT_CONUT = 50;//注册用户超出节点限制提示付费
        this.USER_NODE_LIMIT_Operation_CONUT = 10000;//注册用户超出节点限制提示付费不可操作
        this.GUEST_NODE_LIMIT_CONUT = 10;//游客超出节点限制提示注册
        this.LIMIT_Pop_Up_Times = 3;//付费弹窗只弹三次
        this.STORAGE_LIMIT_POPUP_WINDOW_TIME = 1 * 60 * 1000;//超出存储空间弹付费提示时间毫秒
        this.REGISTER_POPUP_WINDOW_TIME = 2 * 60 * 1000;//注册用户非会员，弹付费窗时间
        this.MAPS_MORE_THAN_TWO_TIME = 30 * 60 * 1000;//已登录免费用户导图数量超过2个，弹付费窗时间
        this.REGISTER_POPUP_WINDOW_FLOAT_TIME = 10 * 60 * 1000;//注册用户非会员，弹付费窗时间

        this.STYLE_PROMPT_BOX_NODE_LIMT = 12;//风格提示框节点限制数量
        
        this.Number_Imported_File_Nodes = 200;//导入word,txt文件节点数量限制
        this.Number_Imported_File_Node_Characters = 80;//导入word,txt文件节点字符限制
        this.ServerLocalTimeDifference = -2;
        this.MachineId = "";
        this.TextWritingModeVertical_LR = "writing-mode: horizontal-tb;"//"writing-mode: vertical-lr; text-orientation:upright;";
        this.TextWritingModeHorizontal_TB = "writing-mode: horizontal-tb;";
        // this.UiUtils = new UiUtil()s
        this.PrintSizes = [new PrintSizeCell("自适应", -1, -1),
                           new PrintSizeCell("A4", 210, 297), 
                           new PrintSizeCell("A5", 148, 210),
                           new PrintSizeCell("A6", 105, 144),
                           new PrintSizeCell("A0", 841, 1189),
                           new PrintSizeCell("A1", 597, 840),
                           new PrintSizeCell("A2", 420, 597),
                           new PrintSizeCell("A3", 297, 420),
                           new PrintSizeCell("8K", 270, 390),
                           new PrintSizeCell("16K",270, 195),
                           new PrintSizeCell("F4", 216, 279),
                           new PrintSizeCell("B4", 257, 364),
                           new PrintSizeCell("B5", 182, 257),
                           new PrintSizeCell("B6", 128, 257),
                           ]
        this.strokeDasharray1 = [12,3]
        this.strokeDasharray1Str = "12,3"
        this.startTime = -1
        this.TextSizeCaluleMap = new HashMap()        

        this.FontFamilys = [
            {id:-1, name:getString(strings.Mind_Add_Font_Label), class:"", file:""},
            {id:0, name:"默认字体", class:"", file:""},            
            {id:1, name:"黑体-简", class:"FangZhengHeiTiJianTi", file:"FangZhengHeiTiJianTi-1.ttf"},
            {id:2, name:"苹方-Regular", class:"苹方-Regular", file:"苹方-Regular.ttf"},
            {id:3, name:"苹方-Medium", class:"苹方-Medium", file:"苹方-Medium.ttf"},
            {id:4, name:"苹方-Bold", class:"苹方-Bold", file:"苹方-Bold.ttf"},
            {id:5, name:"思源宋体", class:"思源宋体CN-Regular", file:"思源宋体CN-Regular.otf"},
            {id:6, name:"思源柔黑", class:"思源柔黑-Regular", file:"思源柔黑-Regular.ttf"},
            {id:7, name:"猫啃什锦黑", class:"猫啃什锦黑", file:"MaoKenShiJinHei-2.ttf"},
            {id:8, name:"包图小白体", class:"包图小白体", file:"包图小白体.ttf"},
            {id:9, name:"优设标题黑", class:"优设标题黑", file:"YouSheBiaoTiHei-2.ttf"},
            {id:10, name:"站酷酷黑", class:"站酷酷黑", file:"ZhanKuKuHei-1.ttf"},
            {id:11, name:"庞门正道标题体", class:"庞门正道标题体", file:"庞门正道标题体.ttf"},
            {id:12, name:"庞门正道粗书体", class:"庞门正道粗书体", file:"庞门正道粗书体.ttf"},
            {id:13, name:"缘默体", class:"KNFontYuanmo", file:"KNFontYuanmo.otf"},
            {id:14, name:"追光体", class:"追光体", file:"douyuFont.otf"},
            {id:15, name:"濑户可爱风", class:"濑户可爱风", file:"SetoFont-1.ttf"},
            {id:16, name:"电影字幕体", class:"dianyingzimuti-2", file:"dianyingzimuti-2.ttf"}, 
            {id:17, name:"仓耳舒圆体", class:"CangErShuYuanTiW03-2", file:"CangErShuYuanTiW03-2.ttf"},
            {id:18, name:"仓耳与墨", class:"CangErYuMoW02-2", file:"CangErYuMoW02-2.ttf"},
            {id:19, name:"仓耳渔阳体", class:"CangErYuYangTiW02-2", file:"CangErYuYangTiW02-2.ttf"},
            {id:20, name:"赤薔薇", class:"ChiQiang-2", file:"ChiQiang-2.ttf"},
            {id:21, name:"创可贴金刚体", class:"ChuangKeTieJinGangTi-2", file:"ChuangKeTieJinGangTi-2.otf"},
            {id:22, name:"泡泡体", class:"AZhuPaoPaoTi-2", file:"AZhuPaoPaoTi-2.ttf"}, 
            {id:53, name:"NeverMind-Regular", class:"NeverMind-Regular", file:"NeverMind-Regular.ttf"},           
            {id:23, name:"AiDeep", class:"AiDeep", file:"AiDeep-2.otf"},
            {id:24, name:"Aclonica-Regular", class:"Aclonica-Regular", file:"Aclonica-Regular-2.ttf"},
            {id:25, name:"AlloyInk-lgdWw", class:"AlloyInk-lgdWw", file:"AlloyInk-lgdWw-2.otf"},
            {id:26, name:"Allegro", class:"Allegro-2", file:"Allegro-2.otf"},
            {id:27, name:"Ammonite", class:"Ammonite-2", file:"Ammonite-2.otf"},
            {id:28, name:"Angkasa-Regular", class:"Angkasa-Regular-2", file:"Angkasa-Regular-2.otf"},
            {id:29, name:"Aqum-Classic", class:"Aqum-2-Classic-2", file:"Aqum-2-Classic-2.otf"},
            {id:30, name:"Arciform", class:"Arciform-2", file:"Arciform-2.otf"},
            {id:31, name:"Arturito-Slab", class:"Arturito-Slab-2", file:"Arturito-Slab-2.ttf"},
            {id:32, name:"Astron", class:"Astron-2", file:"Astron-2.otf"},
            {id:33, name:"Athene", class:"Athene-2", file:"Athene-2.otf"},
            {id:34, name:"AznauriRound-Regular", class:"AznauriRoundRegular-3", file:"AznauriRoundRegular-3.ttf"},
            {id:35, name:"AznauriRound-Bold", class:"AznauriRoundBold-2", file:"AznauriRoundBold-2.ttf"},
            {id:36, name:"Bagnard", class:"Bagnard-2", file:"Bagnard-2.otf"},
            {id:37, name:"Bareona", class:"Bareona-2", file:"Bareona-2.ttf"},
            {id:38, name:"Bilthers", class:"Bilthers-2", file:"Bilthers-2.ttf"},
            {id:39, name:"Bluu-Next-Cyrillic", class:"Bluu-Next-Cyrillic-2", file:"Bluu-Next-Cyrillic-2.otf"},
            {id:40, name:"branch-zystoo-Regular", class:"branch-zystoo-Regular-2", file:"branch-zystoo-Regular-2.otf"},
            {id:41, name:"Brusher", class:"Brusher-2", file:"Brusher-2.otf"},
            {id:42, name:"Burukuduk", class:"Burukuduk-2", file:"Burukuduk-2.otf"},
            {id:43, name:"Career", class:"Career-2", file:"Career-2.ttf"},
            {id:44, name:"Cascadia-Code-Regular", class:"Cascadia-Code-Regular-2", file:"Cascadia-Code-Regular-2.ttf"},
            {id:45, name:"Concrete", class:"Concrete-2", file:"Concrete-2.ttf"},
            {id:46, name:"Cramaten", class:"Cramaten-2", file:"Cramaten-2.ttf"},
            {id:47, name:"CrimsonText-Bold", class:"CrimsonText-Bold-2", file:"CrimsonText-Bold-2.ttf"},
            {id:48, name:"CrimsonText-BoldItalic", class:"CrimsonText-BoldItalic-3", file:"CrimsonText-BoldItalic-3.ttf"},
            {id:49, name:"CrimsonText-Italic", class:"CrimsonText-Italic-4", file:"CrimsonText-Italic-4.ttf"},
            {id:50, name:"CrimsonText-Regular", class:"CrimsonText-Regular-5", file:"CrimsonText-Regular-5.ttf"},
            {id:51, name:"Cute-Aurora", class:"Cute-Aurora-2", file:"Cute-Aurora-2.ttf"},
            {id:52, name:"Disclaimer-Classic", class:"Disclaimer-Classic-2", file:"Disclaimer-Classic-2.otf"}]

        this.EditMindMapBackgroundImage = [
            {id:0, name:"微风拂面思起伏", data:[{url:"qkw_loutai_shui.gif", left:0, top:-1, right:-1, bottom:40, width: 240, heigth: 240, opacity: 1.0}]},
            {id:1, name:"思如泉涌", data:[{url:"yzs_dt_l_b.gif", left:0, top:-1, right:-1, bottom:40, width: 200, heigth: 200, opacity: 1.0}]},
            {id:2, name:"笔下生花", data:[{url:"bing_2.gif", left:0, top:-1, right:-1, bottom:40, width: 200, heigth: 200, opacity: 1.0}]},
            {id:3, name:"微风拂面思起伏", data:[{url:"qke_liushui.gif", left:0, top:-1, right:-1, bottom:40, width: 200, heigth: 200, opacity: 1.0}]},
            {id:4, name:"微风拂面思起伏", data:[{url:"qkw_kejilei.gif", left:0, top:-1, right:-1, bottom:40, width: 240, heigth: 240, opacity: 1.0}]},            
            {id:5, name:"微风拂面思起伏", data:[{url:"qkw_deng_1.gif", left:0, top:-1, right:-1, bottom:40, width: 200, heigth: 200, opacity: 1.0}]},
            {id:6, name:"微风拂面思起伏", data:[{url:"qkw_sikao.gif", left:0, top:-1, right:-1, bottom:40, width: 200, heigth: 200, opacity: 1.0}]},
            {id:7, name:"放松身心创作", data:[{url:"ls_dt_l_t_2.gif", left:0, top:66, right:-1, bottom:-1, width: 200, heigth: 200, opacity: 1.0}]},
            {id:8, name:"微风拂面思起伏", data:[{url:"qkw_hdz.gif", left:0, top:46, right:-1, bottom:-1, width: 200, heigth: 200, opacity: 1.0}]},
            {id:9, name:"微风拂面思起伏", data:[{url:"qkw_d_n_m.gif", left:0, top:66, right:-1, bottom:-1, width: 200, heigth: 200, opacity: 1.0}]},
            {id:10, name:"微风拂面思起伏", data:[{url:"qkw_chuan.gif", left:0, top:-1, right:-1, bottom:40, width: 200, heigth: 200, opacity: 1.0}, {url:"ls_dt_l_t_2.gif", left:0, top:66, right:-1, bottom:-1, width: 200, heigth: 200, opacity: 1.0}]}]
            

        
        }

    init() {
        // var mindWidth = (new Util).getScreenWidth() * 5;
        // var mindHieght = (new Util).getScreenHeight() * 5;
        // var size = Math.max(mindWidth, mindHieght);
        // if (size < 4000) {
        //     size = 4000;
        // } else if (size > 20000) {
        //     size = 20000;
        // }
        // this.Mind_Width = this.Mind_Height = size;
    }

    // setServerTime(time) {
    //     // new Date().getTime()
    //     this.ServerLocalTimeDifference = System.currentTimeMillis() - time * 1000;
    //     StorageManager.get().saveServerLocalTimeDifference(this.ServerLocalTimeDifference);
    // }

    // getServerLocalTimeDifference() {
    //     if (this.ServerLocalTimeDifference == -2) {
    //         this.ServerLocalTimeDifference = StorageManager.get().getServerLocalTimeDifference();
    //     }
    //     return this.ServerLocalTimeDifference;
    // }


    getgMyMachineId() {
        if (this.machineId == null || this.machineId.length == 0) {
            this.machineId = getMachineId()
        }
        return this.machineId;
    }

    getLineFullWidth(lineWidth) {
        let UiUtils = new UiUtil()
        let result = UiUtils.dip2px(lineWidth * 1.5);
        if (result < UiUtils.dip2px(1)) {
            result = UiUtils.dip2px(1);
        }
        return result;
    }

    getEllipse3DHeight(data, width, height) {
        if (!data.isEmpty()) {
            width = this.getMindElementContentWidth(data)
        }
        let UiUtils = new UiUtil()
        let result = width * 0.4;
        if (result > UiUtils.dip2px(60)) {
            result = UiUtils.dip2px(60);
        } else if (result < this.Ellipse_3D_Height) {
            result = this.Ellipse_3D_Height;
        }
        return result;
    }

    setTightModeEdgeInsets(tightMode) {
        if (tightMode) {
            this.MainInputUIEdgeInsets = new EdgeInsets(10, 4, 10, 5);
            this.SubjectInputUIEdgeInsets = new EdgeInsets(5, 2, 5, 3);
            this.SonSubjectInputUIEdgeInsets = new EdgeInsets(3, 1, 3, 1);
            this.GeneralizationInputUIEdgeInsets = new EdgeInsets(5, 2, 5, 3);

            this.NodeVerticalSpacee = 15;//new UiUtil().dip2px(10); //右布局
            this.SonNodeVerticalSpacee = 3//new UiUtil().dip2px(4); //右布局
        } else {
            this.MainInputUIEdgeInsets = new EdgeInsets(20, 8, 20, 9);
            this.SubjectInputUIEdgeInsets = new EdgeInsets(10, 5, 10, 6);
            this.SonSubjectInputUIEdgeInsets = new EdgeInsets(6, 1, 6, 1);
            this.GeneralizationInputUIEdgeInsets = new EdgeInsets(7, 3, 7, 4);

            this.NodeVerticalSpacee = 30;//new UiUtil().dip2px(10); //右布局
            this.SonNodeVerticalSpacee = 5//new UiUtil().dip2px(4); //右布局
        }
    }

    getCube3DHeight(data, width, height) {
        if (!data.isEmpty()) {
            width = this.getMindElementContentWidth(data)
        }
        let UiUtils = new UiUtil()
        if (width < UiUtils.dip2px(60)) {
            return this.Cube_3D_Height;
        }
        let result = width * 0.2;

        if (result > UiUtils.dip2px(60)) {
            result = UiUtils.dip2px(60);
        } else if (result < this.Cube_3D_Height) {
            result = this.Cube_3D_Height;
        }
        return result;
    }

    getFormHeight(data, width, height) {
        if (!data.isEmpty()) {
            width = this.getMindElementContentWidth(data)
        }
        let result = width / 8;

        if (result < this.Cube_3D_Height) {
            result = this.Cube_3D_Height;
        }
        return result;
    }

    getMindElementContentWidth(data) {
        var width = 0;
        if (data != null && !data.isEmpty()) {
            if (data.textContent != null) {
                width = data.textContent.width;
            }
            if (data.generalizationContent != null) {
                width = data.generalizationContent.width;
            }
            if (data.imageContent != null &&
                    (data.imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT ||
                            data.imageContent.layoutType == ImageLayoutType.LAYOUT_RIGHT)) {
                width += data.imageContent.width;
            }
            if (data.iconElementContents != null) {
                let iconCount = data.iconElementContents.length;
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        if (data.iconElementContents[index].layoutType == IconLayoutType.LAYOUT_LEFT ||
                                data.iconElementContents[index].layoutType == IconLayoutType.LAYOUT_RIGHT)
                            width += data.iconElementContents[index].width;
                    }
                }
            }
            if (data.linkElementContent != null) {
                width += data.linkElementContent.width;
            }
            if (data.remarksElementContent != null) {
                width += data.remarksElementContent.width;
            }
        }
        return width; 
    }

    registerBz() {
        if (HttpServerConfig.Channel != 55) {
            return
        }
        // this.getIP()
        var machineId = getMachineId();
        let bzObj = {tagId: 55, productId:10019, ip: machineId, type:4, money:0.0}
        postBZApiRequest(
            bzObj,
            (res) => {
                console.log("res-> ", res);                
            },
            (error) => {
                console.log("res error-> ", error);       
            }
        );
    }

    purchaseBz(money) {
        if (((typeof money) != "number") || money <= 0 || money >= 200 || HttpServerConfig.Channel != 55) {
            return
        }
        var machineId = getMachineId();
        let bzObj = {tagId: 55, productId:10019, ip: machineId, type:5, money:money}
        postBZApiRequest(
            bzObj,
            (res) => {
                console.log("res-> ", res);                
            },
            (error) => {
                console.log("res error-> ", error);       
            }
        );
    }

    // getIP() {
    //     const xhr = new XMLHttpRequest();
    //     xhr.onreadystatechange = function() {
    //         if (xhr.readyState === 4 && xhr.status === 200) {
    //             const result = JSON.parse(xhr.responseText);
    //             console.log("get result ip ", result.ip);    
    //         }
    //     };
    //     xhr.open('GET', 'http://api.ipify.org?format=json', true);
    //     xhr.send();
    // }

    activationBz() {
        if (HttpServerConfig.Channel != 55) {
            return
        }
        var machineId = getMachineId();
        let bzObj = {tagId: 55, productId:10019, ip: machineId, type:3, money:0}
        postBZApiRequest(
            bzObj,
            (res) => {
                // console.log("res-> ", res);                
            },
            (error) => {
                console.log("res error-> ", error);       
            }
        );
    }
}

export default new Config()

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/2
 * Copyright(c) 2020 mindyushu.com
 */

let  MindFileStyleType = {
    Normal: 0,
    Red: 1,
    Blue: 2,
    Green: 3,
    Gray: 4

}
export default MindFileStyleType
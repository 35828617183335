// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

// import mind.yushu.com.mindmap.utils.Colors;
import Colors from '../../../utils/Colors'
// import mind.yushu.com.mindmap.viewmodel.datatype.IconLayoutType;
import IconLayoutType from '../../datatype/IconLayoutType'

//java中同一个文件夹中不需要引用，但js需要
import MindElementContent from "./MindElementContent";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class ConnectMapElementContent extends MindElementContent {
    constructor(id = -1, uuid = "", sheetId = "", width, height, styleColor = Colors.white, thitColor = Colors.clear) {
        super(width, height);
        this.id = id;
        this.uuid = uuid;
        this.sheetId = sheetId;
        this.styleColor = styleColor;
        this.tintColor = thitColor;
        this.layoutType = IconLayoutType.LAYOUT_LEFT;
        this.ConnectMapElementContent(id, uuid, sheetId, width, height, styleColor, thitColor)
    }


    // public ConnectMapElementContent() {
    //     super();
    // }

    // public ConnectMapElementContent(long id, String uuid, int width, int height) {
    //     this(id, uuid, width, height, Colors.white, Colors.clear);
    // }

    ConnectMapElementContent(id, uuid, sheetId, width, height, styleColor, tintColor) {
        // super();
        this.id = id;
        this.uuid = uuid;
        this.sheetId = sheetId;
        this.width = width;
        this.height = height;
        this.styleColor = styleColor;
        this.tintColor = tintColor;
        this.sheetId = sheetId;
    }

    copy() {
        let data = new ConnectMapElementContent(this.id, this.uuid, this.sheetId, this.width, this.height, this.styleColor, this.tintColor);
        data.x = this.x;
        data.y = this.y;
        data.layoutType = this.layoutType;
        data.sheetId = this.sheetId;
        return data;
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.styleColor != this.styleColor;
        result = result || data.layoutType != this.layoutType;
        result = result || data.tintColor != this.tintColor;
        result = result || data.id != this.id;
        result = result || data.uuid != this.uuid;
        result = result || data.sheetId != this.sheetId;
        return !result;
    }
}
export default ConnectMapElementContent

<template>
  <main id="cell-phone-model" @click.stop="closeModelBox()">
    <section class="cell-phone-box" @click.stop="">
      <div class="model-head">
        <div class="head-title">
          {{ modelHeadTitle }}
        </div>
        <div class="close-model">
          <img
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
            @click.stop="closeModelBox()"
          />
        </div>
      </div>
      <section class="cell-phone-container" @click.stop="">
        <div class="cell-phone-numeber">
          <div class="drop-down">
            <CountryCodePicker v-model="countryCode" />
          </div>
          <!-- 手机号 -->
          <input
            type="tel"
            id="phoneNumber"
            class="input-style"
            :placeholder="
              getString(strings.User_Lolgin_Please_Enter_Phone_Number)
            "
            style="width: 100%; padding-left: 33%"
            v-model="phoneNumberVal"
            maxlength="11"
            @input="inputForValidateCellNumber"
            @focus="focusSelect($event)"
          />
        </div>
        <div class="verification-code">
          <!-- 验证码 -->
          <input
            type="text"
            id="verificationCode"
            class="input-style"
            :placeholder="
              getString(strings.Input_Please_Enter_Verification_Code)
            "
            style="width: 218px"
            v-model="verificationCodeVal"
            maxlength="6"
            @input="inputForValidateVerificationCode"
          />
          <div @click="getVerificationCode()">
            <a-button class="dra-btn" disabled v-if="showTimeData">
              {{ timeData }}
              <span>s</span>
            </a-button>
            <a-button
              class="dra-btn"
              :class="canGetVerificationCode ? 'can-use' : 'out-of-commission'"
              v-else
            >
              {{ getString(strings.Get_Verification_Code) }}
            </a-button>
          </div>
        </div>
        <a-button class="confirm-button" type="primary" @click="handleSubmit">
          {{ getString(strings.Global_Ok) }}
        </a-button>
      </section>
    </section>
  </main>
</template>

<script>
import CountryCodePicker from "../../../loginComponents/CountryCodePicker.vue";

import strings from "../../../../common/lang/strings";
import getString from "../../../../common/lang/language";
import {
  postUserCaptcha,
  postUserChangePhone,
} from "../../../../common/netWork/base_api";
import regExp from "../../../../components/loginComponents/regular.js";
const { phoneNumberRegExp, iCaptcha } = regExp;
export default {
  components: {
    CountryCodePicker, //国家区号
  },
  props: {
    showType: {
      type: String,
      defualt() {
        return "";
      },
    },
  },
  data() {
    return {
      strings,
      modelHeadTitle: "", //手机号弹框标题，bind：绑定手机号；change：更换手机号
      countryCode: 86, //国家区号
      phoneNumberVal: "", //手机号
      verificationCodeVal: "", //验证码
      canGetVerificationCode: false, //能够获取验证码，手机号验证通过
      canSubmit: false, //能够提交，验证码验证通过
      timeData: "",
      showTimeData: false,
    };
  },
  created() {
    this.setModelHeadTitle(this.showType);

  },
  methods: {
    getString(i) {
      return getString(i);
    },
    //得到焦点选中内容
    focusSelect(event) {
      event.currentTarget.select();
    },
    setModelHeadTitle(newVal) {
      if (newVal == "change") {
        this.modelHeadTitle = getString(strings.Change_Mobile_Phone_Number);
      } else if (newVal == "bind") {
        this.modelHeadTitle = getString(strings.Bind_Mobile_Phone_Number);
      }
    },
    inputForValidateCellNumber() {
      // 手机号验证
      this.phoneNumberVal = this.phoneNumberVal.replace(/[^\d]/g, ""); //只允许输入数字
      let value = this.phoneNumberVal;
      if (value) {
        if ("" + this.countryCode == "86") {
          let iphoneNumber = phoneNumberRegExp;
          if (iphoneNumber.test(value)) {
            this.setGetVerificationCode(true);
          } else {
            this.setGetVerificationCode(false);
          }
        }
      } else {
        this.setGetVerificationCode(false);
      }
    },
    setGetVerificationCode(val) {
      this.canGetVerificationCode = val;
    },
    setCanSubmit(val) {
      this.canSubmit = val;
    },
    inputForValidateVerificationCode() {
      // 验证码验证
      this.verificationCodeVal = this.verificationCodeVal.replace(/[^\d]/g, ""); //只允许输入数字
      let value = this.verificationCodeVal;
      if (value) {
        let iVerificationCode = iCaptcha;
        if (iVerificationCode.test(value)) {
          this.setCanSubmit(true);
        } else {
          this.setCanSubmit(false);
        }
      } else {
        this.setCanSubmit(false);
      }
    },
    getVerificationCode() {
      // 获取验证码
      if (!this.canGetVerificationCode) {
        this.$message.error(
          getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!"
        );
        return;
      }
      const SignInData = {
        areaCode: "+" + this.countryCode,
        phoneNumber: this.phoneNumberVal,
      };
      if (SignInData.areaCode && SignInData.phoneNumber) {
        postUserCaptcha(
          SignInData,
          (res) => {
            if (res == false) {
              this.showTimeData = res;
            } else {
              this.showTimeData = true;
              this.timeData = 60;
              let countDown = setInterval(() => {
                if (this.timeData < 1) {
                  this.timeData = 0;
                  this.showTimeData = false;
                  clearInterval(countDown);
                } else {
                  this.timeData--;
                }
              }, 1000);
            }
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      }
    },
    handleSubmit() {
      //确定
      if (!this.canGetVerificationCode) {
        this.$message.error(
          getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!"
        );
        return;
      } else if (!this.canSubmit) {
        this.$message.error(
          getString(
            strings.Input_The_Please_Enter_The_Correct_Verification_Code
          )
        );
        return;
      }
      const SignInData = {
        areaCode: "+" + this.countryCode,
        phoneNumber: this.phoneNumberVal,
        captcha: this.verificationCodeVal,
      };
      if (SignInData.areaCode && SignInData.phoneNumber && SignInData.captcha) {
        postUserChangePhone(
          SignInData,
          (res) => {
            this.$message.success(getString(strings.Message_Tips_Modification_Success));
            this.closeModelBox();
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      }
    },
    closeModelBox(){
        //关闭弹窗
        this.$emit('cellPhoneModelfun', false)
    }
  },

  watch: {
    showType(newVal) {
      this.setModelHeadTitle(newVal);
    },
  },
};
</script>

<style lang="less" scoped>
#cell-phone-model {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  .cell-phone-box {
    width: 382px;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3000;
    cursor: auto;
    .model-head {
      width: 100%;
      height: 36px;
      position: relative;
      background: #efefef;
      border-radius: 4px 4px 0 0;
      padding-left: 24px;
      display: flex;
      align-items: center;
      .head-title {
        font-size: 16px;
        color: #333333;
      }
      .close-model {
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        .close-button-img {
          width: 16px;
          cursor: pointer;
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .cell-phone-container {
      width: 100%;
      padding: 24px;
      .input-style {
        height: 40px;
        background: #efefef;
        border-radius: 4px;
        border: 0;
        outline: none;
        padding: 10px;
      }
      .cell-phone-numeber {
        width: 100%;
        position: relative;
        .drop-down {
          position: absolute;
          z-index: 5000;
          top: 0;
          left: 0;
        }
      }
      .verification-code {
        width: 100%;
        position: relative;
        display: flex;
        margin-top: 12px;
        .dra-btn {
          width: 100px;
          height: 40px;
          font-size: 14px;
          border-radius: 4px;
          margin-left: 16px;
        }
        .can-use {
          background: #fd492b;
          color: #fff;
          border: none;
        }
        .out-of-commission {
          color: #999999;
          background: #efefef;
        }
      }
      .confirm-button {
        width: 100%;
        height: 44px;
        margin-top: 30px;
        border-radius: 4px;
        font-size: 16px;
      }
    }
  }
}
</style>
const { default: strings } = require("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Разум + карта разума';
stringMap [strings.TEMPLATE] = 'Шаблон';
stringMap [strings.Mind_Edit_Main_Topic] = 'Тема ветки';
stringMap [strings.Global_Add_Free_Node_Tips] = 'Щелкните по экрану, чтобы выбрать место для добавления';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'Дважды щелкните экран, чтобы выбрать место для добавления';
stringMap [strings.Global_Add_Relationship_Tips] = 'Выберите цель, которую необходимо связать';
stringMap [strings.Global_Add_Target_Tips] = 'Выберите узел для добавления объектов';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Этот объект уже связан с другими картами';
stringMap [strings.Mind_Edit_Main_Idea] = 'Центральная тема';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'Соответствующая карта не существует';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'Приоритет';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'Прогресс';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'Звезды';
stringMap [strings.Mind_Style_Add_Icon_Week] = 'неделя';
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'Портрет';
stringMap [strings.Establish] = 'Создать';
stringMap [strings.Create_From_Template] = 'Создать из шаблона';
stringMap [strings.Common] = 'Обычный';
stringMap [strings.Function_List] = 'Список функций';
stringMap [strings.Recent_Updates] = 'Недавно обновлено';
stringMap [strings.The_Latest_To_Delete] = 'Недавно удалено';
stringMap [strings.Setting_Up] = 'Свяжитесь с нами';
stringMap [strings.Usinghelp] = 'Использование справки';
stringMap [strings.The_Download_Client] = 'Загрузить клиентскую / мобильную версию';
stringMap [strings.Global_Purchase] = 'Покупка';
stringMap [strings.Global_Login] = 'Логин';
stringMap [strings.My_map] = 'Моя карта';
stringMap [strings.Create_Map] = 'Создать карту';
stringMap [strings.Storm] = 'Шторм';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Обычный \ nCharacteristic';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'Характеристика';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'Организационный центр';
stringMap [strings.Mind_Edit_Free_Topic] = 'Бесплатная тема';
stringMap [strings.Mind_Edit_Sub_Topic] = 'Дополнительная тема';
stringMap [strings.Mind_Organization_Main_Topic] = 'Отдел';
stringMap [strings.Mind_Edit_Main_Topic] = 'Тема ветки';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'Планирование';
stringMap [strings.Mind_Time_Edit_Head] = 'Время';
stringMap [strings.Mind_Time_Edit_Title] = 'План';
stringMap [strings.Mind_Edit_Details] = 'Подробное описание';
stringMap [strings.Checking_Data] = 'Проверка данных';
stringMap [strings.Setting_Information] = 'Информация о настройке';
stringMap [strings.Sign_Out] = 'Выйти';

// список функций
stringMap [strings.Create_a_Mind_Map] = 'Создать интеллектуальную карту';
stringMap [strings.Create_Mind_Map_1] = '[1] Создайте карту с четырнадцатью макетами (двусторонняя / правая / левая / организационная структура / одиночная пузырьковая диаграмма / двойная пузырьковая диаграмма / левая древовидная карта / правая древовидная карта / двусторонняя древовидная диаграмма / таблица / горизонтальная шкала времени / вертикальная шкала времени / временная шкала S / пирамида) ';
stringMap [strings.Create_Mind_Map_2] = '[2] Шаблоны можно использовать для быстрого создания карт (большое количество ссылок на содержимое шаблона)';
stringMap [strings.Create_Mind_Map_3] = '[3] Отредактируйте карту с девятью типами стилей линий';
stringMap [strings.Create_Mind_Map_4] = '[4] Внесите девять изменений макета и правок на карту';
stringMap [strings.Create_Mind_Map_5] = '[5] Добавить дочерние узлы к целевому узлу и добавить узлы на том же уровне (верхний и нижний)';
stringMap [strings.Create_Mind_Map_6] = '[6] Сводка: добавьте [Сводка] для описания одного узла / нескольких узлов ';
stringMap [strings.Create_Mind_Map_7] = '[7] Блок: добавить [блок] в блоки с одним узлом / несколькими узлами карты, которая должна быть модульной ';
stringMap [strings.Create_Mind_Map_8] = '[8] Добавить свободный узел';
stringMap [strings.Create_Mind_Map_9] = '[9] Создать ассоциацию четырех стилей линий между любыми узлами';
stringMap [strings.Create_Mind_Map_10] = '[10] Вставить изображения (локальная + библиотека материалов), значки, заметки, ссылки на узел';
stringMap [strings.Create_Mind_Map_11] = '[11] Копировать / вставить узел, скопировать / вставить стиль';
stringMap [strings.Create_Mind_Map_12] = '[12] Отменить, восстановить и отменить';
stringMap [strings.Create_Mind_Map_13] = '[13] Доступно несколько вариантов выбора, несколько узлов могут работать одновременно ';
stringMap [strings.Create_Mind_Map_14] = '[14] Статистический график можно вставить';
stringMap [strings.Create_Mind_Map_15] = '[15] Можно записать всю карту';
stringMap [strings.Create_Mind_Map_16] = '[16] Копировать карту';
stringMap [strings.Create_Mind_Map_17] = '[17] Вы можете скопировать карту или всю карту на карту других файлов';
stringMap [strings.Create_Mind_Map_18] = '[18] Карта может быть создана после сводки';
stringMap [strings.Create_Mind_Map_19] = '[19] Редактирование карты можно переключить во время редактирования (на стороне компьютера)';
stringMap [strings.Create_Mind_Map_20] = '[20] Карта редактирования клавиатуры Bluetooth: \ n \ t [Command] + [a] Выберите главный узел \ n \ t [Command] + [←] [↑] [→] [↓ ] Изменить цель выбора или переместить карту \ n \ t [Команда] + [e] Изменить цель \ n \ t [Команда] + [w] Создать дочерний узел \ n \ t [Команда] + [s] Создать целевой одноуровневый узел ( Вверху) \ n \ t [Command] + [d] Создать целевой родственный узел (внизу) \ n \ t [Command] + [q] Выйти из выбранной цели \ n \ t [Command] + [-] [+] Zoom карта';

stringMap [strings.Beautify_The_Mind_Map] = 'Украсить интеллектуальную карту';
stringMap [strings.Beautify_The_Mind_Map_1] = '[1] Установить цвет фона интеллект-карты';
stringMap [strings.Beautify_The_Mind_Map_2] = '[2] Установите толщину и цвет всей линии карты, независимо от того, сужается она или нет ';
stringMap [strings.Beautify_The_Mind_Map_3] = '[3] Установить цвет заливки текста, цвет границы, формы границы 13, ширину границы, эффект тени';
stringMap [strings.Beautify_The_Mind_Map_4] = '[4] Установить полностью или частично цвет линии и ширину карты';
stringMap [strings.Beautify_The_Mind_Map_5] = '[5] Установить размер шрифта текста, полужирный, курсив, удалить стиль';
stringMap [strings.Beautify_The_Mind_Map_6] = '[6] Установить размер, положение и стиль формы изображения';
stringMap [strings.Beautify_The_Mind_Map_7] = '[7] Добавьте значки семи категорий (обычные / 3D / учеба / работа / человек / животное / еда) и установите размер / местоположение';
stringMap [strings.Beautify_The_Mind_Map_8] = '[8] Добавить ссылку стиля одним щелчком на всю карту';
stringMap [strings.Beautify_The_Mind_Map_9] = '[9] Карта управления группой';
stringMap [strings.Beautify_The_Mind_Map_10] = '[10] Персонализированный фон (библиотека материалов) может быть добавлен ко всей карте';
stringMap [strings.Beautify_The_Mind_Map_9] = '[11] Отдельные границы или фон (библиотека материалов) могут быть добавлены к одному узлу';
stringMap [strings.Beautify_The_Mind_Map_9] = '[12] Длину узла можно перетаскивать';


stringMap [strings.Map_Output] = 'Вывод карты';
stringMap [strings.Map_Output_1] = '[1] Сохранить картинку';
stringMap [strings.Map_Output_2] = '[2] Сохранить PDF';
stringMap [strings.Map_Output_3] = '[3] Поделиться ссылкой';
stringMap [strings.Map_Output_4] = '[4] Поделиться с бурей';
stringMap [strings.Map_Output_4] = '[5] Печать';

stringMap [strings.Work_toght] = 'Работайте вместе';
stringMap [strings.Work_together_1] = '[1] Создать общую группу (метка группы, групповое объявление)';
stringMap [strings.Work_together_2] = '[2] Новые общие члены группы';
stringMap [strings.Work_together_3] = '【3】 Несколько членов в группе вместе редактируют одну и ту же карту';
stringMap [strings.Work_together_4] = '[4] Загрузить общие файлы внутри группы (word / excle / pdf)';
stringMap [strings.Work_together_5] = '[5] Вы можете оставить сообщение в группе';
stringMap [strings.Work_together_6] = '[6] Настройки полномочий управления общей группой';
stringMap [strings.Work_together_7] = '[7] Расформировать группу';

stringMap [strings.Work_toght] = 'Совместное творчество (буря)';
stringMap [strings.Community_Sharing_1] = '[1] Вы можете поделиться своим творением со штормом, чтобы больше людей могли его увидеть';
stringMap [strings.Community_Sharing_1] = '[2] Вы можете выбрать теги для просмотра связанного содержимого шторма';
stringMap [strings.Community_Sharing_1] = '[3] Вы можете лайкать и комментировать работы шторма';

//Недавно обновленный
stringMap [strings.Recently_Updated_Title_1] = 'Создать личную учетную запись';
stringMap [strings.Recently_Updated_Title_2] = 'Обширная база знаний по шаблонам';
stringMap [strings.Recently_Updated_Title_3] = 'Поддержка нескольких платформ';
stringMap [strings.Recently_Updated_Title_4] = 'Настроить каждую деталь';
stringMap [strings.Recently_Updated_Title_5] = 'Персональная библиотека стилей';
stringMap [strings.Recently_Updated_Title_6] = 'Вывод карты';
stringMap [strings.Recently_Updated_Title_7] = 'Облачное хранилище';
stringMap [strings.Recently_Updated_Title_8] = 'Пятнадцать типов карт';


stringMap [strings.Recently_Updated_Content_1] = 'Создайте личную учетную запись и сохраните созданную карту вместе с учетной записью';
stringMap [strings.Recently_Updated_Content_2] = 'Большое количество баз знаний шаблонов напрямую цитируется для повышения эффективности обучения';
stringMap [strings.Recently_Updated_Content_3] = 'Поддержка онлайн-производства веб-версии, мобильного терминала IOS, терминала Mac, мобильного терминала Android, многоплатформенного использования планшета Android';
stringMap [strings.Recently_Updated_Content_4] = 'Вы можете украсить дизайн текста, фона, линий, значков изображений и т. д. в соответствии с личными предпочтениями';
stringMap [strings.Recently_Updated_Content_5] = 'Библиотека личных стилей напрямую цитируется, что устраняет проблему сопоставления цветов';
stringMap [strings.Recently_Updated_Content_6] = 'Вы можете сохранять изображения в формате PDF и делиться ими напрямую с друзьями';
stringMap [strings.Recently_Updated_Content_7] = 'Сильная поддержка облачного хранилища, больше не нужно беспокоиться о потере данных';
stringMap [strings.Recently_Updated_Content_8] = 'Двусторонняя, правая, левая, организационная структура, одиночная пузырьковая диаграмма, двойная пузырьковая диаграмма, левая древовидная диаграмма, правая древовидная диаграмма, двусторонняя древовидная диаграмма, таблица, горизонтальная временная шкала, вертикальная временная шкала, S временная шкала, восемь раскладок могут быть изменены по желанию ';

// Недавно удалено
stringMap [strings.Mind_Mindmap_Deleted_Title] = 'Недавно удалено';
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = 'Восстановление';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'Удалить';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'Имя файла';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'После удаления здесь он будет удален навсегда';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'Операция';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = 'Искать недавно удаленную карту';
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = 'Удалить дату';

//свяжитесь с нами
stringMap [strings.Contact_Us_No_Public] = 'Публичная учетная запись';
stringMap [strings.Contact_Us_QQ_Group] = 'Группа QQ';
stringMap [strings.Contact_Us_Message_Board] = 'Доска сообщений';
stringMap [strings.Contact_Us_Username] = 'Имя';
stringMap [strings.Contact_Us_Email] = 'Электронная почта';
stringMap [strings.Contact_Us_Voicemail] = 'Пожалуйста, оставьте свои ценные комментарии';
stringMap [strings.Contact_Us_Send_Email] = 'Отправить электронное письмо';

// проверяем сообщение
stringMap [strings.Input_Username_isNull] = 'Имя не может быть пустым';
stringMap [strings.Input_Email_isNull] = 'Пожалуйста, введите правильный адрес электронной почты';
stringMap [strings.Input_Voicemail_isNull] = 'Пожалуйста, введите отправленное содержимое';
stringMap [strings.Input_Introduction_isNull] = 'Введение не может быть пустым';
stringMap [strings.Input_Title_isNull] = 'Заголовок не может быть пустым';

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'Пожалуйста, введите правильный номер телефона';
stringMap [strings.Input_Please_Enter_Verification_Code] = 'Пожалуйста, введите проверочный код! ';
stringMap [strings.Input_Please_Enter_The_Password] = 'Пожалуйста, введите пароль! ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = 'Неверный формат пароля! ';
stringMap [strings.Input_Please_Enter_A_New_Password] = 'Пожалуйста, введите новый пароль! ';
stringMap [strings.Input_Confirm_The_New_Password] = 'Пожалуйста, введите пароль подтверждения! ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = 'Пароли несовместимы! ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = 'Пожалуйста, введите правильный код подтверждения! ';
stringMap [strings.Input_The_Verification_Code_Error] = 'Ошибка кода подтверждения! ';

// Использование справки
stringMap [strings.Mind_Usinghelp_1] = 'Создать карту разума';
stringMap [strings.Mind_Usinghelp_2] = 'Как добавить узел';
stringMap [strings.Mind_Usinghelp_3] = 'Создать свободный узел';
stringMap [strings.Mind_Usinghelp_4] = 'Установить связь';
stringMap [strings.Mind_Usinghelp_5] = 'украсить холст';
stringMap [strings.Mind_Usinghelp_6] = 'украсить текст';
stringMap [strings.Mind_Usinghelp_7] = 'Вставить примечания, ссылки';
stringMap [strings.Mind_Usinghelp_8] = 'Вставить значок';
stringMap [strings.Mind_Usinghelp_9] = 'Вставить картинку';
stringMap [strings.Mind_Usinghelp_10] = 'Как восстановить удаление';
stringMap [strings.Mind_Usinghelp_11] = 'Экспортировать интеллектуальную карту';
stringMap [strings.Mind_Usinghelp_12] = 'Повышение членства';
stringMap [strings.Mind_Usinghelp_13] = 'Сочетание клавиш';
stringMap [strings.Mind_Usinghelp_Understand] = 'Я понял';
stringMap [strings.Mind_Usinghelp_14] = 'Вставить библиотеку материалов';
// Используйте help-2
stringMap [strings.Mind_Usinghelp_15] = 'Новая карта';
// stringMap [strings.Mind_Usinghelp_2] = 'Добавить узел';
// stringMap [strings.Mind_Usinghelp_4] = 'Установить связь';
// stringMap [strings.Mind_Usinghelp_5] = 'Украсить холст';
// stringMap [strings.Mind_Usinghelp_6] = 'Украсить текст';
// stringMap [strings.Mind_Usinghelp_7] = 'Добавить примечания, ссылки';
stringMap [strings.Mind_Usinghelp_17] = 'Отменить операцию, отменить';
stringMap [strings.Mind_Usinghelp_18] = 'Перетащите узел для обмена';
stringMap [strings.Mind_Usinghelp_19] = 'Выравнивание узла';
stringMap [strings.Mind_Usinghelp_20] = 'Изменить тип карты';
stringMap [strings.Mind_Usinghelp_21] = 'Стиль линии карты';
stringMap [strings.Mind_Usinghelp_22] = 'Цвет фона карты';
stringMap [strings.Mind_Usinghelp_23] = 'Установить форму узла';
stringMap [strings.Mind_Usinghelp_24] = 'Стиль линии узла';
stringMap [strings.Mind_Usinghelp_25] = 'Настройки изображения узла';
stringMap [strings.Mind_Usinghelp_26] ='Настройки значка узла';
stringMap [strings.Mind_Usinghelp_27] = 'Сводка добавления узла';
stringMap [strings.Mind_Usinghelp_28] = 'Выбор блока одного узла';
stringMap [strings.Mind_Usinghelp_29] = 'Несколько узлов в блоки';
stringMap [strings.Mind_Usinghelp_30] = 'Граница добавления узла';
stringMap [strings.Mind_Usinghelp_31] = 'Добавить статистическую карту';
stringMap [strings.Mind_Usinghelp_32] = 'Скрыть узел и восстановить';
stringMap [strings.Mind_Usinghelp_33] = 'Узел множественных операций';
stringMap [strings.Mind_Usinghelp_34] = 'Изменить стиль';
stringMap [strings.Mind_Usinghelp_35] = 'Вставить голос и объяснение';
stringMap [strings.Mind_Usinghelp_36] = 'Направить карту в картинку';
stringMap [strings.Mind_Usinghelp_37] = 'Сопоставить документ PDF';
stringMap [strings.Mind_Usinghelp_38] = 'Распечатать карту';
stringMap [strings.Mind_Usinghelp_39] = 'Обмен ссылками и просмотр';
stringMap [strings.Mind_Usinghelp_40] = 'Карта для публикации в шторм';
stringMap [strings.Mind_Usinghelp_41] = 'Использование папки';
stringMap [strings.Mind_Usinghelp_42] = 'Удалить и восстановить карту';
// Используем справку 3
stringMap [strings.Mind_Usinghelp_43] = 'Друзья подают заявку на присоединение к группе';
stringMap [strings.Mind_Usinghelp_44] = 'Как распустить группу';
stringMap [strings.Mind_Usinghelp_45] = 'Загрузить информацию о файле';
stringMap [strings.Mind_Usinghelp_46] = 'Согласен присоединиться к приложению';
stringMap [strings.Mind_Usinghelp_47] = 'Совместное редактирование';
stringMap [strings.Mind_Usinghelp_48] = 'Новая группа общего доступа';
stringMap [strings.Mind_Usinghelp_49] = 'Пригласить участников группы';

//гроза
stringMap [strings.Mind_Storm_Recommend] = 'Рекомендация';
stringMap [strings.Mind_Storm_Boilingpoint] = 'Точка кипения';
stringMap [strings.Mind_Storm_Unfold] = 'Развернуть';
stringMap [strings.Mind_Storm_Putaway] = 'Приемка на склад';
stringMap [strings.Mind_Storm_Myclassification] = 'Моя классификация';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'Рекомендуемая классификация';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'Щелкните, чтобы добавить категорию';
stringMap [strings.Mind_Storm_No_Classification] = 'Еще нет классификации';
stringMap [strings.Mind_Storm_No_Tintehibited] = 'Не интересно';
stringMap [strings.Mind_Storm_Report] = 'Отчет';
stringMap [strings.Mind_Storm_Views] = 'Просмотры';

stringMap [strings.Mind_Storm_Pornographic] = 'Вульгарная порнография';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'Рекламное преследование';
stringMap [strings.Mind_Storm_Political_Religion] = 'Политика и религия';
stringMap [strings.Mind_Storm_Infringement] = 'Нарушение прав (портрет, клевета, плагиат, мошенническое использование)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'Запрещенный контент';
stringMap [strings.Mind_Storm_Spread_Rumors] = 'Распространять слухи и распространять слухи';
stringMap [strings.Mind_Storm_Report_Successful] = 'Отчет об успешном';

//редактировать
stringMap [strings.Global_Edit] = 'Редактировать';
stringMap [strings.Global_Cancel] = 'Отменить';
stringMap [strings.Global_Ok] = 'ОК';
stringMap [strings.Global_Name] = 'Псевдоним';
stringMap [strings.Global_Introduction] = 'Введение';
stringMap [strings.Global_Change_Avatar] = 'Сменить аватар';
stringMap [strings.Global_Local_Upload] = 'Локальная загрузка';

//персональная информация
stringMap [strings.User_Personal_information] = 'Личная информация';
stringMap [strings.User_Member_Validity_Period] = 'Срок действия участника';
stringMap [strings.User_My_Share] = 'Моя доля';
stringMap [strings.User_My_Collection] = 'Моя коллекция';
stringMap [strings.User_More_Message] = 'Еще';
stringMap [strings.User_Deleted_Sotrm] = 'Удалить шторм';
stringMap [strings.User_Folder_Rename] = 'Переименовать';
stringMap [strings.User_Folder_Rename_Root] = 'Изменить разрешения';
stringMap [strings.User_Folder_Rename_Root_Title] = 'Изменить разрешения';
stringMap [strings.User_Folder_Root_Public] = 'Полностью общедоступный';
stringMap [strings.User_Folder_Root_Viewonly] = 'Общую папку разрешен только для просмотра';
stringMap [strings.User_Folder_Root_Private] = 'Частный';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'Другие люди могут просматривать ваши избранные на странице вашего профиля';
stringMap [strings.User_Message_Link] = 'Ссылка';
stringMap [strings.User_New_Group] = 'Новая группа';
stringMap [strings.User_New_Group_Title] = 'Создать новую группу';
stringMap [strings.User_New_Group_Style_Title] = 'Тип файла';
stringMap [strings.User_Share_Is_Null] = 'Еще нет доступа! ';
stringMap [strings.User_Deleted_Storm_Tips] = 'Вы уверены, что хотите удалить этот шторм? ';
stringMap [strings.User_Remove_From_List] = 'Удалить из списка',
    stringMap [strings.User_Move_To] = 'Переместить в',
    stringMap [strings.User_Deleted_Link] = 'Удалить ссылку'
stringMap [strings.User_Collction_Is_Null] = 'Еще нет коллекции'
stringMap [strings.User_Collection] = 'Коллекция'

// Поле входа
stringMap [strings.User_Lolgin_Welcome_To_Login] = 'Добро пожаловать в Mind +'
stringMap [strings.User_Lolgin_Free_Registration] = 'Бесплатная регистрация'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'Пожалуйста, введите свой номер телефона'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'Пожалуйста, введите проверочный код'
stringMap [strings.User_Lolgin_Obtain] = 'Получить'
stringMap [strings.User_Lolgin_Password_Login] = 'Пароль для входа'
stringMap [strings.User_Lolgin_Login] = 'Войти'
stringMap [strings.User_Lolgin_Register_An_Account] = 'Регистрация учетной записи'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'Уже есть учетная запись'
stringMap [strings.User_Lolgin_To_Log_In] = 'Перейти к авторизации'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'Пожалуйста, введите пароль'
stringMap [strings.User_Lolgin_Confirm_Password] = 'Подтвердите пароль'
stringMap [strings.User_Lolgin_Registered] = 'Зарегистрировано'
stringMap [strings.User_Lolgin_Account_Login] = 'Вход в учетную запись'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'Сканировать код входа в систему'
stringMap [strings.User_Lolgin_Forgot_Password] = 'Забыли пароль'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'Код подтверждения входа в систему'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Открытый разум + сканирование в верхней части интерфейса настроек'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'Срок действия этого QR-кода истекает через пять минут, щелкните, когда он истечет'
stringMap [strings.User_Lolgin_Refresh] = 'Обновить'
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'Сканирование прошло успешно, подтвердите на своем телефоне'
stringMap [strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'Следует ли авторизовать вход в систему'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'Пожалуйста, введите новый пароль'
stringMap [strings.User_Lolgin_Reset] = 'Сбросить'

// Моя карта
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'Тип шаблона поиска или шаблон'
stringMap [strings.My_Mind_Date] = 'Дата'
stringMap [strings.My_Mind_Create_Folder] = 'Создать папку'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'Пожалуйста, введите имя папки'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'Пожалуйста, введите новое имя папки'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'Карты еще нет, иди быстро'
stringMap [strings.My_Mind_Create] = 'Создать'
stringMap [strings.My_Mind_Right] = 'Бар!'
stringMap [strings.My_Mind_Open] = 'Открыть'
stringMap [strings.My_Mind_Type_Left_Right] = 'Двойная путевая карта'
stringMap [strings.My_Mind_Type_Unateral] = 'Односторонняя карта'
stringMap [strings.My_Mind_Type_Bottom] = 'Организационная диаграмма'
stringMap [strings.My_Mind_Type_Bubble] = 'Пузырьковая диаграмма'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'Карта расписания'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'Пустая карта разума'
stringMap [strings.My_Mind_All] = 'Все'
stringMap [strings.My_Mind_Views] = 'Просмотры:'

// Поиск бури
stringMap [strings.Storm_Search_How_Title] = 'Популярные теги'
stringMap [strings.Storm_History_Search] = 'Поиск по истории'

// Публичный заголовок
stringMap [strings.Public_Header_Mind] = 'Карта'
stringMap [strings.Public_Header_Outline] = 'Структура'
stringMap [strings.Public_Header_Download_Apps] = 'Загрузить клиент'
stringMap [strings.Public_Header_Mobile_Version] = 'Мобильная версия'
stringMap [strings.Public_Header_Creator] = 'Создатель:'
stringMap [strings.Public_Header_View_Frequency] = 'Просмотры:'
stringMap [strings.Public_Header_Save] = 'Сохранить'
stringMap [strings.Header_Mind_Tips_Saved] = 'Сохранено'
stringMap [strings.Header_Mind_Tips_Edit] = 'Отредактировано'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'Обновление сохранено'

// Меню редактирования карты
stringMap [strings.Mind_Edit_Revoke] = 'Отозвать'
stringMap [strings.Mind_Edit_Restore] = 'Восстановить'
stringMap [strings.Mind_Edit_Subtopic] = 'Подтема'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'Первая подтема'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'Нижняя подтема'
stringMap [strings.Mind_Edit_Free_Node] = 'Свободный узел'
stringMap [strings.Mind_Edit_Summary] = 'Сводка'
stringMap [strings.Mind_Edit_Block] = 'В блок'
stringMap [strings.Mind_Edit_Insert] = 'Вставить'
stringMap [strings.Mind_Edit_Related] = 'Связанный'
stringMap [strings.Mind_Edit_Style] = 'Стиль'
stringMap [strings.Mind_Edit_Recording] = 'Запись'
stringMap [strings.Mind_Edit_Insert_Picture] = 'Вставить картинку'
stringMap [strings.Mind_Edit_Insert_Icon] = 'Вставить значок'
stringMap [strings.Mind_Edit_Insert_Remarks] = 'Вставить примечания'
stringMap [strings.Mind_Edit_Insert_Link] = 'Вставить ссылку'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'Вставить статистический график'
stringMap [strings.Mind_Edit_Upload] = 'Загрузить'
stringMap [strings.Mind_Edit_Right_Icon] = 'Значок'
stringMap [strings.Mind_Edit_Right_Style] = 'Стиль'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'Декодирование ...'
stringMap [strings.Mind_Edit_Audio_Duration] = 'Продолжительность'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = 'Вы действительно хотите удалить записанный звук? '
stringMap [strings.Mind_Edit_Join_Membership] = 'Открытое членство'
stringMap [strings.Mind_Edit_Upgrade] = 'Обновить'
stringMap [strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = 'У участников может быть больше времени для записи, теперь перейдите к обновлению до членства? '

stringMap [strings.Mind_Edit_Choose_Material] = 'Выбрать материал'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'Перетащите сюда картинки'
stringMap [strings.Mind_Edit_Select_locally] = 'Выбрать из локального'
stringMap [strings.Mind_Edit_Choose_From] = 'Выбрать из библиотеки материалов'
stringMap [strings.Mind_Edit_Mine] = 'Моя'
stringMap [strings.Mind_Edit_My_Material] = 'Мой материал'
stringMap [strings.Mind_Edit_Recently_Used] = 'Недавно использовалось'
stringMap [strings.Mind_Edit_Use] = 'Использовать'
stringMap [strings.Mind_Edit_No_Material_Tempoporary_Go_Add_It] = 'В данный момент материала нет, идите и добавьте его! '
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'Выбрать тип диаграммы'
stringMap [strings.Mind_Edit_Remarks] = 'Замечания'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'Пожалуйста, введите примечания'
stringMap [strings.Mind_Edit_Add] = 'Добавить'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'Пожалуйста, введите ссылку'

// Редактируем меню в левой части страницы
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'Сохранить изображение'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = 'Экспорт PDF'
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'Поделиться ссылками'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'Поделиться с бурей'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'Печать'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'Разблокировать полную версию'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'Справка'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'Сохранить формат'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'формат png'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'формат jpg'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'формат webp'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'Поделиться картой'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'Переключатель общего доступа'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'Вы можете скопировать ссылку и отправить ее'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'Копировать ссылку'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'Если вы хотите изменить ссылку, нажмите'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'Регенерировать'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'Пароль ссылки:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ='Позволит сэкономить'
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Максимальное количество просмотров'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'Бесконечный'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'Предел'
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'Второй'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'Срок действия ссылки'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = '1 день'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = '3 дня'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = '7 дней'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'Постоянный'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'Копировать контент и ссылку'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = 'Вы уверены, что хотите регенерировать? Предыдущая ссылка будет недействительной! '
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'Поделиться'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'Пользовательская категория:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'Разрешить ли комментарии'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'Изменить, чтобы добавить категорию'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'Права членства'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'Платформа поддержки'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'Открытое членство'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'Обмен кодами активации'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = 'Преимущества членства после покупки'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'Выберите способ оплаты'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'Цена активности'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'Фактически уплаченная сумма'
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Немедленное продление'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《Условия использования》'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《Политика конфиденциальности》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'Код активации:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Пожалуйста, введите код активации'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'Проверить код активации'
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'Выбрать категории'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'Основной заголовок не может быть пустым'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'Купон:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Пожалуйста, введите код купона'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'Подтвердите использование купонов'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'Проверка'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'Льготная сумма'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'Платить немедленно'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Подтвердить обмен информацией'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'Код обмена:'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Обмен'

// Редактируем меню в правой части страницы
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'Холст'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'Цвет фона'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'Макет'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'Стиль линии'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'Цвет линии'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'Ширина линии'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = 'Линии сужаются'
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'Шрифт темы'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'Детский шрифт'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'Цвет текста'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'Размер шрифта'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'Стиль шрифта'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'Цвет границы'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'Цвет заливки'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'Форма границы'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'Ширина границы'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Показать эффект тени (функция подписки)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'Цвет линии'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'Пунктирная линия'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'Размер изображения'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'Местоположение изображения'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'Форма изображения'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'Размер значка'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'Положение значка'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'Общие'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'Приоритет'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'Прогресс'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'Звезды'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'неделя'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Портрет'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'Обучение'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'Работа'
stringMap [strings.Mind_Edit_Right_Menu_Characters] = 'Персонажи'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'Животные'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'Еда'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'Копировать стиль'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'Стиль прилипания'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'Изменить форму'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'Просмотреть изображение'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'Удалить изображение'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'Копировать'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'Вставить'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'Удалить строку'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Удалить'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'Редактировать примечания'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'Открыть ссылку'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'Удалить примечания'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'Удалить ссылку'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'Удалить значок'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'Стиль значка'

// Панель редактирования статистического графика
stringMap [strings.Echart_Edit_Statistical] = 'Статистика'
stringMap [strings.Echart_Edit_Value] = 'Значение'
stringMap [strings.Echart_Edit_Color] = 'Цвет'
stringMap [strings.Echart_Edit_Title] = 'Заголовок'
stringMap [strings.Echart_Edit_Detailed_Description] = 'Подробное описание'
stringMap [strings.Echart_Edit_Value_Sort] = 'Сортировать'
stringMap [strings.Echart_Edit_Value_Display_Type] = 'Тип отображения'
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'Цветовая схема'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'Размер отображения'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'Размер заголовка'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'Объясните размер'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'Подписаться'

// Вкладка Storm
stringMap [strings.StormBookTag_Subscribed] = 'Подписка'
stringMap [strings.StormBookTag_List] = 'Список'

// Страница обмена штормом
stringMap [strings.Storm_Share_Comment] = 'Комментарий'
stringMap [strings.Storm_Share_Reply] = 'Ответить'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'Позвольте мне также прокомментировать это ~'
stringMap [strings.Storm_Share_Release] = 'Выпуск'
stringMap [strings.Storm_Share_Main_Title] = 'Основной заголовок'
stringMap [strings.Storm_Share_Subtitle] = 'Субтитры'
stringMap [strings.Storm_Share_Author] = 'Автор'
stringMap [strings.Storm_Share_Replies] = 'Ответить'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'Пока нет комментариев. Будьте первым, кто будет первым.'
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'Пожалуйста, введите комментарий ~'
stringMap [strings.Storm_Share_Delete_Comment_Tips] = 'Вы уверены, что хотите удалить этот комментарий? '

// Справка по внутреннему использованию карты
stringMap [strings.Mind_Usinghelp_Btn_1] = 'Горячие клавиши (окна)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'Сочетание клавиш (mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'Описание'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Команда] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'Добавить дочерний узел'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[Удалить]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'Удалить текущий узел (обратите внимание, что его нельзя удалить, если он находится в состоянии редактирования)'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Команда] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'Изменить текущий узел'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Команда] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Команда] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'Увеличить карту'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Команда] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'Следите за картой'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Команда] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Команда] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Команда] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Команда] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'Выйти из редактирования'

// Текст подсказки
stringMap [strings.Message_Tips_Login_Success] = 'Успешный вход в систему'
stringMap [strings.Message_Tips_Dlt_Success] = 'Удалить успешно'
stringMap [strings.Message_Tips_Report_Success] = 'Отчет об успешном'
stringMap [strings.Message_Tips_Copy_Success] = 'Копирование выполнено успешно'
stringMap [strings.Message_Tips_Collction_Success] = 'Сбор успешен'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'Успешное изменение аватара'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'Успешное изменение подписи'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'псевдоним успешно изменен'
stringMap [strings.Message_Tips_Modification_Success] = 'Изменение выполнено успешно'
stringMap [strings.Message_Tips_New_Profile_Success] = 'Успешная новая папка'
stringMap [strings.Message_Tips_Mobile_Success] = 'Успешно на мобильных устройствах'
stringMap [strings.Message_Tips_Submit_Success] = 'Отправить успешно'
stringMap [strings.Message_Tips_Exchange_Success] = 'Обмен успешен'
stringMap [strings.Message_Tips_Please_Code] = 'Пожалуйста, введите код активации'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'Пожалуйста, примите политику конфиденциальности'
stringMap [strings.Message_Tips_Please_Sign_In] = 'Пожалуйста, войдите'
stringMap [strings.Message_Tips_Share_Success] = 'Поделиться успешно'
stringMap [strings.Message_Tips_Create_Success] = 'Успешно создано'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'Имя папки не может быть пустым'
stringMap [strings.Message_Tips_Selecte_Success] = 'Поиск завершен'
stringMap [strings.Message_Tips_Subscription_Success] = 'Подписка успешна'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'Отказаться от подписки успешно'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'Связанный контент не найден'
stringMap [strings.Message_Tips_Max_Add_Class] = 'Можно только добавить не более'
stringMap [strings.Message_Tips_Registered_Success] = 'Зарегистрировано успешно'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'Ошибка входа при сканировании кода'
stringMap [strings.Message_Tips_Share_Is_Null] = 'Ссылка для общего доступа не существует'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = 'Загруженный файл не может превышать 3 МБ'
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'Успешно получить разрешение'
stringMap [strings.Message_Tips_Membership_Required] = 'Требуется членство'
stringMap [strings.Message_Tips_Save_Success] = 'Успешное сохранение'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = 'Успешно сканировать код для входа в систему'
stringMap [strings.Message_Tips_Style] = 'Категория?'

// Режим контура
stringMap [strings.Outline_Menu_Indent] = 'Отступ'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'Уменьшить отступ'

// Подсказка помощи
stringMap [strings.Help_Background_Content] = 'Связано с фоном строки, связано с фоном строки, связано с фоном строки, связано с фоном строки, связано с фоном строки, с связано с фоном строки, связано с фоном строки, связано с фоном строки, связано с фоном строки,'
stringMap [strings.Help_Mind_Content] = 'Карты с различными макетами могут быть созданы в соответствии с предпочтениями: двойная направляющая карта, левая карта, правая карта, организационная диаграмма, двустороннее дерево, левое дерево, правое дерево, таблица'
stringMap [strings.Help_Share_Link_Content] = 'Ссылки для обмена можно делиться в WeChat, QQ, Weibo и других каналах в виде ссылок, а друзья могут просматривать контент '
stringMap [strings.Using_Templates] = 'Использование шаблонов'

//Код приглашения
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'Пожалуйста, введите код приглашения'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Пожалуйста, введите правильный код приглашения'
stringMap [strings.User_Lolgin_Optional] = 'Необязательно'

// Используем материал

stringMap [strings.Cannot_Add_To_Node_Background] = 'Неприменимо к фону узла'
stringMap [strings.Cannot_Add_To_Img_Background] = 'Неприменимо к текстовому фону'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'Неприменимо к фону линии участка'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Добавить фоновое изображение'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Удалить фоновое изображение'
stringMap [strings.Mind_Add_Background_Image] = 'Вставить фоновое изображение'
stringMap [strings.Mind_Delete_Background_Image] = 'Удалить фоновое изображение'

stringMap [strings.Permanent_Member] = 'Постоянный член'
stringMap [strings.Insufficient_Storage_Space] = 'Недостаточно места для хранения, обновите членство, чтобы увеличить пространство. '
stringMap [strings.Used_Space] = 'Место для хранения'
stringMap [strings.Current_Storage] = 'Текущее хранилище:'
stringMap [strings.Used_Space_Upgrade] = 'Обновление места для хранения'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Разум + карта разума и заметки Супермнемоника, добавляйте крылья мышлению, создавайте, отображайте, планируйте и записывайте '
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = 'Недостаточно места для хранения. Вы можете использовать шаблон при обновлении членства. '

stringMap [strings.Group_Management] = 'Группа'
stringMap [strings.Save_Img_Fill_Full] = 'Полное проложить'
stringMap [strings.Save_Img_Center_Tilt] = 'Центральный наклон'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'Полностью (темный)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'Водяной знак Mind + Mind Map'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'Добавить водяной знак для защиты создания знаний:'
stringMap [strings.Save_Img_Watermark_Text] = 'Текст водяного знака:'
stringMap [strings.Save_Img_Watermark_Logo] = 'ЛОГОТИП водяного знака:'
stringMap [strings.Save_Img_Watermark_Style] = 'Стиль водяного знака:'
stringMap [strings.Save_Img_Please_Input] = 'Пожалуйста, введите'
stringMap [strings.Save_Img_Chop_Tips] = 'Вы можете пользоваться правами на водяной знак, открывая участника'
stringMap [strings.Public_Header_Mindmap_Mind] = 'Режим карты'
stringMap [strings.Copy_Mind_Map] = 'Копировать интеллектуальную карту'
stringMap [strings.Font_Style_Bold] = 'Полужирный'
stringMap [strings.Font_Style_Italics] = 'Курсив'
stringMap [strings.Font_Style_Delete_Line] = 'Зачеркнутый'
stringMap [strings.Remove_Any] = 'Удалить'

stringMap [strings.Mindmap_Review] = 'Обзор карты'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'Углубить впечатление и улучшить память, эффективно сортировать идеи и информацию'
stringMap [strings.Mindmap_Review_Content] = 'Обзор карты относится к воспроизведению процесса редактирования, и контент воспроизводится в последовательности, начиная с центральной темы; обзор карты может помочь вам провести инвентаризацию системы, анализ и найти идеи и лазейки . В процессе проверки вы можете найти разные источники вдохновения '
stringMap [strings.Mindmap_Review_Speed] = 'Скорость'
stringMap [strings.Mindmap_Review_Word_Speed] = 'Скорость слова'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'Коэффициент фона'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'Увеличение текущего узла воспроизведения'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'Порядок воспроизведения узла'


stringMap [strings.Mind_Group_Picture_Upload] = 'Загрузка изображения'
stringMap [strings.Mind_Group_Upload_From_Local] = 'Локальная загрузка'
stringMap [strings.Mind_Group_Mind_Mapping] = 'Интеллектуальная карта'
stringMap [strings.Mind_Group_Information] = "Данные"

stringMap [strings.Mind_Group_Invite_New_Members] = 'Пригласить новых участников'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Пожалуйста, введите свой номер телефона'
stringMap [strings.Mind_Group_Cancel] = 'Отменить'
stringMap [strings.Mind_Group_Confirm] = 'ОК'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'Номер мобильного телефона не может быть пустым'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'Приглашение успешно'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "Общие члены группы"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = 'Максимальное количество общих членов группы'
stringMap [strings.Mind_Group_Membership_Limit] = "Предел членства"
stringMap [strings.Mind_Group_Current_Member_Limit] = "Верхний предел текущего члена"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "Обновить верхний предел участника"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "Поиск участников"
stringMap [strings.Mind_Group_Creator] = "Создатель"
stringMap [strings.Mind_Group_Administrator] = "Администратор"
stringMap [strings.Mind_Group_Number_Of_People] = "Люди"
stringMap [strings.Mind_Group_Selected] = "Выбрано"
stringMap [strings.Mind_Group_Member] = "Участник"
stringMap [strings.Mind_Group_Check_All] = "Выбрать все"
stringMap [strings.Mind_Group_Remove] = "Удалить"
stringMap [strings.Mind_Group_Invite_New_Members] = "Пригласить новых участников"
stringMap [strings.Mind_Group_Delete_Members] = "Удалить участников"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Пожалуйста, выберите участника для удаления"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Вы уверены, что хотите удалить пользователя из этой группы?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "Добавить администратора"
stringMap [strings.Mind_Group_Accomplish] = "Завершено"
stringMap [strings.Mind_Group_Compile] = "Редактировать"
stringMap [strings.Mind_Group_Group_Owner] = "Владелец группы"
stringMap [strings.Mind_Group_Add_Admin] = "Добавить администратора"
stringMap [strings.Mind_Group_Group_ID] = "Идентификатор группы"
stringMap [strings.Mind_Group_Group_Number] = "Номер группы"
stringMap [strings.Mind_Group_Name] = "Имя"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Пожалуйста, введите имя общей группы"
stringMap [strings.Mind_Group_Introduce] = "Представить"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Пожалуйста, кратко представьте общие группы"
stringMap [strings.Mind_Group_Announcement] = "Объявление"
stringMap [strings.Mind_Group_Shared_Group_Tag] = 'Тег общей группы'
stringMap [strings.Mind_Group_Custom_Tag] = "Пользовательский тег"
stringMap [strings.Mind_Group_Save] = "Сохранить"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "Имя группы не может быть пустым"
stringMap [strings.Mind_Group_Establish] = "Создать"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "Разрешить создавать карты / папки"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "Разрешить редактирование карт"
stringMap [strings.Mind_Group_Holder] = "Все"
stringMap [strings.Mind_Group_Only_The_Administrator] = "Только администратор"
stringMap [strings.Mind_Group_Not_Allow] = "Не разрешено"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "Разрешить удаление карты"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Разрешить форуму публиковать сообщения"
stringMap [strings.Mind_Group_Export] = "Экспорт"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Разрешить сохранять изображения / PDF"
stringMap [strings.Mind_Group_Open_Group_Watermark] = 'Открыть водяной знак группы'
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "После открытия логотип группы будет напечатан на интеллект-карте"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "Разрешить сохранение карт"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "Сохранить на моей карте"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Разрешить ли доступ к шторму"
stringMap [strings.Mind_Group_Join_The_Way] = "Как присоединиться"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "Разрешить ли приглашать новых участников"
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Разрешить ли добавление номеров групп поиска"
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Требуется ли подтверждение сообщения при присоединении"
stringMap [strings.Mind_Group_Shared_Group_Data] = "Общие данные группы"
stringMap [strings.Mind_Group_Authority_Management] = "Управление полномочиями"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "Выйти из группы совместного использования"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Вы действительно хотите выйти из общей группы?"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Группа растворения"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "Файлы будут удалены после расформирования группы. Подтвердить роспуск?"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "Вернуться на главную страницу"
stringMap [strings.Mind_Group_Join_The_Group] = "Присоединиться к группе"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "Пожалуйста, введите номер группы / название группы для поиска"
stringMap [strings.Mind_Group_Apply_To_Join] = "Подать заявку на присоединение"
stringMap [strings.Mind_Group_Join] = "Присоединиться"
stringMap [strings.Mind_Group_Verification] = "Подтверждение"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "Пожалуйста, введите сообщение проверки"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "Контент не может быть пустым"
stringMap [strings.Mind_Group_Name] = "Имя общей группы"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Пожалуйста, введите имя общей группы"
stringMap [strings.Mind_Group_Introduction] = "Знакомство с группой обмена"
stringMap [strings.Mind_Group_Group_Indication] = "Номер группы"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "Создатель ленив, я еще не представил его ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "Объявление общей группы"
stringMap [strings.Mind_Group_No_Announcement] = "Еще нет объявлений"
stringMap [strings.Mind_Group_Click_On] = "Щелкните, чтобы развернуть"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = 'Щелкните, чтобы скрыть'
stringMap [strings.Mind_Group_Altoght] = "Всего"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Копирование номера группы успешно"
stringMap [strings.Mind_Group_Stick] = "Придерживайтесь вершины"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "Есть новый контент"
stringMap [strings.Mind_Group_Cancel_The_Top] = "Отменить начало"
stringMap [strings.Mind_Group_Setting_Up] = "Настройки"
stringMap [strings.Mind_Group_Usinghelp] = "Использование справки"
stringMap [strings.Mind_Group_Set_The_Message] = "Групповое сообщение"
stringMap [strings.Mind_Group_Apply_To_Join] = "Подать заявку на присоединение"
stringMap [strings.Mind_Group_Invite_To_Join] = "Пригласить присоединиться"
stringMap [strings.Mind_Group_Consent] = "Согласен"
stringMap [strings.Mind_Group_Turn_Down] = "Отклонить"
stringMap [strings.Mind_Group_Label] = "Метка"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Пожалуйста, введите имя ярлыка общей группы"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleague_To_Create_And_Share_Community] = "Пригласите друзей и коллег для совместного создания и обмена информационным содержанием"
stringMap [strings.Mind_Group_Immediately_Create] = "Создать немедленно"
stringMap [strings.Mind_Group_Immediately_To_Join] = "Присоединяйтесь сейчас"

stringMap [strings.Currently_Selected] = "В настоящее время подписан"
stringMap [strings.LabelManagement] ='Управление этикетками'
stringMap [strings.NoAttention] = "Без внимания"
stringMap [strings.Mindmap_Review_Play] = 'Играть'

stringMap [strings.Mind_Group_Joined] = "присоединился"
stringMap [strings.Mind_Group_Download_App] = "Загрузить приложение"
stringMap [strings.Mind_Group_No_Content] = "Пока нет содержимого"
stringMap [strings.Mind_Group_Individual] = "кусочки"
stringMap [strings.Mind_Group_Message_Area] = "Область сообщений"
stringMap [strings.Mind_Group_No_Message] = "Нет сообщения, иди сейчас"
stringMap [strings.Mind_Group_Leave_Message] = 'Оставить сообщение'
stringMap [strings.Mind_Group_Bar] = "Бар"
stringMap [strings.Mind_Group_See_More] = "Узнать больше"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "Введите код приглашения"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Подать заявку на присоединение к группе совместного использования"
stringMap [strings.Reasons_For_Application_0_30_Words] = "Причины подачи заявки (0-30 слов)"
stringMap [strings.Mind_Group_Send_Out] = "Отправить"
stringMap [strings.Mind_Group_Join_Successfully] = 'Присоединиться успешно'
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "Приложение было успешно отправлено"

stringMap [strings.Mind_Group_Save_The_Mapping] = 'Сохранить карту'
stringMap [strings.Mind_Group_Update_Record] = 'Изменить запись'

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "Переключить карту"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "Обзор карты"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Существующий пользователь редактирует карту"
stringMap [strings.Mind_Edit_Page_Password] = "Пароль"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = 'Группа общего доступа'
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "Мой MBean"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Платеж через Alipay"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "Пожалуйста, перейдите на страницу оплаты, чтобы произвести оплату"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "Всего"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "Подтвердить платеж"
stringMap [strings.Mind_Shooping_Redeem_Now] = "Погасить сейчас"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "Подтверждать ли оплату покупки"
stringMap [strings.Mind_Tips] = "Советы"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "У вас недостаточно M Beans, вы хотите купить M Beans"

stringMap [strings.Mind_share_scan_code] = 'Разум + скан-код'

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "Стоит ли восстанавливать на последнее играемое место?"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Оставшееся время воспроизведения"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "Файл будет полностью удален, вы уверены, что будете распущены?"
stringMap [strings.Group_Mind_Up_One_Level] = 'На один уровень выше'
stringMap [strings.Group_Mind_No_Documents] = "Файлов пока нет"
stringMap [strings.Group_Mind_PaymentSuccessful] = "Платеж успешен"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "Совместное редактирование людей, синхронизация в реальном времени с несколькими терминалами, совместное использование документов, повышение эффективности совместной работы в команде"

stringMap [strings.Mind_Edit_Subscript] = "Подстрочный индекс"
stringMap [strings.Mind_Edit_Text_Location] = "Местоположение текста"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = 'Импортировать в общую группу'

stringMap [strings.Standard_Colors] = "Стандартные цвета"
stringMap [strings.More_Colors] = "Больше цветов"
stringMap [strings.Reset_Password] = "Сбросить пароль"
stringMap [strings.Account_Password_Login] = "Пароль для входа в аккаунт"
stringMap [strings.Authentication_code_Login_registration] = "Код подтверждения входа / регистрации"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "Создание одного конца, синхронизация нескольких концов"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "Богатая и разнообразная структура карты"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "Огромная изысканная библиотека шаблонов"
stringMap [strings.Map_Review_Play] = "Просмотр карты - Игра"
stringMap [strings.CommunityLearningAndSharingPlatform] = "Платформа обмена знаниями и обмена"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "Обновить место для хранения"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "Позвольте мне также прокомментировать это"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Уведомлений о сообщениях еще нет"
stringMap [strings.Failed_To_Get_Recording_Device] = "Не удалось получить записывающее устройство"

stringMap [strings.Search_Pagination] = "Поиск по страницам"
stringMap [strings.Please_Enter_A_Paging_Name] = "Пожалуйста, введите имя страницы"
stringMap [strings.Operation_Settings] = "Настройки работы"
stringMap [strings.Shortcut_key_Settings] = "Настройки сочетания клавиш"
stringMap [strings.Press_Key_Combination] = "Пожалуйста, нажмите требуемую комбинацию клавиш"
stringMap [strings.Mind_Reduction] = "Сокращение"
stringMap [strings.Double_Click_Add_Free_Node] = "Дважды щелкните пустое пространство, чтобы добавить свободный узел"
stringMap [strings.Shortcut_Conflict] = "Конфликт горячих клавиш"
stringMap [strings.Select_Main_Node] = "Выбрать главный узел"
stringMap [strings.Telephone] = "Телефон"
stringMap [strings.Microblog] = "Микроблог"
stringMap [strings.Address] = "Адрес"
stringMap [strings.Company_Address] = "Блок B, здание 21, № 1158 Zhongxin Road, район Сунцзян, Шанхай (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "Архив"
stringMap [strings.Mind_Bookshelf] = "Книжная полка"
stringMap [strings.Search_Archive] = "Поиск на книжной полке"
stringMap [strings.Modify_Archive_Order] = "Изменить порядок книжной полки"
stringMap [strings.Modification_Order] = "Порядок модификации"
stringMap [strings.Please_Enter_Archive_Name] = "Пожалуйста, введите название книжной полки"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "Название книжной полки не может быть пустым"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "Вы действительно хотите удалить эту книжную полку?"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "Вы уверены, что хотите удалить этот книжный шкаф?"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "Вы действительно хотите удалить эту карту?"
stringMap [strings.Mind_Edit_Confirm] = "Подтвердить"
stringMap [strings.Mind_Import_Map] = "Импортировать карту"
stringMap [strings.Mind_Bookcase] = "Книжный шкаф"
stringMap [strings.Added_Successfully] = "Успешно добавлено"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "Название книжного шкафа не может быть пустым"

stringMap [strings.Mind_Association_Map] = "Карта ассоциации"
stringMap [strings.Mind_confirm_Association_Map] = "Вы уверены, что связали эту карту?"
stringMap [strings.Mind_Association_Map_See] = "Просмотреть карту ассоциаций"
stringMap [strings.Mind_Association_Map_Delete] = "Удалить карту ассоциации"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "Структура линии корреляции"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Начало линии корреляции"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "Конец линии корреляции"

stringMap [strings.Share_Group_To_Storm] = "Делить ли группу для шторма"
stringMap [strings.Apply_To_Join_Storm_Platform] = "На платформе шторма другие люди видят и подают заявки на присоединение, проводя мозговой штурм с энтузиастами, обладающими общими знаниями"
stringMap [strings.Pay_To_Goin] = "Платите, чтобы присоединиться"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "M beans необходимо заплатить при присоединении"
stringMap [strings.Mbean_Can_Be_Realized] = "Реализован Mbean"

stringMap [strings.Mind_Visit] = "Посетить"
stringMap [strings.Mind_Details] = "Подробности"
stringMap [strings.Mind_See] = "Просмотр"
stringMap [strings.Earn_Mbeans] = "Зарабатывайте Mbeans"
stringMap [strings.Mind_Explain] = "Описание"
stringMap [strings.Mind_Parenting] = "Воспитание"
stringMap [strings.Mind_Internet] = "Интернет"
stringMap [strings.Mind_Psychology] = "Психология"
stringMap [strings.Mind_literature] = "Литература"
stringMap [strings.Mind_Technology] = "Технология"
stringMap [strings.Mind_Workplace] = "Рабочее место"
stringMap [strings.Mind_Life] = "Жизнь"
stringMap [strings.Mind_Subject] = "Тема"
stringMap [strings.Hot_Group] = "Горячая группа"
stringMap [strings.Open_Group] = "Открытая группа"
stringMap [strings.Mind_Group_Used] = "Используется"

stringMap [strings.Mind_Click_Select] = "Щелкните, чтобы выбрать"
stringMap [strings.Mind_Double_Click_Enter] = "Дважды щелкните, чтобы войти"
stringMap [strings.Search_Shared_Groups] = "Поиск общих групп"
stringMap [strings.Search_Storm] = "Поисковая буря"
stringMap [strings.Members_Save_Free] = "Члены экономят бесплатно"
stringMap [strings.Bank_Card_Account_Name] = "Пожалуйста, введите название счета вашей банковской карты"
stringMap [strings.Bank_Card_No] = "Номер банковской карты"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "Пожалуйста, введите номер своей банковской карты"
stringMap [strings.Branch_Sub_Branch_Information] = "Информация о филиале"
stringMap [strings.Branch_Sub_Branch_Example] = "Например: филиал в Ханчжоу, филиал в Чэнси"
stringMap [strings.Application_For_Withdrawal] = "Заявка на снятие средств"
stringMap [strings.Withdrawal_Amount] = "Сумма вывода"
stringMap [strings.Withdrawal] = "Снять"
stringMap [strings.Current_Revenue] = "«Текущий доход»"
stringMap [strings.Recharge] = "Пополнить"
stringMap [strings.Current_Total_MBeans] = "Текущее общее количество компонентов MBeans"
stringMap [strings.Withdrawal_Status] = "Статус вывода"
stringMap [strings.Insufficient_Withdrawal_Amount] = "Недостаточная сумма вывода"
stringMap [strings.Load_More] = "Загрузить еще"
stringMap [strings.Loading] = "Загрузка"
stringMap [strings.It_Is_Over] = "Все кончено"
stringMap [strings.Default_Label] = "Метка по умолчанию"
stringMap [strings.Selected_Label] = "Выбранный ярлык"
stringMap [strings.Current_Location] = "Текущее местоположение"
stringMap [strings.MGold_Introduction] = "M золота заработано вами через Mind +"
stringMap [strings.MGold_Details] = "Ваша платная облачная группа, в ней есть карты знаний, учебные материалы и т.д. чем 50 могут быть отозваны »"
stringMap [strings.Cloud_Group_Introduction] = "Поделитесь своей группой, другие могут просматривать ее на странице шторма"
stringMap [strings.Cloud_Group_Details] = "Ваша платная облачная группа. В группе есть карты знаний, учебные материалы и т. д. Если пользователи присоединятся к платежу M beans, 50% из них будут преобразованы в M gold, а M gold больше чем 50 могут быть извлечены"
stringMap [strings.Share_Storm_Introduction] = "Поделитесь своей картой с сообществом штормов, чтобы больше людей могли учиться и изучать ваши знания"
stringMap [strings.Share_Storm_Details] = "Если вы разрешите использование компонентов M для сохранения карт, другие пользователи смогут использовать вашу карту знаний, и вы также получите 50% компонентов M. Bean M можно использовать для покупки участников и платного присоединения группы, Вы также можете заплатить, чтобы использовать карты других людей "

stringMap [strings.Linked_Successfully] = "Связано успешно"
stringMap [strings.Cancel_Archiving] = "Отменить архивирование"
stringMap [strings.Please_Select_Archive_Location] = "Пожалуйста, выберите расположение архива"
stringMap [strings.The_Search_Result_Is_Empty] = "Результаты поиска пусты"

stringMap [strings.Customize_A_Style] = "Не члены могут настроить только один стиль"
stringMap [strings.Adds_Current_Guide_Style_Library] = "Добавить текущий стиль карты в библиотеку стилей"
stringMap [strings.Trial_Version] = "Пробная версия"
stringMap [strings.Not_Supported_At_The_Moment] = "В настоящее время не поддерживается"
stringMap [strings.Mind_File] = "Файл"
stringMap [strings.Mind_Import] = "Импорт"
stringMap [strings.Importing] = "Импорт"

stringMap [strings.Mind_Edit_Insert_Video] = "Вставить видео"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Перетащите сюда видео"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "Продолжительность загрузки видео не может превышать 60 секунд"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "Просмотреть видео"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "Удалить видео"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "Размер загруженного видео не может превышать 10 МБ"
stringMap [strings.Mind_Upload_Video_Restrictions] = "Поддерживает загрузку только видео в формате MP4, размером до 120 секунд и размером 10M"
stringMap [strings.Mind_Video_Size] = "Размер видео"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "Экспорт слова"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "«Экспорт TXT»"
stringMap [strings.Export_Failed] = "«Ошибка экспорта»"
stringMap [strings.Add_File] = "Добавить файл"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "Перетащите прямо в файл для загрузки"
stringMap [strings.Supported_Imported_File_Types] = "Типы файлов, которые в настоящее время поддерживаются для импорта: Word (только .docx), XMind, .txt"
stringMap [strings.No_Permission_Do_This] = "У вас нет разрешения на выполнение этой операции"

stringMap [strings.Upgrade_To_Professional] = "Обновить до Профессиональной версии"
stringMap [strings.Non_Members_Insert_Videos] = "Не участники могут вставлять видео только через 24 часа после регистрации"
stringMap [strings.Upload_Failed] = "Ошибка загрузки"
stringMap [strings.Upload_Failed_Title_Message] = "Ошибка загрузки, нажмите для подтверждения, удалите оставшиеся изображения, которые не удалось загрузить"

stringMap [strings.Mind_Edit_Members_Use] = "Использование участниками"
stringMap [strings.Invitation_Code] = "Код приглашения"
stringMap [strings.Student_Certification] = "Сертификация студента"
stringMap [strings.I_Get_It] = "Я пропущу руководство"
stringMap [strings.Welcome_To_Mind] = "Добро пожаловать, молодой мастер присоединился к Mind +, давайте начнем путь мышления!"
stringMap [strings.Mind_Before] = "Первый"
stringMap [strings.Try_A_Map] = "Попробуйте карту"
stringMap [strings.Click_Create] = "Щелкните, чтобы создать"
stringMap [strings.Click_Create_A_Mind_Map] = "Щелкните, чтобы создать интеллектуальную карту"
stringMap [strings.Mind_About] = "О"
stringMap [strings.See_Checking_Data] = "Просмотр личной информации"
stringMap [strings.Modify_Checking_Data] = "Изменить личную информацию"
stringMap [strings.Mind_Shear] = "Сдвиг"

stringMap [strings.Mind_Style_Empty] = "Нет избранного или нестандартного стиля"
stringMap [strings.Mind_Branch_Free_Layout] = "Разметка без ветвей"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "Ветви двусторонней структуры и пузырьковой карты можно свободно перетаскивать в компоновку"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Free Theme Drag Snap"

stringMap [strings.Select_A_Node] = "Выберите тему"
stringMap [strings.Create_Child_Node] = "Создать дочернюю тему"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "Затем мы добавим красивую тему в дочернюю тему"
stringMap [strings.Click_Icon_Panel] = "Щелкните панель значков"
stringMap [strings.Select_An_Icon_You_Like] = "Выберите понравившийся значок"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "Затем мы заменим интеллектуальную карту на новую"
stringMap [strings.Select_The_Central_Theme] = "Выберите центральную тему"
stringMap [strings.Switch_To_Canvas_Panel] = "Переключиться на панель холста"
stringMap [strings.Click_Layout] = "Щелкните для макета"
stringMap [strings.Choose_A_Layout_You_Like] = "Выберите макет, который вам нравится"
stringMap [strings.Mind_Map_Save_Share] = "Поздравляем, вы успешно создали красивую интеллектуальную карту! Давайте сохраним ее и поделимся с друзьями ~"
stringMap [strings.Click_Save_Picture] = "Щелкните, чтобы сохранить изображение"
stringMap [strings.Click_OK] = "Нажмите ОК"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "Поздравляем с завершением руководства для новичков ~"
stringMap [strings.Upload_Succeeded] = "Загружено успешно"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "Быстрый стиль"
stringMap [strings.Mind_Extremely_Important] = "Чрезвычайно важно"
stringMap [strings.Mind_Important] = "Важно"
stringMap [strings.Mind_Commonly] = "Общие"
stringMap [strings.Mind_Ordinary] = "Обычный"
stringMap [strings.Member_Exclusive] = "Эта функция доступна только участникам. Хотите повысить уровень членства?"
stringMap [strings.Member_Exclusive_Color_Scheme] = "Эта цветовая схема предназначена только для участников. Вы хотите обновить свое членство?"
stringMap [strings.Mind_Enlarge] = "Увеличить"
stringMap [strings.Mind_Narrow] = "Узкий"
stringMap [strings.Mind_Center] = "По центру"
stringMap [strings.Mind_Full_Screen] = "Полный экран"

stringMap [strings.Mind_Association_Node] = "Узел ассоциации"

stringMap [strings.Mind_Resource_See] = "Просмотреть вложение"
stringMap [strings.Mind_Resource_Delete] = "Удалить вложения"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Перетащите файлы сюда"
stringMap [strings.Mind_Edit_choose_explain] = "Выбрать файл"
stringMap [strings.Message_Tips_Share_Explain_Does_Not_Exist] = "Размер загруженного файла не может превышать 20 МБ"
stringMap [strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap [strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

module.exports = stringMap;
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

import Antd from 'ant-design-vue';
import {message} from "ant-design-vue" 
import 'ant-design-vue/dist/antd.less'; 
import VueLazyload from 'vue-lazyload'  //懒加载

// import VueI18n from 'vue-i18n' //语言包

// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'

import VueCropper from "vue-cropper" //截图

import store from './core/store'

import App from './App'
import router from './router'

import 'babel-polyfill'

import tools from './common/commonFuntion'
import utils from './common/utils';

import Strings from './common/lang/strings';
import getString from './common/lang/language';

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
// import './common/load_mathjax';

import VueKatex from 'vue-katex'
import katex from 'katex'
 
Vue.use(VueKatex)

import "./../node_modules/katex/dist/katex.min.css";
import './common/font-face/font-face.css'

// 引入katex下的自动渲染函数
import renderMathInElement from 'katex/contrib/auto-render/auto-render'

// import vColorPicker from 'vcolorpicker'
// app.use(vColorPicker)

// 定义自动渲染的配置参数,这些参数根据你的需求进行修改，下面的参数是官网上抄下来的
const renderOption = {
  delimiters: [
    {left: '$$', right: '$$', display: true},
    {left: '$', right: '$', display: false},
    {left: '\\(', right: '\\)', display: false},
    {left: '\\[', right: '\\]', display: true}
  ],
  throwOnError : false
}
// 挂载自动渲染函数到vue原型
Vue.prototype.$formula = function (dom) {
  renderMathInElement(dom, renderOption)
}

Strings.install = function (Vue, options) {
  Vue.prototype.$Strings = Strings
  Vue.Strings = Strings
}

let getStringObj = {
  getString:getString,
  install:function (Vue, options) {
    Vue.prototype.$getStringObj = getStringObj
    Vue.getStringObj = getStringObj
  }
}
Vue.use(Strings)
Vue.use(getStringObj)

Vue.use(tools)
Vue.use(utils)

// Vue.use(x2js)

// delete
// import x2js from 'x2js' //xml数据处理
// import ResizeObserver from 'resize-observer-polyfill';
// Vue.use(ResizeObserver);
Vue.config.productionTip = false
// Vue.config.performance = true;
Vue.use(Antd)
Vue.use(VueCropper)
// Vue.use(mavonEditor)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: require('../static/img/addIcon/addIcon.png'),
  // loading: require('../static/img/addIcon/addIcon.png'),
  attempt: 1
  })
Vue.use(store)
// Vue.use(VueI18n)
Vue.use(message)

message.config({
  duration: 2,
  top: `100px`,
  maxCount: 2,
  customClass: 'message-Content',
  zIndex:9999
})

// vue事件总线
Vue.prototype.bus = new Vue();


/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   i18n,
//   store,
//   components: { App },
//   template: '<App/>'
// })

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })

new Vue({
  render: h => h(App),
  router,
  // i18n,
  store,
}).$mount('#app')


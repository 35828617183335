import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";
import TextAndIconAndLinkCalculation from "./TextAndIconAndLinkCalculation";
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndIconAndResourceAndTaskCalculation extends TextAndIconAndLinkCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }
    calculate() {
        if (!this.data.isContainResourceContent() == null || !this.data.taskContent == null) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return;
        }
        let resourceContent = this.data.resourceContent;

        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        resourceContent.width = textHeight;
        resourceContent.height = textHeight;

        let size = this.includeIconAndTextMoreAndTaskSize();
        let iconAndTextResourceSize = this.includeIconAndTextResourceSize();
        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let textResourceSize = this.includeMoreAndTextSize();
        let iconLeft = 0;
        let iconTop = 0;
        let resourceTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == IconLayoutType.LAYOUT_LEFT) {
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconMargin) : 0;
            iconTop = (iconAndTextResourceSize.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((iconAndTextResourceSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            resourceContent.x = textMindElementContent.x + textMindElementContent.width + resourceTextSpace;
            resourceContent.y = ((iconAndTextResourceSize.getHeight()) - resourceContent.height) / 2 + marginY;
            

        } else if (layoutType == IconLayoutType.LAYOUT_RIGHT) {
            iconLeft = (iconAndTextResourceSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
            iconTop = (iconAndTextResourceSize.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((iconAndTextResourceSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            resourceContent.x = textMindElementContent.x + textMindElementContent.width + resourceTextSpace;
            resourceContent.y = ((iconAndTextResourceSize.getHeight()) - resourceContent.height) / 2 + marginY;
            

        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            iconLeft = (iconAndTextResourceSize.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = (this.iconSpace);
            textMindElementContent.x = ((iconAndTextResourceSize.getWidth()) - (textResourceSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            resourceContent.x = textMindElementContent.x + textMindElementContent.width + resourceTextSpace;
            resourceContent.y = textMindElementContent.y + ((textResourceSize.getHeight()) - resourceContent.height) / 2;

        }
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = iconLeft + marginX;
            } else {
                iconCell.x = iconElementContents[index - 1].x + iconElementContents[index - 1].width + (this.iconSpace);
            }
            iconCell.y = iconTop + marginY;
        }
        let textEdgeInsets = this.getTextEdgeInsets();
        this.data.taskContent.x = marginX + textEdgeInsets.left;
        this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}

export default TextAndIconAndResourceAndTaskCalculation

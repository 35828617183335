
// package mind.yushu.com.mindmap.core.calcule.elementCalculation;

// import android.graphics.Point;
// import android.util.Size;

// import mind.yushu.com.mindmap.core.basemode.EdgeInsets;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGRect;
// import mind.yushu.com.mindmap.viewmodel.datatype.ImageLayoutType;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindElementShapeType;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindElementType;
// import mind.yushu.com.mindmap.viewmodel.datatype.NodeLayoutType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.ConnectMapElementContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.ImageContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.LinkElementContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.MindElementContent;
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import UiUtil from "../../../../utils/UiUtil";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndLinkAndConnectMap extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data, nodeLayoutType);
    }
    // public TextAndImageAndLinkAndConnectMap(MindElementData data, NodeLayoutType nodeLayoutType) {
    //     super(data, nodeLayoutType);
    // }

    calculate() {
        if (this.data.imageContent == null || !this.data.isContainLinkContent() || !this.data.isContainConnectMapContent()) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();
        let linkElementContent = this.data.linkElementContent;
        let connectMapElementContent = this.data.connectMapElementContent;

        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        linkElementContent.width = textHeight;
        linkElementContent.height = textHeight;
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;

        let textLinkConnectMapSize = this.includeTextAndLinkAndConnectMapSize();
        let size = this.includeImageAndTextAndLinkAndConnectMapSize();
        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        
        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;
        let linkTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconMargin) : 0;
            iconLeft = iconTop > iconLeft ? iconLeft : iconTop;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = ((size.getHeight()) - linkElementContent.height) / 2;
            connectMapElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
            connectMapElementContent.y = linkElementContent.y;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = ((size.getHeight()) - linkElementContent.height) / 2;
            connectMapElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
            connectMapElementContent.y = linkElementContent.y;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = iconLeft > (this.iconSpace) ? (this.iconSpace) : iconLeft;
            textMindElementContent.x = ((size.getWidth()) - (textLinkConnectMapSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = textMindElementContent.y + ((textLinkConnectMapSize.getHeight()) - linkElementContent.height) / 2;
            connectMapElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
            connectMapElementContent.y = linkElementContent.y;
        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }

}

export default TextAndImageAndLinkAndConnectMap
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import TextAndImageAndIconCalculation from "./TextAndImageAndIconCalculation";
import TextIAndmageCalculation from "./TextIAndmageCalculation";
import Size from "../../../../viewmodel/core/base/Size";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndIconAndTaskCalculation extends TextAndImageAndIconCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }


    calculate() {
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();
        let taskContent = this.data.taskContent;

        if (imageContent == null || textMindElementContent == null || taskContent == null) {
            return;
        }
        if (this.data.iconElementContents == null || this.data.iconElementContents.isEmpty()) {
            new TextIAndmageCalculation(this.data).calculate();
            return;
        }

        let iconElementContents = this.data.iconElementContents;
        let size = this.includeImageAndTextAndIconAndTaskSize();
        let marginPoint = this.getMargin(size);
        let left = marginPoint.left;
        let top = marginPoint.top;

        let isShowText = this.isShowText();
        let textSize = new Size(isShowText ? textMindElementContent.width : 0, isShowText ? imageContent.height : 0);
        let imageSize = new Size(this.data.imageContent.width, this.data.imageContent.height);
        let imageAndTextAndIcon = this.includeImageAndTextAndIconSize();
        let iconsSize = this.caluleIconElementSize();
        let iconsAndTextHeight = Math.max(iconsSize.getHeight() + this.iconSpace * 2, textSize.getHeight());
        let layoutType = imageContent.layoutType;
        let iconLayoutType = iconElementContents[0].layoutType;
        let textEdgeInsets = this.getTextEdgeInsets();

        if (imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT) {
            imageContent.x = left;
            if (imageContent.height > iconsAndTextHeight) {
                imageContent.y = top;
            } else {
                imageContent.y = top + (iconsAndTextHeight - imageContent.height)/2;
            }
            if (iconLayoutType == IconLayoutType.LAYOUT_LEFT || iconLayoutType == IconLayoutType.LAYOUT_TOP) {
                textMindElementContent.y = top + (size.getHeight() - textMindElementContent.height) / 2;
                let iconsRect;
                if (this.data.type == MindElementType.MAIN_SUBJECT ||
                    this.data.type == MindElementType.SUBJECT ||
                    this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                    iconsRect = this.caluleIconElementRect(left + this.iconSpace + imageContent.width,
                            top + (imageAndTextAndIcon.getHeight() - iconElementContents[0].height) / 2);
                } else {
                    iconsRect = this.caluleIconElementRect(left + this.iconSpace + imageContent.width,
                            top + (imageAndTextAndIcon.getHeight() - iconElementContents[0].height) / 2);
                }
                textMindElementContent.x = imageContent.x + imageContent.width + iconsSize.getWidth() + this.iconSpace;
            } else {
                textMindElementContent.x = imageContent.x + imageContent.width;
                textMindElementContent.y = top + (imageAndTextAndIcon.getHeight() - textMindElementContent.height) / 2;

                let iconsRect;
                if (this.data.type == MindElementType.MAIN_SUBJECT ||
                    this.data.type == MindElementType.SUBJECT ||
                    this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                    iconsRect = this.caluleIconElementRect(textMindElementContent.x + textMindElementContent.width + this.iconSpace,
                            top + (imageAndTextAndIcon.getHeight() - iconElementContents[0].height) / 2);
                } else {
                    iconsRect = this.caluleIconElementRect(textMindElementContent.x + textMindElementContent.width + this.iconSpace,
                            top + (imageAndTextAndIcon.getHeight() - iconElementContents[0].height) / 2);
                }
            }
        } else if (imageContent.layoutType == ImageLayoutType.LAYOUT_TOP) {
            if (iconLayoutType == IconLayoutType.LAYOUT_LEFT || iconLayoutType == IconLayoutType.LAYOUT_TOP) {
                let iconsRect;
                if (this.data.type == MindElementType.MAIN_SUBJECT ||
                    this.data.type == MindElementType.SUBJECT ||
                    this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                    iconsRect = this.caluleIconElementRect(left + this.iconSpace,
                        top + (imageAndTextAndIcon.getHeight() - iconElementContents[0].height) / 2);
                } else {
                    iconsRect = this.caluleIconElementRect(left + this.iconSpace,
                        top + (imageAndTextAndIcon.getHeight() - iconElementContents[0].height) / 2);
                }
                textMindElementContent.x = left + iconsSize.getWidth() + this.iconSpace;
                imageContent.x = textMindElementContent.x + textMindElementContent.width + this.imageSpace;
            } else {
                textMindElementContent.x = left;
                textMindElementContent.y = top + (imageAndTextAndIcon.getHeight() - textMindElementContent.height) / 2;

                let iconsRect;
                if (data.type == MindElementType.MAIN_SUBJECT ||
                        data.type == MindElementType.SUBJECT ||
                        data.type == MindElementType.CONTENT_GENERALIZATION) {
                    iconsRect = this.caluleIconElementRect(textMindElementContent.x + textMindElementContent.width + this.iconSpace,
                            top + (imageAndTextAndIcon.getHeight() - iconElementContents[0].height) / 2);
                } else {
                    iconsRect = this.caluleIconElementRect(textMindElementContent.x + textMindElementContent.width + this.iconSpace,
                            top + (imageAndTextAndIcon.getHeight() - iconElementContents[0].height) / 2);
                }
                imageContent.x = textMindElementContent.x + textMindElementContent.width + iconsSize.getWidth() + this.imageSpace;
            }
            if (imageContent.height > iconsAndTextHeight) {
                imageContent.y = top;
            } else {
                imageContent.y = top + (iconsAndTextHeight - imageContent.height)/2;
            }
        } else if (imageContent.layoutType == ImageLayoutType.LAYOUT_TOP) {
            imageContent.y = top;
            imageContent.x = left + (size.getWidth() - imageContent.width)/2;
            if (iconLayoutType == IconLayoutType.LAYOUT_LEFT || iconLayoutType == IconLayoutType.LAYOUT_TOP) {
                textMindElementContent.y = imageContent.y + imageContent.height + (imageAndTextAndIcon.getHeight() - imageContent.height - textMindElementContent.height) / 2;
                let iconsRect;

                if (data.type == MindElementType.MAIN_SUBJECT ||
                        data.type == MindElementType.SUBJECT ||
                        data.type == MindElementType.CONTENT_GENERALIZATION) {
                    iconsRect = this.caluleIconElementRect(left + this.iconSpace + (size.getWidth() - iconsSize.getWidth() - textMindElementContent.width)/2,
                            imageContent.y + imageContent.height + (imageAndTextAndIcon.getHeight() - imageContent.height - iconsSize.getHeight()) / 2);
                } else {
                    iconsRect = this.caluleIconElementRect(left + this.iconSpace + (size.getWidth() - iconsSize.getWidth() - textMindElementContent.width)/2,
                            imageContent.y + imageContent.height + (imageAndTextAndIcon.getHeight() - imageContent.height - iconsSize.getHeight()) / 2);
                }
                textMindElementContent.x = left + this.iconSpace + (size.getWidth() - iconsSize.getWidth() - textMindElementContent.width)/2 + iconsSize.getWidth();
            } else {
                textMindElementContent.x = left + (size.getWidth() - iconsSize.getWidth() - textMindElementContent.width)/2;
                textMindElementContent.y = imageContent.y + imageContent.height + (imageAndTextAndIcon.getHeight() - imageContent.height - textMindElementContent.height) / 2;
                let iconsRect;
                if (data.type == MindElementType.MAIN_SUBJECT ||
                        data.type == MindElementType.SUBJECT ||
                        data.type == MindElementType.CONTENT_GENERALIZATION) {
                    iconsRect = this.caluleIconElementRect(textMindElementContent.x + textMindElementContent.width,
                            imageContent.y + imageContent.height + (imageAndTextAndIcon.getHeight() - imageContent.height) / 2);
                } else {
                    iconsRect = this.caluleIconElementRect(textMindElementContent.x + textMindElementContent.width,
                            imageContent.y + imageContent.height + (imageAndTextAndIcon.getHeight() - imageContent.height) / 2);
                }
            }
        }
        this.data.taskContent.x = left + (size.getWidth() - this.taskOriginalSize.getWidth())/2;
        this.data.taskContent.y = top + imageAndTextAndIcon.getHeight();
        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();

        setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}
export default TextAndImageAndIconAndTaskCalculation
import Config from "../../../core/core/calcule/Config";
import BoldData from "../../mindelementdata/mindcontent/BoldData"
import MindEditSectionId from "./MindEditSectionId";

 class SettingData {
    constructor() {
        this.freeNodeMoveAdsorbent = new BoldData().create(true), //自由节点是否在拖动时吸附到其他节点上
        this.branchNodeFreelayout = new BoldData().create(false) //主分支子节点是否允许自由布局
        this.subjectSpcaeHorizontal = 0;
        this.subjectSpcaeVertical = 0;
        this.sonSubjectSpcaeHorizontal = 0;
        this.sonSubjectSpcaeVertical = 0;
        this.useTimeLineColor = false;
        this.tightMode = false;
        this.sameLevelAlignment = false;
        this.lineThicken = false;
        this.lineEndThicken = false;
        this.isOverlap = false;
        this.lineColor = [];
        this.changeDataStyleId = [];
        this.sectionIds = [];
    }

    copy() {
        let data = new SettingData()
        data.freeNodeMoveAdsorbent = this.freeNodeMoveAdsorbent.copy();
        data.branchNodeFreelayout = this.branchNodeFreelayout.copy();
        data.subjectSpcaeHorizontal = this.subjectSpcaeHorizontal;
        data.subjectSpcaeVertical = this.subjectSpcaeVertical;
        data.sonSubjectSpcaeHorizontal = this.sonSubjectSpcaeHorizontal;
        data.sonSubjectSpcaeVertical = this.sonSubjectSpcaeVertical;
        data.lineThicken = this.lineThicken;
        data.lineEndThicken = this.lineEndThicken;
        data.useTimeLineColor = this.useTimeLineColor;
        data.tightMode = this.tightMode;
        data.sameLevelAlignment = this.sameLevelAlignment;
        data.isOverlap = this.isOverlap;
        data.lineColor = [];
        let count = this.lineColor.length;
        for (let index = 0; index < count; index++) {
            data.lineColor.push(this.lineColor[index])
        }
        data.changeDataStyleId = [];
        let changeStyleDataCount = this.changeDataStyleId.length;
        for (let index = 0; index < changeStyleDataCount; index++) {
            data.changeDataStyleId.push(this.changeDataStyleId[index])
        }
        data.sectionIds = []
        for (let index = 0; index < this.sectionIds.length; index++) {
            let cell = this.sectionIds[index];
            let newCell = cell.copy();
            data.sectionIds.push(newCell);
        }
        return data
    }

    addRemoveData(id) {
        let sectionId = this.getSelfSectionId();
        if (sectionId == null) {
            return;
        }
        sectionId.addRemoveData(id);
    }

    addChangeData(id) {
        let sectionId = this.getSelfSectionId();
        if (sectionId == null) {
            return;
        }
        sectionId.addChangeData(id);
    }

    mergeSectionId(data) {
        if (this.sectionIds == null) {
            this.sectionIds = [];
        }
        let find = false;
        for(let index = 0; index < this.sectionIds.length; index++) {
            let cell = this.sectionIds[index];
            if (cell.uuid == data.uuid) {
                cell.merged(data);
                find = true;
                break;
            }
        }
        if (!find) {
            this.sectionIds.push(data.copy());
        }
    }

    getSelfSectionId() {
        if (this.sectionIds == null || this.sectionIds.isEmpty()) {
            return null;
        }
        let deviceUuid = Config.getgMyMachineId();

        for(let index = 0; index < this.sectionIds.length; index++) {
            let cell = this.sectionIds[index];
            if (cell.uuid == deviceUuid) {
                return cell;
            }
        }
        return null;
    }
}

export  default SettingData
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";
import TextAndIconCalculation from "./TextAndIconCalculation";


/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */
class TextAndIconAndLatexCalculation extends TextAndIconCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainLatextContent()) {
            return;
        }
        let latexContent = this.data.latexContent;
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null || latexContent == null) {
            return;
        }

        let size = this.includeIconAndTextAndLatexSize();
        let iconAndTextSize = this.includeIconAndTextSize();
        let marginPoint = this.getMargin(size);

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();

        let marginX = marginPoint.left;
        let marginY = marginPoint.top;
        let textEdgeInsets = this.getTextEdgeInsets();

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            latexContent.x = marginPoint.left + textEdgeInsets.left;
            latexContent.y = textMindElementContent.y + textMindElementContent.height;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;

        if (layoutType == IconLayoutType.LAYOUT_LEFT) {
            iconLeft = this.iconMargin;
            iconTop = (iconAndTextSize.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((iconAndTextSize.getHeight()) - textMindElementContent.height) / 2 + marginY;
            if (iconsSize.getHeight() > textMindElementContent.height) {
                latexContent.y = iconTop + iconsSize.getHeight() + marginY;
            } else {
                latexContent.y = textMindElementContent.y + textMindElementContent.height;
            }
        } else if (layoutType == IconLayoutType.LAYOUT_RIGHT) {
            iconLeft = (iconAndTextSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
            iconTop = (iconAndTextSize.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((iconAndTextSize.getHeight()) - textMindElementContent.height) / 2 + marginY;
            if (iconsSize.getHeight() > textMindElementContent.height) {
                latexContent.y = iconTop + iconsSize.getHeight() + marginY;
            } else {
                latexContent.y = textMindElementContent.y + textMindElementContent.height;
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            iconLeft = (iconAndTextSize.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = (this.iconSpace);
            textMindElementContent.x = ((iconAndTextSize.getWidth()) - (textConnectMapSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            latexContent.y = textMindElementContent.y + textMindElementContent.height;
        }
        latexContent.x = marginPoint.left + textEdgeInsets.left;
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = iconLeft + marginX;
            } else {
                iconCell.x = iconElementContents[index - 1].x + iconElementContents[index - 1].width + (this.iconSpace);
            }
            iconCell.y = iconTop + marginY;
        }

        latexContent.width = this.latexOriginalSize.getWidth();
        latexContent.height = this.latexOriginalSize.getHeight();
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}
export default TextAndIconAndLatexCalculation
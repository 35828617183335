
/**
 * Created by tony on 2021/12/16
 */

class PrintSizeCell {
    constructor(name, width, height) {
        this.name = name;
        this.width = width;
        this.height = height;
    }
}
export default PrintSizeCell
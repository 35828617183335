// package mind.yushu.com.mindmap.core.calcule.elementCalculation;

// import android.graphics.Point;
// import android.util.Size;

// import mind.yushu.com.mindmap.core.basemode.EdgeInsets;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGRect;
// import mind.yushu.com.mindmap.viewmodel.datatype.ImageLayoutType;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindElementShapeType;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindElementType;
// import mind.yushu.com.mindmap.viewmodel.datatype.NodeLayoutType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.ConnectMapElementContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.ImageContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.MindElementContent;


import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";


/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndConnectMap extends BaseMindElementDataCalculation {
    constructor(data, nodeLayoutType) {
        super(data, nodeLayoutType);
    }
    // public TextAndImageAndConnectMap(MindElementData data, NodeLayoutType nodeLayoutType) {
    //     super(data, nodeLayoutType);
    // }

    calculate() {
        if (this.data.imageContent == null || !this.data.isContainConnectMapContent()) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();

        let connectMapElementContent = this.data.connectMapElementContent;
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;

        let textConnectMapSize = this.includeConnectMapAndTextSize();
        let size = this.includeImageAndTextConnectMapSize();
        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;
        let connectMapTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2 + marginY;
            iconLeft = ((this.data.type == MindElementType.MAIN_SUBJECT || 
            this.data.type == MindElementType.SUBJECT || 
            this.data.type == MindElementType.CONTENT_GENERALIZATION  ||
            this.data.type == MindElementType.EXPLAIN) ? (this.iconMargin) : 0) + marginX;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + connectMapTextSpace;
            connectMapElementContent.y = ((size.getHeight()) - connectMapElementContent.height) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin) + marginX;
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2+ marginY;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + connectMapTextSpace;
            connectMapElementContent.y = ((size.getHeight()) - connectMapElementContent.height) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2 + marginX;
            iconTop = marginY;
            textMindElementContent.x = ((size.getWidth()) - (textConnectMapSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + this.iconSpace;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + connectMapTextSpace;
            connectMapElementContent.y = textMindElementContent.y + ((textConnectMapSize.getHeight()) - connectMapElementContent.height) / 2;
        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndImageAndConnectMap
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndIconAndRemarksAndConnectMap extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }
    calculate() {
        if (!this.data.isContainRemarksContent() || !this.data.isContainConnectMapContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let connectMapElementContent = this.data.connectMapElementContent;
        let remarksElementContent = this.data.remarksElementContent;

        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;
        remarksElementContent.width = textHeight;
        remarksElementContent.height = textHeight;

        let size = this.includeIconAndTextAndConnectMapAndRemarksSize();
        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;
        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let textConnectMapRemarksSize = this.includeTextAndRemarksAndConnectMapSize();
        let iconLeft = 0;
        let iconTop = 0;
        let iconAndTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == IconLayoutType.LAYOUT_LEFT) {
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconMargin) : 0;
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + iconAndTextSpace;
            connectMapElementContent.y = ((size.getHeight()) - connectMapElementContent.height) / 2 + marginY;
            remarksElementContent.x = connectMapElementContent.x + connectMapElementContent.width + (this.iconSpace);
            remarksElementContent.y = connectMapElementContent.y;

        } else if (layoutType == IconLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + iconAndTextSpace;
            connectMapElementContent.y = ((size.getHeight()) - connectMapElementContent.height) / 2 + marginY;
            remarksElementContent.x = connectMapElementContent.x + connectMapElementContent.width + (this.iconSpace);
            remarksElementContent.y = connectMapElementContent.y;

        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = (this.iconSpace);
            textMindElementContent.x = ((size.getWidth()) - (textConnectMapRemarksSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + iconAndTextSpace;
            connectMapElementContent.y = textMindElementContent.y + ((textConnectMapRemarksSize.getHeight()) - connectMapElementContent.height) / 2;
            remarksElementContent.x = connectMapElementContent.x + connectMapElementContent.width + (this.iconSpace);
            remarksElementContent.y = connectMapElementContent.y;

        }
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = iconLeft + marginX;
            } else {
                iconCell.x = iconElementContents[index - 1].x + iconElementContents[index - 1].width + (this.iconSpace);
            }
            iconCell.y = iconTop + marginY;
        }
        // this.setMindElementSize(size.getWidth(), size.getHeight(), marginX, marginY);
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}

export default TextAndIconAndRemarksAndConnectMap

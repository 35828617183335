// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

import MindElementContent from './MindElementContent.js';

// import mind.yushu.com.mindmap.viewmodel.datatype.ImageLayoutType;
import ImageLayoutType from '../../datatype/ImageLayoutType'

// import mind.yushu.com.mindmap.viewmodel.datatype.ImageShapeType;
import ImageShapeType from '../../datatype/ImageShapeType'

/**
 * Created by tony on 2020/5/27
 */

class ImageContent extends MindElementContent {
    constructor(key="",width,height){
        super(width,height);
        this.key = key;
        this.layoutType = ImageLayoutType.LAYOUT_TOP;
        this.shapeType = ImageShapeType.Circular;
        this.videoUrl = "";
    }
    steDatas(key,width,height,videoUrl) {
        this.width = width;
        this.height = height;
        this.key = key;
        this.videoUrl = videoUrl;
    }

    copy() {
        let data = new ImageContent();
        data.x = this.x;
        data.y = this.y;
        data.width = this.width;
        data.height = this.height;
        data.key = this.key;
        data.layoutType = this.layoutType;
        data.shapeType = this.shapeType;
        data.videoUrl = this.videoUrl;
        return data;
    }

    stickStyle(data) {
        if (data == null) {
            return;
        }
        this.width = data.width;
        this.height = data.height;
        this.layoutType = data.layoutType;
        this.shapeType = data.shapeType;
    }

    equal(data, ignoreInsidePoint = false) {
        let result = false;
        if (!ignoreInsidePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        result = result || data.width != this.width;
        result = result || data.height != this.height;
        result = result || data.key != this.key;
        result = result || data.layoutType != this.layoutType;
        result = result || data.shapeType != this.shapeType;
        result = result || data.videoUrl != this.videoUrl;
        return !result;
    }
    isVideo() {
        return !(this.key=="" || this.key==null) && !(this.videoUrl=="" || this.videoUrl==null);
    }

}

export default ImageContent

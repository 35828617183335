//正则表达式
const phoneNumberRegExp = /^[1][0-9][0-9]{9}$/;//手机号
const mailboxRegExp = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;//邮箱
const iCaptcha = /^[0-9]*$/;//验证码,0-9的数字
const passwordRegExp = /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,24}/i;//密码,由8-24位的“字母+数字”组成


export default {
    phoneNumberRegExp,
    mailboxRegExp,
    iCaptcha,
    passwordRegExp
}
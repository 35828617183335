// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * Created by tony on 2019/12/29
 */

let LineOrientation = {
    LEFT:1,
    TOP:2,
    RIGHT:3,
    BOTTOM:4,
    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.RIGHT;
    }

}

export default LineOrientation

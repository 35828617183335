import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndLinkAndRemarksCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }
    calculate() {
        if (this.data.imageContent == null || !this.data.isContainLinkContent() || !this.data.isContainRemarksContent()) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();
        let linkElementContent = this.data.linkElementContent;
        let remarksElementContent = this.data.remarksElementContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        linkElementContent.width = textHeight;
        linkElementContent.height = textHeight;
        remarksElementContent.width = textHeight;
        remarksElementContent.height = textHeight;

        let textLinkRemarksSize = this.includeTextAndLinkAndRemarksSize();
        let size = this.includeImageAndTextAndLinkAndRemarksSize();
        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));
        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;
        let linkTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconMargin) : 0;
            iconLeft = Math.min(iconTop, iconLeft);
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = ((size.getHeight()) - linkElementContent.height) / 2;
            remarksElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
            remarksElementContent.y = linkElementContent.y;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - iconMargin);
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = ((size.getHeight()) - linkElementContent.height) / 2;
            remarksElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
            remarksElementContent.y = linkElementContent.y;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = Math.min(iconLeft, (this.iconSpace));
            textMindElementContent.x = ((size.getWidth()) - (textLinkRemarksSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = textMindElementContent.y + ((textLinkRemarksSize.getHeight()) - linkElementContent.height) / 2;
            remarksElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
            remarksElementContent.y = linkElementContent.y;
        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        // this.setMindElementSize(size.getWidth(), size.getHeight(), marginX, marginY);
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}
export default TextAndImageAndLinkAndRemarksCalculation

import Colors from "../../../utils/Colors";
import BoldData from "./BoldData";

class TextStyleCell {
    constructor() {
        this.startIndex = 0;
        this.endIndex = 0;

        this.textColor = -10000;
        this.textColorAlpha = 1.0;
        this.textFontSize = 0;
        this.bgColor = -10000;
        this.textShadow = -10000;
        this.textBold = new BoldData();
        this.textItalics = new BoldData();
        this.textStrikethrough = new BoldData();

    }

    copy() {
        var data = new TextStyleCell();
        data.startIndex = this.startIndex;
        data.endIndex = this.endIndex;
        data.bgColor = this.bgColor;
        data.textColor = this.textColor;
        data.textShadow = this.textShadow;
        data.textFontSize = this.textFontSize;
        data.textBold = this.textBold.copy();
        data.textItalics = this.textItalics.copy();
        data.textStrikethrough = this.textStrikethrough.copy();
        data.textColorAlpha = this.textColorAlpha;
        return data;
    }

    equal(data) {
        var result = false;
        if (!Colors.isClear(data.textColor) || !Colors.isClear(this.textColor)) {
            result = result || data.textColor != this.textColor;
        }
        if (!Colors.isClear(data.bgColor) || !Colors.isClear(this.bgColor)) {
            result = result || data.bgColor != this.bgColor;
        }
        result = result || data.startIndex != this.startIndex;
        result = result || data.endIndex != this.endIndex;
        result = result || data.textShadow != this.textShadow;
        result = result || data.textFontSize != this.textFontSize;
        result = result || !data.textBold.equal(this.textBold);
        result = result || !data.textItalics.equal(this.textItalics);
        result = result || !data.textStrikethrough.equal(this.textStrikethrough);
        result = result || data.textColorAlpha != this.textColorAlpha;
        return !result;
    }

    setTextBold(textBold) {
        this.textBold.setValue(textBold);
    }

    setTextItalics(textItalics) {
        this.textItalics.setValue(textItalics);
    }

    setTextStrikethrough(textStrikethrough) {
        this.textStrikethrough.setValue(textStrikethrough);
    }

    isBackgorunColor() {
        return this.bgColor != -10000 && !Colors.isClear(this.bgColor);
    }

    isTextShadowColor() {
        return this.textShadow != -10000 && !Colors.isClear(this.textShadow);
    }

    isSetColor() {
        return this.textColor != -10000;
    }

    isSetFontSize() {
        return this.textFontSize > 0;
    }
}
export default TextStyleCell
// package mind.yushu.com.mindmap.core.calcule.elementCalculation;

// import android.graphics.Point;
// import android.util.Size;

// import mind.yushu.com.mindmap.core.basemode.EdgeInsets;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGRect;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindElementShapeType;
// import mind.yushu.com.mindmap.viewmodel.datatype.NodeLayoutType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.ConnectMapElementContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.LinkElementContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.MindElementContent;

import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndLinkAndConnectMap extends BaseMindElementDataCalculation {
    constructor(data, nodeLayoutType) {
        super(data, nodeLayoutType);
    }
    // public TextAndLinkAndConnectMap(MindElementData data, NodeLayoutType nodeLayoutType) {
    //     super(data, nodeLayoutType);
    // }

    calculate() {
        if (!this.data.isContainLinkContent() || !this.data.isContainConnectMapContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let linkElementContent = this.data.linkElementContent;
        let connectMapElementContent = this.data.connectMapElementContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        linkElementContent.width = textHeight;
        linkElementContent.height = textHeight;
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;

        let size = this.includeTextAndLinkAndConnectMapSize();
        let iconsSize = new Size((linkElementContent.width + connectMapElementContent.width) + this.iconSpace, (linkElementContent.height));


        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        if (!this.data.isContainLinkContent()) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

        linkElementContent.x = iconLeft + marginX;
        linkElementContent.y = iconTop + marginY;

        connectMapElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
        connectMapElementContent.y = linkElementContent.y;

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndLinkAndConnectMap

import {apiRequest} from './base'

//登录或注册-获取短信验证码
export function postUserCaptcha(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/captcha',req,onOk,onFail,eventually,headers,onProgress)
}
//登录或注册
export function postUserLogin(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/login',req,onOk,onFail,eventually,headers,onProgress)
}
//一键登陆
export function postUserFastLogin(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/fast-login',req,onOk,onFail,eventually,headers,onProgress)
}
//第三方登陆
export function postUserThirdLogin(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/third-login',req,onOk,onFail,eventually,headers,onProgress)
}
//获取微信web登陆二维码
export function postUserMpwxLoginQrcode(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/mpwx-login-qrcode',req,onOk,onFail,eventually,headers,onProgress)
}
//游客登录
export function postUserVisitorLogin(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/visitor-login',req,onOk,onFail,eventually,headers,onProgress)
}
//检测是否设置过密码
export function postUserCheckPassword(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/check-password',req,onOk,onFail,eventually,headers,onProgress)
}
//获取注册验证码
export function postUserRegisterCaptcha(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/register-captcha',req,onOk,onFail,eventually,headers,onProgress)
}
//用户注册
export function postUserRegister(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/register',req,onOk,onFail,eventually,headers,onProgress)
}
//密码登录
export function postUserPasswordLogin(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/password-login',req,onOk,onFail,eventually,headers,onProgress)
}
//忘记密码页面-获取验证码
export function postUserResetPasswordCaptcha(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/reset-password-captcha',req,onOk,onFail,eventually,headers,onProgress)
}
//忘记密码页面-重置密码
export function postUserResetPassword(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/reset-password',req,onOk,onFail,eventually,headers,onProgress)
}
//邮箱验证码
export function postUserEmailCaptcha(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/email-captcha',req,onOk,onFail,eventually,headers,onProgress)
}
//邮箱登陆
export function postUserEmailLogin(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/email-login',req,onOk,onFail,eventually,headers,onProgress)
}
//邮箱注册
export function postUserEmailRegister(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/email-register',req,onOk,onFail,eventually,headers,onProgress)
}
//邮箱重置密码
export function postUserEmailResetPassword(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/email-reset-password',req,onOk,onFail,eventually,headers,onProgress)
}
//初次安装请求
export function postUserInstall(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/install',req,onOk,onFail,eventually,headers,onProgress)
}
//获取通用配置
export function postBaseGlobalConfig(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/base/global-config',req,onOk,onFail,eventually,headers,onProgress)
}
//学生认证状态
export function postUserStudentInfo(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/student-info',req,onOk,onFail,eventually,headers,onProgress)
}
//存储空间使用情况
export function postUserStorageInfo(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/storage-info',req,onOk,onFail,eventually,headers,onProgress)
}
//检查更新
export function postUserCheckUpdate(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/check-update',req,onOk,onFail,eventually,headers,onProgress)
}
//获取特定设备类型的最新版本
export function postUserLatestRelease(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/latest-release',req,onOk,onFail,eventually,headers,onProgress)
}
//获取会员价格
export function postPayPrice(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/price',req,onOk,onFail,eventually,headers,onProgress)
}
//了解权益
export function postPayMemberRights(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/member-rights',req,onOk,onFail,eventually,headers,onProgress)
}
//获取支付方式
export function postPayMethods(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/methods',req,onOk,onFail,eventually,headers,onProgress)
}
//获取可购买的优惠券类型
export function postPayCouponTypes(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/coupon-types',req,onOk,onFail,eventually,headers,onProgress)
}
//获取可购买的激活码类型
export function postPayFcodeTypes(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/fcode-types',req,onOk,onFail,eventually,headers,onProgress)
}
//获取用于绑定劵码的短信验证码
export function postPayBindCodeCaptcha(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/bind-code-captcha',req,onOk,onFail,eventually,headers,onProgress)
}
//绑定劵码到手机号
export function postPayBindCode(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/bind-code',req,onOk,onFail,eventually,headers,onProgress)
}
//验证激活码
export function postPayVerifyFcode(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/verify-fcode',req,onOk,onFail,eventually,headers,onProgress)
}
//验证优惠券
export function postPayVerifyCoupon(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/verify-coupon',req,onOk,onFail,eventually,headers,onProgress)
}
//我的信息
export function postUserMe(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/me',req,onOk,onFail,eventually,headers,onProgress)
}
//账户安全信息
export function postUserAccount(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/account',req,onOk,onFail,eventually,headers,onProgress)
}
//修改密码-获取验证码
export function postUserChangePasswordCaptcha(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/change-password-captcha',req,onOk,onFail,eventually,headers,onProgress)
}
//修改密码
export function postUserChangePassword(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/change-password',req,onOk,onFail,eventually,headers,onProgress)
}
//退出登录
export function postUserLogout(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/logout',req,onOk,onFail,eventually,headers,onProgress)
}
//绑定新的手机号
export function postUserChangePhone(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/change-phone',req,onOk,onFail,eventually,headers,onProgress)
}
//修改邮箱
export function postUserChangeEmail(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/change-email',req,onOk,onFail,eventually,headers,onProgress)
}
//修改第三方登录
export function postUserChangeThirdLogin(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/change-third-login',req,onOk,onFail,eventually,headers,onProgress)
}
//查看已登陆设备
export function postUserBoundMachines(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/bound-machines',req,onOk,onFail,eventually,headers,onProgress)
}
//移除已登陆的设备
export function postUserUnbindMachine(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/unbind-machine',req,onOk,onFail,eventually,headers,onProgress)
}
//获取可用的优惠券
export function postPayAvailableCoupons(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/available-coupons',req,onOk,onFail,eventually,headers,onProgress)
}
//获取可用激活码
export function postPayAvailableFcodes(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/available-fcodes',req,onOk,onFail,eventually,headers,onProgress)
}
//支付下单
export function postPayPay(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/pay',req,onOk,onFail,eventually,headers,onProgress)
}
//使用激活码
export function postPayUseFcode(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/use-fcode',req,onOk,onFail,eventually,headers,onProgress)
}
//轮训支付结果
export function postPayQueryOrder(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/query-order',req,onOk,onFail,eventually,headers,onProgress)
}
//领取激活码
export function postPayReceiveFcode(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/receive-fcode',req,onOk,onFail,eventually,headers,onProgress)
}
//领取优惠券
export function postPayReceiveCoupon(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/receive-coupon',req,onOk,onFail,eventually,headers,onProgress)
}
//支付宝回调接口
export function postPayAlipayNotify(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/alipay-notify',req,onOk,onFail,eventually,headers,onProgress)
}
//微信支付回调借口
export function postPayWechatpayNotify(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/pay/wechatpay-notify',req,onOk,onFail,eventually,headers,onProgress)
}
//微信公众号回调
export function allBaseMpwxCallback(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('ALL','/base/mpwx-callback',req,onOk,onFail,eventually,headers,onProgress)
}
//二维码登录-获取二维码
export function postUserQrcodeGet(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/qrcode-get',req,onOk,onFail,eventually,headers,onProgress)
}
//二维码登录-轮询二维码状态
export function postUserQrcodeStatus(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/qrcode-status',req,onOk,onFail,eventually,headers,onProgress)
}
//二维码登录-客户端扫码
export function postUserQrcodeScan(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/qrcode-scan',req,onOk,onFail,eventually,headers,onProgress)
}
//二维码登录-客户端点击确认登录
export function postUserQrcodeLogin(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/qrcode-login',req,onOk,onFail,eventually,headers,onProgress)
}
//二维码登录-客户端点击取消登录
export function postUserQrcodeCancel(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/qrcode-cancel',req,onOk,onFail,eventually,headers,onProgress)
}
//我的订单列表
export function postUserMyOrders(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/my-orders',req,onOk,onFail,eventually,headers,onProgress)
}
//优惠券列表
export function postUserMyCoupons(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/my-coupons',req,onOk,onFail,eventually,headers,onProgress)
}
//查询优惠券
export function postUserQueryCoupon(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/query-coupon',req,onOk,onFail,eventually,headers,onProgress)
}
//激活码列表
export function postUserMyFcodes(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/my-fcodes',req,onOk,onFail,eventually,headers,onProgress)
}
//绑定优惠券到特定用户
export function postUserBindCoupon(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/bind-coupon',req,onOk,onFail,eventually,headers,onProgress)
}
//绑定激活码到特定用户
export function postUserBindFcode(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/bind-fcode',req,onOk,onFail,eventually,headers,onProgress)
}
//查询激活码
export function postUserQueryFcode(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/query-fcode',req,onOk,onFail,eventually,headers,onProgress)
}
//下载优惠券或激活码到excel文件
export function postUserDownloadCouponsOrFcodes(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/download-coupons-or-fcodes',req,onOk,onFail,eventually,headers,onProgress)
}
//我的分享预览（包含私密）
export function postUserMyShares(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/my-shares',req,onOk,onFail,eventually,headers,onProgress)
}
//我的收藏预览（包含私密）
export function postUserMyCollects(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/my-collects',req,onOk,onFail,eventually,headers,onProgress)
}
//列出我分享的项目
export function postUserMySharedItems(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/my-shared-items',req,onOk,onFail,eventually,headers,onProgress)
}
//列出我的收藏夹列表
export function postUserMyCollections(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/my-collections',req,onOk,onFail,eventually,headers,onProgress)
}
//浏览我的收藏夹下的收藏项
export function postUserMyCollectedItems(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/my-collected-items',req,onOk,onFail,eventually,headers,onProgress)
}
//取消收藏/从收藏夹删除某项
export function postUserDeleteCollectItem(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/delete-collect-item',req,onOk,onFail,eventually,headers,onProgress)
}
//新建收藏夹
export function postUserNewCollection(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/new-collection',req,onOk,onFail,eventually,headers,onProgress)
}
//重命名收藏夹
export function postUserRenameCollection(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/rename-collection',req,onOk,onFail,eventually,headers,onProgress)
}
//修改收藏夹权限
export function postUserModifyCollection(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/modify-collection',req,onOk,onFail,eventually,headers,onProgress)
}
//删除收藏夹
export function postUserDeleteCollection(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/delete-collection',req,onOk,onFail,eventually,headers,onProgress)
}
//移动收藏项
export function postUserMoveCollectItem(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/move-collect-item',req,onOk,onFail,eventually,headers,onProgress)
}
//将别人的收藏夹保存到我的
export function postUserSaveCollection(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/save-collection',req,onOk,onFail,eventually,headers,onProgress)
}
//上传苹果设备会员信息
export function postUserUploadAppleMember(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/upload-apple-member',req,onOk,onFail,eventually,headers,onProgress)
}
//获取苹果设备会员信息
export function postUserGetAppleMember(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/get-apple-member',req,onOk,onFail,eventually,headers,onProgress)
}
//我的邀请码
export function postUserMyInvitation(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/my-invitation',req,onOk,onFail,eventually,headers,onProgress)
}
//获取微信JS-SDK签名
export function postBaseWxSignature(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/base/wx-signature',req,onOk,onFail,eventually,headers,onProgress)
}
//拉取弹窗消息
export function postBaseGetPushMessage(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/base/get-push-message',req,onOk,onFail,eventually,headers,onProgress)
}
//我的界面banner广告
export function postBaseMeBanner(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/base/me-banner',req,onOk,onFail,eventually,headers,onProgress)
}
//留言板
export function postBaseSendLeaveNote(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/base/send-leave-note',req,onOk,onFail,eventually,headers,onProgress)
}
//官网文章查询
export function postBaseQueryArticles(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/base/query-articles',req,onOk,onFail,eventually,headers,onProgress)
}
//官网文章查看页
export function postBaseGetArticle(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/base/get-article',req,onOk,onFail,eventually,headers,onProgress)
}
//邀请实时排行榜
export function postBaseTopInvitors(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/base/top-invitors',req,onOk,onFail,eventually,headers,onProgress)
}
//统计访问量
export function postBaseAccessStat(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/base/access-stat',req,onOk,onFail,eventually,headers,onProgress)
}
//获取系统提供的默认头像
export function postUserListSystemAvatars(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/list-system-avatars',req,onOk,onFail,eventually,headers,onProgress)
}
//修改用户名
export function postUserChangeUsername(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/change-username',req,onOk,onFail,eventually,headers,onProgress)
}
//修改头像
export function postUserChangeAvatar(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/change-avatar',req,onOk,onFail,eventually,headers,onProgress)
}
//修改个性签名
export function postUserChangeMotto(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/change-motto',req,onOk,onFail,eventually,headers,onProgress)
}
//申请学生认证
export function postUserApplyStudent(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/apply-student',req,onOk,onFail,eventually,headers,onProgress)
}
//获取用户主页
export function postUserProfile(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/profile',req,onOk,onFail,eventually,headers,onProgress)
}
//列出用户分享的项
export function postUserQuerySharedItems(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/query-shared-items',req,onOk,onFail,eventually,headers,onProgress)
}
//列出用户的收藏夹列表
export function postUserQueryCollections(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/query-collections',req,onOk,onFail,eventually,headers,onProgress)
}
//查看用户收藏夹下面的项目
export function postUserQueryCollectItems(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/user/query-collect-items',req,onOk,onFail,eventually,headers,onProgress)
}

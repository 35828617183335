const { default: strings } = require("./strings");

var stringMap = {};
stringMap[strings.About_App_Name] = "Taswirar hankali +";
stringMap[strings.TEMPLATE] = "samfuri";
stringMap[strings.Mind_Edit_Main_Topic] = "Branch taken";
stringMap[strings.Global_Add_Free_Node_Tips] = "Latsa allon don zaɓar wuri";
stringMap[strings.Global_Add_Free_Chart_Node_Tips] = "Taɓa allo sau biyu don zaɓar wurin da za a ƙara";
stringMap[strings.Global_Add_Relationship_Tips] = "Za thei manufa da za a hade";
stringMap[strings.Global_Add_Target_Tips] = "Da fatan za a zaɓi kumburi don ƙara abubuwa";
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] = "Wannan abun yana da alaƙa da sauran taswira";
stringMap[strings.Mind_Edit_Main_Idea] = "Jigon tsakiya";
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = "Babu taswirar da ke hade";
stringMap[strings.Mind_Style_Add_Icon_Priority] = "fifiko";
stringMap[strings.Mind_Style_Add_Icon_Progress] = "jadawalin";
stringMap[strings.Mind_Style_Add_Icon_Stars] = "tauraro";
stringMap[strings.Mind_Style_Add_Icon_Week] = "mako";
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] = "Avatar";
stringMap[strings.Establish] = "halitta";
stringMap[strings.Create_From_Template] = "Fromirƙira daga samfuri";
stringMap[strings.Common] = "Duniya";
stringMap[strings.Function_List] = "jerin aiki";
stringMap[strings.Recent_Updates] = "Kwanan nan aka sabunta";
stringMap[strings.The_Latest_To_Delete] = "An share kwanan nan";
stringMap[strings.Setting_Up] = "tuntube mu";
stringMap[strings.Usinghelp] = "Amfani da taimako";
stringMap[strings.The_Download_Client] = "Zazzage abokin ciniki / wayar hannu";
stringMap[strings.Global_Purchase] = "saya";
stringMap[strings.Global_Login] = "shiga";
stringMap[strings.My_map] = "Taswira na";
stringMap[strings.Create_Map] = "Irƙiri taswira";
stringMap[strings.Storm] = "hadari";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = "Na kowa \ nfeatures";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = "Fasali";
stringMap[strings.Mind_Organization_Edit_Main_Idea] = "Cibiyar kungiya";
stringMap[strings.Mind_Edit_Free_Topic] = "Jigon kyauta";
stringMap[strings.Mind_Edit_Sub_Topic] = "Subtopic";
stringMap[strings.Mind_Organization_Main_Topic] = "sashen";
stringMap[strings.Mind_Edit_Main_Topic] = "Branch taken";
stringMap[strings.Mind_Time_Edit_Main_Idea] = "tsarin gudanarwa";
stringMap[strings.Mind_Time_Edit_Head] = "lokaci";
stringMap[strings.Mind_Time_Edit_Title] = "shirya";
stringMap[strings.Mind_Edit_Details] = "Cikakken Bayani";
stringMap[strings.Checking_Data] = "duba bayanai";
stringMap[strings.Setting_Information] = "Kafa bayani";
stringMap[strings.Sign_Out] = "fita";

//jerin aiki
stringMap[strings.Create_a_Mind_Map] = "Irƙiri taswirar hankali";
stringMap[strings.Create_Mind_Map_1] = "[1] Createirƙira taswira na shimfidu 14 (hanya biyu / dama / hagu / tsarin ƙungiya / jadawalin kumfa guda ɗaya) / S lokacin / dala)";
stringMap[strings.Create_Mind_Map_2] = "[2] Ana iya amfani da samfura don ƙirƙirar taswira da sauri (adadi mai yawa na nassoshin abun ciki na samfuri)";
stringMap[strings.Create_Mind_Map_3] = "[3] Gyara nau'ikan salon layi tara akan taswirar";
stringMap[strings.Create_Mind_Map_4] = "[4] Shirya shimfidu tara na taswirar";
stringMap[strings.Create_Mind_Map_5] = "[5] Addara nodes ɗin yara zuwa mahadar niyya kuma ƙara nodu a daidai matakin (babba da ƙananan)";
stringMap[strings.Create_Mind_Map_6] = "[6] Abstract: Bayani game da kumburi guda / ƙari mai yawa [Abstract]";
stringMap[strings.Create_Mind_Map_7] = "[7] Toshe: ƙara [toshewa] zuwa akwatin kumburi ɗaya / akwatin mahaɗi da yawa na taswirar don yin kwaskwarima";
stringMap[strings.Create_Mind_Map_8] = "[8] nara kumburi kyauta";
stringMap[strings.Create_Mind_Map_9] = "[9] Createirƙiri ƙungiya ta salon layi huɗu tsakanin kowane yanki";
stringMap[strings.Create_Mind_Map_10] = "[10] Saka hotuna (na cikin gida + laburaren kayan abu), gumaka, bayanan kula, hanyoyin haɗi zuwa kumburin";
stringMap[strings.Create_Mind_Map_11] = "[11] Kwafi / liƙa kumburi, kwafa / liƙa salon";
stringMap[strings.Create_Mind_Map_12] = "[12] Sokewa, sake dawowa";
stringMap[strings.Create_Mind_Map_13] = "[13] Akwai zaɓuɓɓuka da yawa, kuma nodes da yawa suna aiki lokaci guda";
stringMap[strings.Create_Mind_Map_14] = "[14] Za'a iya saka isticsididdiga";
stringMap[strings.Create_Mind_Map_15] = "[15] Ana iya yin rikodin duka taswirar";
stringMap[strings.Create_Mind_Map_16] = "[16] Kwafin taswira";
stringMap[strings.Create_Mind_Map_17] = "[17] Taswirori waɗanda za su iya kwafa taswira ko taswirar duka zuwa wasu fayiloli";
stringMap[strings.Create_Mind_Map_18] = "[18] Ana iya ƙirƙirar taswira bayan m";
stringMap[strings.Create_Mind_Map_19] = "[19] Lokacin gyara, za ka iya sauya taswirar taswira (gefen kwamfuta)";
stringMap[strings.Create_Mind_Map_20] = "[20]map Taswirar gyara maɓallin kewayawa ta Bluetooth: \ n \ t [Umurnin] + [a] Zaɓi babban kumburi \ n \ t [Umurnin] + [←] [↑] [→] [↓] Canza maɓallin da aka zaɓa ko matsar da taswira \ n \ t [Umurnin] + [e] Shirya manufa \ n \ t [Umurnin] + [w] Createirƙirar kumburin yaro \ n \ t [Umurnin] + [s] Createirƙira kumburin ɗan uwansu da aka nufa (sama) \ n \ t [Umurnin] + [d] Createirƙira kumburin ɗan'uwan niyya (ƙasa) \ n \ t [Umurnin] + [q] Fita daga abin da aka zaɓa \ n \ t [Umurnin] + [-] [+] Taswirar zuƙowa ";

stringMap[strings.Beautify_The_Mind_Map] = "Kawata taswirar hankali";
stringMap[strings.Beautify_The_Mind_Map_1] = "[1] Sanya launin bango na taswirar hankali";
stringMap[strings.Beautify_The_Mind_Map_2] = "[2] Sanya kauri da launi na dukkan taswirar, ko an manne shi ko a'a";
stringMap[strings.Beautify_The_Mind_Map_3] = "[3] Saita launi mai cika rubutu, launi iyaka, siffofi kan iyaka 13, faɗin iyaka, tasirin inuwa";
stringMap[strings.Beautify_The_Mind_Map_4] = "[4] Sanya duka ko ɓangaren launi layi da nisa na taswirar";
stringMap[strings.Beautify_The_Mind_Map_5] = "[5] Saita girman rubutu, mai ƙarfi, baƙaƙe, da share salo";
stringMap[strings.Beautify_The_Mind_Map_6] = "[6] Saita girman hoto, matsayi, salon fasali";
stringMap[strings.Beautify_The_Mind_Map_7] = "[7] Sanya gumaka na rukuni bakwai (na kowa / 3D / karatu / aiki / hali / dabba / abinci) kuma saita girman / matsayin";
stringMap[strings.Beautify_The_Mind_Map_8] = "[8] referenceara nunin salon salon danna sau ɗaya akan duka taswirar";
stringMap[strings.Beautify_The_Mind_Map_9] = "[9] map Taswirar gudanarwa ta rukuni";
stringMap[strings.Beautify_The_Mind_Map_10] = "[10] Za a iya ƙara bayanan da keɓaɓɓu zuwa cikin taswirar gaba ɗaya (laburaren kayan abu)";
stringMap[strings.Beautify_The_Mind_Map_9] = "[11] Iyakokin kowane ɗaiɗaikun mutane ko bayan fage za a iya ƙara su a kan kumburi ɗaya (laburaren kayan abu)";
stringMap[strings.Beautify_The_Mind_Map_9] = "[12] Za a iya jan tsawon kumburin";


stringMap[strings.Map_Output] = "Taswirar taswira";
stringMap[strings.Map_Output_1] = "[1]Ajiye hoton";
stringMap[strings.Map_Output_2] = "[2] Ajiye PDF";
stringMap[strings.Map_Output_3] = "[3] Share mahada";
stringMap[strings.Map_Output_4] = "[4] Share zuwa hadari";
stringMap[strings.Map_Output_4] = "[5] Buga";

stringMap[strings.Work_together] = "yi aiki tare";
stringMap[strings.Work_together_1] = "[1] Createirƙiri rukunin da aka raba (lakabin rukuni, sanarwar ƙungiya)";
stringMap[strings.Work_together_2] = "[2] Addara sabbin mambobin kungiyar rabawa";
stringMap[strings.Work_together_3] = "[3]members Membobi da yawa a cikin rukunin suna shirya taswira ɗaya tare";
stringMap[strings.Work_together_4] = "[4] Loda fayilolin da aka raba a cikin rukuni (kalma / Excel / pdf)";
stringMap[strings.Work_together_5] = "[5] Zaka iya barin saƙo a cikin ƙungiyar";
stringMap[strings.Work_together_6] = "[6] setting Raba ikon hukumar gudanarwa";
stringMap[strings.Work_together_7] = "[7] Rushewar rukuni";

stringMap[strings.Work_together] = "Rarraba Ilimi (Hadari)";
stringMap[strings.Community_Sharing_1] = "[1] Kuna iya raba halittarku ga hadari, don mutane da yawa su gani";
stringMap[strings.Community_Sharing_1] = "[2] Zaka iya zaɓar alamun don duba abin da ya shafi guguwar";
stringMap[strings.Community_Sharing_1] = "[3] Kuna iya so da sharhi akan ayyukan Storm";

//Kwanan nan aka sabunta
stringMap[strings.Recently_Updated_Title_1] = "Irƙiri asusu na sirri";
stringMap[strings.Recently_Updated_Title_2] = "Tushen ilimin ilimin samfuri";
stringMap[strings.Recently_Updated_Title_3] = "Goyi bayan dandamali da yawa";
stringMap[strings.Recently_Updated_Title_4] = "Sanya kowane daki-daki";
stringMap[strings.Recently_Updated_Title_5] = "Keɓaɓɓun salon salo na musamman";
stringMap[strings.Recently_Updated_Title_6] = "Taswirar taswira";
stringMap[strings.Recently_Updated_Title_7] = "Ma'ajin girgije";
stringMap[strings.Recently_Updated_Title_8] = "Taswirori iri goma sha biyar";


stringMap[strings.Recently_Updated_Content_1] = "Createirƙiri asusun sirri, kuma adana ƙirƙirar taswirar tare da asusun";
stringMap[strings.Recently_Updated_Content_2] = "Ana ambaton asalin ilimin samfurin samfuri kai tsaye don inganta ƙwarewar ilmantarwa da aiki";
stringMap[strings.Recently_Updated_Content_3] = "Tallafa sigar gidan yanar gizo kan samar da intanet, IOS mobile terminal, Mac terminal, Android mobile terminal, Android tablet multi-platform platform";
stringMap [strings.Recently_Updated_Content_4] = "Zanen rubutu, bango, layuka, gumakan hoto, da sauransu ana iya kawata su gwargwadon abubuwan da kuka fi so";
stringMap [strings.Recently_Updated_Content_5] = "An ambaci ɗakin karatun salon salo kai tsaye, yana kawar da matsalar daidaita launi";
stringMap [strings.Recently_Updated_Content_6] = "Za a iya adana hotuna, tsarin PDF, kuma a raba tare da abokai kai tsaye";
stringMap [strings.Recently_Updated_Content_7] = "supportarfin ajiyar girgije mai ƙarfi, ba damuwa da asarar data";
stringMap [strings.Recently_Updated_Content_8] = "Hanya biyu, dama, hagu, tsarin tsari, tsarin zane guda, zane guda biyu, jadawalin itace na hagu, ginshiƙi na dama, ginshiƙin itace mai hanya biyu, tebur, jerin lokutan kwance, Tsarin lokaci na tsaye, S jerin lokuta, shimfidawa takwas za a iya sauya yadda ake so ";

//An share kwanan nan
stringMap [strings.Mind_Mindmap_Deleted_Title] = "An Goge kwanan nan";
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = "Maidawa";
stringMap [strings.Mind_Mindmap_Deleted_Forever] = "Share";
stringMap [strings.Mind_Mindmap_Deleted_Filename] = "Sunan fayil";
stringMap [strings.Mind_Mindmap_Deleted_Tips] = "Bayan an goge shi a nan, za a cire shi dindindin";
stringMap [strings.Mind_Mindmap_Deleted_Operating] = "Aiki";
stringMap [strings.Mind_Mindmap_Deleted_Selected] = "Binciko taswirar da aka goge kwanan nan";
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = "Share Kwanan Wata";

//tuntube mu
stringMap [strings.Contact_Us_No_Public] = "Asusun Jama'a";
stringMap [strings.Contact_Us_QQ_Group] = "Kungiyar QQ";
stringMap [strings.Contact_Us_Message_Board] = "Kwamitin Sako";
stringMap [strings.Contact_Us_Username] = "Suna";
stringMap [strings.Contact_Us_Email] = "Imel";
stringMap [strings.Contact_Us_Voicemail] = "Da fatan za a bar mahimman bayananku";
stringMap [strings.Contact_Us_Send_Email] = "Aika Imel";

//tantance sako
stringMap [strings.Input_Username_isNull] = "Suna ba zai zama fanko ba";
stringMap [strings.Input_Email_isNull] = "Da fatan a shigar da adireshin imel daidai";
stringMap [strings.Input_Voicemail_isNull] = "Da fatan za a shigar da abun cikin aikawa";
stringMap [strings.Input_Introduction_isNull] = "Gabatarwa ba zata zama fanko ba";
stringMap [strings.Input_Title_isNull] = "Take ba zai iya zama fanko ba";

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = "Da fatan a shigar da lambar wayar daidai";
stringMap [strings.Input_Please_Enter_Verification_Code] = "Da fatan za a shigar da lambar tabbatarwa!";
stringMap [strings.Input_Please_Enter_The_Password] = "Da fatan za a shigar da kalmar sirri!";
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = "Tsarin kalmar wucewa ba daidai bane!";
stringMap [strings.Input_Please_Enter_A_New_Password] = "Da fatan za a shigar da sabon kalmar sirri!";
stringMap [strings.Input_Confirm_The_New_Password] = "Da fatan za a shigar da kalmar tabbatarwa!";
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = "Kalmomin shiga basu dace ba!";
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = "Da fatan a shigar da lambar tabbatarwa daidai!";
stringMap [strings.Input_The_Verification_Code_Error] = "Kuskuren lambar tabbatarwa!";

//Amfani da taimako
stringMap [strings.Mind_Usinghelp_1] = "Createirƙiri taswirar hankali";
stringMap [strings.Mind_Usinghelp_2] = "Yadda za a ƙara sabon kumburi";
stringMap [strings.Mind_Usinghelp_3] = "Createirƙira kumburi kyauta";
stringMap [strings.Mind_Usinghelp_4] = "Kafa Associationungiya";
stringMap [strings.Mind_Usinghelp_5] = "kawata zane";
stringMap [strings.Mind_Usinghelp_6] = "kawata rubutu";
stringMap [strings.Mind_Usinghelp_7] = "Saka bayanan kula, hanyoyin haɗi";
stringMap [strings.Mind_Usinghelp_8] = "Saka Alamar";
stringMap [strings.Mind_Usinghelp_9] = "Saka Hoto";
stringMap [strings.Mind_Usinghelp_10] = "Yadda za a dawo da sharewa";
stringMap [strings.Mind_Usinghelp_11] = "Fitar da Taswirar Zuciya";
stringMap [strings.Mind_Usinghelp_12] = "Ingantaccen Memberaukaka";
stringMap [strings.Mind_Usinghelp_13] = "Maɓallin Gajerar hanya";
stringMap [strings.Mind_Usinghelp_Understood] = "Na samu";
stringMap [strings.Mind_Usinghelp_14] = "Saka Laburare Na Kayan";

//Yi amfani da taimako-2
stringMap [strings.Mind_Usinghelp_15] = "Sabon Taswira";

// stringMap [strings.Mind_Usinghelp_2] = "Nara Node";
// stringMap [strings.Mind_Usinghelp_4] = "Kafa Associationungiya";
// stringMap [strings.Mind_Usinghelp_5] = "ifyawata zane";
// stringMap [strings.Mind_Usinghelp_6] = "Inganta Rubutu";
// stringMap [strings.Mind_Usinghelp_7] = "notesara bayanan kula, hanyoyin haɗi";
stringMap [strings.Mind_Usinghelp_17] = "Maimaita aiki, soke";
stringMap [strings.Mind_Usinghelp_18] = "Ja kumburi don musanya";
stringMap [strings.Mind_Usinghelp_19] = "Jeri Node";
stringMap [strings.Mind_Usinghelp_20] = "Canja Nau'in Taswira";
stringMap [strings.Mind_Usinghelp_21] = "Salon Layin Taswira";
stringMap [strings.Mind_Usinghelp_22] = "Launin asalin taswira";
stringMap [strings.Mind_Usinghelp_23] = "Kafa Siffar Yanki";
stringMap [strings.Mind_Usinghelp_24] = "Salon Layin Node";
stringMap [strings.Mind_Usinghelp_25] = "Saitunan Hoto Node";
stringMap [strings.Mind_Usinghelp_26] = "Saitunan Node Node";
stringMap [strings.Mind_Usinghelp_27] = "Node Sara Takaitawa";
stringMap [strings.Mind_Usinghelp_28] = "Zaɓin akwatin kumburi ɗaya";
stringMap [strings.Mind_Usinghelp_29] = "Mahara nodes cikin tubalan";
stringMap [strings.Mind_Usinghelp_30] = "Node ƙara iyaka";
stringMap [strings.Mind_Usinghelp_31] = "Mapara Taswirar Statididdiga";
stringMap [strings.Mind_Usinghelp_32] = "ideoye kumburi da murmurewa";
stringMap [strings.Mind_Usinghelp_33] = "Hanyoyin Node da yawa";
stringMap [strings.Mind_Usinghelp_34] = "Canja Salo";
stringMap [strings.Mind_Usinghelp_35] = "Saka murya da bayani";
stringMap [strings.Mind_Usinghelp_36] = "Taswirar Jagora zuwa Hoto";
stringMap [strings.Mind_Usinghelp_37] = "Taswirar PDF daftarin aiki";
stringMap [strings.Mind_Usinghelp_38] = "Taswirar Buga";
stringMap [strings.Mind_Usinghelp_39] = "Raba Haɗin Kai da Dubawa";
stringMap [strings.Mind_Usinghelp_40] = "Raba taswirar ga hadari";
stringMap [strings.Mind_Usinghelp_41] = "Amfani da Jaka";
stringMap [strings.Mind_Usinghelp_42] = "Share kuma dawo da taswira";
//Yi amfani da taimako 3
stringMap [strings.Mind_Usinghelp_43] = "Abokai sun nemi shiga kungiyar";
stringMap [strings.Mind_Usinghelp_44] = "Yadda ake narkar da rukuni";
stringMap [strings.Mind_Usinghelp_45] = "Loda bayanan fayil";
stringMap [strings.Mind_Usinghelp_46] = "Yarda da shiga aikin";
stringMap [strings.Mind_Usinghelp_47] = "Gyara Haɗin Gwiwa";
stringMap [strings.Mind_Usinghelp_48] = "Sabuwar Rukunin Raba Raba";
stringMap [strings.Mind_Usinghelp_49] = "Gayyatar mambobin rukuni";

//hadari
stringMap [strings.Mind_Storm_Recommend] = "Shawara";
stringMap [strings.Mind_Storm_Boilingpoint] = "Wurin Tafasa";
stringMap [strings.Mind_Storm_Unfold] = "An buɗe";
stringMap [strings.Mind_Storm_Putaway] = "Putaway";
stringMap [strings.Mind_Storm_Myclassification] = "Nawa Nawa";
stringMap [strings.Mind_Storm_Recommended_Classification] = "endedwararren Classayyadewa";
stringMap [strings.Mind_Storm_Click_Add_Category] = "Danna don ƙara rukunin";
stringMap [strings.Mind_Storm_No_Classification] = "Babu rarrabuwa tukuna";
stringMap [strings.Mind_Storm_No_Tinterested] = "Ba sha'awar";
stringMap [strings.Mind_Storm_Report] = "Rahoton";
stringMap [strings.Mind_Storm_Views] = "Yawan Kallo";

stringMap [strings.Mind_Storm_Pornographic] = "Batsa da Vulgar";
stringMap [strings.Mind_Storm_Advertising_Harassment] = "Tursasawar Talla";
stringMap [strings.Mind_Storm_Political_Religion] = "Siyasa da Addini";
stringMap [strings.Mind_Storm_Infringement] = "Keta dokawa (hoto, batanci, sata, yin amfani da zamba)";
stringMap [strings.Mind_Storm_Prohibited_Content] = "Haramtaccen Abun ciki";
stringMap [strings.Mind_Storm_Spread_Rumors] = "Yada jita-jita";
stringMap [strings.Mind_Storm_Report_Successful] = "Rahoton ya Ci nasara";

//gyara
stringMap [strings.Global_Edit] = "Shirya";
stringMap [strings.Global_Cancel] = "Soke";
stringMap [strings.Global_Ok] = "Yayi";
stringMap [strings.Global_Name] = "Sunan suna";
stringMap [strings.Global_Introduction] = "Gabatarwa";
stringMap [strings.Global_Change_Avatar] = "Canja Avatar";
stringMap [strings.Global_Local_Upload] = "Shigar da Gida";

//bayanan sirri
stringMap [strings.User_Personal_information] = "Bayanin Sirri";
stringMap [strings.User_Member_Validity_Period] = "Lokacin Memberimar Ingantaccen Memba"
stringMap [strings.User_My_Share] = "Raba ta";
stringMap [strings.User_My_Collection] = "Colleungiyata";
stringMap [strings.User_More_Message] = "Moreari";
stringMap [strings.User_Deleted_Sotrm] = "Share Guguwa";
stringMap [strings.User_Folder_Rename] = "Sake suna";
stringMap [strings.User_Folder_Rename_Root] = "Canza Izini";
stringMap [strings.User_Folder_Rename_Root_Title] = "Canza Izini";
stringMap [strings.User_Folder_Root_Public] = "Cikakken jama'a";
stringMap [strings.User_Folder_Root_Viewonly] = "Fayil din jama'a kawai aka yarda a kalla shi";
stringMap [strings.User_Folder_Root_Private] = "Masu zaman kansu";
stringMap [strings.User_Folder_Root_Public_Introduction] = "Wasu na iya duba abubuwan da kafi so a shafinka na furofayil";
stringMap [strings.User_Message_Link] = "Haɗi";
stringMap [strings.User_New_Group] = "Sabon Rukuni";
stringMap [strings.User_New_Group_Title] = "Createirƙiri sabon rukuni";
stringMap [strings.User_New_Group_Style_Title] = "Nau'in Fayil";
stringMap [strings.User_Share_Is_Null] = "Babu rabawa tukuna!";
stringMap [strings.User_Deleted_Storm_Tips] = "Shin kun tabbata kuna son share wannan guguwar?";
stringMap [strings.User_Remove_From_List] = "Cire daga lissafin",
     stringMap [strings.User_Move_To] = "Matsar zuwa",
     stringMap [strings.User_Deleted_Link] = "Share hanyar haɗi"
stringMap [strings.User_Collction_Is_Null] = "Babu tarin tukuna"
stringMap [strings.User_Collection] = "Tattara"

//Akwatin shiga
stringMap [strings.User_Lolgin_Welcome_To_Login] = "Maraba da Zuciya +"
stringMap [strings.User_Lolgin_Free_Registration] = "Rajista Kyauta"
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = "Da fatan za a shigar da lambar wayarku"
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = "Da fatan za a shigar da lambar tabbatarwa"
stringMap [strings.User_Lolgin_Obtain] = "Samu"
stringMap [strings.User_Lolgin_Password_Login] = "Shiga kalmar shiga"
stringMap [strings.User_Lolgin_Login] = "Shiga ciki"
stringMap [strings.User_Lolgin_Register_An_Account] = "Rijistar Asusun"
stringMap [strings.User_Lolgin_Already_Have_An_Account] = "Dama kuna da asusun"
stringMap [strings.User_Lolgin_To_Log_In] = "Je ka shiga"
stringMap [strings.User_Lolgin_Please_Enter_Password] = "Da fatan za a shigar da kalmar sirri"
stringMap [strings.User_Lolgin_Confirm_Password] = "Tabbatar da Kalmar wucewa"
stringMap [strings.User_Lolgin_Registered] = "Rijista"
stringMap [strings.User_Lolgin_Account_Login] = "Shiga Asusun"
stringMap [strings.User_Lolgin_Scan_Code_Login] = "Shiga cikin Shafin shiga"
stringMap [strings.User_Lolgin_Forgot_Password] = "An manta kalmar sirri"
stringMap [strings.User_Lolgin_Verification_Code_Login] = "Shiga Code Na Tabbatarwa"
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = "Buɗe Zuciya + kuma a bincika a saman saitin ƙirar"
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = "Wannan lambar QR din ta kare a cikin mintina biyar, da fatan za a latsa lokacin da ta kare"
stringMap [strings.User_Lolgin_Refresh] = "Sabuntar"
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = "Binciken ya yi nasara, da fatan za a tabbatar a wayarku"
stringMap [strings.User_Lolgin_Whether_Yo_Authorize_Login] = "Ko a ba da izinin shiga"
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = "Da fatan za a shigar da sabon kalmar sirri"
stringMap [strings.User_Lolgin_Reset] = "Sake saita"

//Taswira na
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = "Bincika nau'in samfuri ko samfuri"
stringMap [strings.My_Mind_Date] = "Kwanan wata"
stringMap [strings.My_Mind_Create_Folder] = "Createirƙiri Jaka"
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = "Da fatan a shigar da sunan fayil"
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = "Da fatan za a shigar da sabon sunan fayil"
stringMap [strings.My_Mind__No_Map_Yet_Go] = "Babu taswira tukuna, tafi yanzu"
stringMap [strings.My_Mind_Create] = "Createirƙira"
stringMap [strings.My_Mind_Right] = "Yanzu!"
stringMap [strings.My_Mind_Open] = "Buɗe"
stringMap [strings.My_Mind_Type_Left_Right] = "Taswirar Mayen Dual"
stringMap [strings.My_Mind_Type_Unilateral] = "Taswirar Unilate"
stringMap [strings.My_Mind_Type_Bottom] = "Chart na Kungiyar"
stringMap [strings.My_Mind_Type_Bubble] = "Bubble Chart"
stringMap [strings.My_Mind_Type_Time_Vertical] = "Taswirar Tsara"
stringMap [strings.My_Mind_Blank_Mind_Map] = "Blank Zuciyar Taswira"
stringMap [strings.My_Mind_All] = "Duk"
stringMap [strings.My_Mind_Views] = "Dubawa:"

//Binciken hadari
stringMap [strings.Storm_Search_How_Title] = "Manya Manya"
stringMap [strings.Storm_History_Search] = "Binciken Tarihi"

//公共 header
stringMap [strings.Public_Header_Mind] = "Taswira"
stringMap [strings.Public_Header_Outline] = "Shaci"
stringMap [strings.Public_Header_Download_Apps] = "Zazzage abokin ciniki"
stringMap [strings.Public_Header_Mobile_Version] = "Sigar wayar hannu"
stringMap [strings.Public_Header_Creator] = "Mahalicci:"
stringMap [strings.Public_Header_View_Frequency] = "Ra'ayoyi:"
stringMap [strings.Public_Header_Save] = "Ajiye"
stringMap [strings.Header_Mind_Tips_Saved] = "An sami ceto"
stringMap [strings.Header_Mind_Tips_Edit] = "An gyara"
stringMap [strings.Header_Mind_Tips_Update_Saved] = "An adana ɗaukakawa"

//Taswirar shirya taswira
stringMap [strings.Mind_Edit_Revoc] = "Soke"
stringMap [strings.Mind_Edit_Restore] = "Mayar da"
stringMap [strings.Mind_Edit_Subtopic] = "Subtopic"
stringMap [strings.Mind_Edit_Top_Subtopic] = "Top Subtopic"
stringMap [strings.Mind_Edit_Bottom_Subtopic] = "Subtopic na Kasa"
stringMap [strings.Mind_Edit_Free_Node] = "Kyauta Kyauta"
stringMap [strings.Mind_Edit_Summary] = "Takaitawa"
stringMap [strings.Mind_Edit_Block] = "A cikin toshe"
stringMap [strings.Mind_Edit_Insert] = "Saka"
stringMap [strings.Mind_Edit_Related] = "Mai dangantaka"
stringMap [strings.Mind_Edit_Style] = "Salo"
stringMap [strings.Mind_Edit_Recording] = "Rikodi"
stringMap [strings.Mind_Edit_Insert_Picture] = "Saka Hoto"
stringMap [strings.Mind_Edit_Insert_Icon] = "Saka Alamar"
stringMap [strings.Mind_Edit_Insert_Remarks] = "Saka bayanai"
stringMap [strings.Mind_Edit_Insert_Link] = "Saka hanyar haɗi"
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = "Saka Shafi na Graididdiga"
stringMap [strings.Mind_Edit_Upload] = "Loda"
stringMap [strings.Mind_Edit_Right_Icon] = "Alamar"
stringMap [strings.Mind_Edit_Right_Style] = "Salo"
stringMap [strings.Mind_Edit_Audio_Decoding] = "dikodi mai ..."
stringMap [strings.Mind_Edit_Audio_Duration] = "Tsawon lokaci"
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = "Shin kun tabbata za ku share sautin da aka yi rikodin?"
stringMap [strings.Mind_Edit_Join_Membership] = "Shiga Membobinsu"
stringMap [strings.Mind_Edit_Upgrade] = "Haɓakawa"
stringMap [strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = "Membobin za su iya samun karin lokacin yin rikodi, yanzu je zuwa haɓaka zuwa memba?"

stringMap [strings.Mind_Edit_Choose_Material] = "Zaɓi abu"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = "Jawo ka sauke hotuna anan"
stringMap [strings.Mind_Edit_Select_locally] = "Zaɓi daga Gari"
stringMap [strings.Mind_Edit_Choose_From] = "Zaɓi daga laburaren kayan aiki"
stringMap [strings.Mind_Edit_Mine] = "Nawa"
stringMap [strings.Mind_Edit_My_Material] = "Kayana"
stringMap [strings.Mind_Edit_Recently_Used] = "An Yi Amfani Da Kwanan Nan"
stringMap [strings.Mind_Edit_Use] = "Yi amfani da"
stringMap [strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = "Babu kayan aiki a halin yanzu, je ka ƙara!"
stringMap [strings.Mind_Edit_Select_Chart_Type] = "Zaɓi nau'in ginshiƙi"
stringMap [strings.Mind_Edit_Remarks] = "Bayani"
stringMap [strings.Mind_Edit_Remarks_Tips] = "Da fatan za a shigar da bayanan bayanan"
stringMap [strings.Mind_Edit_Add] = "Addara"
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = "Da fatan za a shigar da mahaɗin"

//Shirya menu na hagu na hagu
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = "Ajiye Hoto"
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = "Fitar da PDF"
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = "Raba hanyoyin haɗin yanar gizo"
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = "Raba zuwa Guguwar"
stringMap [strings.Mind_Edit_Left_Menu_Printing] = "Buga"
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = "Buɗe cikakken sigar"
stringMap [strings.Mind_Edit_Left_Menu_Help] = "Taimako"
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = "Ajiye tsari"
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = "tsarin png"
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = "tsarin jpg"
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = "tsarin gidan yanar gizo"
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = "Raba Taswira"
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = "Share Canja"
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = "Ana iya kwafa kuma a aika hanyar haɗin hanyar"
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = "Kwafi mahada"
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = "Idan kuna son canza hanyar haɗin, don Allah danna"
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = "Sabuntawa"
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = "Haɗa kalmar wucewa:"
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] = "Bada izinin adanawa"
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = "Matsakaicin adadin ra'ayoyi"
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = "Ba shi da iyaka"
stringMap [strings.Mind_Edit_Left_Menu_Limit] = "Rage"
stringMap [strings.Mind_Edit_Left_Menu_Second] = "Na biyu"
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = "Lokacin ingancin mahada"
stringMap[strings.Mind_Edit_Left_Menu_1_Day] = "kwana 1"
stringMap[strings.Mind_Edit_Left_Menu_3_Days] = "kwanaki 3"
stringMap[strings.Mind_Edit_Left_Menu_7_Days] = "Kwanaki 7"
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = "Dindindin"
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = "Kwafi Abubuwan & Haɗa"
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = "Shin kun tabbata kuna son sabuntawa? Haɗin haɗin da ya gabata ba zai yi aiki ba!"
stringMap [strings.Mind_Edit_Left_Menu_Share] = "Raba"
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = "Kundin al'ada:"
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = "Ko a ba da izinin bayani"
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = "Shirya Categara Rukunin"
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = "Hakkokin Membobi"
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = "Dandalin Tallafawa"
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = "Buɗe Membobinsu"
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = "Musayar Lambar Kunnawa"
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = "Amfanin membobin bayan sayan"
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = "Zaɓi hanyar biyan kuɗi"
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = "Farashin Ayyuka"
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = "Adadin da aka biya a zahiri"
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = "Sabunta nan da nan"
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = "Sharuɗɗan Sabis"
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = "Manufar Sirri"
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = "Lambar kunnawa:"
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = "Da fatan a shigar da lambar kunnawa"
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = "Tabbatar da lambar kunnawa"
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = "Zaɓi rukuni"
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = "Babban taken ba zai zama fanko ba"
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = "Coupon:"
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = "Da fatan a shigar da lambar coupon"
stringMap [strings.Mind_Confirm_Use_Coupons] = "Tabbatar da amfani da takardun shaida"
stringMap [strings.Mind_Edit_Left_Menu_Verification] = "Tabbatarwa"
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = "Adadin da Aka fi so"
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = "Biya nan da nan"
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = "WeChat Biya"
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = "Tabbatar da Bayanin Musayar"
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = "Lambar Musanya:"
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = "Musayar"

//编辑页右侧菜单
stringMap[strings.Mind_Edit_Right_Menu_canvas] = "Canvas"
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = "Launin fage"
stringMap [strings.Mind_Edit_Right_Menu_layout] = "Tsari"
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = "Salon layi"
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = "Launin Layi"
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = "Nisan layi"
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = "Layuka sun yi laushi"
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = "Rubutun Jigo"
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = "Rubutun yara"
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = "Launin rubutu"
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = "girman rubutu"
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = "Font Style"
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = "Launin iyaka"
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = "Cika Launi"
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = "Siffar iyaka"
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = "Faɗin iyaka"
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = "Nuna tasirin inuwa (aikin biyan kuɗi)"
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = "Launin Layi"
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = "Tsararren layi"
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = "Girman hoto"
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = "Wurin Hoto"
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = "Siffar Hoto"
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = "Girman Girman"
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = "Matsayin alama"
stringMap [strings.Mind_Edit_Right_Menu_routine] = "Janar"
stringMap [strings.Mind_Edit_Right_Menu_priority] = "Fifiko"
stringMap [strings.Mind_Edit_Right_Menu_Progress] = "Ci gaba"
stringMap [strings.Mind_Edit_Right_Menu_Stars] = "Taurari"
stringMap[strings.Mind_Edit_Right_Menu_Week] = "mako"
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = "Hoto"
stringMap[strings.Mind_Edit_Right_Menu_3D] = "3D"
stringMap [strings.Mind_Edit_Right_Menu_Learning] = "Ilmantarwa"
stringMap [strings.Mind_Edit_Right_Menu_Work] = "Aiki"
stringMap[strings.Mind_Edit_Right_Menu_Characters] = "Maƙallan"
stringMap [strings.Mind_Edit_Right_Menu_Animals] = "Dabbobi"
stringMap[strings.Mind_Edit_Right_Menu_Food] = "Abinci"
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = "Kwafin Salo"
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = "Style Style"
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = "Gyara Siffa"
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = "Duba Hoton"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = "Share hoto"
stringMap [strings.Mind_Edit_Node_Menu_Copy] = "Kwafa"
stringMap [strings.Mind_Edit_Node_Menu_Paste] = "Manna"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = "Share layi"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = "Share"
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = "Shirya Magana"
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = "Buɗe hanyar haɗi"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = "Share Magana"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = "Share hanyar haɗi"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = "Share Alamar"
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = "Tsarin alama"
//统计图编辑面板
stringMap [strings.Echart_Edit_Statistical] = "Lissafi"
stringMap [strings.Echart_Edit_Value] = "Daraja"
stringMap [strings.Echart_Edit_Color] = "Launi"
stringMap [strings.Echart_Edit_Title] = "Take"
stringMap [strings.Echart_Edit_Detailed_Description] = "Cikakken bayani"
stringMap [strings.Echart_Edit_Value_Sort] = "Tsara"
stringMap [strings.Echart_Edit_Value_Display_Type] = "Nau'in Nuni"
stringMap [strings.Echart_Edit_Value_Color_Scheme] = "Shirye-shiryen launi"
stringMap [strings.Echart_Edit_Value_Display_Size] = "Girman nunawa"
stringMap [strings.Echart_Edit_Value_Title_Size] = "Girman taken"
stringMap [strings.Echart_Edit_Value_Explain_Size] = "Bayyana girman"
stringMap [strings.Echart_Edit_Value_Subscribe] = "Biyan kuɗi"

//风暴标签页
stringMap [strings.StormBookTag_Subscribed] = "An yi rajista"
stringMap [strings.StormBookTag_List] = "Jerin"

//风暴分享页
stringMap [strings.Storm_Share_Comment] = "Sharhi"
stringMap [strings.Storm_Share_Reply] = "Amsa"
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = "Bari in ma yi bayani a kansa ~"
stringMap [strings.Storm_Share_Release] = "Saki"
stringMap [strings.Storm_Share_Main_Title] = "Babban Take"
stringMap [strings.Storm_Share_Subtitle] = "Subtitle"
stringMap [strings.Storm_Share_Author] = "Mawallafi"
stringMap [strings.Storm_Share_Replies] = "Amsoshi"
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = "Babu wani tsokaci a halin yanzu. Kasance na farko."
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = "Da fatan za a shigar da bayani ~"
stringMap [strings.Storm_Share_Delete_Comment_Tips] = "Shin kun tabbata kuna son share wannan tsokaci?"

//导图内部使用帮助
stringMap [strings.Mind_Usinghelp_Btn_1] = "Mabuɗin Gajerar (windows)"
stringMap [strings.Mind_Usinghelp_Btn_2] = "Mabuɗin gajerar hanya (mac)"
stringMap [strings.Mind_Usinghelp_Btn_3] = "Bayani"
stringMap[strings.Mind_Usinghelp_Btn_4] = "[Ctrl] + [D]"
stringMap[strings.Mind_Usinghelp_Btn_5] = "[Command] + [D]"
stringMap [strings.Mind_Usinghelp_Btn_6] = "Addara kumburin yaro"
stringMap[strings.Mind_Usinghelp_Btn_7] = "[Delet]"
stringMap [strings.Mind_Usinghelp_Btn_8] = "Share kumburin yanzu (lura cewa ba za a iya share shi ba idan yana cikin yanayin gyarawa)"
stringMap[strings.Mind_Usinghelp_Btn_9] = "[Ctrl] + [E]"
stringMap[strings.Mind_Usinghelp_Btn_10] = "[Command] + [E]"
stringMap [strings.Mind_Usinghelp_Btn_11] = "Gyara kumburin yanzu"
stringMap[strings.Mind_Usinghelp_Btn_12] = "[Ctrl] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_13] = "[Command] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_14] = "[Ctrl] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_15] = "[Command] + [+]"
stringMap [strings.Mind_Usinghelp_Btn_16] = "faɗaɗa taswira"
stringMap[strings.Mind_Usinghelp_Btn_17] = "[Ctrl] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_18] = "[Command] + [-]"
stringMap [strings.Mind_Usinghelp_Btn_19] = "Taswirar hankali"
stringMap[strings.Mind_Usinghelp_Btn_20] = "[Ctrl] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_21] = "[Command] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_22] = "[Ctrl] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_23] = "[Command] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_24] = "[Ctrl] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_25] = "[Command] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_26] = "[Ctrl] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_27] = "[Command] + [B]"
stringMap [strings.Mind_Usinghelp_Btn_28] = "Fita daga gyara"

//Rubuta nan da nan
stringMap [strings.Message_Tips_Login_Success] = "An yi nasarar shiga"
stringMap [strings.Message_Tips_Dlt_Success] = "Share cikin nasara"
stringMap [strings.Message_Tips_Report_Success] = "Rahoton nasara"
stringMap [strings.Message_Tips_Copy_Success] = "Kwafi yayi nasara"
stringMap [strings.Message_Tips_Collction_Success] = "Nasarar tarin"
stringMap [strings.Message_Tips_Avatar_Modification_Success] = "Ingantaccen Ingantaccen Avatar"
stringMap [strings.Message_Tips_Signature_Modification_Success] = "Nasarar nasarar gyare-gyare"
stringMap [strings.Message_Tips_Name_Modification_Success] = "an gyara sunan laƙabi da nasara"
stringMap [strings.Message_Tips_Modification_Success] = "An sami nasarar gyarawa"
stringMap [strings.Message_Tips_New_Profile_Success] = "Createirƙiri sabon fayil cikin nasara"
stringMap [strings.Message_Tips_Mobile_Success] = "Nasarar wayar hannu"
stringMap [strings.Message_Tips_Submit_Success] = "ƙaddamar da nasara"
stringMap [strings.Message_Tips_Exchange_Success] = "Canza musayar nasara"
stringMap [strings.Message_Tips_Please_Code] = "Da fatan a shigar da lambar kunnawa"
stringMap [strings.Message_Tips_Agree_To_Terms] = "Da fatan za a yarda da manufar keɓancewa"
stringMap [strings.Message_Tips_Please_Sign_In] = "Da fatan za a shiga"
stringMap [strings.Message_Tips_Share_Success] = "Raba yayi nasara"
stringMap [strings.Message_Tips_Create_Success] = "An ƙirƙira shi cikin nasara"
stringMap [strings.Message_Tips_Profile_Name_No_Null] = "Sunan fayil ɗin ba zai zama fanko ba"
stringMap [strings.Message_Tips_Selecte_Success] = "An kammala bincike"
stringMap [strings.Message_Tips_Subscription_Success] = "Biyan kuɗi ya ci nasara"
stringMap [strings.Message_Tips_Unsubscribe_Success] = "Cire rajista ba shi da nasara"
stringMap [strings.Message_Tips_Related_Content_Not_Found] = "Ba a samo abubuwan da ke da alaƙa ba"
stringMap [strings.Message_Tips_Max_Add_Class] = "Ba za ku iya ƙarawa kawai ba a mafi yawancin"
stringMap [strings.Message_Tips_Registered_Success] = "An yi rijista cikin nasara"
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = "Kuskuren shigar da kodin ya kasa"
stringMap [strings.Message_Tips_Share_Is_Null] = "Share mahaɗin babu shi"
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = "Fayil ɗin da aka ɗora ba zai iya wuce 3MB ba"
stringMap [strings.Message_Tips_Get_Permission_Successfully] = "Samu izini cikin nasara"
stringMap [strings.Message_Tips_Membership_Required] = "Ana Bukatar Mamba"
stringMap [strings.Message_Tips_Save_Success] = "Ajiye cikin nasara"
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = "Nasarar duba lambar shiga"
stringMap [strings.Message_Tips_Style] = "Wani rukuni?"

//Yanayin shaci
stringMap [strings.Outline_Menu_Indent] = "Ba da labari"
stringMap [strings.Outline_Menu_Decrease_Indent] = "Rage ƙin ciki"

//帮助提示
stringMap [strings.Help_Background_Content] = "Bayan layi, layin da ya danganci, layin da ya shafi layi, layin da ya shafi layi, layin da ya shafi layi, abin da ya shafi layin, layin da ya shafi layin, layin da ya shafi layin, layin da ya shafi layin,"
stringMap [strings.Help_Mind_Content] = "Kuna iya yin taswira tare da shimfidu daban-daban gwargwadon abubuwan da kuke so: taswirar jagora biyu, taswirar hagu, taswirar dama, ginshiƙan ƙungiya biyu, itace ta hagu, itace dama, tebur"
stringMap [strings.Help_Share_Link_Content] = "Za a iya raba hanyoyin raba a cikin WeChat, QQ, Weibo da sauran tashoshi ta hanyar haɗin yanar gizo, kuma abokai na iya duba abubuwan da ke ciki"
stringMap [strings.Using_Templates] = "Ta amfani da Samfura"

//邀请码
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = "Da fatan a shigar da lambar gayyata"
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = "Da fatan a shigar da lambar gayyata daidai"
stringMap [strings.User_Lolgin_Optional] = "Zabi"

//使用素材

stringMap [strings.Cannot_Add_To_Node_Background] = "Ba za a iya zartar da bayanan kumburi ba"
stringMap [strings.Cannot_Add_To_Img_Background] = "Ba za a iya zartar da asalin rubutu ba"
stringMap [strings.Cannot_Add_To_Mind_Background] = "Ba za a zartar da bayanan layi ba"
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = "Addara hoton baya"
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = "Share hoton baya"
stringMap [strings.Mind_Add_Background_Image] = "Saka hoton bayan fage"
stringMap [strings.Mind_Delete_Background_Image] = "Share hoton bango"

stringMap [strings.Permanent_Member] = "Memba Na Dindindin"
stringMap [strings.Insufficient_Storage_Space] = "Rashin isasshen wurin ajiya, haɓaka memba don ƙara sarari."
stringMap [strings.Used_Space] = "sararin Ajiye"
stringMap [strings.Current_Storage] = "Ma'ajin Yanzu:"
stringMap [strings.Used_Space_Upgrade] = "Haɓakar Sararin Samaniya"
stringMap [strings.My_Mind_Is_Null_Tips] = "Mind + Mind Map & Bayanan kula Super mnemonics, ƙara fuka-fuki zuwa tunani, ƙirƙira, nuna, tsara, da bayanin kula"
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = "Rashin isasshen wurin ajiya, za ku iya amfani da samfurin lokacin da kuka haɓaka membobinku."

stringMap [strings.Group_Management] = "Rukuni"
stringMap [strings.Save_Img_Fill_Full] = "A cika cikakke"
stringMap [strings.Save_Img_Center_Tilt] = "Cibiyar Tsarkaka"
stringMap [strings.Save_Img_File_Full_Dark_Color] = "Cikakken (Duhu)"
stringMap [strings.Save_Img_Mind_Map_Watermarking] = "Mind + Zuciyar Taswirar Ruwa"
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = "waterara alamar ruwa don kare ƙirƙirar ilimin:"
stringMap [strings.Save_Img_Watermark_Text] = "Rubutun alamar ruwa:"
stringMap [strings.Save_Img_Watermark_Logo] = "Watermark LOGO:"
stringMap [strings.Save_Img_Watermark_Style] = "Salon alamar ruwa:"
stringMap [strings.Save_Img_Please_Input] = "Da fatan za a shiga"
stringMap [strings.Save_Img_Chop_Tips] = "Kuna iya jin daɗin haƙƙin alamar ruwa lokacin da kuka buɗe memba"
stringMap [strings.Public_Header_Mindmap_Mind] = "Yanayin Taswira"
stringMap [strings.Copy_Mind_Map] = "Kwafi Zuciyar Taswira"
stringMap[strings.Font_Style_Bold] = "Bold"
stringMap [strings.Font_Style_Italics] = "Italics ne"
stringMap [strings.Font_Style_Delete_Line] = "Gaggawar hanyar"
stringMap [strings.Remove_Any] = "Cire"

stringMap [strings.Mindmap_Review] = "Binciken Mindmap"
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = "zurfafa tunani da haɓaka ƙwaƙwalwa, tsara ra'ayoyi da bayanai yadda ya kamata"
stringMap [strings.Mindmap_Review_Content] = "Nazarin taswira yana nufin sake kunnawa na aikin gyara, kuma ana kunna abun ciki bi da bi daga mahimmin taken; sake nazarin taswirar zai iya taimaka muku aiwatar da ƙididdigar tsarin, bincike, da nemo ra'ayoyi da ramuka. tsarin bita, kuna iya samun wahayi daban-daban "
stringMap [strings.Mindmap_Review_Speed] = "Gudun"
stringMap [strings.Mindmap_Review_Word_Speed] = "Saurin Magana"
stringMap [strings.Mindmap_Review_Background_Ratio] = "Yanayin baya"
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = "narfafa kumburin sake kunnawa na yanzu"
stringMap [strings.Mindmap_Review_Node_Playback_Order] = "Dokar Sake kunnawa Node"


stringMap [strings.Mind_Group_Picture_Upload] = "Loda hoto"
stringMap [strings.Mind_Group_Upload_From_Local] = "Loda na gari"
stringMap [strings.Mind_Group_Mind_Mapping] = "Mind Map"
stringMap [strings.Mind_Group_Information] = "Bayanai"

stringMap [strings.Mind_Group_Invite_New_Members] = "Gayyatar sabbin mambobi"
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = "Da fatan za a shigar da lambar wayarku"
stringMap [strings.Mind_Group_Cancel] = "Soke"
stringMap [strings.Mind_Group_Confirm] = "Ya yi kyau"
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = "Lambar wayar hannu ba zata zama fanko ba"
stringMap [strings.Mind_Group_Invite_The_Successful] = "Gayyata mai nasara"

stringMap [strings.Mind_Group_Shared_Group_Membership] = "Raba mambobin rukuni"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Matsakaicin adadin mambobin rukunin da aka raba"
stringMap [strings.Mind_Group_Membership_Limit] = "Membersayyadaddun Membobi"
stringMap [strings.Mind_Group_Current_Member_Limit] = "Memberayyadadden Memberimar Memba na Yanzu"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "Haɓaka iyakar memba na sama"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "Binciko membobi"
stringMap [strings.Mind_Group_Creator] = "Mahalicci"
stringMap [strings.Mind_Group_Administrator] = "Mai gudanarwa"
stringMap [strings.Mind_Group_Number_Of_People] = "Mutane"
stringMap [strings.Mind_Group_Selected] = "An zaɓi"
stringMap [strings.Mind_Group_Member] = "Memba"
stringMap [strings.Mind_Group_Check_All] = "Zaɓi duka"
stringMap [strings.Mind_Group_Remove] = "Cire"
stringMap [strings.Mind_Group_Invite_New_Members] = "Gayyatar sabbin mambobi"
stringMap [strings.Mind_Group_Delete_Members] = "Share mambobi"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Da fatan za a zaɓi memba don sharewa"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Shin kun tabbata kuna son cire mai amfani daga wannan ƙungiyar?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "Addara Mai Gudanarwa"
stringMap [strings.Mind_Group_Accomplish] = "An kammala"
stringMap [strings.Mind_Group_Compile] = "Shirya"
stringMap [strings.Mind_Group_Group_Owner] = "Mai Rukuni"
stringMap [strings.Mind_Group_Add_Admin] = "Addara Mai Gudanarwa"
stringMap [strings.Mind_Group_Group_ID] = "Groupungiyar id"
stringMap [strings.Mind_Group_Group_Number] = "Lambar Rukuni"
stringMap [strings.Mind_Group_Name] = "Suna"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Da fatan za a shigar da sunan rukunin da aka raba"
stringMap [strings.Mind_Group_Introduce] = "Gabatar"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Da fatan za a gabatar da raba rukuni a takaice"
stringMap [strings.Mind_Group_Announcement] = "Sanarwa"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "Raba Rukuni na Rukuni"
stringMap [strings.Mind_Group_Custom_Tag] = "Tag na Musamman"
stringMap [strings.Mind_Group_Save] = "Ajiye"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "Sunan rukuni ba zai zama fanko ba"
stringMap [strings.Mind_Group_Establish] = "Createirƙira"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "Bada izinin ƙirƙirar taswira / manyan fayiloli"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "Bada damar gyara taswira"
stringMap [strings.Mind_Group_Holder] = "Kowa da kowa"
stringMap [strings.Mind_Group_Only_The_Administrator] = "Mai gudanarwa kawai"
stringMap [strings.Mind_Group_Not_Allow] = "Ba a yarda ba"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "Bada izinin share taswira"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Bada tarurruka su buga sakonni"
stringMap [strings.Mind_Group_Export] = "Fitarwa"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Bada izinin adana hotuna / PDF"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "Buɗe Rukunin Rukunin Ruwa"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Bayan buɗewa, za a buga tambarin ƙungiyar a kan taswirar tunani"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "Bada izinin taswira"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "Ajiye zuwa Taswirana"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Ko a ba da izinin rabawa ga hadari"
stringMap [strings.Mind_Group_Join_The_Way] = "Yadda ake haɗuwa"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "Ko za a ba da damar gayyatar sabbin mambobi"
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Ko za a ba da izinin a tara lambobin rukunin bincike"
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Ko sakon yana bukatar a inganta shi yayin shiga"
stringMap [strings.Mind_Group_Shared_Group_Data] = "Raba bayanan rukuni"
stringMap [strings.Mind_Group_Authority_Management] = "Gudanar da Mulki"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "Bar rukunin raba"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Shin an tabbatar da fita daga rukunin da aka raba?"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Rarraba Rukuni"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "Za a share fayilolin bayan an wargaza ƙungiyar. An tabbatar da watsewa?"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "Koma zuwa shafin gida"
stringMap [strings.Mind_Group_Join_The_Group] = "Shiga Rukuni"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "Da fatan za a shigar da lambar rukuni / sunan rukuni don bincika"
stringMap [strings.Mind_Group_Apply_To_Join] = "Aika don shiga"
stringMap [strings.Mind_Group_Join] = "Haɗa"
stringMap [strings.Mind_Group_Verification] = "Tabbatarwa"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "Da fatan za a shigar da saƙon inganci"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "Abun ciki ba zai zama fanko ba"
stringMap [strings.Mind_Group_Name] = "Raba sunan rukuni"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Da fatan za a shigar da sunan rukunin da aka raba"
stringMap [strings.Mind_Group_Introduction] = "Raba gabatarwar rukuni"
stringMap [strings.Mind_Group_Group_Indication] = "Lambar Rukuni"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "Mahaliccin yana rago sosai, har yanzu babu gabatarwa ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "Raba sanarwar ƙungiya"
stringMap [strings.Mind_Group_No_Announcement] = "Babu sanarwa tukuna"
stringMap [strings.Mind_Group_Click_On] = "Danna don faɗaɗa"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "Danna don ɓoye"
stringMap [strings.Mind_Group_Altogether] = "Jimla"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Kwafin lambar ƙungiya cikin nasara"
stringMap [strings.Mind_Group_Stick] = "Tsaya zuwa saman"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "Akwai sabon abun ciki"
stringMap [strings.Mind_Group_Cancel_The_Top] = "Soke Sama"
stringMap [strings.Mind_Group_Setting_Up] = "Saituna"
stringMap [strings.Mind_Group_Usinghelp] = "Amfani da taimako"
stringMap [strings.Mind_Group_Set_The_Message] = "Sakon Rukuni"
stringMap [strings.Mind_Group_Apply_To_Join] = "Aika don shiga"
stringMap [strings.Mind_Group_Invite_To_Join] = "Gayyata don shiga"
stringMap [strings.Mind_Group_Consent] = "Amince"
stringMap [strings.Mind_Group_Turn_Down] = "ƙi"
stringMap [strings.Mind_Group_Label] = "Alamar"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Da fatan a shigar da sunan lakabin rukunin da aka raba"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Gayyaci abokai da abokan aiki don haɓaka tare da raba abubuwan ilimin"
stringMap [strings.Mind_Group_Immediately_Create] = "Createirƙira nan take"
stringMap [strings.Mind_Group_Immediately_To_Join] = "Shiga Yanzu"
stringMap [strings.Currently_Selected] = "A halin yanzu yana bin"
stringMap [strings.LabelManagement] = "Gudanar da Alamar"
stringMap [strings.NoAttention] = "Babu Hankali"
stringMap [strings.Mindmap_Review_Play] = "Kunna"

stringMap [strings.Mind_Group_Joined] = "An Shiga"
stringMap [strings.Mind_Group_Download_App] = "Zazzage APP"
stringMap [strings.Mind_Group_No_Content] = "Babu abun ciki tukuna"
stringMap [strings.Mind_Group_Individual] = "guda"
stringMap [strings.Mind_Group_Message_Area] = "Yankin Sako"
stringMap [strings.Mind_Group_No_Message] = "Babu sako, tafi yanzu"
stringMap [strings.Mind_Group_Leave_Message] = "Bar sako"
stringMap [strings.Mind_Group_Bar] = "Bar"
stringMap [strings.Mind_Group_See_More] = "Duba ƙari"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "Shigar da lambar gayyata"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Aika don shiga ƙungiyar rabawa"
stringMap [strings.Reasons_For_Application_0_30_Words] = "Dalilin aikace-aikace (kalmomin 0-30)"
stringMap [strings.Mind_Group_Send_Out] = "Aika"
stringMap [strings.Mind_Group_Join_Successfully] = "Haɗa cikin nasara"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "An aika da aikace-aikacen cikin nasara"

stringMap [strings.Mind_Group_Save_The_Mapping] = "Ajiye Taswira"
stringMap [strings.Mind_Group_Update_Record] = "Gyara Rikodi"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "Sauya Taswira"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "Binciken Taswira"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Wani mai amfani da ke aiki yanzu yana gyara taswirar"
stringMap [strings.Mind_Edit_Page_Password] = "Kalmar wucewa"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "Raba Rukuni"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "MBean na na"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Biyan Alipay"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "Da fatan za a je shafin biyan kuɗi"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "Jimla"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "Tabbatar da biyan"
stringMap [strings.Mind_Shooping_Redeem_Now] = "fanshe yanzu"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "Ko don tabbatar da biyan kuɗi don sayan"
stringMap [strings.Mind_Tips] = "Tukwici"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "Wake M naku bai isa ba, kuna so ku sayi wake M?"

stringMap [strings.Mind_share_scan_code] = "Hankali + lambar dubawa"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Plays] = "Ko za a dawo da wurin da aka buga na ƙarshe?"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Ragowar lokutan wasa ne"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "Fayil din gaba daya za'a share shi, shin kun tabbata za a warwatse?"
stringMap [strings.Group_Mind_Up_One_Level] = "Mataki Na Daya"
stringMap [strings.Group_Mind_No_Documents] = "Babu fayiloli har yanzu"
stringMap [strings.Group_Mind_PaymentSuccessful] = "Biyan kuɗi ya yi nasara"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "Editan haɗin gwiwar mutane, aiki tare na ainihi mai amfani, musayar takardu, inganta ƙwarewar haɗin gwiwar ƙungiya"

stringMap [strings.Mind_Edit_Subscript] = "Rubuta rubutu"
stringMap [strings.Mind_Edit_Text_Location] = "Wurin Rubutu"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "Shigo da Raba Rukuni"

stringMap [strings.Standard_Colors] = "Matsakaitan Launuka"
stringMap [strings.More_Colors] = "Morearin launuka"
stringMap [strings.Reset_Password] = "Sake saita kalmar wucewa"
stringMap [strings.Account_Password_Login] = "Shiga kalmar shiga ta asusun"
stringMap [strings.Authentication_code_Login_registration] = "Lambar tabbatarwa ta shiga / rajista"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "endaya daga cikin halittar ƙarshe, aiki tare mai ƙarewa da yawa"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "Tsarin wadataccen tsari da taswira"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "Babban dakin adana samfura masu kyau"
stringMap [strings.Map_Review_Play] = "Binciken Taswira-Wasa"
stringMap [strings.CommunityLearningAndSharingPlatform] = "Ilimin Ilmi da Rarraba Dandalin Musayar"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "Haɓaka sararin ajiya"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "Bari ni ma in yi sharhi a kansa"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Babu sanarwar sanarwa tukuna"
stringMap [strings.Failed_To_Get_Recording_Device] = "An kasa samun na'urar yin rikodi"

stringMap [strings.Search_Pagination] = "Binciken Pagination"
stringMap [strings.Please_Enter_A_Paging_Name] = "Da fatan a shigar da sunan shafin"
stringMap [strings.Operation_Settings] = "Saitunan Aiki"
stringMap [strings.Shortcut_key_Settings] = "Saitunan Maɓallan Gajerar hanya"
stringMap [strings.Press_Key_Combination] = "Da fatan za a danna haɗin maɓallin da ake buƙata"
stringMap [strings.Mind_Reduction] = "Ragewa"
stringMap [strings.Double_Click_Add_Free_Node] = "Danna sau biyu a sarari don ƙara kumburi kyauta"
stringMap [strings.Shortcut_Conflict] = "Rikicin maɓallin gajeriyar hanya"
stringMap [strings.Select_Main_Node] = "Zaɓi Babban Kuɗi"
stringMap [strings.Telephone] = "Waya"
stringMap [strings.Microblog] = "Microblog"
stringMap [strings.Address] = "Adireshin"
stringMap [strings.Company_Address] = "Block B, Gina 21, No. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "Ajiye"
stringMap [strings.Mind_Bookshelf] = "Littafin littattafai"
stringMap [strings.Search_Archive] = "Searchhelhelf"
stringMap [strings.Modify_Archive_Order] = "Gyara odar littattafai"
stringMap [strings.Modification_Order] = "Umarnin Gyara"
stringMap [strings.Please_Enter_Archive_Name] = "Da fatan za a shigar da sunan ɗakin littattafai"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "Sunan shiryayyen littafi ba zai zama fanko ba"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "Shin kun tabbata kuna son goge wannan ɗakin ajiyar littattafan?"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "Shin kun tabbata kuna son share wannan akwati?"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "Shin kun tabbata kuna son share wannan taswirar?"
stringMap [strings.Mind_Edit_Confirm] = "Tabbatar"
stringMap [strings.Mind_Import_Map] = "Taswirar shigowa"
stringMap [strings.Mind_Bookcase] = "akwatin littattafai"
stringMap [strings.Added_Successfully] = "An ƙara nasara"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "Sunan akwatin ba zai iya zama fanko ba"

stringMap [strings.Mind_Association_Map] = "Taswirar Ƙungiya"
stringMap [strings.Mind_confirm_Association_Map] = "Shin kuna da tabbacin haɗa wannan taswirar?"
stringMap [strings.Mind_Association_Map_See] = "Duba Taswirar Ƙungiyar"
stringMap [strings.Mind_Association_Map_Delete] = "Share Taswirar Ƙungiya"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "Tsarin Layin daidaitawa"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Fara Layin Haɗi"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "Ƙarshen layin daidaitawa"

stringMap [strings.Share_Group_To_Storm] = "Ko a raba kungiyar zuwa hadari"
stringMap [strings.Apply_To_Join_Storm_Platform] = "A kan dandamalin hadari, wasu mutane suna gani kuma suna nema don shiga, yin tunani tare da masu son ilimin gama gari"
stringMap [strings.Pay_To_Goin] = "Biya don shiga"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "Ana buƙatar biya wa wake wake lokacin shiga"
stringMap [strings.Mbean_Can_Be_Realized] = "An gane Mbean"

stringMap [strings.Mind_Visit] = "Ziyarci"
stringMap [strings.Mind_Details] = "Cikakkun bayanai"
stringMap [strings.Mind_See] = "Duba"
stringMap [strings.Earn_Mbeans] = "Sami Mbeans"
stringMap [strings.Mind_Explain] = "Bayani"
stringMap [strings.Mind_Parenting] = "Iyaye"
stringMap [strings.Mind_Internet] = "Intanet"
stringMap [strings.Mind_Psychology] = "Psychology"
stringMap [strings.Mind_literature] = "Adabi"
stringMap [strings.Mind_Technology] = "Fasaha"
stringMap [strings.Mind_Workplace] = "wurin aiki"
stringMap [strings.Mind_Life] = "Rayuwa"
stringMap [strings.Mind_Subject] = "Subject"
stringMap [strings.Hot_Group] = "Kungiyar Zafi"
stringMap [strings.Open_Group] = "Open Group"
stringMap [strings.Mind_Group_Used] = "An yi amfani da shi"

stringMap [strings.Mind_Click_Select] = "Danna don zaɓar"
stringMap [strings.Mind_Double_Click_Enter] = "Danna sau biyu don shiga"
stringMap [strings.Search_Shared_Groups] = "Nemo ƙungiyoyin da aka raba"
stringMap [strings.Search_Storm] = "Guguwar Bincike"
stringMap [strings.Members_Save_Free] = "Membobi Ajiye Kyauta"
stringMap [strings.Bank_Card_Account_Name] = "Da fatan za a shigar da sunan asusun bankin ku"
stringMap [strings.Bank_Card_No] = "Lambar Katin Banki"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "Da fatan za a shigar da lambar katin bankin ku"
stringMap [strings.Branch_Sub_Branch_Information] = "Bayanin reshe"
stringMap [strings.Branch_Sub_Branch_Example] = "Misali: Hangzhou Branch Chengxi Sub-branch"
stringMap [strings.Application_For_Withdrawal] = "Aikace -aikace don karbowa"
stringMap [strings.Withdrawal_Amount] = "Adadin cirewa"
stringMap [strings.Withdrawal] = "Cirewa"
stringMap [strings.Current_Revenue] = "Harajin Yanzu"
stringMap [strings.Recharge] = "Recharge"
stringMap [strings.Current_Total_MBeans] = "Jimlar adadin MBeans na yanzu"
stringMap [strings.Withdrawal_Status] = "Matsayin janyewa"
stringMap [strings. Insufficient_Withdrawal_Amount] = "Rashin isasshen adadin cirewa"
stringMap [strings.Load_More] = "ƙara ƙari"
stringMap [strings.Loading] = "Loading"
stringMap [strings.It_Is_Over] = "An gama"
stringMap [strings.Default_Label] = "Tsoffin lakabin"
stringMap [strings.Selected_Label] = "lakabin da aka zaɓa"
stringMap [strings.Current_Location] = "Wurin Yanzu"
stringMap [strings.MGold_Introduction] = "M zinari ne kuka samu ta hanyar Mind+"
stringMap [strings.MGold_Details] = "Ƙungiyar girgije da aka biya, akwai taswirar ilimi, kayan koyo, da sauransu a cikin ƙungiyar. Idan masu amfani suka shiga cikin biyan M wake, 50% daga cikinsu za a canza su zuwa M zinariya, kuma M zinariya mafi girma fiye da 50 za a iya fitar da su "
stringMap [strings.Cloud_Group_Introduction] = "Raba ƙungiyar ku, wasu na iya ganin ta akan shafin hadari"
stringMap [strings.Cloud_Group_Details] = "Ƙungiyar girgije da aka biya. Akwai taswirar ilimi, kayan koyo, da sauransu a cikin ƙungiyar. Idan masu amfani suka shiga cikin biyan M wake, 50% daga cikinsu za a canza su zuwa M zinariya, kuma M zinariya mafi girma fiye da 50 za a iya janyewa. "
stringMap [strings.Share_Storm_Introduction] = "Raba taswirar ku ga jama'ar guguwar, domin mutane da yawa su koya da bincika ilimi daga gare ku"
stringMap [strings.Share_Storm_Details] = "Idan kun ba da damar amfani da wake M don adana taswirori, wasu masu amfani za su iya amfani da taswirar ilimin ku, kuma za ku sami 50% na M wake. ƙungiyoyi, Hakanan kuna iya biyan kuɗi don amfani da taswirar wasu mutane. "

stringMap [strings.Linked_Successfully] = "An haɗu cikin nasara"
stringMap [strings.Cancel_Archiving] = "Soke Adanawa"
stringMap [strings.Please_Select_Archive_Location] = "Da fatan za a zaɓi wurin adana kayan tarihin"
stringMap [strings ._Search_Result_Is_Empty] = "Sakamakon bincike babu komai"

stringMap [strings.Customize_A_Style] = "Wadanda ba membobi ba zasu iya tsara salo daya kawai"
stringMap [strings.Adds_Current_Guide_Style_Library] = "Ƙara salon taswirar yanzu zuwa ɗakin karatu na salo"
stringMap [strings.Trial_Version] = "sigar gwaji"
stringMap [strings.Not_Supported_At_The_Moment] = "Ba a tallafawa a halin yanzu"
stringMap [strings.Mind_File] = "Fayil"
stringMap [strings.Mind_Import] = "Shigo"
stringMap [strings.Importing] = "Shigo da kaya"

stringMap [strings.Mind_Edit_Insert_Video] = "Saka Bidiyo"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Jawo da sauke bidiyo anan"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "Tsawon bidiyon da aka ɗora ba zai iya wuce 60s ba"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "Duba Bidiyo"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "Share bidiyo"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "Bidiyon da aka ɗora ba zai iya wuce 10MB ba"
stringMap [strings.Mind_Upload_Video_Restrictions] = "Yana goyan bayan loda bidiyo a cikin tsarin MP4, har zuwa 120s da 10M a girman"
stringMap [strings.Mind_Video_Size] = "Girman bidiyo"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "Fitar da Kalma"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "Fitar da TXT"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap [strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap [strings.Export_Failed] = "An kasa fitarwa"
stringMap [strings.Add_File] = "Ƙara fayil"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "Jawo kai tsaye cikin fayil ɗin don lodawa"
stringMap [strings.Supported_Imported_File_Types] = "Nau'in fayil a halin yanzu ana tallafawa don shigowa: Kalma (kawai .docx), XMind, .txt"
stringMap [strings.No_Permission_Do_This] = "Ba ku da izinin yin wannan aikin"

stringMap [strings.Upgrade_To_Professional] = "Haɓakawa zuwa Ƙwararren Professionalabi'a"
stringMap [strings.Non_Members_Insert_Videos] = "Wadanda ba membobi ba zasu iya saka bidiyo awanni 24 bayan rajista"
stringMap [strings.Upload_Failed] = "An kasa aikawa"
stringMap [strings.Upload_Failed_Title_Message] = "An kasa aikawa, don Allah danna don tabbatarwa, goge sauran hotunan da suka kasa lodawa"

stringMap [strings.Mind_Edit_Members_Use] = "Membobi Suna Amfani"
stringMap [strings.Invitation_Code] = "Lambar Gayyata"
stringMap [strings.Student_Certification] = "Takaddun Dalibi"
stringMap [strings.I_Get_It] = "Zan, tsallake jagorar"
stringMap [strings. Maraba_To_Mind] = "Maraba da matashin maigidan don shiga Mind+, bari mu fara tafiya ta tunani!"
stringMap [strings.Mind_Before] = "Na farko"
stringMap [strings.Try_A_Map] = "Gwada taswira"
stringMap [strings.Click_Create] = "Danna don ƙirƙirar"
stringMap [strings.Click_Create_A_Mind_Map] = "Danna don ƙirƙirar taswirar hankali"
stringMap [strings.Mind_About] = "Game da"
stringMap [strings.See_Checking_Data] = "Duba bayanan sirri"
stringMap [strings.Modify_Checking_Data] = "Gyara bayanan sirri"
stringMap [strings.Mind_Shear] = "Shear"

stringMap [strings.Mind_Style_Empty] = "Babu salon da aka fi so ko al'ada"
stringMap [strings.Mind_Branch_Free_Layout] = "Layout Free Branch"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "Tsarin hanyoyi biyu da rassan taswirar kumfa za a iya jan su kyauta zuwa shimfidar"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Jigo Jawabin Kyauta"

stringMap [strings.Select_A_Node] = "Zaɓi taken"
stringMap [strings.Create_Child_Node] = "Ƙirƙiri taken yaro"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "Na gaba za mu ƙara mai kyau ga taken yaro"
stringMap [strings.Click_Icon_Panel] = "Danna gunkin gunkin"
stringMap [strings.Select_An_Icon_You_Like] = "Zaɓi gunkin da kuke so"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "Na gaba za mu maye gurbin taswirar hankali da sabuwa"
stringMap [strings.Select_The_Central_Theme] = "Zaɓi jigon tsakiya"
stringMap [strings.Switch_To_Canvas_Panel] = "Canja zuwa Canvas Panel"
stringMap [strings.Click_Layout] = "Danna don shimfidawa"
stringMap [strings.Choose_A_Layout_You_Like] = "Zaɓi tsarin da kuke so"
stringMap [strings.Mind_Map_Save_Share] = "Taya murna, kun yi nasarar yin taswirar hankali mai kyau! Bari mu adana shi kuma mu raba shi da abokanka ~"
stringMap [strings.Click_Save_Picture] = "Danna don adana hoton"
stringMap [strings.Click_OK] = "Danna Ok"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "Taya murna kan kammala jagorar novice ~"
stringMap [strings.Upload_Succeeded] = "An ɗora nasarar cikin nasara"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "Salon Sauri"
stringMap [strings.Mind_Extremely_Important] = "Mai matukar mahimmanci"
stringMap [strings.Mind_Important] = "Muhimmi"
stringMap [strings.Mind_Commonly] = "Janar"
stringMap [strings.Mind_Ordinary] = "Talakawa"
stringMap [strings.Member_Exclusive] = "Wannan fasalin ya keɓance ga membobi. Shin kuna son haɓaka membobin ku?"
stringMap [strings.Member_Exclusive_Color_Scheme] = "Wannan tsarin launi ya keɓance ga membobi. Shin kuna son haɓaka membobin ku?"
stringMap [strings.Mind_Enlarge] = "Ƙara"
stringMap [strings.Mind_Narrow] = "Kunkuntar"
stringMap [strings.Mind_Center] = "Cibiyar"
stringMap [strings.Mind_Full_Screen] = "Cikakken allo"

stringMap [strings.Mind_Association_Node] = "Ƙungiyar Kuɗi"

stringMap[strings.Mind_Resource_See] = "Duba abin da aka makala"
stringMap[strings.Mind_Resource_Delete] = "Share abubuwan da aka makala"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Jawo a sauke fayiloli nan"
stringMap[strings.Mind_Edit_choose_explain] = "Zabi fayil"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "Fayil da aka ɗora ba zai iya wuce 20MB ba"

stringMap[strings.Please_Enter_Mind_Password] = "Please enter a 4-8 digit password"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "Please input a password"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "This function is a member function"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "The file password has been removed"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "The number of password digits is not enough. Please enter the password with 4 inverted 8 digits"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "The password is too long. Please enter the password with 4 inverted 8 digits"
stringMap[strings.Please_Enter_Mind_Password_Success] = "Password set successfully"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "Password format error"
stringMap[strings.Mind_Load_Data_Error] = "Data loading error"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Password error"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Only the creator of this map can set the password"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
module.exports = stringMap;

// package mind.yushu.com.mindmap.utils;

// import android.os.Environment;

// import org.json.JSONArray;
// import org.json.JSONException;
// import org.json.JSONObject;
// import org.xml.sax.InputSource;
// import org.xml.sax.XMLReader;
// import org.xml.sax.helpers.DefaultHandler;

// import java.io.ByteArrayOutputStream;
// import java.io.File;
// import java.io.FileInputStream;
// import java.io.FileOutputStream;
// import java.io.FileWriter;
// import java.io.IOException;
// import java.io.InputStream;
// import java.io.PrintWriter;
// import java.io.StringReader;
// import java.text.DateFormat;
// import java.text.SimpleDateFormat;
// import java.util.ArrayList;
// import java.util.Date;
// import java.util.Enumeration;
// import java.util.HashMap;
// import java.util.Iterator;
// import java.util.List;
// import java.util.Map;
// import java.util.zip.ZipEntry;
// import java.util.zip.ZipFile;

// import javax.xml.parsers.SAXParserFactory;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/29
 * Copyright(c) 2020 mindyushu.com
 */

class Strings {
    // Don't let anyone instantiate this class.
    Strings() {
        // This constructor is intentionally empty.
    }

    /**
     * Compare protocol commands, why we don't use
     * string.equals/string.equalsIgnoreCase is because we need to make it be
     * easy maintained. Like, if we want to make the command comparison be case
     * insensitive, we only need to change this method.
     *
     * @param cmd     the first command
     * @param another another command
     * @return true for command equality, false for otherwise
     */
    equalCommands(cmd, another) {
        return equalsIgnoreCase(cmd, another);
    }

    /**
     * Compare user names, why we don't use
     * string.equals/string.equalsIgnoreCase is because we need to make it be
     * easy maintained. Like, if we want to make the name comparison be case
     * insensitive, we only need to change this method.
     *
     * @param name    the first name
     * @param another another name
     * @return true for name equality, false for otherwise
     */
    equalNames(name, another) {
        return equals(name, another);
    }

    removeLineFeed(value) {
        return value.replace(/^\s+|\s+$/g,'')
    }

    /**
     * Checks if two strings are equals. What this method differs from
     * String.equals(s) is that this method allows the strings to be null.
     *
     * @param s1 the first input string
     * @param s2 the second input string
     * @return true when two not null strings are equal, false for otherwise.
     */
    equals(s1, s2) {
        return (s1 == null && s2 == null) || (s1 != null && s2 != null && s1 == s2);
    }

    /**
     * Checks if two strings are equal case insensitively. What this method
     * differs from String.equals(s) is that this method allows the strings to
     * be null.
     *
     * @param s1 the first input string
     * @param s2 the second input string
     * @return true when two not null strings are equal case insensitively,
     * false for otherwise.
     */
    equalsIgnoreCase(s1, s2) {
        return (s1 == null && s2 == null) || (s1 != null && s2 != null && s1.toLowerCase() == s2.toLowerCase());
    }

    /**
     * Checks if all the chars in the string are digits.
     *
     * @param value the input string
     * @return true for all digits, false for otherwise.
     */
    isDigits(value) {
        return !isNullOrEmpty(value) && value.matches("-?\\d+");
    }

    /**
     * Joins multiple strings to a string with specified separator.
     *
     * @param strings   The set of strings;
     * @param separator The specified separator
     * @return The joined string.
     */
    joinStrings(strings, separator) {
        return joinCollection(strings, separator);
    }

    /**
     * Checks if the string is null or empty.
     *
     * @param value the input string
     * @return true when the string is either null or empty, false for
     * otherwise.
     */
    isNullOrEmpty(value) {
        return value == null || value.length == 0;
    }

    parseBoolean(value) {
        return Strings.equals("1", value) || Boolean(value);
    }

    /**
     * Joins multiple numbers to a string with specified separator.
     *
     * @param numbers   The set of numbers
     * @param separator The specified separator
     * @return The joined string.
     */
    joinLong(numbers, separator) {
        return joinCollection(numbers, separator);
    }

    /**
     * Joins multiple items to a string with specified separator.
     *
     * @param items     The set of items
     * @param separator The specified separator
     * @return The joined string.
     */
    joinCollection(items, separator) {
        if (items == null) return "";
        var builder = new StringBuilder();
        var first = true;
        items.forEach(item => {
            if (!first) builder.append(separator);
            builder.append(item);
            first = false;
        });
        return builder.toString();
    }

    getString(obj) {
        if (obj == null) {
            return null;
        }

        return String.valueOf(obj);
    }

    lineFeed(value) {
        if (this.isEmpty(value)) {
            return "";
        }
        value = value.replace(new RegExp("\n", "g"), "")
        value = value.replace(new RegExp("\r", "g"), "")
        return value;
    }

    removeTextEnter(value) {
        if (this.isEmpty(value)) {
            return "";
        }
        value = value.replace(/[ ]|[\r\n]/g,"");
        return value;
    }

    getThrowableDescription(throwable) {
        if (throwable == null) {
            return null;
        }

        var elements = throwable.getStackTrace();

        if (elements == null) {
            return null;
        }

        var sb = new StringBuilder();

        elements.forEach(element => {
            sb.append(element.toString() + "\n");
        });

        return sb.toString();
    }

    isEmpty(target) {
        return target == null || target.length == 0;
    }

    getBoolean(s) {
        var k = false;

        if (isEmpty(s)) {
            return k;
        }

        try {
            if (s.equals("1")) {
                return true;
            }

            k = Boolean.parseBoolean(s);
        } catch (e) {
            Logger.log(e);
        }

        return k;
    }

    getInteger(s) {
        var k = -1;

        if (isEmpty(s)) {
            return k;
        }

        try {
            k = Integer.parseInt(s);
        } catch (e) {
            Logger.log(e);
        }

        return k;
    }

    getIntegerDefaultZero(s) {
        var k = 0;

        if (isEmpty(s)) {
            return k;
        }

        try {
            k = Integer.parseInt(s);
        } catch (e) {
            Logger.log(e);
        }

        return k;
    }

    getLong(s) {
        var k = -1;

        if (isEmpty(s)) {
            return k;
        }

        try {
            k = Long.parseLong(s);
        } catch (e) {
            Logger.log(e);
        }

        return k;
    }

    getLong(s, def) {
        var k = Long.valueOf(def);

        if (isEmpty(s)) {
            return k;
        }

        try {
            k = Long.parseLong(s);
        } catch (e) {
            Logger.log(e);
        }

        return k;
    }

    getNotNullString(s) {
        if (isEmpty(s)) {
            return "";
        }

        return s;
    }

    parseStringMapFromJson(jsonString) {
        if (jsonString == null) {
            return null;
        }

        if (jsonString.startsWith("\ufeff")) {
            jsonString = jsonString.substring(1);
        }

        var jobj;
        try {
            jobj = new JSONObject(jsonString);
            var map = new HashMap();
            var it = jobj.keys();

            while (it.hasNext()) {
                var key = it.next().toString();
                var value = jobj.getString(key);
                map.put(key, value);
            }
            return map;

        } catch (e) {
            Logger.log(e);
        }
        return null;
    }

    parseMapFromJson(jsonString) {
        if (jsonString == null) {
            return null;
        }

        if (jsonString.startsWith("\ufeff")) {
            jsonString = jsonString.substring(1);
        }

        try {
            var jobj;
            var map;
            map = new HashMap();
            jobj = new JSONObject(jsonString);
            setJsonValueToMap(jobj, map);
            return map;

        } catch (e) {
            Logger.log(e);
        }
        return null;
    }

    setJsonValueToMap(json, map) {

        var it = json.keys();
        try {
            while (it.hasNext()) {
                var key = it.next().toString();
                var value = json.getString(key);
                if (Strings.isNullOrEmpty(value)) {
                    continue;
                }
                if (json.get(key) instanceof JSONArray) {
                    var tempList = parseListFromJson(value);
                    if (tempList != null) {
                        map.put(key, tempList);
                    }
                } else if (json.get(key) instanceof JSONObject) {
                    var valueMap = parseMapFromJson(value);
                    if (valueMap != null) {
                        map.put(key, valueMap);
                    }
                } else {
                    map.put(key, value);
                }
            }
        } catch (e) {
            Logger.log(e);
        }
    }

    parseListFromJson(jsonString) {
        var jarray;

        if (jsonString == null) {
            return null;
        }

        if (jsonString.startsWith("\ufeff")) {
            jsonString = jsonString.substring(1);
        }

        try {
            jarray = new JSONArray(jsonString);

            var list = new Array();

            for (var i = 0; i < jarray.length(); i++) {
                var value = jarray.getString(i);
                if (Strings.isNullOrEmpty(value)) {
                    continue;
                }

                if (jarray.get(i) instanceof JSONObject) {
                    var tempMap = parseMapFromJson(value);
                    if (tempMap != null) {
                        list.push(tempMap);
                    }
                } else if (jarray.get(i) instanceof JSONArray) {
                    var tempList = parseListFromJson(value);
                    if (tempList != null) {
                        list.push(tempList);
                    }
                } else {
                    list.push(value);
                }
            }

            return list;

        } catch (e) {
            Logger.log(e);
        }
        return null;

    }

    parseStringListFromJson(jsonString) {
        var jarray;

        if (jsonString == null) {
            return null;
        }

        if (jsonString.startsWith("\ufeff")) {
            jsonString = jsonString.substring(1);
        }

        try {
            jarray = new JSONArray(jsonString);
            var list = new Array();

            for (var i = 0; i < jarray.length(); i++) {
                var value = jarray.getString(i);
                list.push(value);
            }

            return list;

        } catch (e) {
            Logger.log(e);
        }
        return null;
    }

    toStringFromStringMap(map) {
        if (map == null) {
            return null;
        }

        var iter = map.entrySet().iterator();
        var holder = new JSONObject();

        while (iter.hasNext()) {
            var pairs = iter.next();
            var key = pairs.getKey();
            var value = pairs.getValue();
            holder.put(key, value);
        }

        return holder.toString();
    }

    toStringFromMap(map) {
        if (map == null) {
            return null;
        }

        var iter = map.entrySet().iterator();
        var holder = new JSONObject();
        try {
            while (iter.hasNext()) {
                var pairs = iter.next();
                var key = pairs.getKey();
                var value = pairs.getValue();
                var jsonString;

                if (value instanceof Map) {
                    jsonString = toStringFromMap(value);
                    if (!Strings.isNullOrEmpty(jsonString)) {
                        holder.put(key, new JSONObject(jsonString));
                    }
                } else if (value instanceof List) {
                    jsonString = toStringFromList(value);
                    if (!Strings.isNullOrEmpty(jsonString)) {
                        holder.put(key, new JSONArray(jsonString));
                    }

                } else {
                    jsonString = value == null ? "" : value.toString();
                    try {
                        if (jsonString.contains("{") && jsonString.contains("}")) {
                            var json = new JSONObject(jsonString);
                            holder.put(key, json);
                        } else {
                            holder.put(key, jsonString);
                        }
                    } catch (e) {
                        holder.put(key, jsonString);
                    }
                }
            }
        } catch (e) {
            Logger.log(e.getMessage());
            return "";
        }
        return holder.toString();
    }

    toStringFromList(list) {
        if (list == null) {
            return null;
        }

        var array = new JSONArray();

        list.forEach(value => {
            var jsonString = "";
            if (value instanceof Map) {
                jsonString = toStringFromMap(value);
                if (!Strings.isNullOrEmpty(jsonString)) {
                    array.put(new JSONObject(jsonString));
                }
            } else if (value instanceof List) {
                jsonString = toStringFromList(value);
                if (!Strings.isNullOrEmpty(jsonString)) {
                    array.put(new JSONArray(jsonString));
                }
            } else if (value != null) {
                jsonString = value.toString();
                if (jsonString.contains("{") && jsonString.contains("}")) {
                    try {
                        array.put(new JSONObject(jsonString));
                    } catch (e) {
                        array.put(jsonString);
                    }
                } else {
                    array.put(jsonString);
                }
            }
        });
        return array.toString();
    }
}
class Stream {
    getBytes(is) {
        return getBytes(is, true);
    }

    getBytes(is, isCloseStream) {
        if (is == null) {
            return null;
        }

        try {
            var baos = new ByteArrayOutputStream();
            var buffer = new byte[1024 * 32];
            var k = 0;
            while ((k = is.read(buffer)) > 0) {
                baos.write(buffer, 0, k);
                baos.flush();
            }

            if (isCloseStream) {
                is.close();
            }

            baos.close();

            return baos.toByteArray();
        } catch (e) {
            Logger.log(e);
        }

        return null;
    }

    CopyFile(scrPath, desPath) {
        file.checkAndCreateDirectory(desPath);
        var fis = new FileInputStream(new File(scrPath));
        var fos = new FileOutputStream(new File(desPath));
        var buffer = new byte[1024];
        var l;
        while ((l = fis.read(buffer)) > 0) {
            fos.write(buffer, 0, l);
            fos.flush();
        }

        fis.close();
        fos.close();
    }
}

class file {
    isExist(path) {
        try {
            if (path == null) {
                return false;
            }

            return new File(path).exists();
        } catch (e) {
            Logger.log(e);
        }

        return false;
    }

    getFilename(path) {
        if (path == null) {
            return path;
        }

        var index = path.lastIndexOf("/");

        if (index != -1) {
            return path.substring(index + 1);
        }

        return path;
    }

    extractZipFile(source, desBase) {
        return extractZipFile(-1, source, desBase);
    }

    /*
     * sample:
     * AvqUtils.file.extractZipFile(CacheManager.get().getDebugLogPath
     * ("test.zip"), CacheManager.get().getZipFolderPath());
     */
    extractZipFile(stickerPackId, source, desBase) {
        if (source == null || desBase == null) {
            return null;
        }

        var file = new File(source);

        if (file.exists() == false) {
            return null;
        }

        var endString = desBase.substring(desBase.length() - 1, desBase.length());
        var base = String.valueOf(desBase);
        if (endString.equals("/") == false) {
            base = base + "/";
        }

        try {
            var entry;
            var zipInStream = new ZipFile(source);
            var e = zipInStream.entries();

            var fos = null;
            var is = null;
            var buffer = new byte[1024 * 32];
            var k = 0;
            var map = new HashMap();

            while (e.hasMoreElements()) {
                entry = e.nextElement();
                var entryName = entry.getName();
                var outPath = base + entryName;
                if (entry.isDirectory()) {
                    continue;
                }

                if (entryName.contains("preview") && stickerPackId > 0) {
                    entryName = entryName.replace("preview", "preview_" + stickerPackId);
                    outPath = base + entryName;
                }

                checkAndCreateDirectory(outPath);

                fos = new FileOutputStream(new File(outPath));
                is = zipInStream.getInputStream(entry);

                while ((k = is.read(buffer, 0, buffer.length)) > 0) {
                    fos.write(buffer, 0, k);
                    fos.flush();
                }

                fos.close();
                is.close();
                map.put(entryName, outPath);
            }

            zipInStream.close();

            return map;
        } catch (e) {
            System.out.println("zip error:" + e.getMessage());
        }

        return null;
    }

    deleteFile(path) {
        var result = false;
        try {
            if (path != null) {
                var file = new File(path);
                if (file.exists()) {
                    result = file.delete();
                }
            }
        } catch (e) {
            Logger.log(e);
        }
        return result;
    }

    deleteDirectory(dir) {
        if (dir == null) {
            return true;
        }

        if (!dir.exists() || !dir.isDirectory()) {
            return false;
        }

        var files = dir.list();
        if (files != null) {
            for (var i = 0, len = files.length; i < len; i++) {
                var f = new File(dir, files[i]);

                if (f.isDirectory()) {
                    deleteDirectory(f);
                } else {
                    f.delete();
                }
            }
        }
        return dir.delete();
    }

    copyFile(from, to) {
        if (from == null || to == null) {
            return;
        }
        try {
            var fromFile = new File(from);
            if (fromFile.exists() == false) {
                return;
            }
            checkAndCreateDirectory(to);
            var toFile = new File(to);
            var fis = new FileInputStream(fromFile);
            var fos = new FileOutputStream(toFile);

            var buffer = new byte[32 * 1024];
            var k;
            while ((k = fis.read(buffer)) > 0) {
                fos.write(buffer, 0, k);
                fos.flush();
            }
            fis.close();
            fos.close();
        } catch (e) {
            Logger.log(e);
        }
    }

    printLogToFile(filename, text, append) {
        if (filename == null || text == null) {
            return;
        }

        try {
            var df = new SimpleDateFormat("HH:mm:ss");
            var nowString = df.format(new Date());
            var pw = new PrintWriter(new FileWriter(filename, append));
            pw.println(nowString + " " + text);
            pw.close();
        } catch (e) {
            Logger.log("The error is Print log to file.");
        }
    }

    rename(from, to) {
        var source = new File(from);
        return source.renameTo(new File(to));
    }

    readFromFile(path) {
        try {
            if (new File(path).exists() == false) {
                return null;
            }
            var fis = new FileInputStream(new File(path));
            var bos = new ByteArrayOutputStream();
            var buffer = new byte[1024 * 32];
            var k = 0;
            while ((k = fis.read(buffer)) > 0) {
                bos.write(buffer, 0, k);
                bos.flush();
            }

            fis.close();
            bos.close();

            return bos.toByteArray();

        } catch (e) {
            Logger.log(e);
        }

        return null;
    }

    writeToFile(path, buffer) {
        if (buffer == null || path == null) {
            return;
        }

        checkAndCreateDirectory(path);

        try {
            var fos = new FileOutputStream(new File(path));
            fos.write(buffer);
            fos.flush();
            fos.close();

        } catch (e) {
            e.printStackTrace();
        }
    }

    // String baseUrl="/data/data/"+this.getPackageName()+"/";
    scanFolder(baseUrl) {
        if (baseUrl == null) {
            return;
        }

        if (new File(baseUrl).exists() == false) {
            return;
        }

        var file = new File(baseUrl);
        var list = file.list();

        if (list == null) {
            return;
        }

        list.forEach(s => {
            var subFile = new File(baseUrl + s);
            if (subFile.isDirectory()) {
                scanFolder(baseUrl + s + "/");
            } else {
                var path = baseUrl + s;
                System.out.println(path + "\nsize: " + new File(path).length());
            }
        });
    }

    // String baseUrl="/data/data/"+this.getPackageName()+"/";
    // this.copyFoler(baseUrl, "wificity");
    copyFoler(baseUrl, outputFolderName) {
        var outputUrl = Environment.getExternalStorageDirectory().getPath() + "/" + outputFolderName + baseUrl;

        var file = new File(baseUrl);
        var list = file.list();

        list.forEach(s => {
            var subFile = new File(baseUrl + s);

            if (subFile.isDirectory()) {
                var outFile = new File(outputUrl + s);
                outFile.mkdirs();
                copyFoler(baseUrl + s + "/", outputFolderName);

            } else {
                try {
                    Stream.CopyFile(baseUrl + s, outputUrl + s);
                } catch (e) {
                    e.printStackTrace();
                }

            }
        });
    }

    checkAndCreateDirectory(path) {
        if (path == null) {
            return;
        }

        var file = new File(path);
        var parent = file.getParentFile();

        if (parent != null && !parent.exists()) {
            parent.mkdirs();
        }
    }
}
//??
class xml {
    XMLtoList(saxhandler, xmlstr) {
        try {
            // var factory = SAXParserFactory.newInstance();
            // var reader = factory.newSAXParser().getXMLReader();
            // reader.setContentHandler(saxhandler);
            // reader.parse(new InputSource(new StringReader(xmlstr)));
            var parser = new DOMParser();
            var xmlDoc = parser.parseFromString(xmlstr, "text/xml");
            // var countrys = xmlDoc(xmlstr);
            // var arr = [];

            // for (var i = 0; i < countrys.length; i++) {
            //     arr.push(countrys[i].textContent);
            // };
            // //console.log(arr);
            // return arr;
            // // return saxhandler.getList();
        } catch (e) {
            System.out.println("sax xml error:" + e.getMessage());
        }
        return null;
    }
}
/**
 * static abstract class: extends this class and detail "getList()"
 * before use void "getXMLList" defined next below
 *
 * @author frankie
 */
// class SaxHandler extends DefaultHandler {
//     getList();
// }

export default Strings
import { Point } from "canvg";
import BaseShape from "./BaseShape";
import NodeLayoutType from "../../datatype/NodeLayoutType";
import UiUtil from "../../../utils/UiUtil";
import MindElementType from "../../datatype/MindElementType";
import LinePath from "../base/LinePath";
import CGRect from "../base/basedata/Rect";
import Util from "../../../utils/Util";

class CornerShape extends BaseShape {
    
    constructor(data, space = 0) {
        super(data, space);
    }

    hit(point) {
        let anchorPoint = this.hitAnchorPoint(point);
        if (anchorPoint.x > -1 && anchorPoint.y > -1) {
            return anchorPoint;
        }
        let sidePoint = this.hitSide(point);
        if (sidePoint.x > -1 && sidePoint.y > -1) {
            return sidePoint;
        }
        let withinRect = this.rect()
        if (Util.containsInRectForPoint(withinRect, point)) {
            return point;
        }
        return new Point(-1, -1);
    }

    hitSide(point) {
        if (this.data == null || this.data.isEmpty() || 
            point == null || point.x < 0 || point.y < 0) {
            return new Point(-1, -1);
        }
        let margin = new UiUtil().dip2px(15);
        let outsideRect = this.rect(margin)
        if (outsideRect.width <= 0) {
            return new Point(-1, -1);
        }
        if (!Util.containsInRectForPoint(outsideRect, point)) {
            return new Point(-1, -1);
        }
        margin = -new UiUtil().dip2px(10);
        let withinRect = this.rect(margin)
        if (Util.containsInRectForPoint(withinRect, point)) {
            return new Point(-1, -1);
        }
        margin = new UiUtil().dip2px(15);
        var cornerRadius = this.getCornerRadius();
        let leftTopCircularCenter = new Point(this.data.x + cornerRadius, this.data.y + cornerRadius);
        let rightTopCircularCenter = new Point(this.data.x + this.data.width - cornerRadius, this.data.y + cornerRadius);
        let leftBottomCircularCenter = new Point(this.data.x + cornerRadius, this.data.y + this.data.height - cornerRadius);
        let rightBottomCircularCenter = new Point(this.data.x + this.data.width - cornerRadius, this.data.y + this.data.height - cornerRadius);
       
        if (point.y <= leftTopCircularCenter.y && point.x <= leftTopCircularCenter.x &&
            Util.getPointSpacing(leftTopCircularCenter, point) < cornerRadius + margin + this.space) {
            return Util.getInsertPointCircleAndPoint(point, leftTopCircularCenter.x, leftTopCircularCenter.y, cornerRadius + this.space)
        } else if (point.y <= rightTopCircularCenter.y && point.x >= rightTopCircularCenter.x &&
            Util.getPointSpacing(rightTopCircularCenter, point) < cornerRadius + margin) {
            return Util.getInsertPointCircleAndPoint(point, rightTopCircularCenter.x, rightTopCircularCenter.y, cornerRadius + this.space)
        } else if (point.y >= leftBottomCircularCenter.y && point.x <= leftBottomCircularCenter.x &&
            Util.getPointSpacing(leftBottomCircularCenter, point) < cornerRadius + margin) {
            return Util.getInsertPointCircleAndPoint(point, leftBottomCircularCenter.x, leftBottomCircularCenter.y, cornerRadius + this.space)
        } else if (point.y >= rightBottomCircularCenter.y && point.x >= rightBottomCircularCenter.x &&
            Util.getPointSpacing(rightBottomCircularCenter, point) < cornerRadius + margin) {
            return Util.getInsertPointCircleAndPoint(point, rightBottomCircularCenter.x, rightBottomCircularCenter.y, cornerRadius + this.space)
        }
        let rect = this.rect()
        
        let top = Util.getPointToLineSpaceing(point, 
                                                new Point(rect.x + cornerRadius, rect.y), 
                                                new Point(rect.x + rect.width() - cornerRadius, rect.y));
        if (top <= margin) { //top line
            return new Point(point.x, rect.y)
        }
        let bottom = Util.getPointToLineSpaceing(point, 
            new Point(rect.x + cornerRadius, rect.y + rect.height()), 
            new Point(rect.x + rect.width() - cornerRadius, rect.y + rect.height()));
        if (bottom <= margin) { //bottom line
            return new Point(point.x, rect.y + rect.height())
        }
        let left = Util.getPointToLineSpaceing(point, 
            new Point(rect.x, rect.y + cornerRadius), 
            new Point(rect.x, rect.y + rect.height() - cornerRadius));
        if (left <= margin) { //left line
            return new Point(rect.x, point.y)
        }
        let right = Util.getPointToLineSpaceing(point, 
            new Point(rect.x + rect.width(), rect.y + cornerRadius), 
            new Point(rect.x + rect.width(), rect.y + rect.height() - cornerRadius));
        if (right <= margin) { //left line
            return new Point(rect.x + rect.width(), point.y)
        }
        return new Point(-1, -1);
    }

    hitAnchorPoint(point) {
        let anchorPointList = this.getAnchorPoint();
        // let margin = new UiUtil().dip2px(5); 
        for (let index = 0; index < anchorPointList.length; index++) {
            const cell = anchorPointList[index];
            if (Util.containsInRectForPoint(cell.rect, point)) {
                return cell.point
            }
        }
        return new Point(-1, -1);
    }

    getAnchorPoint() {
        let list = new Array()
        let borderWidth = this.borderWidth()
        let margin = new UiUtil().dip2px(5); 
        list.push({point: new Point(this.data.x + this.data.width/2, this.data.y - borderWidth/2 - this.space),
                    rect: new CGRect(this.data.x + this.data.width/2 - margin, this.data.y - borderWidth/2 - this.space - margin,
                     margin * 2, margin * 2 + this.space)});//top    

        list.push({point: new Point(this.data.x + this.data.width/2, this.data.y + this.data.height + borderWidth/2 + this.space),
                    rect: new CGRect(this.data.x + this.data.width/2 - margin, this.data.y + this.data.height + borderWidth/2 - margin, 
                    margin * 2, margin * 2 + this.space)});//bottom

        list.push({point: new Point(this.data.x - borderWidth/2 - this.space, this.data.y + this.data.height / 2),
                    rect: new CGRect(this.data.x - borderWidth/2 - this.space - margin, this.data.y + this.data.height / 2 - margin, 
                    margin * 2 + this.space, margin * 2)});//left

        list.push({point: new Point(this.data.x + this.data.width + borderWidth/2 + this.space, this.data.y + this.data.height / 2),
                    rect: new CGRect(this.data.x + this.data.width + borderWidth/2 - margin, this.data.y + this.data.height / 2 - margin, 
                    margin * 2 + this.space, margin * 2)});//right
        return list;
    }

    getPath() {
        var cornerRadius = this.getCornerRadius();
        let linePath = new LinePath();

        linePath.moveTo(cornerRadius, 0);
        linePath.lineTo(this.data.width - cornerRadius, 0);
        linePath.moveTo(cornerRadius, 0);
        linePath.lineTo(this.data.width - cornerRadius, 0);
        linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width, cornerRadius);    //这里都是小圆
        linePath.lineTo(this.data.width, this.data.height - cornerRadius);
        linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width - cornerRadius, this.data.height);
        linePath.lineTo(cornerRadius, this.data.height);
        linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, 0, this.data.height - cornerRadius);
        linePath.lineTo(0, cornerRadius);
        linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius, 0);
        return linePath;
    }

    getCornerRadius() {
        var cornerRadius = this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0.0 : new UiUtil().dip2px(6);
        if (cornerRadius > 0) {
            switch (this.data.type) {
                case MindElementType.SUBJECT:
                case MindElementType.CONTENT_GENERALIZATION:
                    cornerRadius = new UiUtil().dip2px(6);
                    break;
                case MindElementType.SON_SUBJECT:
                    cornerRadius = new UiUtil().dip2px(5);
                    break;
            }
        }
        return cornerRadius;
    }
}
export default CornerShape
// package mind.yushu.com.mindmap.core.calcule.elementCalculation;

// import android.graphics.Point;
// import android.util.Size;

// import java.util.ArrayList;

// import mind.yushu.com.mindmap.core.basemode.EdgeInsets;
// import mind.yushu.com.mindmap.main.MindMapApplication;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGRect;
// import mind.yushu.com.mindmap.viewmodel.datatype.IconLayoutType;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindElementShapeType;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindElementType;
// import mind.yushu.com.mindmap.viewmodel.datatype.NodeLayoutType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.ConnectMapElementContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.IconElementContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.LinkElementContent;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.MindElementContent;
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndIconAndLinkAndConnectMap extends BaseMindElementDataCalculation {
    constructor(data, nodeLayoutType) {
        super(data, nodeLayoutType);
    }

    // public TextAndIconAndLinkAndConnectMap(MindElementData data, NodeLayoutType nodeLayoutType) {
    //     super(data, nodeLayoutType);
    // }

    calculate() {
        if (!this.data.isContainLinkContent() || !this.data.isContainConnectMapContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let linkElementContent = this.data.linkElementContent;
        let connectMapElementContent = this.data.connectMapElementContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        let textRext = this.caluleTextCGRect();
        let textHeight = textRext.height();
        linkElementContent.width = textHeight;
        linkElementContent.height = textHeight;
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;

        let size = this.includeIconAndTextAndLinkAndConnectMapSize();
        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let textLinkConnectMapSize = this.includeTextAndLinkAndConnectMapSize();
        let iconLeft = 0;
        let iconTop = 0;
        let linkTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == IconLayoutType.LAYOUT_LEFT) {
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconTextSpace) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = ((size.getHeight()) - linkElementContent.height) / 2 + marginY;
            connectMapElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
            connectMapElementContent.y = linkElementContent.y;

        } else if (layoutType == IconLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconTextSpace);
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = ((size.getHeight()) - linkElementContent.height) / 2 + marginY;
            connectMapElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
            connectMapElementContent.y = linkElementContent.y;

        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = (this.iconSpace);
            textMindElementContent.x = ((size.getWidth()) - (textLinkConnectMapSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = textMindElementContent.y + ((textLinkConnectMapSize.getHeight()) - linkElementContent.height) / 2;
            connectMapElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
            connectMapElementContent.y = linkElementContent.y;

        }
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = iconLeft + marginX;
            } else {
                iconCell.x = iconElementContents[index - 1].x + iconElementContents[index - 1].width + (this.iconSpace);
            }
            iconCell.y = iconTop + marginY;
        }
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}



export default TextAndIconAndLinkAndConnectMap
import Size from "../../../../viewmodel/core/base/Size";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndLinkCalculation extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (this.data.imageContent == null || !this.data.isContainLinkContent()) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();

        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }

        let linkElementContent = this.data.linkElementContent;
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        linkElementContent.width = textHeight;
        linkElementContent.height = textHeight;

        let textLinkSize = this.includeLinkAndTextSize();
        let size = this.includeImageAndTextLinkSize();
        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;
        let linkTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2 + marginY;
            iconLeft = ((this.data.type == MindElementType.MAIN_SUBJECT || 
            this.data.type == MindElementType.SUBJECT || 
            this.data.type == MindElementType.CONTENT_GENERALIZATION ||
            this.data.type == MindElementType.EXPLAIN) ? (this.iconMargin) : 0) + marginX;
            
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = ((size.getHeight()) - linkElementContent.height) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin) + marginX;
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2 + marginY;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = ((size.getHeight()) - linkElementContent.height) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2 + marginX;
            iconTop = marginY;
            textMindElementContent.x = ((size.getWidth()) - (textLinkSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + this.iconSpace;

            linkElementContent.x = textMindElementContent.x + textMindElementContent.width + linkTextSpace;
            linkElementContent.y = textMindElementContent.y + ((textLinkSize.getHeight()) - linkElementContent.height) / 2;
        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        // this.setMindElementSize(size.getWidth(), size.getHeight(), marginX, marginY);
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndImageAndLinkCalculation

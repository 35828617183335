/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/2
 * Copyright(c) 2020 mindyushu.com
 */

let MindHistoryCellType = {
    Map:0,
    File:1
}
export default MindHistoryCellType

var tools = {}

tools.isElectron = function () {
    return navigator.userAgent.indexOf('Electron') > -1
}

tools.isWeb = function () {
    return !tools.isElectron()
}

tools.isWindows = function () {
    return tools.isElectron() && navigator.platform.toLowerCase().indexOf('win32') > -1
}

tools.isMac = function () {
    return tools.isElectron() && navigator.platform.toLowerCase().indexOf('mac') > -1
}

tools.isLinux = function () {
    return tools.isElectron() && navigator.platform.toLowerCase().indexOf('linux') > -1
}

tools.isMacSafari = function () {
    return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
}

tools.isMacComputed = function () {
    return navigator.platform.toLowerCase().indexOf('mac') > -1
}

tools.console = function (res) {
    let len = arguments.length;
    for(let index = 0; index < len; index++) {
        console.log(arguments[index])
    }
}

  /**
  * 检测图片是否存在
  * @param url
  */
let imageIsExist = function(url) {
    return new Promise((resolve) => {
        var img = new Image();img.setAttribute('crossOrigin','Anonymouse');
        img.onload = function () {
            if (this.complete == true){
                resolve(true);
                img = null;
            }
        }
        img.onerror = function () {
            resolve(false);
            img = null;
        }
        img.src = url;
    })
}


tools.install = function (Vue, options) {
    Vue.prototype.$tools = tools
    Vue.tools = tools
    //全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
    Vue.directive('real-img', async function (el, binding) {//指令名称为：real-img
        let imgURL = binding.value;//获取图片地址
        if (imgURL) {
            let exist = await imageIsExist(imgURL);
            if (exist) {
                el.setAttribute('src', imgURL);
            } 
        }
    })

    Vue.directive('clickoutside', {//指令名称为：real-img
        // async function (el, binding, vnode) 
        bind: function (el, binding, vnode) {
            function documentHandler(e) {
              if (el.contains(e.target)) {
                return false
              }
              if (binding.expression) {
                binding.value(e)
              }
            }
    
            function KeyUp(e) {
              if (e.keyCode == 27) {
                if (binding.expression) {
                  binding.value(e)
                }
              }
            }
            el.__vueClickOutSize__ = documentHandler
            el.__vueKeyup__ = KeyUp
            
            document.addEventListener('keyup', KeyUp)
            document.addEventListener('click', documentHandler)
        },

         unbind: function(el, binding) {
            document.removeEventListener('click', el.__vueClickOutSize__)
            delete  el.__vueClickOutSize__
    
            document.removeEventListener('keyup', el.__vueKeyup__)
            delete  el.__vueKeyup__
        }


    })

}


export default tools
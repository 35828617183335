// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

let TimeLineNodeType = {
    TIME_LINE_CIRCULAR:1, //线条节点圆形
    TIME_LINE_DIAMOND:2, //线条节点菱形
    TIME_LINE_CIRCULAR_CONNECT_HEAD:3, //线条节点圆形连接Head
    TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE:4, //线条节点圆形连接Head和Title
    TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER:5, //线条节点连接时间带序号
    TIME_LINE_CIRCULAR_ORDER:6, //线条节点圆形带序号
    TIME_LINE_CIRCULAR_ARROW_HEAD:7, //线条节点连接时间以箭头形式
    TIME_LINE_CIRCULAR_ORDER_1:8, //线条节点圆形带序号
    TIME_LINE_HEXAGON_ORDER:9, //线条节点正六边形序号

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.TIME_LINE_CIRCULAR;
    }

}

export default TimeLineNodeType

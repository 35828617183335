// package mind.yushu.com.mindmap.viewmodel.core.minddata;

// import java.util.ArrayList;
// import java.util.List;
// import java.util.Map;


import MindFileStyleType from "../../datatype/MindFileStyleType"
import MindHistoryCellType from "../../datatype/MindHistoryCellType"
import MindType from "../../datatype/MindType"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/2
 * Copyright(c) 2020 mindyushu.com
 */

class UseHistoryCell {
    constructor() {
        this.INVALID_ID = -1;
        this.id;
        this.name;
        this.subtitle;
        this.imageurl;
        this.detailsId;
        this.createTime;
        //this.modifyTime = System.currentTimeMillis();
        this.mindType;
        this.parentId = -1;
        this.childIds = new Array();
        this.type = MindHistoryCellType.Map;
        this.fileType = MindFileStyleType.Normal;
        this.isIcloud = false;
    }

    UseHistoryCell(id, name, mindType) {
        this.id = id;
        this.name = name;
        this.mindType = mindType;
    }

    setSubtitle(subtitle) {
        this.subtitle = subtitle;
        return this;
    }

    setImageurl(imageurl) {
        this.imageurl = imageurl;
        return this;
    }

    setDetailsId(detailsId) {
        this.detailsId = detailsId;
        return this;
    }

    setCreateTime(createTime) {
        this.createTime = createTime;
        return this;
    }

    setModifyTime(modifyTime) {
        this.modifyTime = modifyTime;
        return this;
    }

    setParentId(id) {
        this.parentId = id;
        return this;
    }

    addChild(id) {
        if (childIds.indexOf(id) != -1) {
            return this;
        }
        this.childIds.push(id);
        return this;
    }

    removeChild(id) {
        if (this.childIds.length == 0) {
            return;
        }
        for (let index = 0; index < this.childIds.length; index++) {
            if (this.childIds[index] == id) {
                this.childIds.remove(index);
                return;
            }
        }
    }

    setChilds(ids) {
        this.childIds = ids;
        return this;
    }

    setType(type) {
        this.type = type;
        return this;
    }

    setFileType(type) {
        this.fileType = type;
        return this;
    }

    isEmpty() {
        return id == new UseHistoryCell().INVALID_ID;
    }

    childsFormatString() {
        let value = "";
        for (let index = 0; index < childIds.length; index++) {
            if (value.length() > 0) {
                value += "," + childIds[index].id;
            } else {
                value = childIds[index].id + "";
            }
        }
        return value;
    }

    create(id, name, mindType) {
        return new UseHistoryCell(id, name, mindType);
    }

    emptyCell() {
        return this.create(new UseHistoryCell.INVALID_ID, "", MindType.LINE_MAP);
    }
}
export default UseHistoryCell

import Vue from 'vue';
import Vuex from 'vuex';
import HttpServerConfig from '../../common/HttpServerConfig';
import NodeLayoutType from "../../viewmodel/datatype/NodeLayoutType"
import ColorToSystem from "../../utils/ColorToSystem"
import colorChangeClass from '../../utils/ColorToSystem';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tempToken: "", //临时验证
    UserData: "",
    colorAccout: "",//颜色状态
    busColor: "",//背景颜色
    borderColor: "",//边框颜色
    textColor: "", //文字颜色
    lineColor: "",  //线条颜色
    fillColor: "", //填充颜色
    borderMinorStyle: "",//分支线条颜色
    chartFillColor: "",//统计图颜色
    spanTextColor: "",//单独文字颜色
    spanTextBgColor: "",//单独文字背景颜色
    spanTextShadowColor: "",//单独文字阴影颜色
    moreColor: false,   //更多颜色
    ChunkColor: null, //显示颜色
    // BorderStyle: "",//线条宽度
    borderline: null, //边框宽度
    nodeWidth:null,//自定义宽度
    textSize: "",//字体大小
    textStyle: null, //文字样式
    nodeFontAlign: null, //文字对齐方式
    themeStyleIndex: -1,
    layoutType: NodeLayoutType.LAYOUT_LEFT,
    editMindmap: null,
    headerMenuType: null,
    headIconMan: {},
    mindIconsManager: null,
    setAddIconPage: null, // "ADD_STYLES", //跳转菜单栏状态
    nodeContentMessage: null,
    urlDomImg: "", //图片路径
    urlDomVideo:"",//视频图片路径
    showVideoUpload: 0, //视频是否在上传中
    nodeId: "",//当前视频存放的节点id
    tsakSelectedNodeId:"",//存放当前选中的节点
    nodeTypeNumber: "",//节点类型
    encircleLineNumber: "", // 包裹线类型
    encircleLinePaddingNumber: "", //包裹线边距
    baoWeiTextSizeNumber: "", //包裹线标题大小
    // encircleLineTitleColor: '', //包裹线标题颜色
    iconSize: "",//图标大小
    iconPlaceAccouts: null,//图标位置
    imageSize: "", //图片大小
    imagePlace: "",//图片形状
    imageLayout: "",//图片位置
    lineMinorWitdh: null,//分支线条宽度
    lineWitdh: null,//主分支宽度
    lineTypeLayout: null,//线条类型
    showAddRemark: null,//显示备注
    showAddLink: null,//显示链接
    showAddAi: null,//显示链接
    remarkContent: null,//添加备注
    isAssociationModel:null,//关联导图，true显示，false隐藏
    isLaTeXModel:null,//添加公式弹窗，true显示，false隐藏
    linkList: [],
    linkToAdd: null,//添加链接
    linkToRemove: null,
    tiemLineArrow: null, //时间线箭头
    changeLineAccout: false, //线条渐细
    initData: [], //初始化数据
    tipsMessage: null,
    cropperImg: null,//图片面板状态
    cropperImgUrl: null,//切割图片url
    canvanDatas: null,//画布数据
    coverDatas: null,//封面
    saveImageBgcolor: null,//保存图片背景颜色
    hideModel: null,//分享链接模态框状态
    showShopping: false,//购买页面
    showLogin: null,//登录模态框
    hideLogin: null,
    baoWeiLineColor: null,//包围线颜色
    baoWeiFillColor: null,//包围填充颜色
    textShadowColor: null,//文字阴影色
    dottedLineAccout: null,//是否虚线
    showIeHite: '', //是否展示ie提示框。
    showDaGangModels: null,//大纲
    userIfLogin: false, //用户是否登录
    initMindData: null,//初始化导图数据
    activtiyModel: false,//活动模态框
    saveAndEditAccout: "",//保存编辑状态
    finishSavingMap: {
      fulfill:false,//完成保存导图
      result:false,//保存导图结果
    },
    userMessageModel: "",//修改用户数据模态框
    sharePotatoChips: false,//分享风暴模态框
    userModelData: "",//个人中心模态框
    colletionNewFileModel: false,//我的收藏新建文件夹
    colletionLimitFileModel: false,//文件夹权限模态框
    colltionMoveFile: "",//移动文件夹
    isSaveMindPhote: 0, // 是否保存图片
    isRevocationMap: 0, //撤销
    isRecoverMap: 0, //恢复
    isRefreshMyMindList: 0, //刷新我的导图页面
    isAddFolder: 0, //创建文件夹
    isShowFileImportWindow: 0, //展示导图面板
    createdMindCrumbsArr: [{ id: "", name: "我的导图" }], //创建导图的面包屑
    firstFontSize: 1, //一级字体大小
    childrenNodeFontSize: 1, //二级字体大小
    mp3Blob: "", //语音数据
    headerMenu: '', //头部的dom对象
    functionDetail: '', //功能详情
    showHelpModal: 0, //帮助弹框的计数器。
    checkHelp: false, //是否展示帮助弹框
    imgTypeModelAccout: '', // img下载类型模态框
    newVersionMessage: null,
    chartContent: null,//统计图数据
    chartContentToVm: null,//修改的统计图数据
    materialImgObj: null, //用于上传的图片。
    showMateriaModal: false, //是否展示添加图片模态框
    ocrScanModelShow:{
      value:false
    },//是否展示ocr扫描模态框
    showResourceModal:false,//添加附件模态框
    showMateriaVideoModal: false,//是否展示添加视屏模态框
    showMaterialLibraryModulesModel: false, //是否展示添加素材的模态框
    outlineViews: null,//大纲数据
    removeElementView: false,//删除大纲
    alignNodeStyleObj: null,//节点对齐方式
    isShowAlignButton: false,//显示对齐按钮
    isShowSameWidth: false, //是否显示节点等宽
    mindElementTypeLayout: null, //时间线节点布局 
    nodeStyleLayout: null,//节点布局
    lineStyleLayout: null,//线条布局
    correlationLineStyleLayout:null,//关联线
    correlationNodeFullShape:null, //填充样式
    correlationLineStartStyle:null,//关联线起点
    correlationLineEndStyle:null,//关联线终点
    borderStyleLayout: null,//边框样式
    encircleLayoutImg:null,//包裹线标题位置图标
    showEchartModal: null,//echart模态框
    groupMenu: null, //群组的菜单是否显示
    groupCreatedMenu: null, //创建群组的菜单是否显示
    refreshMyGroupList: false, //是否刷新我的
    groupDataDetail: null, //共享组详情。
    showGroupSetting: false, // 是否展示组设置页面
    rootDirectory:null,  //设置页面左边目录名
    groupAdminMenu: false, //管理员菜单显示隐藏
    refreshGroup: false, // 刷新我的群组
    mindmapPlayPauseShowSetup: false,//是否显示导图播放设置面板
    isMindmapPlay:false,//是否要导图播放
    isShowMindmapRightMenu:false,//导图右侧菜单是否显示
    topToolDardarkMode: null,//顶部工具栏深色模式
    mapEditIsDardarkMode: {
      darkMode:false,
      bgColor:'#ffffff',
      fontColor:'#333333',
    },//导图编辑页面是否是深色模式,背景颜色，文字颜色
    windowWidth: null, //编辑界面浏览器窗口宽度
    useShortcut: true, //是否可以使用快捷键,默认true
    createdEditMindmapVMNumber: 0, //记录创建了几次vm
    tiemLineDotted: null, //时间线是否是虚线
    freeNodeMoveAdsorbent: {type : true, count: 0}, //是否允许 自由主题拖动吸附
    branchFreeLayout: {type : false, count: 0}, //是否允许 节点自由拖动布局
    showBranchFreeLayout: {type : false, count: 0}, //节点拖动布局能否使用
    spining: false,
    refreshStorageSpace: false,//刷新存储空间
    showStyleTitleModel:false,//风格提示框是否显示
    createABidirectionalMap:false,//新手引导创建一个双向导图
    setRightLayout:false,//新手引导设置右布局
    setBorderlLineStyle:false,//新手引导设置线条样式
    isShowMindmapExportMenu:false,//导图导出菜单是否显示
    isShowMindmapRightMenuLayout:false,//导图右侧菜单布局弹框是否显示
    isShowBorderlLineStyle:false,//导图右侧菜单线条样式弹框是否显示
    isShowEditTask:false,//导图任务编辑菜单是否显示
    isShowFindTextView:false,//导图内容查找
    nameLaTeX:'',//latex公式参数
    latexNodesId:[],//存有latex公式的节点
    isLatexTurnImgSuccess:false,//公式转图片是否成功
    showSkeleton:false,//显示骨架
    globalLineWidth:1,

  },
  mutations: {
    showLoading(state, data) {
      state.spining = data
    },
    sharePotatoChipsModel(state, data) {
      state.sharePotatoChips = data;
      state.useShortcut = !data.accout
    },
    detailsToUserMessageModel(state, accout) {
      state.userMessageModel = accout
    },
    saveEditAccoutToHeader(state, accout) {
      state.saveAndEditAccout = accout
    },
    setFinishSavingMap(state, val) {
      state.finishSavingMap = val
    },
    activtiyModel(state, activtiyAccout) {
      state.activtiyModel = activtiyAccout
    },
    setUserIfLogin(state, bool) {
      state.userIfLogin = bool;
    },
    ieVersion(state) {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
      var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
      var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
      if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
          state.showIeHite = 7
        } else if (fIEVersion == 8) {
          state.showIeHite = 8
        } else if (fIEVersion == 9) {
          state.showIeHite = 9
        } else if (fIEVersion == 10) {
          state.showIeHite = 10
        } else {
          state.showIeHite = 6
        }
      } else if (isEdge) {
        state.showIeHite = 'edge'
      } else if (isIE11) {
        state.showIeHite = 11
      } else {
        state.showIeHite = -1
      }
    },
    //导图样式
    imgStyleContent(state, index) {
      state.themeStyleIndex = index
    },
    //cookie
    userLogin(state, userData) {
      state.UserData = userData
    },
    //修改密码临时验证
    saveTempToken(state, tempTokenData) {
      state.tempToken = tempTokenData
    },
    //改变颜色
    backGroundColor(state, busColor) {
      state.busColor = busColor
    },
    backLineColor(state, lineColor) {
      // console.log(lineColor);
      state.lineColor = colorChangeClass.ColorToSystem(lineColor)
    },
    backTextcolor(state, textColor) {
      state.textColor = colorChangeClass.ColorToSystem(textColor)
    },
    backBorderColor(state, borderColor) {
      state.borderColor = colorChangeClass.ColorToSystem(borderColor)
    },
    backFillColor(state, fillcolor, alpha) {
      state.fillColor = colorChangeClass.ColorToSystem(fillcolor)
    },
    backBorderMinorStyle(state, borderMinorStyle) {
      state.borderMinorStyle = colorChangeClass.ColorToSystem(borderMinorStyle)
    },
    //成块线条
    backBaoWeiLineColor(state, baoWeiLineColor) {
      state.baoWeiLineColor = colorChangeClass.ColorToSystem(baoWeiLineColor)
    },
    //成块填充
    backBaoWeiFillColor(state, baoWeiFillColor) {
      state.baoWeiFillColor = colorChangeClass.ColorToSystem(baoWeiFillColor)
    },
    //Text阴影颜色
    textShadowColor(state, color) {
      state.textShadowColor = colorChangeClass.ColorToSystem(color)
    },
    //统计图颜色
    backChartFillColor(state, chartFillColor) {
      state.chartFillColor = colorChangeClass.ColorToSystem(chartFillColor)
    },
    //单独字体改变颜色
    backSpanTextColor(state, spanTextColor){
      state.spanTextColor = colorChangeClass.ColorToSystem(spanTextColor)
    },
    //单独字体背景改变颜色
    backSpanTextBgColor(state, spanTextColor){
      state.spanTextBgColor = colorChangeClass.ColorToSystem(spanTextColor)
    },
    //单独字体阴影改变颜色
    backSpanTextShadowColor(state, spanTextColor){
      state.spanTextShadowColor = colorChangeClass.ColorToSystem(spanTextColor)
    },
    //显示更多颜色
    showMoreColor(state, showColorAccount) {
      state.moreColor ? state.moreColor = false : state.moreColor = showColorAccount
    },
    showChunkColor(state, ChunckColorAccount) {
      state.ChunkColor = ChunckColorAccount
    },
    //判断颜色状态
    markColorAccout(state, colorAccoutStyle) {
      state.colorAccout = colorAccoutStyle
    },
    getTextSize(state, TextStyleAccount) {
      state.textSize = TextStyleAccount
    },
    getTextStlye(state, textStyle) {
      state.textStyle = textStyle
    },
    //设置文字对齐方式
    setNodeFontAlign(state, type) {
      //考虑到watch如果传入的数据一样的话时不会触发监听的，所以改为监听对象，对象中存放一个只要触发函数就累加的变量。
      //如果用监听中触发一个函数，将数据设置为默认值的确可以解决问题。但是有多个监听的时候会直接导致后面执行的监听会监听到默认值。
      //如果用延时执行默认值（潘潘以前用的）的话，如果设置的太短有可能还会出现上面的问题。如果设置的太长连续点击的话会出现设置不上样式的问题。
      if (state.nodeFontAlign == null) {
        state.nodeFontAlign = {type : null, count: 0};
      }
      
      state.nodeFontAlign.count++;
      state.nodeFontAlign.type = type;
    },
    //图片位置
    ImgSiteStyle(state, SiteStyle) {
      state.AddImgSiteStyle = SiteStyle
    },
    //图片大小
    setImgFontSize(state, imageSize) {
      state.imageSize = imageSize
    },
    setLayoutType(state, type) {
      state.layoutType = type
    },
    setEditMindmap(state, type) {
      state.editMindmap = type
    },
    setHeaderMenuType(state, type) {
      state.headerMenuType = type
    },
    setHeadIconMan(state, type) {
      state.headIconMan = type
    },
    setMindIconsManager(state, type) {
      state.mindIconsManager = type
    },
    //跳转图标页面
    setAddIconPage(state, accout) {  //跳转到画布、样式、图标菜单。
      if ( state.setAddIconPage == null ) {
        state.setAddIconPage = {
          type : accout,
          count: 0
        }
      } else {
        state.setAddIconPage.type = accout
        state.setAddIconPage.count++;
      }

      // state.setAddIconPage = accout
    },
    //选中节点信息
    setNodeContentMessage(state, message) {
      state.nodeContentMessage = message

    },
    //边框宽度
    setBorderlLine(state, borderline) {
      state.borderline = borderline
    },
    setNodeWidth(state, nodeWidth){
      state.nodeWidth = nodeWidth
    },
    //添加图片
    setDomImg(state, urlDomImg) {
      state.urlDomImg = urlDomImg
    },
    //添加视频or图片
    setDomVideo(state, urlDomVideo) {
      state.urlDomVideo = urlDomVideo
    },
    //视频是否在上传中
    setShowVideoUpload(state, showVideoUpload) {
      state.showVideoUpload = showVideoUpload
    },
    //存放上传视频节点的id
    setShowNodeId(state, nodeId) {
      state.nodeId = nodeId
    },
    getNodeId(state){
      return state.nodeId
    },

    setTsakSelectedNodeId(state, nodeId) {
      state.tsakSelectedNodeId = nodeId
    },
    
    //节点类型
    setNodeType(state, nodeType) {
      state.nodeTypeNumber = nodeType
    },
    //包裹线类型
    setEncircleLineType(state, number) {
      state.encircleLineNumber = number
    },
    setPaddingSize(state, number) {
      state.encircleLinePaddingNumber = number
    },
    setbaoWeiTextSize(state, number) {
      state.baoWeiTextSizeNumber = number
    },
    // setBaoWeiTextColor(state, number) {
    //   state.encircleLineTitleColor = number
    // },
    //图标大小
    setIconSize(state, iconSize) {
      state.iconSize = iconSize
    },
    //图标位置
    iconPlaceAccouts(state, iconPlace) {
      state.iconPlaceAccouts = iconPlace
    },
    imagePlaceAccouts(state, imagePlace) {
      state.imagePlace = imagePlace
    },
    iconLayoutAccout(state, imageLayout) {
      state.imageLayout = imageLayout
    },
    //分支线条宽度
    setLineMinorWitdh(state, lineMinorWitdh) {
      state.lineMinorWitdh = lineMinorWitdh
    },
    //时间线箭头
    changeTimeLineArrowVuex(state,tiemLineArrow) {
      if ( state.tiemLineArrow == null ) {
        state.tiemLineArrow = {
          type : tiemLineArrow,
          count: 0
        }
      } else {
        state.tiemLineArrow.type = tiemLineArrow
        state.tiemLineArrow.count++;
      }
    },
    //时间线是否虚线。
    changeTimeLineDottedVuex(state, tiemLineDotted) {
      if ( state.tiemLineDotted == null ) {
        state.tiemLineDotted = {
          type : tiemLineDotted,
          count: 0
        }
      } else {
        state.tiemLineDotted.type = tiemLineDotted
        state.tiemLineDotted.count++;
      }
    },
    //主线条宽度
    mainLineWitdh(state, LineWitdh) {
      state.lineWitdh = LineWitdh
    },
    createdEditMindmapVM(state) {
      state.createdEditMindmapVMNumber++;
    },
    //线条类型
    getLineTypeLayout(state, linetype) {
      state.lineTypeLayout = linetype
    },
    //显示备注
    setShowAddRemark(state, remarkAccout) {
      state.showAddRemark = remarkAccout;
      state.useShortcut = !remarkAccout;
    },
    setShowAddLink(state, linkAccout) {
      state.showAddLink = linkAccout;
      state.useShortcut = !linkAccout;
    },
    setShowAddAi(state, aiAccout) {
      state.showAddAi = aiAccout;
      state.useShortcut = !aiAccout;
    },
    //添加备注
    setAddRemarkContent(state, remarkContent) {
      state.remarkContent = remarkContent
    },
    //显示关联导图
    setIsAssociationModel(state, isAssociationModel) {
      state.isAssociationModel = isAssociationModel
      state.useShortcut = !isAssociationModel;
    },
    //latex公式弹窗是否显示
    setIsLaTeXModel(state, isLaTeXModel) {
      state.isLaTeXModel = isLaTeXModel
    },
    setRemoveLinkContent(state, linkToRemove) {
      if (typeof linkToRemove == 'string') {
        setTimeout(() => {
          state.linkToRemove = linkToRemove;
        }, 500);
      }
    },
    setAddLinkContent(state, linkToAdd) {
      if (typeof linkToAdd == 'string') {
        state.linkToAdd = linkToAdd;
      } else {
        state.linkList = linkToAdd;
      }
    },
    //线条渐细
    onChangeLineAccout(state, changeLineAccout) {
      state.changeLineAccout = changeLineAccout
    },
    //初始化数据
    getInitData(state, initData) {
      state.initData = initData
    },
    showTipsMessageAccouts(state, tipsMessage) {
      state.tipsMessage = tipsMessage
    },
    //切割图片面板
    showCropperImg(state, cropperImg) {
      state.cropperImg = cropperImg
    },
    //切割图片url
    showCropperImgUrl(state, cropperImgUrl) {
      state.cropperImgUrl = cropperImgUrl
      // console.log(cropperImgUrl);
    },
    //画布数据
    getCanvanDatas(state, canvanDatas) {
      state.canvanDatas = canvanDatas
    },
    getCanvanImageDatas(state, coverDatas) {
      state.coverDatas = coverDatas
    },
    //保存图片背景色
    saveImageBgcolors(state, saveImageBgcolor) {
      state.saveImageBgcolor = saveImageBgcolor
    },
    //分享大纲模态框
    hideModelLink(state, hidemodel) {
      state.hideModel = hidemodel;
      state.useShortcut = !hidemodel.accout
    },
    //购买页面
    isShowShoppingModel(state, showShopping) {
      state.showShopping = showShopping
    },
    //登录显示
    showLoginModel(state, showLogin) {
      state.showLogin = showLogin;
    },
    //是否虚线
    onChangeDottedLineAccout(state, dottedLineAccout) {
      if (state.dottedLineAccout == null) {
        state.dottedLineAccout = {type : null, count: 0};
      }
      // console.log('走入了index',dottedLineAccout);
      state.dottedLineAccout.count++;
      state.dottedLineAccout.type = dottedLineAccout;

      // state.dottedLineAccout = dottedLineAccout
    },
    showDaGangModel(state, showModel) {
      state.showDaGangModels = showModel
    },
    //初始化导图数据
    initMindDataToMenu(state, mindData) {
      state.initMindData = mindData
    },
    showUserDataModel(state, userData) {
      state.userModelData = userData
    },
    //修改文件夹名字or新建
    colletionNewFileModel(state, data) {
      state.colletionNewFileModel = data
    },
    //修改文件夹权限
    updateCollectionFileLimit(state, data) {
      state.colletionLimitFileModel = data
    },
    //移动文件夹
    moveCollectionTemplateToNewFile(state, data) {
      state.colltionMoveFile = data
    },
    //保存导图到图片
    saveMindPhoto(state, data) {
      state.isSaveMindPhote++
    },
    //撤销
    revocationMap(state, data) {
      state.isRevocationMap++
    },
    //恢复
    recoverMap(state, data) {
      state.isRecoverMap++
    },
    //改变创建导图的面包屑
    checkCreatedMindCrumbsArr(state, data) {
      state.createdMindCrumbsArr = data
    },
    refreshMyMindList(state, data) {
      state.isRefreshMyMindList++
    },
    addFolder(state, data) {
      state.isAddFolder++
    },
    showFileImportWindow(state, data) {
      state.isShowFileImportWindow++
    },
    setFirstFontSize(state, data) {
      state.firstFontSize = data
    }, //一级字体大小
    setChildrenNodeFontSize(state, data) {
      state.childrenNodeFontSize = data
    },
    //录音状态
    mp3BlobData(state, data) {
      //如果传空字符串则删除 不为空说明有数据传数据
      if (data == "") {
        state.mp3Blob = 'del'
      } else {
        state.mp3Blob = data
      }
    },
    //录音界面控制符元素
    setHeaderMenu(state, data) {
      state.headerMenu = data
    },
    setFunctionDetail(state, data) {
      state.functionDetail = data
    },
    helpModal(state, data) {
      if (data == true) {
        state.showHelpModal++
        state.checkHelp = true;
      } else {
        state.showHelpModal++
        state.checkHelp = false;
      }
    },
    //下载图片 选择类型 模态框
    imgTypeModelAccout(state, data) {
      state.imgTypeModelAccout = data
      state.useShortcut = !data.accout
      // console.log(data);
    },
    setVersionUpdatingModal(state, data) {
      state.newVersionMessage = data
    },
    //获取统计图数据
    chartContent(state, data) {
      state.chartContent = data
    },
    //修改的统计图数据
    chartContentToVm(state, data) {
      state.chartContentToVm = data
    },
    //使用素材图片
    setMaterialImg(state, data) {
      state.materialImgObj = data
    },
    //设置添加图片模态框的显示与隐藏。
    materialLibraryModel(state, data) {
      state.showMateriaModal = data;
    },
    setOcrScanModel(state, data) {
      //设置ocr扫描模态框
      state.ocrScanModelShow = data;
    },
    //设置添加附件框。
    setResourceModel(state, data) {
      state.showResourceModal = data;
    },
    materiaVideoModal(state, data){
      state.showMateriaVideoModal = data;
    },
    //设置添加素材的模态框的显示与隐藏
    setmaterialLibraryModulesModel(state, data) {
      state.showMaterialLibraryModulesModel = data;
    },
    //大纲数据
    outlineView(state, data) {
      state.outlineViews = data
    },
    //删除大纲
    removeElementViews(state, data) {
      state.removeElementView = data
    },
    //节点对齐方式
    alignNodeStyle(state, data) {
      state.alignNodeStyleObj = data
    },
    //显示对齐按钮
    isShowAlignButton(state, data) {
      state.isShowAlignButton = data
    },
    //显示节点等宽按钮
    isShowSameWidth(state, data) {
      state.isShowSameWidth = data
    },
    //时间线节点布局 
    mindElementTypeLayout(state, data) {
      state.mindElementTypeLayout = data
    },
    //线条布局
    nodeStyleLayout(state, data) {
      state.nodeStyleLayout = data
    },
    //线条布局
    lineStyleLayout(state, data) {
      state.lineStyleLayout = data
    },
    //关联线切换
    correlationLineStyleLayout(state, data) {
      state.correlationLineStyleLayout = data
    },
     //填充样式切换
     correlationNodeFullShape(state, data) {
      state.correlationNodeFullShape = data
    },
    //关联线起点形状
    correlationLineStartStyle(state, data) {
      state.correlationLineStartStyle = data
    },
    //关联线切换
    correlationLineEndStyle(state, data) {
      state.correlationLineEndStyle = data
    },
    setFreeNodeMoveAdsorbent(state, data) {
      if (state.freeNodeMoveAdsorbent == null) {
        state.freeNodeMoveAdsorbent = {type : null, count: 0};
      }
      state.freeNodeMoveAdsorbent.count++;
      state.freeNodeMoveAdsorbent.type = data;
    },
    setGlobalLineWidth(state, data) {      
      state.globalLineWidth = data;
    },
    //自由节点自由拖动布局
    setBranchFreeLayout(state, data){
      if (state.branchFreeLayout == null) {
        state.branchFreeLayout = {type : null, count: 0};
      }
      state.branchFreeLayout.count++;
      state.branchFreeLayout.type = data;
    },
    //
    setShowBranchFreeLayout(state, data){
      if (state.showBranchFreeLayout == null) {
        state.showBranchFreeLayout = {type : null, count: 0};
      }
      state.showBranchFreeLayout.count++;
      state.showBranchFreeLayout.type = data;
    },
    //边框形状
    borderStyleLayout(state, data) {
      state.borderStyleLayout = data
    },
    //边框形状
    setEncircleLayoutImg(state, data) {
      state.encircleLayoutImg = data
    },
    //显示echart添加模态框
    showEchartModal(state, data) {
      state.showEchartModal = data
    },
    //设置显示群组菜单
    setgroupMenu(state, data) {
      state.groupMenu = data
    },
    setgroupCreatedMenu(state, data) {
      state.groupCreatedMenu = data
    },
    setRefreshMyGroupList(state, data) {  //刷新创建的共享组。
      state.refreshMyGroupList = !state.refreshMyGroupList
    },
    setGroupDataDetail(state, data) {    //设置共享组详情信息
      // console.log('data',data)
      //改变组详情数据
      if (data != 'noChangeData') {
        state.groupDataDetail = data
      }
      state.showGroupSetting = !state.showGroupSetting
    },
    setGroupDataDetailParameter(state, data) {    //进入设置共享组详情信息 有参数的情况
      // console.log('data',data)
      //改变组详情数据
      if (data != 'noChangeData') {
        state.groupDataDetail = data.data
        state.rootDirectory = data.rootDirectory;
        state.showGroupSetting = true;
      }
      else{
        state.rootDirectory = null;
        state.showGroupSetting = false;
      }
    },
    changeGroupDataDetail(state, data) {
      state.groupDataDetail = data
    },
    setgroupAdminMenu(state, data) { //管理员菜单
      state.groupAdminMenu = data
    },
    /**
     * @method mindmapPlayPauseShowSetup 显示导图播放设置面板
     * @param {Object} data showSetup 显示面板 playAccout 播放状态
     */
    mindmapPlayPauseShowSetup(state, data) {
      state.mindmapPlayPauseShowSetup = data
    },
    isMindmapPlay(state, data) {
      state.isMindmapPlay = data
    },
    //导图右边菜单是否显示
    mindmapRightMenu(state, data) {
      state.isShowMindmapRightMenu = data
    },
    topToolDardarkMode(state, data) {
      state.topToolDardarkMode = data
    },
    setMapEditIsDardarkMode(state, data) {
      state.mapEditIsDardarkMode = data
    },
    setWindowWidth(state, data) {
      state.windowWidth = data;
    },
    useShortcut(state, data) {
      state.useShortcut = data;
    },
    setUseShortcut(state, data) {
      state.useShortcut = data;
    },
    reloadStorageSpace(state, data) {
      state.refreshStorageSpace = data;
    },
    // 导图导出菜单是否显示
    mindmapExportMenu(state, data) {
      state.isShowMindmapExportMenu = data;
    },
    // 导图右侧菜单布局弹框是否显示
    mindmapRightMenuLayout(state, data) {
      state.isShowMindmapRightMenuLayout = data;
    },
    // 导图右侧菜单线条样式弹框是否显示
    isShowBorderlLineStyle(state, data) {
      state.isShowBorderlLineStyle = data;
    },
    // 导图右侧菜单线条样式弹框是否显示
    setIsShowEditTask(state, data) {
      state.isShowEditTask = data;
    },
    // 导图内容查找
    setIsShowFindTextView(state, data) {
      state.isShowFindTextView = data;
    },
    // 设置隐藏的latex公式计算宽高
    setNameLaTeX(state, data) {
      state.nameLaTeX = data;
    },
    //存放latex公式的节点id
    setLatexNodesId(state, latexNodesId){
      state.latexNodesId = latexNodesId
    },

    setIsLatexTurnImgSuccess(state, data) {
      state.isLatexTurnImgSuccess = data;
    },
    setShowSkeleton(state, data) {
      state.showSkeleton = data;
    },
    
  },
  actions: {
  },
  modules: {
  },
});

<template>
    <div>
        <input type="text" style="width: 100px;border: none;margin:2px;border-radius: 2px;"
            list="country-codes-data" placeholder='+86' v-on:change="onValueChanged" value="+86"/>
        <datalist id="country-codes-data">
            <datalist id="country-codes-data">
                <option value="Afghanistan (‫افغانستان‬‎) af +93"></option>
                <option value="Albania (Shqipëri) al +355"></option>
                <option value="Algeria (‫الجزائر‬‎) dz +213"></option>
                <option value="American Samoa as +1684"></option>
                <option value="Andorra ad +376"></option>
                <option value="Angola ao +244"></option>
                <option value="Anguilla ai +1264"></option>
                <option value="Antigua and Barbuda ag +1268"></option>
                <option value="Argentina ar +54"></option>
                <option value="Armenia (Հայաստան) am +374"></option>
                <option value="Aruba aw +297"></option>
                <option value="Australia au +61"></option>
                <option value="Austria (Österreich) at +43"></option>
                <option value="Azerbaijan (Azərbaycan) az +994"></option>
                <option value="Bahamas bs +1242"></option>
                <option value="Bahrain (‫البحرين‬‎) bh +973"></option>
                <option value="Bangladesh (বাংলাদেশ) bd +880"></option>
                <option value="Barbados bb +1246"></option>
                <option value="Belarus (Беларусь) by +375"></option>
                <option value="Belgium (België) be +32"></option>
                <option value="Belize bz +501"></option>
                <option value="Benin (Bénin) bj +229"></option>
                <option value="Bermuda bm +1441"></option>
                <option value="Bhutan (འབྲུག) bt +975"></option>
                <option value="Bolivia bo +591"></option>
                <option value="Bosnia and Herzegovina (Босна и Херцеговина) ba +387"></option>
                <option value="Botswana bw +267"></option>
                <option value="Brazil (Brasil) br +55"></option>
                <option value="British Indian Ocean Territory io +246"></option>
                <option value="British Virgin Islands vg +1284"></option>
                <option value="Brunei bn +673"></option>
                <option value="Bulgaria (България) bg +359"></option>
                <option value="Burkina Faso bf +226"></option>
                <option value="Burundi (Uburundi) bi +257"></option>
                <option value="Cambodia (កម្ពុជា) kh +855"></option>
                <option value="Cameroon (Cameroun) cm +237"></option>
                <option value="Canada ca +1"></option>
                <option value="Cape Verde (Kabu Verdi) cv +238"></option>
                <option value="Caribbean Netherlands bq +599"></option>
                <option value="Cayman Islands ky +1345"></option>
                <option value="Central African Republic (République centrafricaine) cf +236"></option>
                <option value="Chad (Tchad) td +235"></option>
                <option value="Chile cl +56"></option>
                <option value="China (中国) cn +86"></option>
                <option value="Colombia co +57"></option>
                <option value="Comoros (‫جزر القمر‬‎) km +269"></option>
                <option value="Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo) cd +243"></option>
                <option value="Congo (Republic) (Congo-Brazzaville) cg +242"></option>
                <option value="Cook Islands ck +682"></option>
                <option value="Costa Rica cr +506"></option>
                <option value="Côte d’Ivoire ci +225"></option>
                <option value="Croatia (Hrvatska) hr +385"></option>
                <option value="Cuba cu +53"></option>
                <option value="Curaçao cw +599"></option>
                <option value="Cyprus (Κύπρος) cy +357"></option>
                <option value="Czech Republic (Česká republika) cz +420"></option>
                <option value="Denmark (Danmark) dk +45"></option>
                <option value="Djibouti dj +253"></option>
                <option value="Dominica dm +1767"></option>
                <option value="Dominican Republic (República Dominicana) do +1"></option>
                <option value="Ecuador ec +593"></option>
                <option value="Egypt (‫مصر‬‎) eg +20"></option>
                <option value="El Salvador sv +503"></option>
                <option value="Equatorial Guinea (Guinea Ecuatorial) gq +240"></option>
                <option value="Eritrea er +291"></option>
                <option value="Estonia (Eesti) ee +372"></option>
                <option value="Ethiopia et +251"></option>
                <option value="Falkland Islands (Islas Malvinas) fk +500"></option>
                <option value="Faroe Islands (Føroyar) fo +298"></option>
                <option value="Fiji fj +679"></option>
                <option value="Finland (Suomi) fi +358"></option>
                <option value="France fr +33"></option>
                <option value="French Guiana (Guyane française) gf +594"></option>
                <option value="French Polynesia (Polynésie française) pf +689"></option>
                <option value="Gabon ga +241"></option>
                <option value="Gambia gm +220"></option>
                <option value="Georgia (საქართველო) ge +995"></option>
                <option value="Germany (Deutschland) de +49"></option>
                <option value="Ghana (Gaana) gh +233"></option>
                <option value="Gibraltar gi +350"></option>
                <option value="Greece (Ελλάδα) gr +30"></option>
                <option value="Greenland (Kalaallit Nunaat) gl +299"></option>
                <option value="Grenada gd +1473"></option>
                <option value="Guadeloupe gp +590"></option>
                <option value="Guam gu +1671"></option>
                <option value="Guatemala gt +502"></option>
                <option value="Guinea (Guinée) gn +224"></option>
                <option value="Guinea-Bissau (Guiné Bissau) gw +245"></option>
                <option value="Guyana gy +592"></option>
                <option value="Haiti ht +509"></option>
                <option value="Honduras hn +504"></option>
                <option value="Hong Kong (香港) hk +852"></option>
                <option value="Hungary (Magyarország) hu +36"></option>
                <option value="Iceland (Ísland) is +354"></option>
                <option value="India (भारत) in +91"></option>
                <option value="Indonesia id +62"></option>
                <option value="Iran (‫ایران‬‎) ir +98"></option>
                <option value="Iraq (‫العراق‬‎) iq +964"></option>
                <option value="Ireland ie +353"></option>
                <option value="Israel (‫ישראל‬‎) il +972"></option>
                <option value="Italy (Italia) it +39"></option>
                <option value="Jamaica jm +1876"></option>
                <option value="Japan (日本) jp +81"></option>
                <option value="Jordan (‫الأردن‬‎) jo +962"></option>
                <option value="Kazakhstan (Казахстан) kz +7"></option>
                <option value="Kenya ke +254"></option>
                <option value="Kiribati ki +686"></option>
                <option value="Kuwait (‫الكويت‬‎) kw +965"></option>
                <option value="Kyrgyzstan (Кыргызстан) kg +996"></option>
                <option value="Laos (ລາວ) la +856"></option>
                <option value="Latvia (Latvija) lv +371"></option>
                <option value="Lebanon (‫لبنان‬‎) lb +961"></option>
                <option value="Lesotho ls +266"></option>
                <option value="Liberia lr +231"></option>
                <option value="Libya (‫ليبيا‬‎) ly +218"></option>
                <option value="Liechtenstein li +423"></option>
                <option value="Lithuania (Lietuva) lt +370"></option>
                <option value="Luxembourg lu +352"></option>
                <option value="Macau (澳門) mo +853"></option>
                <option value="Macedonia (FYROM) (Македонија) mk +389"></option>
                <option value="Madagascar (Madagasikara) mg +261"></option>
                <option value="Malawi mw +265"></option>
                <option value="Malaysia my +60"></option>
                <option value="Maldives mv +960"></option>
                <option value="Mali ml +223"></option>
                <option value="Malta mt +356"></option>
                <option value="Marshall Islands mh +692"></option>
                <option value="Martinique mq +596"></option>
                <option value="Mauritania (‫موريتانيا‬‎) mr +222"></option>
                <option value="Mauritius (Moris) mu +230"></option>
                <option value="Mexico (México) mx +52"></option>
                <option value="Micronesia fm +691"></option>
                <option value="Moldova (Republica Moldova) md +373"></option>
                <option value="Monaco mc +377"></option>
                <option value="Mongolia (Монгол) mn +976"></option>
                <option value="Montenegro (Crna Gora) me +382"></option>
                <option value="Montserrat ms +1664"></option>
                <option value="Morocco (‫المغرب‬‎) ma +212"></option>
                <option value="Mozambique (Moçambique) mz +258"></option>
                <option value="Myanmar (Burma) (မြန်မာ) mm +95"></option>
                <option value="Namibia (Namibië) na +264"></option>
                <option value="Nauru nr +674"></option>
                <option value="Nepal (नेपाल) np +977"></option>
                <option value="Netherlands (Nederland) nl +31"></option>
                <option value="New Caledonia (Nouvelle-Calédonie) nc +687"></option>
                <option value="New Zealand nz +64"></option>
                <option value="Nicaragua ni +505"></option>
                <option value="Niger (Nijar) ne +227"></option>
                <option value="Nigeria ng +234"></option>
                <option value="Niue nu +683"></option>
                <option value="Norfolk Island nf +672"></option>
                <option value="North Korea (조선 민주주의 인민 공화국) kp +850"></option>
                <option value="Northern Mariana Islands mp +1670"></option>
                <option value="Norway (Norge) no +47"></option>
                <option value="Oman (‫عُمان‬‎) om +968"></option>
                <option value="Pakistan (‫پاکستان‬‎) pk +92"></option>
                <option value="Palau pw +680"></option>
                <option value="Palestine (‫فلسطين‬‎) ps +970"></option>
                <option value="Panama (Panamá) pa +507"></option>
                <option value="Papua New Guinea pg +675"></option>
                <option value="Paraguay py +595"></option>
                <option value="Peru (Perú) pe +51"></option>
                <option value="Philippines ph +63"></option>
                <option value="Poland (Polska) pl +48"></option>
                <option value="Portugal pt +351"></option>
                <option value="Puerto Rico pr +1"></option>
                <option value="Qatar (‫قطر‬‎) qa +974"></option>
                <option value="Réunion (La Réunion) re +262"></option>
                <option value="Romania (România) ro +40"></option>
                <option value="Russia (Россия) ru +7"></option>
                <option value="Rwanda rw +250"></option>
                <option value="Saint Barthélemy (Saint-Barthélemy) bl +590"></option>
                <option value="Saint Helena sh +290"></option>
                <option value="Saint Kitts and Nevis kn +1869"></option>
                <option value="Saint Lucia lc +1758"></option>
                <option value="Saint Martin (Saint-Martin (partie française)) mf +590"></option>
                <option value="Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon) pm +508"></option>
                <option value="Saint Vincent and the Grenadines vc +1784"></option>
                <option value="Samoa ws +685"></option>
                <option value="San Marino sm +378"></option>
                <option value="São Tomé and Príncipe (São Tomé e Príncipe) st +239"></option>
                <option value="Saudi Arabia (‫المملكة العربية السعودية‬‎) sa +966"></option>
                <option value="Senegal (Sénégal) sn +221"></option>
                <option value="Serbia (Србија) rs +381"></option>
                <option value="Seychelles sc +248"></option>
                <option value="Sierra Leone sl +232"></option>
                <option value="Singapore sg +65"></option>
                <option value="Sint Maarten sx +1721"></option>
                <option value="Slovakia (Slovensko) sk +421"></option>
                <option value="Slovenia (Slovenija) si +386"></option>
                <option value="Solomon Islands sb +677"></option>
                <option value="Somalia (Soomaaliya) so +252"></option>
                <option value="South Africa za +27"></option>
                <option value="South Korea (대한민국) kr +82"></option>
                <option value="South Sudan (‫جنوب السودان‬‎) ss +211"></option>
                <option value="Spain (España) es +34"></option>
                <option value="Sri Lanka (ශ්‍රී ලංකාව) lk +94"></option>
                <option value="Sudan (‫السودان‬‎) sd +249"></option>
                <option value="Suriname sr +597"></option>
                <option value="Swaziland sz +268"></option>
                <option value="Sweden (Sverige) se +46"></option>
                <option value="Switzerland (Schweiz) ch +41"></option>
                <option value="Syria (‫سوريا‬‎) sy +963"></option>
                <option value="Taiwan (台灣) tw +886"></option>
                <option value="Tajikistan tj +992"></option>
                <option value="Tanzania tz +255"></option>
                <option value="Thailand (ไทย) th +66"></option>
                <option value="Timor-Leste tl +670"></option>
                <option value="Togo tg +228"></option>
                <option value="Tokelau tk +690"></option>
                <option value="Tonga to +676"></option>
                <option value="Trinidad and Tobago tt +1868"></option>
                <option value="Tunisia (‫تونس‬‎) tn +216"></option>
                <option value="Turkey (Türkiye) tr +90"></option>
                <option value="Turkmenistan tm +993"></option>
                <option value="Turks and Caicos Islands tc +1649"></option>
                <option value="Tuvalu tv +688"></option>
                <option value="U.S. Virgin Islands vi +1340"></option>
                <option value="Uganda ug +256"></option>
                <option value="Ukraine (Україна) ua +380"></option>
                <option value="United Arab Emirates (‫الإمارات العربية المتحدة‬‎) ae +971"></option>
                <option value="United Kingdom gb +44"></option>
                <option value="United States us +1"></option>
                <option value="Uruguay uy +598"></option>
                <option value="Uzbekistan (Oʻzbekiston) uz +998"></option>
                <option value="Vanuatu vu +678"></option>
                <option value="Vatican City (Città del Vaticano) va +39"></option>
                <option value="Venezuela ve +58"></option>
                <option value="Vietnam (Việt Nam) vn +84"></option>
                <option value="Wallis and Futuna wf +681"></option>
                <option value="Yemen (‫اليمن‬‎) ye +967"></option>
                <option value="Zambia zm +260"></option>
                <option value="Zimbabwe zw +263"></option>
            </datalist>
        </datalist>
    </div>
</template>

<script>
export default {
    props: {
        value: Number,
    },
    model: {
        prop: 'value',
        event: 'countryCodeChange'
    },
    methods: {
        onValueChanged(e) {
            let code = e.currentTarget.value;
            code = code.substring(code.indexOf('+') + 1);
            this.$emit('countryCodeChange', parseInt(code));
        }
    }
}
</script>
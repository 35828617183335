
import Config from "../../../core/core/calcule/Config"
import HashMap from "../../core/base/HashMap";
import LineLayout from "../../../viewmodel/datatype/LineLayout";
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode";
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation";
import IdGenerator from "../../core/base/IdGenerator"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import MindElementType from "../../datatype/MindElementType"
import MindDataLayoutDelegate from "../../defer/MindDataLayoutDelegate"
import MindMapStyleColor from "../base/MindMapStyleColor";
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import BoldData from "../../mindelementdata/mindcontent/BoldData";
import SettingData from "../minddata/SettingData";
import NodeLayoutType from "../../datatype/NodeLayoutType"
import Colors from "../../../utils/Colors";
import LineOrientation from "../../datatype/LineOrientation";
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType";
import OrderVauleManagers from "../tools/OrderVauleManagers";
import OrderContent from "../../mindelementdata/mindcontent/OrderContent";
import OrderType from "../../datatype/OrderType";
import MindElementShapeType from "../../datatype/MindElementShapeType";
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation";
/**
 * MindDataLayoutDelegate
 * Created by tony on 2019/12/26
 */
class BaseLayout {
    constructor() {
        this.isFromLeftRightLayout = false;
        this.UiUtil = new UiUtil();
        this.Util = Util;
        this.delegate = new MindDataLayoutDelegate();
        this.originalRootTreeNode = new LineMindTypeNode(); //主体树状数据结构
        this.rootTreeNode; //主体树状数据结构
        this.lineMindElementDataDict = new HashMap; //主体线字典， Int为线ID data为line
        this.mainMindElementDataDict = new HashMap; //主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.textElementLineMindElementDataDict = new HashMap; //主体节点对应的线，Int为节点ID，data为line
        this.generalizationLineMindElementDataDict = new HashMap; //概要线字典， Int为线ID data为line
        this.generalizationMindElementDataDict = new HashMap; //概要字典， Int为概要ID data为概要节点
        this.explainMindElementDataDict = new HashMap();
        this.rootNodesDict = new HashMap();
        this.encircleMindElementDataDict = new HashMap;
        this.globalLayout = LineLayout.CURVE_LINE;
        this.mindMapStyleColor = new MindMapStyleColor(3);
        this.mindBGColor = -1;
        this.isCalculeMindDataSize = true;
        this.settingData = new SettingData()
        this.dataHeightMap = new HashMap;//
        this.dataTopHeightMap = new HashMap;
        this.dataBottomHeightMap = new HashMap;

        this.sameLevelMaxWidth = new HashMap();
        this.calculeSizeDatas = [];

        this.NodeVerticalSpacee = Config.NodeVerticalSpacee;
        this.SonNodeHorizontalSpacee = Config.SonNodeHorizontalSpacee;
        this.NodeHorizontalSpacee = Config.NodeHorizontalSpacee;
        this.currentLayoutNodeDict = new HashMap(); //概要线字典， Int为线ID data为line
        this.layoutDataPointMap = new HashMap();
        this.mindLayoutSelect = null;
        this.pushCurrentLayoutNode(this.rootTreeNode);
    }

    clearDatas() {
        this.currentLayoutNodeDict.clear();
        this.rootTreeNode = null;
        this.lineMindElementDataDict = new HashMap;
        this.mainMindElementDataDict = new HashMap;
        this.textElementLineMindElementDataDict = new HashMap;
        this.generalizationLineMindElementDataDict = new HashMap;
        this.generalizationMindElementDataDict = new HashMap;
        this.explainMindElementDataDict = new HashMap;
        this.mindMapStyleColor = null;
        this.dataHeightMap.clear();
        this.dataTopHeightMap.clear();
        this.dataBottomHeightMap.clear();
        this.layoutDataPointMap = new HashMap();
        this.sameLevelMaxWidth = new HashMap();
        this.calculeSizeDatas = [];
        this.delegate = null;
    }

    setDatas(rootTreeNode,
        lineMindElementDataDict,
        mainMindElementDataDict,
        textElementLineMindElementDataDict,
        generalizationLineMindElementDataDict,
        generalizationMindElementDataDict,
        explainMindElementDataDict,
        mindMapStyleColor,
        mindBGColor,
        settingData) {
        this.rootTreeNode = rootTreeNode;
        this.lineMindElementDataDict = lineMindElementDataDict;
        this.mainMindElementDataDict = mainMindElementDataDict;
        this.textElementLineMindElementDataDict = textElementLineMindElementDataDict;
        this.generalizationLineMindElementDataDict = generalizationLineMindElementDataDict;
        this.generalizationMindElementDataDict = generalizationMindElementDataDict;
        this.explainMindElementDataDict = explainMindElementDataDict;
        this.mindMapStyleColor = mindMapStyleColor;
        this.mindBGColor = mindBGColor;
        this.settingData = settingData;
        this.dataHeightMap.clear();
        this.dataTopHeightMap.clear();
        this.dataBottomHeightMap.clear();
        this.layoutDataPointMap.clear();
        this.rootNodesDict.clear();
        this.sameLevelMaxWidth = new HashMap();
        this.calculeSizeDatas = [];
        this.addNodeToRootMap(rootTreeNode);
        this.pushCurrentLayoutNode(this.rootTreeNode);
    }

    addNodeToRootMap( node) {
        if (node == null || node.isEmpty()) {
            return;
        }
        this.rootNodesDict.put(node.value.id, node);
        let count = node.children.length
        for(let index = 0; index < count; index++){
            let child = node.children[index]
            this.addNodeToRootMap(child);
        }
    }

    pushCurrentLayoutNode(node) {
        if (node == null || node.isEmpty() || this.currentLayoutNodeDict == null) {
            return;
        }
        this.currentLayoutNodeDict.put(node.value.id, node);
    }

    isIncludeNodeInCurrentLayout(node) {
        return this.currentLayoutNodeDict != null && node != null && !node.isEmpty() && this.currentLayoutNodeDict.containsKey(node.value.id);
    }

    isIncludeGeneralizationInCurrentLayout(generalization) {
        if (generalization == null || generalization.isEmpty() ||
            generalization.value.type != MindElementType.CONTENT_GENERALIZATION ||
            generalization.value.generalizationContent == null ||
            generalization.value.generalizationContent.targetIds.isEmpty()) {
            return false;
        }
        let targetSCount = generalization.value.generalizationContent.targetIds.length
        for (let index = 0; index < targetSCount; index++) {
            let id = generalization.value.generalizationContent.targetIds[index]
            if (this.currentLayoutNodeDict.containsKey(id)) {
                return true;
            }
        }
        return false;
    }

    getGeneralizationLineWidth(targetHeight) {
        let generalizationLineWidth = Config.GeneralizationLineWidth;
        if (targetHeight > 700) {
            generalizationLineWidth = generalizationLineWidth * 3.2
        } else if (targetHeight > 600) {
            generalizationLineWidth = generalizationLineWidth * 2.8
        } else if (targetHeight > 500) {
            generalizationLineWidth = generalizationLineWidth * 2.4
        } else if (targetHeight > 400) {
            generalizationLineWidth = generalizationLineWidth * 2.0
        } else if (targetHeight > 300) {
            generalizationLineWidth = generalizationLineWidth * 1.9
        } else if (targetHeight > 240) {
            generalizationLineWidth = generalizationLineWidth * 1.6
        } else if (targetHeight > 200) {
            generalizationLineWidth = generalizationLineWidth * 1.3
        }
        return generalizationLineWidth;
    }

    getGeneralizationMindElementDatas() {
        let list = new Array();
        let addToListMap = new HashMap();
        let keys = this.generalizationMindElementDataDict.keys()
        let count = keys.length;
        for(let index = 0; index < count; index++) {
            let id = keys[index];
            let node = this.generalizationMindElementDataDict.get(id);
            if (node.isEmpty() ||
                    node.value.generalizationContent == null) {
                continue;
            }
            if (node.value.generalizationContent.targetIds.isEmpty()) {
                list.push(node);
                addToListMap.put(node.value.id, node);
                continue;
            }
            let targetNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
            if (targetNode.isEmpty()) {
                continue;
            }
            if (this.rootNodesDict.containsKey(targetNode.value.id)) {
                list.push(node);
                addToListMap.put(node.value.id, node);
            }
        }
        for(let index = 0; index < count; index++){
                let id = keys[index];
                let node = this.generalizationMindElementDataDict.get(id);
            if (node.isEmpty() ||
                    node.value.generalizationContent == null) {
                continue;
            }
            if (node.value.generalizationContent.targetIds.isEmpty()) {
                continue;
            }
            let targetNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
            if (targetNode.isEmpty()) {
                continue;
            }
            if (!addToListMap.containsKey(node.value.id)) {
                list.push(node);
                addToListMap.put(node.value.id, node);
            }
        }
        let that = this;
        list.sort(function (node1, node2) {
            if (node1.value.generalizationContent.targetIds.length == 0 ||
                node2.value.generalizationContent.targetIds.length == 0) {
                return 0;
            }
            let target1 = that.getNodeById(node1.value.generalizationContent.targetIds[0])
            let target2 = that.getNodeById(node2.value.generalizationContent.targetIds[0])
            if (target1.isEmpty() || target2.isEmpty()) {
                return 0;
            }
            if (target1.value.level == target2.value.level) {
                return 0;
            } else if (target1.value.level < target2.value.level) {
                return 1;
            } else {
                return -1;
            }
        })

        return list;
    }

    initConfig() {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            return;
        }
        this.dataHeightMap.clear();
        this.dataTopHeightMap.clear();
        this.dataBottomHeightMap.clear();
        this.currentLayoutNodeDict.clear();
        this.layoutDataPointMap.clear();
        this.sameLevelMaxWidth = new HashMap();
        this.calculeSizeDatas = [];
        if (!this.rootTreeNode.children.isEmpty()) {
            let line = this.getLineData(this.rootTreeNode.children[0]);
            if (!line.isEmpty() && line.lineContent != null) {
                this.globalLayout = line.lineContent.lineLayout;
            }
        }
    }

    layout(isChange) {
        this.initConfig();
        if (!this.isFromLeftRightLayout) {
            this.clearDataOrderSizeAndPoint();
            this.calculeSizeAndPoint();
            this.calculeDataOrderSizeAndPoint();
        }
        
        this.setEncircleSizePoint();// 计算包裹线
        this.onLayout(isChange);
        this.callbackResetDatas();
        this.dataHeightMap.clear();
        this.dataTopHeightMap.clear();
        this.dataBottomHeightMap.clear();
    }

    onLayout(isChange) { };

    clearDataOrderSizeAndPoint() {
        if (!this.isCalculeMindDataSize) {
            return;
        }
        if (this.rootTreeNode != null) {
            this.clearDataOrder(this.rootTreeNode);
        }
        
        let generalizationMindElementDataDict = this.generalizationMindElementDataDict.keys();
        let generalizationCount = generalizationMindElementDataDict.length;
        for (let index = 0; index < generalizationCount; index++) {
            this.clearDataOrder(this.generalizationMindElementDataDict.get(generalizationMindElementDataDict[index]));
        }
    }

    clearDataOrder(node) {
        if (node == null || node.isEmpty() ) {
            return
        }
        if (node.value.orderContent != null) {
            if (node.value.orderContent.parentNodeOrderLevel == -1 || 
                node.value.orderContent.parentNodeOrderLevel != node.value.level) {
                node.value.orderContent = null
            } else {
                node.value.orderContent.order = "";
            }
        }
        if (node.isChildrenEmpty()) {
            return;
        }
        for (var index = 0; index < node.children.length; index++) {
            this.clearDataOrder(node.children[index]);
        }
    }

    calculeDataOrderSizeAndPoint() {
        if (!this.isCalculeMindDataSize) {
            return;
        }
        if (this.rootTreeNode != null) {
            this.setDataOrder(this.rootTreeNode);
        }
        
        let generalizationMindElementDataDict = this.generalizationMindElementDataDict.keys();
        let generalizationCount = generalizationMindElementDataDict.length;
        for (let index = 0; index < generalizationCount; index++) {
            this.setDataOrder(this.generalizationMindElementDataDict.get(generalizationMindElementDataDict[index]));
        }
    }

    setDataOrder(node) {
        if (node == null || node.isEmpty()) {
            return
        }

        if (node.value.orderContent == null || node.value.orderContent.parentNodeOrderLevel == -1 || 
            node.value.orderContent.parentNodeOrderLevel != node.value.level ||
            node.children.length == 0) {
            if (node.isChildrenEmpty()) {
                return;
            }
            for (var index = 0; index < node.children.length; index++) {
                this.setDataOrder(node.children[index]);
            }
            return
        }
        for (let index = 0; index < node.children.length; index++) {
            const cell = node.children[index];
            
            if (cell.value.textContent == null) {
                continue
            }
            if (cell.value.orderContent != null && cell.value.orderContent.order != null && cell.value.orderContent.order.length > 0) {
                continue
            }
            if (cell.value.orderContent == null) {
                cell.value.orderContent = node.value.orderContent.copy();
            }
            
            let orderStr = OrderVauleManagers.get().getOrderValue(cell.value, index, node.value.orderContent.orderType)
            if (orderStr == null || orderStr.length == 0) {
                continue
            }
            let contentLeft = cell.value.getPadingLeft()
            let size = MindElementCalculation.publicCaluleText(orderStr, 
                                                                cell.value.textContent.textFontSize, 
                                                                cell.value.textContent.textBold,
                                                                cell.value.textContent.textItalics,
                                                                cell.value.textContent.fontFamily);
                                                                
            
            let width = Math.ceil(size.width) + 4                                        
            MindElementCalculation.set(cell.value).setDataContentMoveLeft(width, false, false);
            if (cell.value.isCardContent()) {
                cell.value.titleContent.x = cell.value.titleContent.x + width/2
            }
            cell.value.orderContent.order = orderStr
            cell.value.orderContent.width = width
            cell.value.orderContent.height = size.getHeight()
            cell.value.orderContent.x = contentLeft
            let top = cell.value.getPadingTop(true)
            let bottom = cell.value.getPadingBottom()
            cell.value.orderContent.y = top + cell.value.getTextEdgeInsets().top + (bottom - top - size.getHeight()) / 2
            
            if (node.value.orderContent.orderDepth > 1 && cell.children.length > 0 &&
                node.value.orderContent.parentNodeOrderLevel == cell.value.orderContent.parentNodeOrderLevel) {
                for (var index1 = 0; index1 < cell.children.length; index1++) {
                    let cell1 = cell.children[index1]
                    let orderStr1 = this.setChildOrder(cell1, orderStr, index1, node)

                    if (node.value.orderContent.orderDepth > 2 && cell1.children.length > 0 &&
                        cell.value.orderContent.parentNodeOrderLevel == cell1.value.orderContent.parentNodeOrderLevel) {
                        for (var index2 = 0; index2 < cell1.children.length; index2++) {
                            let cell2 = cell1.children[index2]
                            let orderStrc2 = this.setChildOrder(cell2, orderStr1, index2, node)

                            if (node.value.orderContent.orderDepth > 3 && cell2.children.length > 0 &&
                                cell1.value.orderContent.parentNodeOrderLevel == cell2.value.orderContent.parentNodeOrderLevel) {
                                for (var index3 = 0; index3 < cell2.children.length; index3++) {
                                    let cell3 = cell2.children[index3]
                                    let orderStrc = this.setChildOrder(cell3, orderStrc2, index3, node)
                                }
                            }
                        }
                    }
                }
            }
        }

        if (node.isChildrenEmpty()) {
            return;
        }
        for (var index = 0; index < node.children.length; index++) {
            this.setDataOrder(node.children[index]);
        }
    }

    setChildOrder(child, startOrder, order, node) {
        if (child.value.textContent == null) {
            return "";
        }
        child.value.orderContent = node.value.orderContent.copy();
        let childOrder = OrderVauleManagers.get().getOrder1String(order)
        let orderStr = startOrder.length > 0 ? startOrder + " " + childOrder : startOrder + childOrder
        let contentLeft = child.value.getPadingLeft()
        let size = MindElementCalculation.publicCaluleText(orderStr, 
                                                    child.value.textContent.textFontSize, 
                                                    child.value.textContent.textBold,
                                                    child.value.textContent.textItalics,
                                                    child.value.textContent.fontFamily);
        let width = Math.ceil(size.width) + 4  
        MindElementCalculation.set(child.value).setDataContentMoveLeft(width, false, false);
        if (child.value.isCardContent()) {
            child.value.titleContent.x = child.value.titleContent.x + width/2
        }
        child.value.orderContent.order = orderStr
        child.value.orderContent.width = width
        child.value.orderContent.height = size.getHeight()
        child.value.orderContent.x = contentLeft
        let top = child.value.getPadingTop(true)
        let bottom = child.value.getPadingBottom()
        child.value.orderContent.y = top + child.value.getTextEdgeInsets().top + (bottom - top - size.getHeight()) / 2

        return orderStr
    }

    calculeSizeAndPoint() {
        if (!this.isCalculeMindDataSize) {
            return;
        }
        this.sameLevelMaxWidth = new HashMap();
        this.calculeSizeDatas = [];

        if (this.rootTreeNode != null) {
            this.calculeNodeSizeAndPoint(this.rootTreeNode);
            this.calculeNodeAlignmentChildIgnoreSize(this.rootTreeNode);
        }
        if (this.settingData.sameLevelAlignment) {
            for (let index = 0; index < this.calculeSizeDatas.length; index++) {
                const data = this.calculeSizeDatas[index];
                if (!this.sameLevelMaxWidth.containsKey(data.level)) {
                    continue;
                }
                const maxWidth = this.sameLevelMaxWidth.get(data.level)
                if (data.width < maxWidth) {
                    MindElementCalculation.set(data).setCustomSize(maxWidth);
                }
            }
        } else {

        }
        this.sameLevelMaxWidth = new HashMap();
        this.calculeSizeDatas = [];
        let generalizationMindElementDataDict = this.generalizationMindElementDataDict.keys();
        let generalizationCount = generalizationMindElementDataDict.length;
        for (let index = 0; index < generalizationCount; index++) {
            this.calculeNodeSizeAndPoint(this.generalizationMindElementDataDict.get(generalizationMindElementDataDict[index]));
        }
        if (this.settingData.sameLevelAlignment) {
            for (let index = 0; index < this.calculeSizeDatas.length; index++) {
                const data = this.calculeSizeDatas[index];
                if (data.type == MindElementType.CONTENT_GENERALIZATION || !this.sameLevelMaxWidth.containsKey(data.level)) {
                    continue;
                }
                const maxWidth = this.sameLevelMaxWidth.get(data.level)
                if (data.width < maxWidth) {
                    MindElementCalculation.set(data).setCustomSize(maxWidth);
                }
            }
        }
        if (this.explainMindElementDataDict != null && this.explainMindElementDataDict.values != null) {
            let explainMindElementDataDictValues = this.explainMindElementDataDict.values();
            let explainMindElementDataDictValuesCount = explainMindElementDataDictValues.length;
            for (let index = 0; index < explainMindElementDataDictValuesCount; index++) {
                MindElementCalculation.set(explainMindElementDataDictValues[index]).calcule();
            }
        }
    }

    calculeNodeSizeAndPoint(node) {
        if (!this.isCalculeMindDataSize || node == null || node.isEmpty()) {
            return;
        }
        node.value.isFreeTreeNode = false
        MindElementCalculation.set(node.value).calcule();

        if (this.settingData.sameLevelAlignment) {
            if (this.sameLevelMaxWidth.containsKey(node.value.level)) {
                if (this.sameLevelMaxWidth.get(node.value.level) < node.value.width) {
                    this.sameLevelMaxWidth.put(node.value.level, node.value.width);
                }
            } else {
                this.sameLevelMaxWidth.put(node.value.level, node.value.width);
            }
            this.calculeSizeDatas.push(node.value);
        }
        if (node.isChildrenEmpty()) {
            return;
        }
        for (var index = 0; index < node.children.length; index++) {
            this.calculeNodeSizeAndPoint(node.children[index]);
        }
    }

    calculeNodeAlignmentChildIgnoreSize(node) {
        if (node == null || node.isEmpty() || 
            node.children.length == 0 || node.children[0].value.isHidden) {
            return
        }
        let maxWidth = 0;
        let minWidth = 0;
        for (var index = 0; index < node.children.length; index++) {
            if (index == 0) {
                maxWidth = node.children[index].value.width;
                minWidth = maxWidth;
                continue
            }
            if (maxWidth < node.children[index].value.width) {
                maxWidth = node.children[index].value.width;
            }
            if (minWidth > node.children[index].value.width) {
                minWidth = node.children[index].value.width;
            }
        }
        let ignoreSize = node.value.type == MindElementType.MAIN_SUBJECT ? 50 : 36;
        if (maxWidth != minWidth && Math.abs(maxWidth - minWidth) < ignoreSize) {
            for (var index = 0; index < node.children.length; index++) {
                let data = node.children[index].value;
                if (maxWidth > data.width) {
                    MindElementCalculation.set(data).setCustomSize(maxWidth)
                }
            }
        }
        if (node.isChildrenEmpty()) {
            return;
        }
        for (var index = 0; index < node.children.length; index++) {
            this.calculeNodeAlignmentChildIgnoreSize(node.children[index]);
        }
    }

    getNodeById(id) {
        if (this.mainMindElementDataDict.containsKey(id)) {
            return this.mainMindElementDataDict.get(id);
        } else if (this.generalizationMindElementDataDict.containsKey(id)) {
            return this.generalizationMindElementDataDict.get(id);
        } else if (this.explainMindElementDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.explainMindElementDataDict.get(id));
        } else if (this.encircleMindElementDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.encircleMindElementDataDict.get(id));
        }
        return new LineMindTypeNode();
    }

    getSiblingsMindElementDataById(id) {
        let data = this.getNodeById(id).value;
        let node = this.getNodeById(data.parentNodeId);
        if (data.id < 0) {
            return new Array();
        }
        let elementDatas = new Array();
        node.children.forEach(item => {
            elementDatas.push(item.value);
        });
        return elementDatas;
    }

    getBrotherNodeLine(node) {
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (parentNode.isEmpty()) {
            return new MindElementData();
        }
        let childCount = parentNode.children.length;
        for (let index = 0; index < childCount; index++) {
            let child = parentNode.children[index]
            if (child.value.id == node.value.id) {
                continue;
            }
            // let line = this.getNodeLine(child.value.id);
            let line = this.getLineData(child);
            if (line.isEmpty() || line.lineContent == null) {
                continue;
            }
            return line;
        }
        return new MindElementData();
    }

    getNodeLineLayout( node,  lineData) {
        let lineLayout = LineLayout.RIGHT_ANGLE_LINE;
        if (lineData.isEmpty()) {
            let referenceLine = this.getReferenceLineInBrother(node.value.id);
            if (referenceLine.isEmpty() || referenceLine.lineContent == null) {
                if (node.value.type == MindElementType.SON_SUBJECT) {
                    let parentNode = this.getNodeById(node.value.parentNodeId);
                    if (parentNode.isEmpty() || this.isRootNode(parentNode)) {
                        lineLayout = this.globalLayout;
                    } else {
                        if (parentNode.value.isCardContent() && this.globalLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE) {
                            lineLayout = LineLayout.CURVE_LINE;
                        } else if (this.globalLayout == LineLayout.CURVE_LINE) {
                            lineLayout = LineLayout.CURVE_LINE;
                        } else {
                            referenceLine = this.getReferenceLine(node.value.id);
                            if (referenceLine.isEmpty() || referenceLine.lineContent == null) {
                                lineLayout = this.globalLayout;
                            } else {
                                lineLayout = referenceLine.lineContent.lineLayout;
                            }
                        }
                    }
                } else {
                    referenceLine = this.getReferenceLine(node.value.id);
                    if (referenceLine.isEmpty() || referenceLine.lineContent == null) {
                        lineLayout = this.globalLayout;
                    } else {
                        lineLayout = referenceLine.lineContent.lineLayout;
                    }
                }

            } else {
                lineLayout = referenceLine.lineContent.lineLayout;
            }
        } else if (lineData.lineContent != null) {
            lineLayout = lineData.lineContent.lineLayout;
        }
        let brotherNodeLine = this.getBrotherNodeLine(node);
        if (!brotherNodeLine.isEmpty() && brotherNodeLine.lineContent != null &&
                brotherNodeLine.lineContent.lineLayout != lineLayout) {
            if (lineData.lineContent != null) {
                lineData.lineContent.lineLayout = brotherNodeLine.lineContent.lineLayout;
            }
            lineLayout = brotherNodeLine.lineContent.lineLayout;
        }
        return lineLayout;
    }

    getSiblingsNodesHeight(nodes) {
        if (nodes.length == 0) {
            return 0;
        }
        var height = this.getTreeHeight(this.getNodeById(nodes[0].id));
        if (nodes.length > 1) {
            for (var index = 1; index < nodes.length; index++) {
                let data = nodes[index];
                if (data.isHidden) {
                    continue
                }
                height += this.getTreeHeight(this.getNodeById(data.id)) + (data.type == MindElementType.SON_SUBJECT ? Config.SonNodeVerticalSpacee : Config.NodeVerticalSpacee);
            }
        }
        return height;
    }
    getSiblingsNodesWidth(nodes) {
        if (nodes.length == 0) {
            return 0;
        }

        let width = this.getTreeWidth(this.getNodeById(nodes[0].id));
        if (nodes.length > 1) {
            for (var index = 1; index < nodes.length; index++) {
                let data = nodes[index];
                if (data.isHidden) {
                    continue
                }
                width += this.getTreeWidth(this.getNodeById(data.id)) + (data.type == MindElementType.SON_SUBJECT ? Config.BottomLayoutSonNodeHorizontalSpacee : Config.BottomLayoutNodeHorizontalSpacee);
            }
        }
        return width;
    }

    getNodeInNodesPosition(nodes, node) {
        if (nodes.length == 0) {
            return 0;
        }
        for (var index = 0; index < nodes.length; index++) {
            if (node.id == nodes[index].id) {
                return index;
            }
        }
        return IdGenerator.INVALID_ID;
    }

    getNodeInParentPosition(node) {
        if (node.isEmpty()) {
            return 0;
        }
        let parentNode = this.getNodeById(node.value.parentNodeId)
        if (parentNode.isEmpty()) {
            return 0
        }
        for (var index = 0; index < parentNode.children.length; index++) {
            if (node.value.id == parentNode.children[index].value.id) {
                return index;
            }
        }
        return 0;
    }

    getTreeHeight(node) {
        if ((node.value.id == IdGenerator.INVALID_ID) || node.value.isHidden) {
            return 0;
        }
        let height = node.value.height;
        let chlidHeight = 0;
        let nodeGeneralizationHeight = this.getNodeGeneralizationHeight(node);
        height = height < nodeGeneralizationHeight ? nodeGeneralizationHeight : height;
        let length = node.children.length
        for (let index = 0; index < length; index++) {
            let chlid = node.children[index]
            let verticalSpacee = chlid.value.type == MindElementType.SON_SUBJECT ? Config.SonNodeVerticalSpacee : Config.NodeVerticalSpacee;
            if (!chlid.children.length == 0) {
                chlidHeight += this.getTreeHeight(chlid) + (chlidHeight > 0 ? verticalSpacee : 0);
            } else {
                if (chlid.value.isHidden) {
                    continue
                }
                let nodeHeight = chlid.value.height;
                let chlidGeneralizationHeight = this.getNodeGeneralizationHeight(chlid);
                nodeHeight = nodeHeight < chlidGeneralizationHeight ? chlidGeneralizationHeight : nodeHeight;
                chlidHeight += nodeHeight + (chlidHeight > 0 ? verticalSpacee : 0);
            }
        }
        return chlidHeight > height ? chlidHeight : height;
    }

    getTreeWidth(node) {
        return this.getTreeWidthByTaken(node, true);
    }

    getTreeWidthByTaken(node, isContainSelfGeneralization) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        let width = node.value.width;
        let chlidWidth = 0;
        if (isContainSelfGeneralization) {
            let nodeGeneralizationWidth = this.getNodeGeneralizationWidth(node);
            width = width < nodeGeneralizationWidth ? nodeGeneralizationWidth : width;
        }
        for (let index = 0; index < node.children.length; index++) {
            let verticalSpacee = node.children[index].value.type == MindElementType.SON_SUBJECT ? Config.BottomLayoutSonNodeHorizontalSpacee : Config.BottomLayoutNodeHorizontalSpacee;
            if (!node.children[index].children.length == 0) {
                chlidWidth += this.getTreeWidth(node.children[index]) + (chlidWidth > 0 ? verticalSpacee : 0);
            } else {
                if (node.children[index].value.isHidden) {
                    continue
                }
                let nodeidth = node.children[index].value.width;
                let chlidGeneralizationWidth = this.getNodeGeneralizationWidth(node.children[index]);
                nodeidth = nodeidth < chlidGeneralizationWidth ? chlidGeneralizationWidth : nodeidth;
                chlidWidth += nodeidth + (chlidWidth > 0 ? verticalSpacee : 0);
            }
        }
        return chlidWidth > width ? chlidWidth : width;
    }

    getNodeGeneralizationWidth(node) { //获取节点node的摘要宽度
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        let keys = this.generalizationMindElementDataDict.keys()
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let item = this.generalizationMindElementDataDict.get(keys[index]);
            if (item.value.isHidden || item.value.generalizationContent.targetIds.isEmpty()) {
                continue
            }
            if (item.value.generalizationContent.targetIds.indexOf(node.value.id) > -1) {
                return this.getTreeWidth(item);
            }
        }
        return 0;
    }

    getNodeGeneralizationLineWidth(node) { //获取节点node的摘要目标高度
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        let keys = this.generalizationMindElementDataDict.keys()
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let item = this.generalizationMindElementDataDict.get(keys[index]);
            if (item.value.isHidden || item.value.generalizationContent.targetIds.isEmpty()) {
                continue
            }
            let height = 0
            if (item.value.generalizationContent.targetIds.indexOf(node.value.id) > -1) {
                for (let j = 0; j < item.value.generalizationContent.targetIds.length; j++) {
                    let targetNode = this.getNodeById(item.value.generalizationContent.targetIds[j])
                    if (targetNode.isEmpty()) {
                        continue
                    }
                    height += this.getTreeHeight(targetNode);
                }
            }
            if (height > 0) {
                return this.getGeneralizationLineWidth(height)
            }
        }
        return 0;
    }

    getNodeGeneralizationHeight(node) { //获取节点node的摘要高度
        if (node.value.id == IdGenerator.INVALID_ID) {
            return 0;
        }
        let keys = this.generalizationMindElementDataDict.keys()
        for (let index = 0; index < keys.length; index++) {
            let item = this.generalizationMindElementDataDict.get(keys[index]);
            if (item.value.generalizationContent.targetIds.indexOf(node.value.id) > -1) {
                return this.getTreeHeight(item);
            }
        }
        return 0;
    }

    getGeneralizationTargetHeight( generalization) {
        if (this.rootTreeNode == null || generalization.isEmpty() ||
                generalization.value.type != MindElementType.CONTENT_GENERALIZATION ||
                generalization.value.generalizationContent == null) {
            return 0;
        }

        let layout = this.getNodeLayout1(this.getLayoutType(), this.rootTreeNode, false);
        if (generalization.value.generalizationContent.targetIds.isEmpty()) {
            let node = this.getNodeById(generalization.value.parentNodeId);
            if (node.isEmpty()) {
                return 0;
            }
            return layout.getTreeHeight(node);
        }

        let list = new Array();
        let idCount = generalization.value.generalizationContent.targetIds.length;
        for (var index = 0; index < idCount; index++) {
            let id = generalization.value.generalizationContent.targetIds[index];
            let targetNode = this.getNodeById(id);
            if (!targetNode.isEmpty()) {
                list.push(targetNode.value);
            }
        }
        return layout.getSiblingsNodesHeight(list);

    }

    getGeneralizationsHeight(nodes) {
        if (nodes.length == 0) {
            return 0;
        }
        let height = 0;
        let length = nodes.length
        for (let index = 0; index < length; index++) {
            let item = nodes[index];
            if (item.value.isHidden) {
                continue
            }
            if (item.value.generalizationContent != null) {
                item.value.generalizationContent.targetIds.forEach(id => {
                    this.getTreeHeight(this.getNodeById(id));
                });
            }
            height = height + item.value.height + Config.GeneralizationLineWidth * 2 + Config.GeneralizationSpacing;
        }
        return height;
    }

    getNodeGeneralization(node) { //获取节点node的摘要高度
        if (node.value.id == IdGenerator.INVALID_ID) {
            return new LineMindTypeNode();
        }
        let keys = this.generalizationMindElementDataDict.keys()
        for (let index = 0; index < keys.length; index++) {
            let item = this.generalizationMindElementDataDict.get(keys[index]);
            if (item.value.generalizationContent.targetIds.indexOf(node.value.id) > -1) {
                return item;
            }
        }
        return new LineMindTypeNode();
    }

    getNodeExplain(node) { //获取节点node的解释
        if (node.value.id == IdGenerator.INVALID_ID) {
            return new MindElementData()
        }
        let explainMindElementDataDictKeys = this.explainMindElementDataDict.keys();
        let explainMindElementDataDictKeysCount = explainMindElementDataDictKeys.length;
        for (let index = 0; index < explainMindElementDataDictKeysCount; index++) {
            let key = explainMindElementDataDictKeys[index];
            let item = this.explainMindElementDataDict.get(key)
            if (item.parentNodeId == node.value.id) {
                return item;
            }
        }
        return new MindElementData();
    }

    getLineData(node) {
        if (!node.isEmpty() && this.textElementLineMindElementDataDict.containsKey(node.value.id)) {
            let line = this.textElementLineMindElementDataDict.get(node.value.id);
            if (line.type == MindElementType.LINE ||
                line.type == MindElementType.SON_LINE) {
                return line
            }
        }
        let lineKeys = this.lineMindElementDataDict.keys();
        let length = lineKeys.length;
        for (let index = 0; index < length; index++) {
            let key = lineKeys[index]
            let data = this.lineMindElementDataDict.get(key);
            if (data.lineContent != null && data.parentNodeId == node.value.parentNodeId &&
                data.lineContent.targetId == node.value.id) {
                return data;
            }
        }
        return new MindElementData();
    };

    getCustomLineData(node) {
        if (!node.isEmpty() && this.textElementLineMindElementDataDict.containsKey(node.value.id)) {
            let line = this.textElementLineMindElementDataDict.get(node.value.id);
            if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                return line
            }
        }
        let lineKeys = this.lineMindElementDataDict.keys();
        let length = lineKeys.length;
        for (let index = 0; index < length; index++) {
            let key = lineKeys[index]
            let data = this.lineMindElementDataDict.get(key);
            if (data.type == MindElementType.LAYOUT_CUSTOM_LINE &&
                data.parentNodeId == node.value.parentNodeId &&
                data.lineContent.targetId == node.value.id) {
                return data;
            }
        }
        return new MindElementData();
    };

    callbackResetDatas() {
        if (this.delegate.callbackLeftAndRightLayoutResetDatas != null) {
            this.delegate.callbackLeftAndRightLayoutResetDatas(
                this.rootTreeNode,
                this.lineMindElementDataDict,
                this.mainMindElementDataDict,
                this.textElementLineMindElementDataDict,
                this.generalizationLineMindElementDataDict,
                this.generalizationMindElementDataDict,
                this.explainMindElementDataDict);
        } else {
            this.delegate.callbackResetDatas(
                this.rootTreeNode,
                this.lineMindElementDataDict,
                this.mainMindElementDataDict,
                this.textElementLineMindElementDataDict,
                this.generalizationLineMindElementDataDict,
                this.generalizationMindElementDataDict,
                this.explainMindElementDataDict);
        }

    }

    getGeneralizationLineData(node) {
        let keys = this.generalizationLineMindElementDataDict.keys()
        for (let index = 0; index < keys.length; index++) {
            let data = this.generalizationLineMindElementDataDict.get(keys[index]);
            if (data.type == MindElementType.LEFTBRACELINE && data.parentNodeId == node.value.id &&
                data.lineContent.targetId == node.value.id) {
                return data;
            }
        }
        return new MindElementData();
    }

    getMainGeneralizationNode(data) {
        if (this.generalizationMindElementDataDict == null || data == null || data.isEmpty()) {
            return new LineMindTypeNode();
        }
        if (this.generalizationMindElementDataDict.containsKey(data.id) ||
            data.type == MindElementType.CONTENT_GENERALIZATION) {
            return this.generalizationMindElementDataDict.get(data.id);
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        if (parentNode.isEmpty()) {
            return new LineMindTypeNode();
        }
        return this.getMainGeneralizationNode(parentNode.value);
    }

    getReferenceLine(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return new MindElementData();
        }
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (parentNode.isEmpty()) {
            return new MindElementData();
        }
        let length = parentNode.children.length
        for (let index = 0; index < length; index++) {
            let cell = parentNode.children[index]
            if (cell.value.id != id && this.textElementLineMindElementDataDict.containsKey(cell.value.id)) {
                return this.textElementLineMindElementDataDict.get(cell.value.id);
            }
        }
        if (this.textElementLineMindElementDataDict.containsKey(parentNode.value.id)) {
            return this.textElementLineMindElementDataDict.get(parentNode.value.id);
        }
        if (this.isGeneralizatioData(node.value)) {
            let generalizatioNode = this.getMainGeneralizationNode(node.value)
            let line = this.getGeneralizationLineData(generalizatioNode)
            if (!line.isEmpty()) {
                return line;
            }
        }
        return new MindElementData();
    }

    getReferenceLineInBrother(id){
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return new MindElementData();
        }
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (parentNode.isEmpty()) {
            return new MindElementData();
        }

        let len = parentNode.children.length;
        for (let index = 0; index < len; index++) {
            let cell = parentNode.children[index];
            if (cell.value.id != id && this.textElementLineMindElementDataDict.containsKey(cell.value.id)) {
                return this.textElementLineMindElementDataDict.get(cell.value.id);
            }
        }
        let idArr = this.textElementLineMindElementDataDict.keys();
        let idLen = idArr.length;
        for (let index = 0; index < idLen; index++) {
            let nodeId = idArr[index];
            let lineNode = this.getNodeById(nodeId);
            if (lineNode.value.level == node.value.level) {
                return this.textElementLineMindElementDataDict.get(nodeId);
            }
        }
        return new MindElementData();
    }

    setNodeWidth(node, newAddWidth) {
        if (node.isEmpty()) {
            return;
        }
        node.value.width = node.value.width + newAddWidth;
        let data = node.value;
        if (data.imageContent != null) {
            data.imageContent.x = data.imageContent.x + newAddWidth / 2;
        }
        if (data.textContent != null) {
            data.textContent.x = data.textContent.x + newAddWidth / 2;
        }
        if (data.generalizationContent != null) {
            data.generalizationContent.x = data.generalizationContent.x + newAddWidth / 2;
        }
        if (data.iconElementContents != null) {
            let iconCount = data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    data.iconElementContents[index].x = data.iconElementContents[index].x + newAddWidth / 2;
                }
            }
        }
        if (data.linkElementContent != null) {
            data.linkElementContent.x = data.linkElementContent.x + newAddWidth / 2;
        }
        if (data.remarksElementContent != null) {
            data.remarksElementContent.x = data.remarksElementContent.x + newAddWidth / 2;
        }
    }

    setNodeHeight(node, newAddHeight, moveElement) {
        if (node.isEmpty()) {
            return;
        }
        node.value.height = node.value.height + newAddHeight;
        let data = node.value;
        let moveY = moveElement ? newAddHeight / 2 : newAddHeight;
        if (data.imageContent != null) {
            data.imageContent.y = data.imageContent.y + moveY;
        }
        if (data.textContent != null) {
            data.textContent.y = data.textContent.y + moveY;
        }
        if (data.generalizationContent != null) {
            data.generalizationContent.y = data.generalizationContent.y + moveY;
        }
        if (data.iconElementContents != null) {
            let iconCount = data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    data.iconElementContents[index].y = data.iconElementContents[index].y + moveY;
                }
            }
        }
        if (data.linkElementContent != null) {
            data.linkElementContent.y = data.linkElementContent.y + moveY;
        }
        if (data.remarksElementContent != null) {
            data.remarksElementContent.y = data.remarksElementContent.y + moveY;
        }
    }

    setEncircleSizePoint() {
        if (!this.isCalculeMindDataSize) {
            return;
        }

        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        let encircleMindElementDataDictArrLen = encircleMindElementDataDictArr.length
        for (let index = 0; index < encircleMindElementDataDictArrLen; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                let size = MindElementCalculation.caluleText(mind.lineContent.textContent.text, mind.lineContent.textContent.textFontSize, mind.lineContent.textContent.textBold);
                mind.lineContent.textContent.width = size.getWidth() + new EncircleNodesPointsCalculation().textSpace * 3;
                mind.lineContent.textContent.height = size.getHeight() + new EncircleNodesPointsCalculation().textSpace;
                if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_LEFT ||
                    mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE ||
                    mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    mind.lineContent.textContent.width = mind.lineContent.textContent.width + mind.lineContent.textContent.height ;
            }
            }
        }
    }

    isTopNode(id, ids) {
        if (ids.isEmpty() || ids.length == 1) {
            return true;
        }
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return true;
        }
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if ((!parentNode.children.isEmpty())) {
            for (let index = 0; index < parentNode.children.length; index++) {
                let childNode = parentNode.children[index];
                for (let idIndex in ids) {
                    if (childNode.value.id == ids[idIndex]) {
                        return ids[idIndex] == id;
                    }
                }
            }
        }
        return false;
    }

    isBottomNode(id, ids) {
        if (ids.isEmpty() || ids.length == 1) {
            return true;
        }
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return true;
        }
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if ((!parentNode.children.isEmpty())) {
            for (let index = parentNode.children.length - 1; index >= 0; index--) {
                let childNode = parentNode.children[index];
                for (let idIndex in ids) {
                    if (childNode.value.id == ids[idIndex]) {
                        return ids[idIndex] == id;
                    }
                }
            }
        }
        return false;
    }

    getNodeExplainHeight(data) {
        if (data.isEmpty() || !data.isContainExplainContent()) {
            return 0;
        }

        let size = MindElementCalculation.caluleText(data.explain, Config.NodeExplainFontSize, false);
        return size.getHeight() + Config.NodeExplainPadding * 2;
    }

    getNodeHeight(data) {
        if (data.isContainExplainContent()) {
            return data.height + this.getNodeExplainHeight(data) + Config.NodeExplainTop;
        } else {
            return data.height;
        }
    }


    getSubjectLineWidth() {
        if (this.rootTreeNode == null ||
            this.rootTreeNode.isEmpty() ||
            this.rootTreeNode.children.isEmpty()) {
            return 0;
        }
        let lineWidth = 0;
        let rootTreeNodeChildrenLength = this.rootTreeNode.children.length;
        for (let index = 0; index < rootTreeNodeChildrenLength; index++) {
            let node = this.rootTreeNode.children[index];
            let line = this.getNodeLine(node.value.id);
            if (!line.isEmpty() && line.lineContent != null && line.lineContent.lineWidth > lineWidth) {
                lineWidth = (line.lineContent.lineWidth);
                if (line.lineContent.lineThicken) {
                    lineWidth += Config.getLineFullWidth(line.lineContent.lineWidth);
                }
            }
        }
        return lineWidth;
    }

    getNodeLine(id) {
        if (this.textElementLineMindElementDataDict.containsKey(id)) {
            return this.textElementLineMindElementDataDict.get(id);
        } else {
            return new MindElementData();
        }
    }

    resetNodeLayout(node) {
        let elementLayout = this.getNodeLayout(node);
        elementLayout.layout(true);
        let nodeChildCount = node.children.length;
        for (let index = 0; index < nodeChildCount; index++) {
            let child = node.children[index];
            elementLayout.setLayoutDataPointMapInResetLayout(child);
        }
        return elementLayout;
    }

    setFloatExplainPoint( isChange) {
        // let explainMindElementDataDictKeys = this.explainMindElementDataDict.keys();
        // let explainMindElementDataDictKeysCount = explainMindElementDataDictKeys.length;
        // for (let index = 0; index < explainMindElementDataDictKeysCount; index++) {
        //     let key = explainMindElementDataDictKeys[index];
        //     let mindElementData = this.explainMindElementDataDict.get(key)

        //     let parentNode = this.getNodeById(mindElementData.parentNodeId);
        //     if (!mindElementData.isEmpty() &&
        //             !parentNode.isEmpty()) {
        //         mindElementData.isHidden = parentNode.value.isHidden
        //     }

        //     if (mindElementData.isEmpty() ||
        //             parentNode.isEmpty() ||
        //             parentNode.value.isHidden) {
        //         continue;
        //     }
        //     mindElementData.isHidden = false
        //     let borderWidth = new UiUtil().dip2px(1);
        //     if (parentNode.value.borderWidth > 0 && !Colors.isClear(parentNode.value.borderColor)) {
        //         borderWidth += parentNode.value.borderWidth;
        //     }
        //     if (mindElementData.borderWidth > 0 && !Colors.isClear(mindElementData.borderColor)) {
        //         borderWidth += mindElementData.borderWidth;
        //     }
        //     mindElementData.layout = this.getLayoutType();
        //     mindElementData.x = parentNode.value.x + (parentNode.value.width - mindElementData.width)/2;
        //     mindElementData.y = parentNode.value.y - mindElementData.height - Config.NodeFloatExplainSpace - borderWidth;
        //     mindElementData.lineContent.height = Config.NodeFloatExplainSpace;
        //     mindElementData.lineContent.width = Config.NodeFloatExplainWidth;
        //     mindElementData.lineContent.orientation = this.getFloatExplainLineOrientation();
        // }
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_RIGHT;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }

    setLayoutDataPointMapInResetLayout(node) {
        if (node.isEmpty()) {
            return;
        }
        this.setLayoutDataPointMap(node.value);
        let lineData = this.getLineData(node);
        if (!lineData.isEmpty()) {
            this.setLayoutDataPointMap(lineData);
        }
        let generalizationNode = this.getNodeGeneralization(node);
        if (!generalizationNode.isEmpty()) {
            this.setLayoutDataPointMap(generalizationNode.value);
            let generalizationLineData = this.getGeneralizationLineData(generalizationNode);
            if (!generalizationLineData.isEmpty()) {
                this.setLayoutDataPointMap(generalizationLineData);
            }
            let generalizationNodeCount = generalizationNode.children.length;
            for (let index = 0; index < generalizationNodeCount; index++) {
                let generalizationChild = generalizationNode.children[index];
                this.setLayoutDataPointMapInResetLayout(generalizationChild);
            }
        }
        let nodeChildCount = node.children.length;
        for (let index = 0; index < nodeChildCount; index++) {
            let child = node.children[index];
            this.setLayoutDataPointMapInResetLayout(child);
        }
    }

    setLayoutDataPointMap(data) {
        this.layoutDataPointMap.put(data.id, data);
    }

    moveLayoutDataPoint(moveX, moveY) {
        if ((moveX == 0 && moveY == 0) || this.layoutDataPointMap.isEmpty()) {
            return;
        }
        let values = this.layoutDataPointMap.values();
        let layoutDataPointMapValuesCount = values.length;
        for (let index = 0; index < layoutDataPointMapValuesCount; index++) {
            let mind = values[index];
            if (mind.isEmpty() || mind.isHidden) {
                continue;
            }
            mind.x += moveX;
            mind.y += moveY;
        }
    }

    resetLayoutData(layout, node, moveX, moveY) {
        if (layout.layoutDataPointMap.isEmpty()) {
            return;
        }
        let move = false;
        if ((moveX != 0 ||
            moveY != 0) &&
            (!node.children.isEmpty() &&
                !node.children[0].value.isHidden &&
                layout.layoutDataPointMap.containsKey(node.children[0].value.id))) {
            layout.moveLayoutDataPoint(moveX, moveY);
            move = true;
        }
        if ((moveX != 0 ||
            moveY != 0) &&
            !move) {
            let generalizationData = layout.getNodeGeneralization(node);
            if (!generalizationData.isEmpty()) {
                layout.moveLayoutDataPoint(moveX, moveY);
                // move = true;
            }
        }
        if (!layout.layoutDataPointMap.isEmpty()) {
            let values = layout.layoutDataPointMap.values()
            let layoutDataPointMapValuesCount = values.length;
            for (let index = 0; index < layoutDataPointMapValuesCount; index++) {
                let mind = values[index];
                this.layoutDataPointMap.put(mind.id, mind);
            }
        }
    }

    isLayout(data) {
        return !this.layoutDataPointMap.isEmpty() && this.layoutDataPointMap.containsKey(data.id);
    }

    getNodeLayout(node) {
        return this.getNodeLayout1(node.value.layout, node, true);
    }

    getNodeLayout1(layout, node, isIncludeGeneralization) {
        // if(arguments.length == 1){
        //     return this.getNodeLayout1(node.value.layout, node, true);
        // }else{
            if (this.mindLayoutSelect == null) {
                return null
            }
            let elementLayout = this.mindLayoutSelect.getNodeLayout(node, layout); 
            
            if (isIncludeGeneralization) {
                elementLayout.setDatas(node,
                    this.lineMindElementDataDict,
                    this.mainMindElementDataDict,
                    this.textElementLineMindElementDataDict,
                    this.generalizationLineMindElementDataDict,
                    this.generalizationMindElementDataDict,
                    this.explainMindElementDataDict,
                    this.mindMapStyleColor, this.mindBGColor, this.settingData);
            } else {
                elementLayout.setDatas(node,
                    this.lineMindElementDataDict,
                    this.mainMindElementDataDict,
                    this.textElementLineMindElementDataDict,
                    new HashMap(),
                    new HashMap(),
                    this.explainMindElementDataDict,
                    this.mindMapStyleColor, this.mindBGColor,
                    this.settingData);
            }
    
            elementLayout.mindLayoutSelect = this.mindLayoutSelect
            elementLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
            elementLayout.globalLayout = this.globalLayout;
            elementLayout.isCalculeMindDataSize = false;
            elementLayout.initConfig();
            return elementLayout;
        // }
        
    }

    isRootNode(node) {
        if ((typeof node) == "number") {
            node = this.getNodeById(node)
        }
        if (node == null || this.rootTreeNode == null) {
            return false;
        }
        return node.value.id == this.rootTreeNode.value.id;
    }

    isGeneralizatioData(data) {
        if (this.generalizationLineMindElementDataDict == null || data == null || data.isEmpty()) {
            return false;
        }
        if (this.generalizationMindElementDataDict.containsKey(data.id) ||
            data.type == MindElementType.CONTENT_GENERALIZATION) {
            return true;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        if (parentNode.isEmpty()) {
            return false;
        }
        return this.isGeneralizatioData(parentNode.value);
    }

    getNodeOrdreChildByVertical(node) {
        var list = new Array();
        if (node == null || node.isEmpty() || node.children.isEmpty()) {
            return list;
        }
        for (let index = 0; index < node.children.length; index++) {
            list.push(node.children[index])
        }
        list.sort(function (data1, data2) {
            if (data1.value.y == data2.value.y) {
                return 0;
            } else if (data1.value.y < data2.value.y) {
                return -1;
            } else {
                return 1;
            }
        })
        return list;
    }

    addSubjectSpcaeVertical(value) {
        return value + this.settingData.subjectSpcaeVertical;
    }

    addSonSubjectSpcaeVertical(value) {
        return value + this.settingData.sonSubjectSpcaeVertical;
    }

    addSubjectSpcaeHorizontal(value) {
        return value + this.settingData.subjectSpcaeHorizontal;
    }

    addSonSubjectSpcaeHorizontal(value) {
        return value + this.settingData.sonSubjectSpcaeHorizontal;
    }

    setLineThicken(line) {
        if ((line.type != MindElementType.LINE &&
                line.type != MindElementType.LAYOUT_FISH_RIGHT_LINE &&
                line.type != MindElementType.LAYOUT_CUSTOM_LINE) ||
                line.lineContent == null) {
            return;
        }
        line.lineContent.lineThicken = this.settingData.lineThicken;
    }

    setBorderThicken(node) {
        if (node.isEmpty()) {
            return;
        }
        let parent = this.getNodeById(node.value.parentNodeId);
        if ((node.children.isEmpty() || node.value.hiddenNumber > 0) &&
                this.settingData.lineEndThicken &&
                !node.value.isHidden &&
                parent.value.layout != NodeLayoutType.LAYOUT_BOTTOM &&
                parent.value.layout != NodeLayoutType.LAYOUT_TOP) {
            node.value.borderThicken = true;
        } else {
            node.value.borderThicken = false;
        }
    }

    getNodeFloatExplainHeight( node) {
        let floatExplainData = this.getNodeExplain(node);
        if (!floatExplainData.isEmpty()) {
            if (!Colors.isClear(floatExplainData.borderColor)) {
                return floatExplainData.height + Config.NodeFloatExplainSpace + floatExplainData.borderWidth;
            } else {
                return floatExplainData.height + Config.NodeFloatExplainSpace;
            }
        }
        return 0;
    }

    isGeneralizationTargetDataInEncircle(data) {
        if (data == null || data.isEmpty() || 
            data.type != MindElementType.CONTENT_GENERALIZATION ||
            data.generalizationContent == null || 
            data.generalizationContent.targetIds == null) {
            return false
        }
        let generalizationContent = data.generalizationContent;
        for (let targetIndex = 0; targetIndex < generalizationContent.targetIds.length; targetIndex++) {
            let targetNode = this.getNodeById(generalizationContent.targetIds[targetIndex]);

            if (targetNode.isEmpty() || targetNode.value.isHidden) {
                continue
            }
            if (this.getEncircleSpaceAndPadding(targetNode.value) > 0) {
                return true
            }
        }
        return false
    }


    getEncircleSpaceAndPadding(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        let length = encircleMindElementDataDictArr.length
        for (let index = 0; index < length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    return new EncircleNodesPointsCalculation().space + mind.lineContent.padding
                }
            }
        }
        let parent = this.getNodeById(data.parentNodeId)
        if (parent.isEmpty() || parent.value.isHidden) {
            return 0
        } else {
            return this.getEncircleSpaceAndPadding(parent.value)
        }
        return 0;
    }

    getFormLineData() {
        let keys = this.lineMindElementDataDict.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let data = this.lineMindElementDataDict.get(keys[index]);
            if (data.type == MindElementType.FORM_LINE && 
                data.lineContent != null && 
                data.lineContent.targetId == this.rootTreeNode.value.id) {
                return data;
            }
        }
        return new MindElementData();
    }

    hideRootFormLine() {
        let line = this.getFormLineData();
        if (!line.isEmpty()) {
            line.isHidden = true;
        }
        let keys = this.lineMindElementDataDict.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let formLine = this.lineMindElementDataDict.get(keys[index]);
            if ((formLine.type == MindElementType.FORM_LINE) && formLine.lineContent != null) {
                let targetNode = this.getNodeById(formLine.lineContent.targetId);
                let targetParentNode = this.getNodeById(targetNode.value.parentNodeId);
                if ((targetNode.value.layout != NodeLayoutType.LAYOUT_FORM && targetNode.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) ||
                        (!targetParentNode.isEmpty() && (targetParentNode.value.layout == NodeLayoutType.LAYOUT_FORM || targetParentNode.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL))) {
                    formLine.isHidden = true;
                }
            }
        }
    }

    isUnderline(data) {
        return data != null && (data.mindElementShape == MindElementShapeType.Underline || data.mindElementShape == MindElementShapeType.Two_Underline);
    }

    isBackgroundAndBorder(data) {
        if (data == null) {
            return false
        }
        return this.isBorderColor(data) || this.isBackgroundColor(data);
    }

    isBackgroundColor(data) {
        if (data == null) {
            return false
        }
        return !Colors.isClear(data.backgroundColor);
    }

    isBorderColor(data) {
        if (data == null) {
            return false
        }
        return data.borderWidth > 0 && !Colors.isClear(data.borderColor);
    }

    getNodeHorizontalSpace(node, parentNode) {
        if (parentNode == null || node == null || parentNode.isEmpty() || node.isEmpty() || parentNode.children.isEmpty()) {
            return this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee);
        }
        let lineData = this.getLineData(parentNode.children[0]);
        let lineLayout = this.getNodeLineLayout(node, lineData);
        let nodeList = new NodesRectCalculation().getNodesForHeight(parentNode);
        
        if (parentNode.value.type == MindElementType.MAIN_SUBJECT) {           
            if (parentNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                    parentNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                let space = this.addSubjectSpcaeHorizontal(Config.BracketsNodeHorizontalSpacee + Config.BracketsRightSpacee) + this.getNodeContainEncircleLine(node, parentNode);
                if (nodeList.length > 20 && parentNode.children.length > 1) {
                    if (lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                            space = space + Math.min(4 * (nodeList.length - 8), 50)
                    } else if (lineLayout == LineLayout.RIGHT_ANGLE_LINE ||
                        lineLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE) {
                            space = space + Math.min(4 * (nodeList.length - 8), 40)
                    } else if (lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.RIGHT_ANGLE_LINE ||
                        lineLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                        lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                        lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                        lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                            space = space + Math.min(4 * (nodeList.length - 8), 40)
                    }
                } else if (parentNode.children.length > 6) {
                    if (lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                            space = space + Math.min(6 * (parentNode.children.length - 6), 50)
                    } else if (lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.RIGHT_ANGLE_LINE ||
                        lineLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                        lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                        lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                        lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                        space = space + Math.min(4 * (parentNode.children.length - 6), 30)
                    }
                } else if (nodeList.length > 10) {
                    if (lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                            space = space + Math.min(4 * (nodeList.length - 8), 50)
                    } else if (lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.RIGHT_ANGLE_LINE ||
                        lineLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                        lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                        lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                        lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                            space = space + Math.min(4 * (nodeList.length - 8), 30)
                    }
                }
                return space;
            } else if (this.globalLayout == LineLayout.CURVE_LINE_AVERAGE) {
                return this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee * 2 + this.getSubjectLineWidth()) + this.getNodeContainEncircleLine(node, parentNode);
            } else if (this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) {
                if (!lineData.isEmpty() && lineData.lineContent != null) {
                    if (lineData.lineContent.lineThicken) {
                        return this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee + lineData.lineContent.lineWidth * 2 + this.getSubjectLineWidth()) + this.getNodeContainEncircleLine(node, parentNode);
                    } else {
                        return this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee + lineData.lineContent.lineWidth * 2 + this.getSubjectLineWidth()) + this.getNodeContainEncircleLine(node, parentNode);
                    }
                } else {
                    return this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee + 3 + this.getSubjectLineWidth()) + this.getNodeContainEncircleLine(node, parentNode);
                }
            } else if (this.globalLayout == LineLayout.STRAIGHT_LINE ||
                this.globalLayout == LineLayout.STRAIGHT_LINE_2) {
                let space = this.addSubjectSpcaeHorizontal(Config.NodeStraightLineHorizontalSpacee + this.getSubjectLineWidth()) + this.getNodeContainEncircleLine(node, parentNode);
                let baseSpcae = this.isFromLeftRightLayout ? 2 : 5;
                let maxSpace = this.isFromLeftRightLayout ? 10 : 60;

                if (nodeList.length > 12 && parentNode.children.length > 1) {
                    space = space + Math.min(baseSpcae * (nodeList.length - 8), maxSpace)
                } else if (parentNode.children.length > 6) {
                    if (lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                        space = space + Math.min(baseSpcae * (parentNode.children.length - 6), maxSpace)
                    }
                }
                return space;
            } else {
                let baseSpcae = this.isFromLeftRightLayout ? 2 : 5;
                let maxSpace = this.isFromLeftRightLayout ? 10 : 60;
                let space = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee +this.getSubjectLineWidth()) + this.getNodeContainEncircleLine(node, parentNode);
                if (nodeList.length > 10 && parentNode.children.length > 2) {
                    if (lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                        space = space + Math.min(baseSpcae * (nodeList.length - 6),  maxSpace)
                    }
                } else if (parentNode.children.length > 6) {
                    if (lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                        space = space + Math.min(baseSpcae * (parentNode.children.length - 6), maxSpace)
                    }
                }
                return space;
            }
        } else {
            let lineData = this.getLineData(node);
            let lineLayout = this.getNodeLineLayout(node, lineData);
            if (parentNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                    parentNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                let space =  this.addSonSubjectSpcaeHorizontal(Config.BracketsSonNodeHorizontalSpacee + Config.BracketsRightSpacee) + this.getNodeContainEncircleLine(node, parentNode);
                if (parentNode.children.length > 6) {
                    if (lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                        lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                        space = space + Math.min(3 * (parentNode.children.length - 6), 40)
                    }
                } else if (nodeList.length > 10) {
                    if (lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                        lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                            space = space + Math.min(3 * (nodeList.length - 6), 40)
                    }
                }
                return space
            } else if (lineLayout == LineLayout.STRAIGHT_LINE ||
                    lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                    lineLayout == LineLayout.CURVE_LINE ||
                    lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                    lineLayout == LineLayout.CURVE_LINE_2 ||
                    lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                    lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                let space = this.addSonSubjectSpcaeHorizontal((Config.SonNodeHorizontalSpacee * 1.50)) + this.getNodeContainEncircleLine(node, parentNode);
                if (parentNode.children.length > 6) {
                    if (lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                        lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                        space = space + Math.min(3 * (parentNode.children.length - 6), 40)
                    }
                } else if (nodeList.length > 10) {
                    if (lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                        lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                            space = space + Math.min(3 * (nodeList.length - 6), 40)
                    }
                }
                return space;
            } else if (lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                        lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                return this.addSonSubjectSpcaeHorizontal((Config.SonNodeHorizontalSpacee * 1.20)) + this.getNodeContainEncircleLine(node, parentNode);
            } else {
                return this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee) + this.getNodeContainEncircleLine(node, parentNode);
            }
        }
    }

    getNodeContainEncircleLine(node, parentNode) {
        let space = 0;
        if (parentNode.isEmpty() || node.isEmpty() || node.value.parentNodeId != parentNode.value.id) {
            return space;
        }
        let margin = new EncircleNodesPointsCalculation().space;
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();

        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            var targetIds = [];
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.add(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }

            for (let idIndex = 0; idIndex < targetIds.length; idIndex++) {
                let id = targetIds[idIndex]
                var find = false;
                for (let childIndex = 0; childIndex < parentNode.children.length; childIndex++) {
                    let child = parentNode.children[childIndex]
                    if (child.value.id == id) {
                        find = true;
                        break;
                    }
                }
                if (find) {
                    space = space + margin;
                    break;
                }
            }
            if (space > 0) {
                break;
            }
        }
        return space;
    }

    getTextEdgeInsets(data) {
        switch (data.type) {
            case MindElementType.MAIN_SUBJECT:
                return Config.MainInputUIEdgeInsets;
            case MindElementType.SUBJECT:
                return Config.SubjectInputUIEdgeInsets;
            case MindElementType.CONTENT_GENERALIZATION:
                return Config.GeneralizationInputUIEdgeInsets;
            case MindElementType.SON_SUBJECT:
                return Config.SonSubjectInputUIEdgeInsets;
            case MindElementType.EXPLAIN:
                return Config.ExplainInputUIEdgeInsets;
            default:
                return Config.SubjectInputUIEdgeInsets;
        }
    }
}

export default BaseLayout
let ImageShapeType = {
    Square:1,
    Circular:2,
    Diamond:3,

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.Square;
    }
}

export default ImageShapeType